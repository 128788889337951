import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganization, getEnumeration, CreatePos, GetOrgPos, GetPos, clearOrgPOS } from '../actions'
import PaymentElements from '../components/PaymentElements'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    paymentelementsread: (state.organizations.role && state.organizations.role.paymentelementsread) || false,
    paymentelementswrite: (state.organizations.role && state.organizations.role.paymentelementswrite) || false,
    organizationPos: state.organizations.organizationPos || [],
    detailedPos: state.organizations.detailedPos || {},
    detailedPosRoutes: state.organizations.detailedPos || {},
    environment: state.appstate.environment || 'staging',
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    CreatePos: (params, reqBody) => dispatch(CreatePos(params, reqBody)),
    GetOrgPos: (params, query) => dispatch(GetOrgPos(params, query)),
    GetPos: (params) => dispatch(GetPos(params)),
    clearOrgPOS: () => dispatch(clearOrgPOS()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentElements)