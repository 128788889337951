import axios from 'axios';

export const fetchApi = (env, apiRoutes, method, params, reqBody, query) => {
    method = method.toUpperCase();
    if (params) {
        let apiArr = apiRoutes.split('/')
        // console.log('apiArr => ', apiArr);
        apiRoutes = apiArr.map((queryBreakDown) => {
            let value
            if (queryBreakDown.indexOf(':') > -1) {
            queryBreakDown = queryBreakDown.replace(':', '');
            if (!params) throw Error('No Params');
            value = params[`${queryBreakDown}`];
            } else {
            value = queryBreakDown
            }
            return value
        })
        // console.log('apiRoutes1 => ', apiRoutes);
        apiRoutes = apiRoutes.join('/')
        // console.log('apiRoutes2 => ', apiRoutes);
    }
    // console.log("reqBody => ", reqBody);
    let data = reqBody ? reqBody : null
    const accessToken = window.localStorage.getItem('libJwtAccess')
    let headers = {};
    if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
    }
    headers['Content-Type'] =  'application/json';
    const host = env === 'production' ? process.env.REACT_APP_PRODUCTION_BALANCES_REPORT_BACKEND_URL : process.env.REACT_APP_STAGING_BALANCES_REPORT_BACKEND_URL

    const response = axios({
        method: method,
        baseURL: host,
        url: apiRoutes,
        headers,
        data
    });
    // console.log('response : ', response)
    return response
}
