import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganization, getEnumeration, 
    getDispute, updateDispute, postDisputesSearch, postDisputesSearchAfter, 
    clearDisputesSearch, updateDisputesSearch} from '../actions'
import DisputesManagement from '../components/DisputesManagement'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    disputesread: (state.organizations.role && state.organizations.role.disputesread) || false,
    disputeswrite: (state.organizations.role && state.organizations.role.disputeswrite) || false,
    organizationPos: state.organizations.organizationPos || [],
    nextToken: (state.disputeManagement && state.disputeManagement.nextToken) || "",
    hits: (state.disputeManagement && state.disputeManagement.hits) || [],
    totalHits: (state.disputeManagement && state.disputeManagement.totalHits) || [],
    environment: state.appstate.environment || 'staging',
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    getDispute: (params) => dispatch(getDispute(params)),
    updateDispute: (params, reqBody) => dispatch(updateDispute(params, reqBody)),
    clearDisputesSearch: () => dispatch(clearDisputesSearch()),
    updateDisputesSearch: (reqBody) => dispatch(updateDisputesSearch(reqBody)),
    postDisputesSearch: (reqBody) => dispatch(postDisputesSearch(reqBody)),
    postDisputesSearchAfter: (reqBody) => dispatch(postDisputesSearchAfter(reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisputesManagement)