import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganization, getEnumeration, 
    postPayoutsSearch, postPayoutsSearchAfter, clearPayoutsSearch, updatePayoutsSearch,
    getPayoutSchedules, createPayoutSchedule, updatePayoutSchedule 
} from '../actions'
import PayoutsReport from '../components/PayoutsReport'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    payoutsread: (state.organizations.role && state.organizations.role.payoutsread) || false,
    payoutswrite: (state.organizations.role && state.organizations.role.payoutswrite) || false,
    search_after: state.payoutsReport.search_after || [],
    // hits: (state.payoutsReport && state.payoutsReport.hits) || [],
    // totalHits: (state.payoutsReport && state.payoutsReport.totalHits) || [],
    nextToken: state.payoutsReport.nextToken || "",
    hits: (state.payoutsReport && state.payoutsReport.hits) || [],
    payoutSchedules: (state.payoutsReport && state.payoutsReport.payoutSchedules) || [],
    environment: state.appstate.environment || 'staging',
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    postPayoutsSearch: (reqBody) => dispatch(postPayoutsSearch(reqBody)),
    postPayoutsSearchAfter: (reqBody) => dispatch(postPayoutsSearchAfter(reqBody)),
    clearPayoutsSearch: () => dispatch(clearPayoutsSearch()),
    updatePayoutsSearch: (reqBody) => dispatch(updatePayoutsSearch(reqBody)),
    getPayoutSchedules: (params) => dispatch(getPayoutSchedules(params)),
    createPayoutSchedule: (params, reqBody) => dispatch(createPayoutSchedule(params, reqBody)),
    updatePayoutSchedule: (params, reqBody) => dispatch(updatePayoutSchedule(params, reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayoutsReport)