function s3(state ={isLoading: false}, action) {
    switch (action.type) {
        case 'GET_PRESIGNED_URL_REQUEST':
            // console.log("GET_PRESIGNED_URL_REQUEST", action);
            return {...state, isLoading: true} 
        case 'GET_PRESIGNED_URL_SUCCESS':
            // console.log("GET_PRESIGNED_URL_SUCCESS", action);
            const newUrl = {...state.s3, ...action.data}
            return {...state, isLoading: false, preSignedUrl: newUrl} 
        case 'GET_PRESIGNED_URL_FAILURE':
            // console.log("GET_PRESIGNED_URL_FAILURE", action);
            return {...state, isLoading: false}
        case 'RESET_PRESIGNED_URL':
            return {...state, isLoading: false, preSignedUrl: null} 
      default:
        return state
    }
  }

export default s3