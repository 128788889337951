import React, { useEffect, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import rocket from '../../src/rocket.png';
import Slide from '@material-ui/core/Slide';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { fetchApi } from '../app/utils/callSSOApi';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const axios = require('axios');
const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);

const ButtonMod = withStyles({
    root: {
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
    },
})(Button);

const TextFieldMod = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(TextField);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const useStyles = makeStyles(theme => ({
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    label: {
        color: '#7f7f7f'
    },
    poslist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    card: {
        display: 'flex',
        minHeight: '17em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        position: 'absolute',
        bottom: 0,
    },
    add: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        textTransform: 'none'
    },
    imageRight: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: '-1em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const UserProfile = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont
    } = props
    const theme = useTheme();

    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
        if (appbarIsWhiteFont) toggleAppbarWhiteFont(false)
        return function cleanup() {
        };
    },[]);

    const history = useHistory();

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [passwordFailed, setPasswordFailed] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [values, setValues] = useState({
        showOldPassword: false,
        showNewPassword: false,
        showNewPasswordConfirm: false,
      });

    const checkPasswordFailed = (password) => {
        // Min Password Length
        if (password.length < 8) return true
        // Must Include Numeral
        // Must Include One UpperCase
        // Must Include One LowerCase
        // Must Include One Symbol
        let passw = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        if(password.match(passw)) {
            return false
        } else {
            return true
        }
    }

    const handleChangePassword = () => {
        setIsLoading(true)
        if (newPassword !== newPasswordConfirm) {
            setIsLoading(false)
            alert("New Passwords Don't Match")
            return
        }
        let passWordFailed = checkPasswordFailed(newPassword)
        if (passWordFailed) {
            setIsLoading(false)
            alert("Password Complexity Not Strong Enough")
            return
        }
        try {
            fetchApi(
                '/password/change',
                'patch',
                null,
                {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                }
                ).then((result) => {
                    console.log('password change result => ', result);
    
                    const { data } = result
                    if (data.message) {
                        alert('Invalid Old Password')
                    } else if (data === "OK") {
                        alert('Password Updated!')
                    }
                    setOldPassword('')
                    setNewPassword('')
                    setNewPasswordConfirm('')
                    setIsLoading(false)
            })

        } catch (err) {
            setIsLoading(false)
            alert(err)
        }
    }

    return (
    <main className={classes.topMargin}>
    <Typography variant="h4" className={classes.Title}>Profile Settings</Typography>
    <AppBarMod position="static" color="default">
      </AppBarMod>
        <Container>
        <div className={classes.poslist}>
            <div>
            <Grid container spacing={3}>
                {/* Password */}
                <Grid item md={12} xs={12} sm={12}>
                <Accordion expanded={isExpanded}>
                <AccordionSummary
                    onClick={() => {setIsExpanded(!isExpanded)}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Change Password</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Old Password</Typography>
                            <OutlinedInputMod
                                type={values.showOldPassword ? 'text' : 'password'}
                                fullWidth 
                                onChange={(e) => setOldPassword(e.target.value)} 
                                defaultValue={oldPassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => { 
                                            let currentVal = JSON.parse(JSON.stringify(values))
                                            setValues({ ...currentVal, showOldPassword: !currentVal.showOldPassword })
                                        }}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                            />
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>New Password</Typography>
                            <OutlinedInputMod
                                type={values.showNewPassword ? 'text' : 'password'}
                                fullWidth 
                                onChange={(e) => {
                                    const passFailed = checkPasswordFailed(e.target.value)
                                    if (passFailed) {
                                        setPasswordFailed(true)
                                    } else {
                                        setPasswordFailed(false)
                                    }
                                    setNewPassword(e.target.value)}
                                } 
                                defaultValue={newPassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => { 
                                            let currentVal = JSON.parse(JSON.stringify(values))
                                            setValues({ ...currentVal, showNewPassword: !currentVal.showNewPassword })
                                        }}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                            />
                        </Grid>
                        {passwordFailed && <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="error" gutterBottom>Min Password Length is 8</Typography>
                            <Typography className={classes.title} color="error" gutterBottom>Must Include at least 1 Numeric</Typography>
                            <Typography className={classes.title} color="error" gutterBottom>Must Include at least 1 UpperCase</Typography>
                            <Typography className={classes.title} color="error" gutterBottom>Must Include at least 1 LowerCase</Typography>
                        </Grid>}
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>New Password Confirmation</Typography>
                            <OutlinedInputMod
                                type={values.showNewPasswordConfirm ? 'text' : 'password'}
                                fullWidth 
                                onChange={(e) => setNewPasswordConfirm(e.target.value)} 
                                defaultValue={newPasswordConfirm}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => { 
                                            let currentVal = JSON.parse(JSON.stringify(values))
                                            setValues({ ...currentVal, showNewPasswordConfirm: !currentVal.showNewPasswordConfirm })
                                        }}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {values.showNewPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                            />
                        </Grid>
                        {newPasswordConfirm !== newPassword && <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="error" gutterBottom>New Passwords Don't Match</Typography>
                        </Grid>}
                        <Grid item md={12} xs={12} sm={12}>
                            <ButtonMod onClick={() => {
                                    handleChangePassword()
                                    setIsExpanded(false)
                                }}>
                                CHANGE
                            </ButtonMod>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
                </Grid>
                {/* List of POS */}
            </Grid>
            </div>
        </div>
        </Container>
        <Backdrop className={classes.backdrop} open={isLoading} >
                <CircularProgress className={classes.circularProgress} />
        </Backdrop>
    </main>
    )
}

export default UserProfile