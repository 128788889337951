import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import { green, red, grey } from '@material-ui/core/colors';
import { drawerWidth } from '../app/globals'
import logo from '../logosmall.svg'
import { Link } from "react-router-dom"
import RootRef from '@material-ui/core/RootRef';
import { useHistory } from "react-router-dom";

const GreenSwitch = withStyles({
    switchBase: {
      color: green['A400'],
      '&$checked': {
        color: green['A400'],
      },
      '&$checked + $track': {
        backgroundColor: green['A400'],
      },
    },
    checked: {},
    track: {},
  })(Switch);

const FormControlField = withStyles({
    root: {
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '0px',
        },
    },
})(FormControl);

const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
    },
})(AppBar);

const useStyles = makeStyles((theme) => ({
    green: {
        color: '#fff',
        backgroundColor: green[900],
    },
    switchLive: {
        color: red['A400']
    },
    menuPosition:{
        marginTop: '2em'
    },
    switchTest: {
        color: grey['A700']
    },
    content: {
        color: '#ffffff',
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: drawerWidth,
    },
    contentGreen: {
        color: '#0c3336',
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: drawerWidth,
    },
    contentShift: {
        color: '#ffffff',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    contentShiftGreen: {
        color: '#0c3336',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    title: {
        fontSize: '1.4em',
        flexGrow: 1,
    },
    logo: {
        background: `${logo}`,
        minHeight: '100%',
        maxHeight: '2.1em',
        marginTop: '-0.2em'
    },
    companyDropdown: {
        flexGrow: 1,
        color: '#ffffff'
    },
    companyDropdownGreen: {
        flexGrow: 1,
        color: '#0c3336'
    },
    formControl: {
        color: '#ffffff',
        fontWeight: 700,
    },
    formControlGreen: {
        color: '#0c3336',
        fontWeight: 700,
    },
    selectGreen: {
        '& .MuiInput-underline': {
            border: 0,
            '&:hover': {
                border: 0,
              },
        },
        color: '#0c3336',
        '&::before': {
            border: 0,
            borderColor: '#ffffff',
        },
        '&::after': {
            border: 0,
            borderColor: '#ffffff',
        }
    },
    select: {
        '& .MuiInput-underline': {
            border: 0,
            '&:hover': {
                border: 0,
              },
        },
        color: grey['A100'],
        '&::before': {
            border: 0,
            borderColor: grey['A100'],
        },
        '&::after': {
            border: 0,
            borderColor: grey['A100'],
        }
    },
    icon: {
        fill: grey['A100'],
    },
    iconGreen: {
        fill: '#0c3336',
    },
  }));

const AppTopBar = (props) => {
  const classes = useStyles();
  const { openDrawer, toggleDrawer, appbarIsWhiteFont, user, setAppEnv, ssoInstance, setUser,
    appEnv, organizations, organization, clearOrgState } = props
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const domRef = React.createRef();
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [env, setEnvironment] = React.useState({
    live: true,
  });
  const handleChange = (event) => {
    clearOrgState()
    setEnvironment({ ...env, [event.target.name]: event.target.checked });
    if (event.target.checked) {
        setAppEnv('production')
        localStorage.setItem('environment', "production")
    }
    if (!event.target.checked) {
        setAppEnv('staging')
        localStorage.setItem('environment', "staging")
    }
    history.push({
        pathname: `/`,
    })
  };
  const renderOrgName = (org) => {
      const name = org ? org.name : ''
      return name
  }
  const backToMain = () => {
    clearOrgState()
    history.push({
        pathname: `/`,
    })
  }
  const goToUserProfile = () => {
    handleCloseMenu()
    history.push({
      pathname: `/user/${user.id}`,
    })
  }
  useEffect(() => {
    let targetEnv = localStorage.getItem('environment')
    if (targetEnv === 'staging') {
        setEnvironment({live: false})
        setAppEnv('staging')
    }
    if (targetEnv === 'production') {
        setAppEnv('production')
        setEnvironment({live: true})
    }
  },[])

//   console.log('AppBar openDrawer : ', openDrawer);
//   console.log('AppBar appbarIsWhiteFont : ', appbarIsWhiteFont);
//   console.log("user => ", user);
  return (
    <div
    >
      <AppBarMod position="absolute" color="transparent">
        <Toolbar
            className={clsx((appbarIsWhiteFont ? classes.content: classes.contentGreen), {
                [classes.contentShift]: (!openDrawer && appbarIsWhiteFont),
                [classes.contentShiftGreen]: (!openDrawer && !appbarIsWhiteFont),
            })}
        >
            {openDrawer && <FormControlField 
                className={appbarIsWhiteFont ? classes.formControl : classes.formControlGreen}
                >
                <Select
                    className={appbarIsWhiteFont ? classes.select : classes.selectGreen}
                    inputProps={{
                        classes: {
                            icon: appbarIsWhiteFont ? classes.icon : classes.iconGreen,
                            select: appbarIsWhiteFont ? classes.select : classes.selectGreen,
                        },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={(organization && organization.name) || ''}
                    renderValue={() => renderOrgName(organization)}
                >
                    <MenuItem key={'all'} onClick={() => backToMain()}>See all companies</MenuItem>
                    <Divider/>
                    {organizations && organizations.map((singleOrg)=> {
                        return <MenuItem key={singleOrg.organizationId} to={`/company/${singleOrg.organizationId}`} component={Link}>{singleOrg.organizationName}</MenuItem>
                    })}
                </Select>
            </FormControlField>}
            {openDrawer && <div className={appbarIsWhiteFont ? classes.companyDropdown : classes.companyDropdownGreen}></div>}
            {!openDrawer && <img alt={'liberalize'} className={classes.logo} src={logo}/>}
            {!openDrawer && <Typography variant="h6" className={classes.title}>
                Liberalize
            </Typography>}
            <GreenSwitch
                checked={env.live}
                onChange={handleChange}
                name="live"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            {env.live ? <Typography className={classes.switchLive} variant="h6">Live</Typography>: <Typography className={classes.switchTest} variant="h6">Test</Typography>}
            <RootRef rootRef={domRef}>
                <Button onClick={handleClickMenu} color="inherit">{(user && <Avatar className={classes.green}>{user && user.firstName && user.firstName[0].toUpperCase()}</Avatar>) || ''}</Button>
                <Menu
                    id="simple-menu"
                    className={classes.menuPosition}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    container={domRef.current}
                    onClose={handleCloseMenu}
                >
                    {user && <MenuItem onClick={goToUserProfile}>Profile Settings</MenuItem>}
                    {user && <MenuItem onClick={() => {
                        window.localStorage.libJwt = ""
                        window.localStorage.libJwtAccess = ""
                        setUser({})
                        handleCloseMenu()
                        window.location.replace(window.location.pathname + window.location.search);
                    }}>Sign Out</MenuItem>}
                </Menu>
            </RootRef>
        </Toolbar>
      </AppBarMod>
    </div>
  );
}

export default AppTopBar