import { connect } from 'react-redux'
import { 
    toggleDrawer, toggleAppbarWhiteFont, getOrganization, getEnumeration, postInvoicesSearch, 
    postInvoicesSearchAfter, clearInvoicesSearch, updateInvoice 
} from '../actions'
import InvoicesReport from '../components/InvoicesReport'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    invoicesread: (state.organizations.role && state.organizations.role.invoicesread) || false,
    invoiceswrite: (state.organizations.role && state.organizations.role.invoiceswrite) || false,
    nextToken: state.invoicesReport.nextToken || "",
    hits: (state.invoicesReport && state.invoicesReport.hits) || [],
    environment: state.appstate.environment || 'staging',
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    postInvoicesSearch: (reqBody) => dispatch(postInvoicesSearch(reqBody)),
    postInvoicesSearchAfter: (reqBody) => dispatch(postInvoicesSearchAfter(reqBody)),
    clearInvoicesSearch: () => dispatch(clearInvoicesSearch()),
    updateInvoice: (params, reqBody) => dispatch(updateInvoice(params, reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoicesReport)