import { connect } from 'react-redux'
import { toggleDrawer, setUser, getUserOrganizations, setSSO } from '../actions'
import App from '../App'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users.user,
    openBackdrop: state.misc.isLoading || state.apiKeys.isLoading || state.organizations.isLoading || state.s3.isLoading || state.users.isLoading || state.paymentsReport.isLoading || state.customerManagement.isLoading || state.productManagement.isLoading || state.payoutsReport.isLoading || state.invoicesReport.isLoading,
    appEnv: (state.appstate && state.appstate.environment) ? state.appstate.environment : 'staging'
})
  
const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    setUser: (user) => dispatch(setUser(user)),
    getUserOrganizations: () => dispatch(getUserOrganizations()),
    setSSO: (sso) => dispatch(setSSO(sso)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)