import { CALL_POS_API } from '../app/middleware/callPosApi.js';

export const getApiKeys = (params) => ({
    types: ['GET_APIKEYS_REQUEST', 'GET_APIKEYS_SUCCESS', 'GET_APIKEYS_FAILURE'],
    [CALL_POS_API]: '/pointofsales/:posId/keys',
    method: 'get',
    params: params,
})

export const createApiKeys = (params, reqBody) => ({
    types: ['CREATE_APIKEYS_REQUEST', 'CREATE_APIKEYS_SUCCESS', 'CREATE_APIKEYS_FAILURE'],
    [CALL_POS_API]: '/pointofsales/:posId/keys',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const deleteApiKeys = (params) => ({
    types: ['DELETE_APIKEYS_REQUEST', 'DELETE_APIKEYS_SUCCESS', 'DELETE_APIKEYS_FAILURE'],
    [CALL_POS_API]: '/pointofsales/:posId/keys/:keyId',
    method: 'delete',
    params: params,
})