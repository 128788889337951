import { CALL_API } from '../app/middleware/callApi.js';
import { CALL_POS_API } from '../app/middleware/callPosApi.js';

export const getUserOrganizations = () => ({
    types: ['GET_USER_ORGANIZATIONS_REQUEST', 'GET_USER_ORGANIZATIONS_SUCCESS', 'GET_USER_ORGANIZATIONS_FAILURE'],
    [CALL_API]: '/organizations',
    method: 'get'
})

export const createOrganization = (reqBody) => ({
    types: ['CREATE_ORGANIZATION_REQUEST', 'CREATE_ORGANIZATION_SUCCESS', 'CREATE_ORGANIZATION_FAILURE'],
    [CALL_API]: '/organizations',
    method: 'post',
    reqBody: reqBody
})

export const getOrganization = (params) => ({
    types: ['GET_ORGANIZATION_REQUEST', 'GET_ORGANIZATION_SUCCESS', 'GET_ORGANIZATION_FAILURE'],
    [CALL_API]: '/organizations/:organizationId',
    method: 'get',
    params: params,
})

export const patchOrganization = (params, reqBody) => ({
    types: ['PATCH_ORGANIZATION_REQUEST', 'PATCH_ORGANIZATION_SUCCESS', 'PATCH_ORGANIZATION_FAILURE'],
    [CALL_API]: '/organizations/:organizationId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const updateOrganizationReady = (params) => ({
    types: ['PATCH_ORGANIZATION_IS_READY_REQUEST', 'PATCH_ORGANIZATION_IS_READY_SUCCESS', 'PATCH_ORGANIZATION_IS_READY_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/compliance-approval',
    method: 'patch',
    params: params
})

export const clearOrgState = () => ({
    type: 'CLEAR_ORG_STATE',
})

export const getRoles = (params) => ({
    types: ['GET_ROLES_REQUEST', 'GET_ROLES_SUCCESS', 'GET_ROLES_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/roles',
    method: 'get',
    params: params,
})

export const createRole = (params, reqBody) => ({
    types: ['CREATE_ROLE_REQUEST', 'CREATE_ROLE_SUCCESS', 'CREATE_ROLE_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/roles',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const updateRole = (params, reqBody) => ({
    types: ['UPDATE_ROLE_REQUEST', 'UPDATE_ROLE_SUCCESS', 'UPDATE_ROLE_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/roles/:roleId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const deleteRole = (params, reqBody) => ({
    types: ['DELETE_ROLE_REQUEST', 'DELETE_ROLE_SUCCESS', 'DELETE_ROLE_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/roles/:roleId',
    method: 'delete',
    params: params,
    reqBody: reqBody
})

export const getOrgUsers = (params) => ({
    types: ['GET_ORG_USERS_REQUEST', 'GET_ORG_USERS_SUCCESS', 'GET_ORG_USERS_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/users',
    method: 'get',
    params: params,
})

export const updateOrgUserRole = (params) => ({
    types: ['UPDATE_ORG_USER_REQUEST', 'UPDATE_ORG_USER_SUCCESS', 'UPDATE_ORG_USER_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/roles/:roleId/users/:userId',
    method: 'patch',
    params: params,
})

export const inviteOrgUser = (params, reqBody) => ({
    types: ['CREATE_INVITE_ORG_USER_REQUEST', 'CREATE_INVITE_ORG_USER_SUCCESS', 'CREATE_INVITE_ORG_USER_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/roles/:roleId',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const deleteOrgUser = (params, reqBody) => ({
    types: ['DELETE_ORG_USER_REQUEST', 'DELETE_ORG_USER_SUCCESS', 'DELETE_ORG_USER_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/users/:userId',
    method: 'delete',
    params: params,
})

export const CreatePos = (params, reqBody) => ({
    types: ['CREATE_POS_REQUEST', 'CREATE_POS_SUCCESS', 'CREATE_POS_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/pos',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const GetOrgPos = (params, query) => ({
    types: ['GET_ORG_POS_REQUEST', 'GET_ORG_POS_SUCCESS', 'GET_ORG_POS_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/pos',
    method: 'get',
    params: params,
    query: query
})

export const GetOrgAccounts = (params) => ({
    types: ['GET_ORG_PMTSVCACC_REQUEST', 'GET_ORG_PMTSVCACC_SUCCESS', 'GET_ORG_PMTSVCACC_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/accounts',
    method: 'get',
    params: params,
})

export const GetPos = (params) => ({
    types: ['GET_POS_REQUEST', 'GET_POS_SUCCESS', 'GET_POS_FAILURE'],
    [CALL_POS_API]: '/pointofsales/:posId',
    method: 'get',
    params: params,
})

export const PatchPos = (params, reqBody) => ({
    types: ['PATCH_POS_REQUEST', 'PATCH_POS_SUCCESS', 'PATCH_POS_FAILURE'],
    [CALL_POS_API]: '/pointofsales/:posId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const DeletePos = (params) => ({
    types: ['DELETE_POS_REQUEST', 'DELETE_POS_SUCCESS', 'DELETE_POS_FAILURE'],
    [CALL_POS_API]: '/pointofsales/:posId',
    method: 'delete',
    params: params,
})

export const GetPosRoute = (params) => ({
    types: ['GET_POS_ROUTE_REQUEST', 'GET_POS_ROUTE_SUCCESS', 'GET_POS_ROUTE_FAILURE'],
    [CALL_POS_API]: '/pointofsales/:posId/route',
    method: 'get',
    params: params,
})

export const GetPosAccounts = (params) => ({
    types: ['GET_POS_PMTSVCACC_REQUEST', 'GET_POS_PMTSVCACC_SUCCESS', 'GET_POS_PMTSVCACC_FAILURE'],
    [CALL_POS_API]: '/pointofsales/:posId/accounts',
    method: 'get',
    params: params,
})

export const UpdatePosRoute = (params, reqBody) => ({
    types: ['UPDATE_POS_ROUTE_RULES_REQUEST', 'UPDATE_POS_ROUTE_RULES_SUCCESS', 'UPDATE_POS_ROUTE_RULES_FAILURE'],
    [CALL_POS_API]: '/routes/:routeId/rules',
    method: 'put',
    params: params,
    reqBody: reqBody,
})

export const clearPOSDetail = () => ({
    type: 'SET_CLEAR_POS_DETAILS',
})

export const clearOrgPOS = () => ({
    type: 'SET_CLEAR_ORGANIZATION_POS',
})

export const resetUserInviteSuccess = () => ({
    type: 'RESET_INVITE_ORG_USER_SUCCESS',
})
