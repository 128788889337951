function payoutsReport(state ={isLoading: false, hits: [], search_after: [], totalHits: 0 }, action) {
    switch (action.type) {
        case 'POST_PAYOUTS_SEARCH_REQUEST':
            return {...state, isLoading: true}
        case 'POST_PAYOUTS_SEARCH_SUCCESS':
            console.log('POST_PAYOUTS_SEARCH_SUCCESS', action);
            const queryPayoutsByOrganizationId = (action.data && action.data.data && action.data.data.queryPayoutsByOrganizationId) || null
            const hits = queryPayoutsByOrganizationId ? queryPayoutsByOrganizationId.items ? queryPayoutsByOrganizationId.items : [] : []
            const nextToken = queryPayoutsByOrganizationId ? queryPayoutsByOrganizationId.nextToken : undefined
            return {...state,
                hits: hits,
                nextToken: nextToken,
                isLoading: false
            }
        case 'POST_PAYOUTS_SEARCH_FAILURE':
            return {...state, isLoading: false}
        case 'POST_PAYOUTS_SEARCH_AFTER_REQUEST':
            return {...state, isLoading: true}
        case 'POST_PAYOUTS_SEARCH_AFTER_SUCCESS':
            const nextQueryPayoutsByOrganizationId = (action.data && action.data.data && action.data.data.queryPayoutsByOrganizationId) || null
            const nextHits = nextQueryPayoutsByOrganizationId ? nextQueryPayoutsByOrganizationId.items : nextQueryPayoutsByOrganizationId.items ? [nextQueryPayoutsByOrganizationId.items] : []
            const nextNextToken = nextQueryPayoutsByOrganizationId ? nextQueryPayoutsByOrganizationId.nextToken : undefined
            const combinedHits = state.hits.concat(nextHits);
            return {...state,
                hits: combinedHits,
                nextToken: nextNextToken,
                isLoading: false
            }
        case 'POST_PAYOUTS_SEARCH_AFTER_FAILURE':
            return {...state, isLoading: false}
        case 'CLEAR_PAYOUTS_SEARCH':
            return {...state,
                hits: [],
                nextToken: undefined,
            }
        case 'UPDATE_PAYOUTS_SEARCH':
            let targetUpdatePayoutId = (action && action.reqBody && action.reqBody.Payout && action.reqBody.Payout.id) || null
            const newHits = state.hits.map((eachHit) => {
                if (eachHit.currency === targetUpdatePayoutId) {
                    return action.reqBody.payout
                }
                return eachHit
            })
            return {...state,
                hits: newHits
            }
        case 'GET_PAYOUT_SCHEDULES_REQUEST':
            return {...state, isLoading: true}
        case 'GET_PAYOUT_SCHEDULES_SUCCESS':
            const payoutSchedules = (action.data && action.data.schedules) || []
            return {...state,
                payoutSchedules: payoutSchedules,
                isLoading: false
            }
        case 'GET_PAYOUT_SCHEDULES_FAILURE':
            return {...state, isLoading: false, payoutSchedules: []}

        case 'UPDATE_PAYOUT_SCHEDULE_REQUEST':
            return {...state, isLoading: true}
        case 'UPDATE_PAYOUT_SCHEDULE_SUCCESS':
            const responseSchedule = action.data || {}
            console.log('responseSchedule: ', responseSchedule);
            let currentSchedules = state.payoutSchedules || []
            currentSchedules = currentSchedules.map((eachSchedule) => {
                if (eachSchedule.currency === responseSchedule.currency) {
                    return responseSchedule
                }
                return eachSchedule
            })
            return {...state,
                payoutSchedules: currentSchedules,
                isLoading: false
            }
        case 'UPDATE_PAYOUT_SCHEDULE_FAILURE':
            return {...state, isLoading: false, payoutSchedules: []}
        case 'POST_CREATE_PAYOUT_SCHEDULE_REQUEST':
            return {...state, isLoading: true}
        case 'POST_CREATE_PAYOUT_SCHEDULE_SUCCESS':
            console.log('POST_CREATE_PAYOUT_SCHEDULE_SUCCESS : ', action.data);
            const createdSchedule = action.data || {}
            console.log('createdSchedule: ', createdSchedule);
            let currSchedules = state.payoutSchedules || []
            if (createdSchedule && createdSchedule.currency) {
                currSchedules = [...currSchedules, createdSchedule]
            }
            return {...state,
                payoutSchedules: currSchedules,
                isLoading: false
            }
        case 'POST_CREATE_PAYOUT_SCHEDULE_FAILURE':
            return {...state, isLoading: false, payoutSchedules: []}
        default:
            return {...state, isLoading: false} 
    }
  }

export default payoutsReport