function balancesReport(state ={isLoading: false, hits: [], search_after: [], totalHits: 0 }, action) {
    switch (action.type) {
        case 'POST_BALANCES_SEARCH_REQUEST':
            // console.log('POST_BALANCES_SEARCH_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_BALANCES_SEARCH_SUCCESS':
            // console.log('POST_BALANCES_SEARCH_SUCCESS', action);
            const listBalances = (action.data && action.data.data && action.data.data.listBalances) || null
            let hits = listBalances ? listBalances.items : listBalances.items ? listBalances.items : []
            hits = hits.map((eachHit) => {
                eachHit.id = eachHit.currency
                return eachHit
            })
            const nextToken = listBalances ? listBalances.nextToken : undefined
            return {...state,
                hits: hits,
                nextToken: nextToken,
                isLoading: false
            }
        case 'POST_BALANCES_SEARCH_FAILURE':
            // console.log('POST_BALANCES_SEARCH_FAILURE', action);
            return {...state, isLoading: false}
        case 'POST_BALANCES_SEARCH_AFTER_REQUEST':
            // console.log('POST_BALANCES_SEARCH_AFTER_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_BALANCES_SEARCH_AFTER_SUCCESS':
            // console.log('POST_BALANCES_SEARCH_AFTER_SUCCESS', action);
            const nextListBalances = (action.data && action.data.data && action.data.data.listBalances) || null
            const nextHits = nextListBalances ? nextListBalances.items : nextListBalances.items ? nextListBalances.items : []
            const nextHitsSource = nextHits.map((eachHit) => {
                eachHit.id = eachHit.currency
                return eachHit
            })
            const nextNextToken = nextListBalances ? nextListBalances.nextToken : undefined
            const combinedHits = state.hits.concat(nextHitsSource);
            return {...state,
                hits: combinedHits,
                nextToken: nextNextToken,
                isLoading: false
            }
        case 'POST_BALANCES_SEARCH_AFTER_FAILURE':
            // console.log('POST_BALANCES_SEARCH_AFTER_FAILURE', action);
            return {...state, isLoading: false}
        case 'GET_PAYABLES_REQUEST':
            return {...state, isLoading: true}
        case 'GET_PAYABLES_SUCCESS':
            // console.log('GET_PAYABLES_SUCCESS', action);
            let { payables } = (action && action.data) || {payables: []};
            // console.log('payables -> ', payables);
            payables = (payables && payables.map((eachPayableCurrency) => {
                eachPayableCurrency.id = eachPayableCurrency.currency
                return eachPayableCurrency
            })) || []
            return {...state,
                payables,
                payableCurrencyCount: payables.length,
                isLoading: false
            }
        case 'GET_PAYABLES_FAILURE':
            // console.log('GET_PAYABLES_FAILURE', action);
            return {...state, isLoading: false}
        case 'CLEAR_BALANCES_SEARCH':
            return {...state,
                hits: [],
                totalHits: 0,
                search_after: [],
            }
        case 'UPDATE_BALANCES_SEARCH':
            // console.log('UPDATE_BALANCES_SEARCH', action);
            let targetUpdateBalanceCurrency = (action && action.reqBody && action.reqBody.balance && action.reqBody.balance.currency) || null
            const newHits = state.hits.map((eachHit) => {
                if (eachHit.currency === targetUpdateBalanceCurrency) {
                    // console.log('target eachHit -> ', eachHit);
                    // console.log('intended update balance -> ', action.reqBody.balance);
                    return action.reqBody.balance
                }
                return eachHit
            })
            return {...state,
                hits: newHits
            }
        case 'POST_BILLINGS_SEARCH_REQUEST':
            // console.log('POST_BILLINGS_SEARCH_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_BILLINGS_SEARCH_SUCCESS':
            const queryBillingsByOrganizationID = (action.data && action.data.data && action.data.data.queryBillingsByOrganizationID) || null
            let billingsHits = queryBillingsByOrganizationID ? queryBillingsByOrganizationID.items ? queryBillingsByOrganizationID.items : [] : []
            const billingsNextToken = queryBillingsByOrganizationID ? queryBillingsByOrganizationID.nextToken : undefined
            return {...state,
                billingHits: billingsHits,
                billingsNextToken: billingsNextToken,
                isLoading: false
            }
        case 'POST_BILLINGS_SEARCH_FAILURE':
            return {...state, isLoading: false}
        case 'POST_BILLINGS_SEARCH_AFTER_REQUEST':
            // console.log('POST_BILLINGS_SEARCH_AFTER_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_BILLINGS_SEARCH_AFTER_SUCCESS':
            const nextQueryBillingsByOrganizationID = (action.data && action.data.data && action.data.data.queryBillingsByOrganizationID) || null
            const billingsNexthits = nextQueryBillingsByOrganizationID ? nextQueryBillingsByOrganizationID.items ? nextQueryBillingsByOrganizationID.items : [] : []
            const billingNextNextToken = nextQueryBillingsByOrganizationID ? nextQueryBillingsByOrganizationID.nextToken : undefined
            const billingsCombinedHits = state.billingHits.concat(billingsNexthits);
            return {...state,
                billingHits: billingsCombinedHits,
                billingsNextToken: billingNextNextToken,
                isLoading: false
            }
        case 'POST_BILLINGS_SEARCH_AFTER_FAILURE':
            console.log('POST_BILLINGS_SEARCH_AFTER_FAILURE', action);
            return {...state, isLoading: false}
        default:
            return {...state, isLoading: false} 
    }
  }

export default balancesReport