import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganization, getEnumeration, removeDeletedCustomerHit, createCustomer, getCustomer, updateCustomer, deleteCustomer, postCustomersSearch, postCustomersSearchAfter, clearCustomersSearch, updateCustomersSearch } from '../actions'
import CustomersManagement from '../components/CustomersManagement'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    customersread: (state.organizations.role && state.organizations.role.customersread) || false,
    customerswrite: (state.organizations.role && state.organizations.role.customerswrite) || false,
    organizationPos: state.organizations.organizationPos || [],
    search_after: (state.customerManagement && state.customerManagement.search_after) || [],
    hits: (state.customerManagement && state.customerManagement.hits) || [],
    totalHits: (state.customerManagement && state.customerManagement.totalHits) || [],
    environment: state.appstate.environment || 'staging',
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    createCustomer: (params, reqBody) => dispatch(createCustomer(params, reqBody)),
    getCustomer: (params) => dispatch(getCustomer(params)),
    updateCustomer: (params, reqBody) => dispatch(updateCustomer(params, reqBody)),
    deleteCustomer: (params) => dispatch(deleteCustomer(params)),
    clearCustomersSearch: () => dispatch(clearCustomersSearch()),
    updateCustomersSearch: (reqBody) => dispatch(updateCustomersSearch(reqBody)),
    removeDeletedCustomerHit: (reqBody) => dispatch(removeDeletedCustomerHit(reqBody)),
    postCustomersSearch: (params, reqBody) => dispatch(postCustomersSearch(params, reqBody)),
    postCustomersSearchAfter: (params, reqBody) => dispatch(postCustomersSearchAfter(params, reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersManagement)