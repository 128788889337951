import React, { useState, useEffect, useLayoutEffect } from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { OutlinedInput } from '@material-ui/core';
import { green, red, grey } from '@material-ui/core/colors';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    editIcon: {
        color: grey['A200'],
        '&:hover': {
            color: 'black',
            cursor: 'pointer'
        },
    },
    card: {
        padding: '1em',
        zIndex: 50,
    },
    btn: {
        color: '#1fa595'
    },
    error: {
        color: 'red'
    },
    select: {
        '&:after': {
            borderBottom: '2px solid #68d0af', // 1fa595 
        }
    },
    createBtn: {
        backgroundColor: "#a2a2a2",
        color: 'white'
    },
    alerts: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ButtonMod = withStyles({
    root: {
        '&.MuiButton-root:hover': {
            backgroundColor: '#68d0af' // 1fa595
        },
    },
})(Button);

const PaperMod = withStyles({
    root: {
        marginTop: '1em',
        padding: '1em',
    },
})(Paper);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        },
    },
})(OutlinedInput);

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function capitalize(string) {
    if (string && string.charAt(0)) return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    return ""
}

const UserPermissions = (props) => {
    const classes = useStyles(props)
    const theme = useTheme();
    const { value, organization, getRoles, updateRole, deleteRole, createRole, getOrgUsers, organizationUsers, 
        organizationRoles, updateOrgUserRole, inviteOrgUser, deleteOrgUser, userInviteSuccess, resetUserInviteSuccess
     } = props
    // console.log("ORGAN => ", organization);
    const [showEdit, setShowEdit] = useState('');
    const [organizationID, setOrganizationID] = useState('');
    const [newRoleName, setNewRoleName] = useState('')
    const [newInvitationEmail, setNewInvitationEmail] = useState('')
    const [newUserRole, setNewUserRole] = useState('default')
    const [newUserRoleError, setNewUserRoleError] = useState('')
    const [invitationStatus, setInvitationStatus] = useState(false)
    useEffect(() => {
        if (organization && organization.id) setOrganizationID(organization.id)
        if (organization && organization.id) getRoles({organizationId: organization.id})
        if (organization && organization.id) getOrgUsers({organizationId: organization.id})
    },[organization.id, organization])
    useEffect(() => {
        if (userInviteSuccess) {
            window.alert('User Invitation Sent!')
            resetUserInviteSuccess()
        }
    },[userInviteSuccess])
    const defaultState = {
        TransactionsRead: false,
        TransactionsWrite: false,
        PayoutsRead: false,
        PayoutsWrite: false,
        BalancesRead: false,
        BalancesWrite: false,
        InvoicesRead: false,
        InvoicesWrite: false,
        OrdersRead: false,
        OrdersWrite: false,
        AnalyticsRead: false,
        AnalyticsWrite: false,
        PaymentterminalsRead: false,
        PaymentterminalsWrite: false,
        CollectionsRead: false,
        CollectionsWrite: false,
        CheckoutpagesRead: false,
        CheckoutpagesWrite: false,
        PaymentelementsRead: false,
        PaymentelementsWrite: false,
        CustomersRead: false,
        CustomersWrite: false,
        ProductsvcsRead: false,
        ProductsvcsWrite: false,
        DisputesRead: false,
        DisputesWrite: false
    }
    const [state, setState] = useState(defaultState);
    const handleShow = (field, fieldSubject) => {
        // console.log("state=>", state);
        if (field === 'role') {
            setShowEdit(fieldSubject.id)
            if (fieldSubject.scopes) {
                let tempArr = fieldSubject.scopes.split(' ')
                let tempPermission = defaultState
                tempArr.map((access) => {
                    let accessArr = access.split(':')
                        let section = capitalize(accessArr[0])
                        let accessType = capitalize(accessArr[1])
                        tempPermission[(section+accessType)] = true
                    setState(tempPermission)
                    return access
                })
                // console.log("tempPermission => ", tempPermission);
            }
        } else if (field === 'user') {
            setShowEdit(fieldSubject.id)
        }
    }

    const getScope = (keyParam) => {
        switch (keyParam) {
            case 'TransactionsRead':
                return "transactions:read"
            case 'TransactionsWrite':
                return "transactions:write"
            case 'PayoutsRead':
                return "payouts:read"
            case 'PayoutsWrite':
                return "payouts:write"
            case 'BalancesRead':
                return "balances:read"
            case 'BalancesWrite':
                return "balances:write"
            case 'InvoicesRead':
                return "invoices:read"
            case 'InvoicesWrite':
                return "invoices:write"
            case 'OrdersRead':
                return "orders:read"
            case 'OrdersWrite':
                return "orders:write"
            case 'AnalyticsRead':
                return "analytics:read"
            case 'AnalyticsWrite':
                return "analytics:write"
            case 'PaymentterminalsRead':
                return "paymentterminals:read"
            case 'PaymentterminalsWrite':
                return "paymentterminals:write"
            case 'CollectionsRead':
                return "collections:read"
            case 'CollectionsWrite':
                return "collections:write"
            case 'CheckoutpagesRead':
                return "checkoutpages:read"
            case 'CheckoutpagesWrite':
                return "checkoutpages:write"
            case 'PaymentelementsRead':
                return "paymentelements:read"
            case 'PaymentelementsWrite':
                return "paymentelements:write"
            case 'CustomersRead':
                return "customers:read"
            case 'CustomersWrite':
                return "customers:write"
            case 'ProductsvcsRead':
                return "productsvcs:read"
            case 'ProductsvcsWrite':
                return "productsvcs:write"
            case 'DisputesRead':
                return "disputes:read"
            case 'DisputesWrite':
                return "disputes:write"
            default:
                break;
        }
    }
    const updateOfRole = () => {
        let scopes = ""
        for (const key in state) {
            if (state[key]) scopes = scopes + getScope(key) + " "
        }
        // console.log("scopes => ", scopes);
        updateRole(
            {organizationId: organization.id, roleId: showEdit}, // params
            {scopes} // reqBody
        )
        handleShow('role', {})
    }

    const handleCreateNewRole = () => {
        createRole(
            {organizationId: organization.id}, // params
            {organizationId: organization.id, name: newRoleName} // reqBody
        )
    }

    const handleInviteNewUser = () => {
        setNewUserRoleError('')
        if (newUserRole === 'default') {
            setNewUserRoleError('Please select a role')
            return
        }
        inviteOrgUser(
            {organizationId: organization.id, roleId: newUserRole}, // params
            { userEmail: newInvitationEmail}// reqBody
        )
        setNewUserRole('default')
        setNewInvitationEmail('')
        setInvitationStatus(true)
    }
    return (
    <TabPanel value={value} index={4} dir={theme.direction}>
        <Grid container spacing={3}>
            <Grid item md={10} xs={10} sm={10}>
                <Typography component={'span'} className={classes.Title}>Roles</Typography>
            </Grid>
            <Grid item md={2} xs={2} sm={2}>
                <ButtonMod size={'large'} className={classes.createBtn} onClick={()=> {handleShow('role', {id: 'showCreateRole' })}}>
                    Create Role
                </ButtonMod>
            </Grid>
            {showEdit === 'showCreateRole' && <Grid item md={12} xs={12} sm={12}>
                <Card className={classes.card}>
                    <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                        New Role
                    </Typography>
                    <OutlinedInputMod fullWidth onChange={(e) => setNewRoleName(e.target.value)} />
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('role', {})
                                setNewRoleName('')
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                handleCreateNewRole()
                                handleShow('role', {})
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                </Card></Grid>}
        </Grid>
        <PaperMod elevation={3} >
            {organizationRoles.map((eachRole, index)=> {
                if (eachRole.name === 'Primary Owner') return
                return <Grid container spacing={3} key={index}>
                <Grid item md={9} xs={9} sm={9}>
                    <Typography component={'span'} className={classes.label}>{eachRole.name}</Typography>
                </Grid>
                <Grid item md={2} xs={2} sm={2}>
                    <Typography component={'span'}>
                        <IconButton onClick={() => handleShow('role', eachRole)}>
                            <EditIcon className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                <Grid item md={1} xs={1} sm={1}>
                    <IconButton onClick={() => deleteRole({organizationId: organization.id, roleId: eachRole.id}, {})}>
                        <DeleteOutlineIcon className={classes.editIcon}/>
                    </IconButton>
                </Grid>
                {showEdit === eachRole.id && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            {eachRole.name}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Transactions
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.TransactionsRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            TransactionsRead: !state.TransactionsRead
                                        })
                                    }} 
                                    name="TransactionsRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.TransactionsWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            TransactionsWrite: !state.TransactionsWrite
                                        })
                                    }}
                                    name="TransactionsWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Payouts
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.PayoutsRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            PayoutsRead: !state.PayoutsRead
                                        })
                                    }} 
                                    name="PayoutsRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.PayoutsWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            PayoutsWrite: !state.PayoutsWrite
                                        })
                                    }}
                                    name="PayoutsWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Balances
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.BalancesRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            BalancesRead: !state.BalancesRead
                                        })
                                    }} 
                                    name="BalancesRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.BalancesWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            BalancesWrite: !state.BalancesWrite
                                        })
                                    }}
                                    name="BalancesWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Invoices
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.InvoicesRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            InvoicesRead: !state.InvoicesRead
                                        })
                                    }} 
                                    name="InvoicesRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.InvoicesWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            InvoicesWrite: !state.InvoicesWrite
                                        })
                                    }}
                                    name="InvoicesWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Orders
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.OrdersRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            OrdersRead: !state.OrdersRead
                                        })
                                    }} 
                                    name="OrdersRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.OrdersWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            OrdersWrite: !state.OrdersWrite
                                        })
                                    }}
                                    name="OrdersWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Analytics
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.AnalyticsRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            AnalyticsRead: !state.AnalyticsRead
                                        })
                                    }} 
                                    name="AnalyticsRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.AnalyticsWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            AnalyticsWrite: !state.AnalyticsWrite
                                        })
                                    }}
                                    name="AnalyticsWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Payment Terminals
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.PaymentterminalsRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            PaymentterminalsRead: !state.PaymentterminalsRead
                                        })
                                    }} 
                                    name="PaymentterminalsRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.PaymentterminalsWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            PaymentterminalsWrite: !state.PaymentterminalsWrite
                                        })
                                    }}
                                    name="PaymentterminalsWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Collections
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.CollectionsRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            CollectionsRead: !state.CollectionsRead
                                        })
                                    }} 
                                    name="CollectionsRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.CollectionsWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            CollectionsWrite: !state.CollectionsWrite
                                        })
                                    }}
                                    name="CollectionsWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Checkout Pages
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.CheckoutpagesRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            CheckoutpagesRead: !state.CheckoutpagesRead
                                        })
                                    }} 
                                    name="CheckoutpagesRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.CheckoutpagesWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            CheckoutpagesWrite: !state.CheckoutpagesWrite
                                        })
                                    }}
                                    name="CheckoutpagesWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Payment Elements
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.PaymentelementsRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            PaymentelementsRead: !state.PaymentelementsRead
                                        })
                                    }} 
                                    name="PaymentelementsRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.PaymentelementsWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            PaymentelementsWrite: !state.PaymentelementsWrite
                                        })
                                    }}
                                    name="PaymentelementsWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Customers
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.CustomersRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            CustomersRead: !state.CustomersRead
                                        })
                                    }} 
                                    name="CustomersRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.CustomersWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            CustomersWrite: !state.CustomersWrite
                                        })
                                    }}
                                    name="CustomersWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Products and Services
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.ProductsvcsRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            ProductsvcsRead: !state.ProductsvcsRead
                                        })
                                    }} 
                                    name="ProductsvcsRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.ProductsvcsWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            ProductsvcsWrite: !state.ProductsvcsWrite
                                        })
                                    }}
                                    name="ProductsvcsWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography component={'span'}>
                                    Disputes
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.DisputesRead) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            DisputesRead: !state.DisputesRead
                                        })
                                    }} 
                                    name="DisputesRead" />}
                                label="Read"
                            />
                            <FormControlLabel
                                control={<GreenCheckbox 
                                    checked={(state.DisputesWrite) || false} 
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            DisputesWrite: !state.DisputesWrite
                                        })
                                    }}
                                    name="DisputesWrite" />}
                                label="Write"
                            />
                            </Grid>
                        </Grid>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('role', {})
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={() => {
                                updateOfRole()
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            })}
        </PaperMod><br/>
        <Grid container spacing={3}>
            <Grid item md={10} xs={10} sm={10}>
                <Typography component={'span'} className={classes.Title}>Users</Typography>
            </Grid>
            <Grid item md={2} xs={2} sm={2}>
                <ButtonMod size={'large'} className={classes.createBtn} 
                    onClick={()=> {handleShow('user', {id: 'showInviteUser' })}}
                >
                    Invite User
                </ButtonMod>
            </Grid>
            {showEdit === 'showInviteUser' && <Grid item md={12} xs={12} sm={12}>
                <Card className={classes.card}>
                    <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                        Invite User via email
                    </Typography>
                    <Typography component={'span'} className={classes.error} gutterBottom>
                        {newUserRoleError}
                    </Typography>
                    <OutlinedInputMod fullWidth onChange={(e) => setNewInvitationEmail(e.target.value.toLowerCase())} /><br/><br/>
                    <Select
                            className={classes.select}
                            value={newUserRole}
                            onChange={(e) => {
                                setNewUserRole(e.target.value)
                            }}
                            label="Role"
                        >
                            {
                                organizationRoles.map((singleRole, index) => {
                                    if (singleRole.name !== 'Primary Owner') return <MenuItem key={index} value={singleRole.id}>{singleRole.name}</MenuItem>
                                    return
                                })
                            }
                            <MenuItem key={'default'} value={'default'}>Select User Role</MenuItem>
                    </Select><br/>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('user', {})
                                setNewUserRole('default')
                                setNewInvitationEmail('')
                                setNewUserRoleError('')
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                handleInviteNewUser()
                                handleShow('user', {})
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                </Card></Grid>}
        </Grid>
        <PaperMod elevation={3} >
        {organizationUsers.map((eachUser, index) => {
            // console.log("eachUser => ", eachUser);
            if (eachUser.isPrimaryOwner) {
                return (
<Grid container spacing={3} key={index}>
                <Grid item md={9} xs={9} sm={9}>
                    <Typography component={'span'} className={classes.label}>{eachUser.userEmail}</Typography>
                </Grid>
                <Grid item md={3} xs={3} sm={3}>
                    <Typography component={'span'}>Primary Owner</Typography>
                </Grid>
            </Grid>
                )
            }
            return (<Grid container spacing={3} key={index}>
                <Grid item md={9} xs={9} sm={9}>
                    <Typography component={'span'} className={classes.label}>{eachUser.userEmail}</Typography>
                </Grid>
                <Grid item md={2} xs={2} sm={2}>
                    <Typography component={'span'}>
                        <Select
                            className={classes.select}
                            value={eachUser.roleId}
                            onChange={(e) => {
                                // console.log('subkmit value', e.target.value);
                                updateOrgUserRole({ organizationId: eachUser.organizationId, roleId: e.target.value, userId: eachUser.userId })
                            }}
                            label="Role"
                        >
                            {
                                organizationRoles.map((singleRole, index) => {
                                    if (singleRole.name !== 'Primary Owner') return <MenuItem key={index} value={singleRole.id}>{singleRole.name}</ MenuItem>
                                })
                            }
                        </Select>
                    </Typography>
                </Grid>
                <Grid item md={1} xs={1} sm={1}>
                    <IconButton onClick={() => deleteOrgUser({organizationId: eachUser.organizationId, userId: eachUser.userId})}>
                        <DeleteOutlineIcon className={classes.editIcon}/>
                    </IconButton>
                </Grid>
            </Grid>
            )
        })}
        </PaperMod>
    </TabPanel>
    )
}

export default UserPermissions