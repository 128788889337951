import React, { useEffect, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import rocket from '../../src/rocket.png';
import Slide from '@material-ui/core/Slide';

const axios = require('axios');
const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);

const ButtonMod = withStyles({
    root: {
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
    },
})(Button);

const TextFieldMod = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(TextField);

const useStyles = makeStyles(theme => ({
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    label: {
        color: '#7f7f7f'
    },
    poslist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    card: {
        display: 'flex',
        minHeight: '17em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        position: 'absolute',
        bottom: 0,
    },
    add: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        textTransform: 'none'
    },
    imageRight: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: '-1em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const PaymentTerminals = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont, getOrganization, clearOrgPOS,
        getEnumeration, enumeration, organization, CreatePos, GetOrgPos, organizationPos, GetPos, detailedPos,
        environment
    } = props
    const [currencies, setCurrencies] = useState([]);
    const [createPosOpen, setCreatePosOpen] = React.useState(false);
    const [pName, setPName] = React.useState('');
    const theme = useTheme();

    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
        if (appbarIsWhiteFont) toggleAppbarWhiteFont(false)
        return function cleanup() {
            clearOrgPOS()
        };
    },[]);
    useEffect(() => {
        if (enumeration && enumeration.currency) setCurrencies(enumeration.currency)
    }, [enumeration]);
    useEffect(() => {
        if (!organization.id) {
            const url = window.location.href
            let urlArr = url.split('/')
            let organizationID = urlArr[urlArr.length - 2]
            // console.log('organizationID -> ', organizationID);
            getOrganization({organizationId: organizationID}, {type: "paymentterminals"})
            GetOrgPos({organizationId: organizationID}, {type: "paymentterminals"})
        } else {
            GetOrgPos({organizationId: organization.id}, {type: "paymentterminals"})
        }
        getEnumeration({enumGroup: 'currency'})
    },[environment])
    const CreateNewPos = () => {
        if (pName.length < 1) return
        CreatePos(
            {organizationId: organization.id },
            {
                name: pName,
                type: 'terminals'
            }
        )
    }
    const history = useHistory();
    const posDetail = (id) => {
        // console.log('before push detailed pos id -> ', id);
        GetPos({posId: id})
        history.push({
            pathname: `/paymentterminal/${id}`,
            state: {posId: id}
        })
    }
    useEffect(() => {
        if (detailedPos && detailedPos.id && organizationPos.length > 0) {
            history.push({
                pathname: `/paymentterminal/${(detailedPos && detailedPos.id) || ''}`,
                state: {posId: (detailedPos && detailedPos.id) || ''}
            })
        }
    }, [detailedPos.id]);
    return (
    <main className={classes.topMargin}>
    <Dialog fullScreen open={createPosOpen} onClose={()=> {setCreatePosOpen(false)}} TransitionComponent={Transition}>
        <span className={classes.bannerRight}></span>
        <span  className={classes.imageRight}><img alt='totheMoon' src={rocket}/></span>
        <Container className={classes.formContainer} maxWidth="lg">
            <CloseIcon className={classes.closeBtn} onClick={()=> {setCreatePosOpen(false)}}/>
            <Typography className={classes.formTitle} variant="h2" component="h2">
                Name of your Payment Terminal
                <TextFieldMod
                    onChange={e => setPName(e.target.value)}
                    label="Payment Terminal name"
                    placeholder="Enter the name of the payment terminal"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                        input: classes.greenColor,
                    }}
                    InputProps={{
                        classes: {
                            input: classes.coName,
                        },
                    }}
                />
            <ButtonMod size={'large'} className={classes.createBtn} onClick={()=> {CreateNewPos()}}>
                Submit
            </ButtonMod>
            </Typography>
        </Container>
    </Dialog>
    <Typography variant="h4" className={classes.Title}>Payment Terminals</Typography>
    <AppBarMod position="static" color="default">
      </AppBarMod>
        <Container>
        <div className={classes.poslist}>
            <div>
            <Grid container spacing={3}>
                {/* Add company */}
                <Grid item md={4} xs={12} sm={6}>
                    <Card  className={classes.card}>
                        <Button className={classes.add} onClick={()=> {setCreatePosOpen(true)}}>
                            <AddIcon/><Typography>Add Payment Terminal</Typography>
                        </Button>
                    </Card>
                </Grid>
                {/* List of POS */}
                {
                    organizationPos && organizationPos.length > 0 && organizationPos.map((eachPos, index) => {
                        if (eachPos.type === 'paymentterminals' || eachPos.type === 'terminals') {
                            return <Grid key={index} item md={4} xs={12} sm={6} onClick={() => {
                                    posDetail(eachPos.id)
                                }}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography className={classes.name} gutterBottom>
                                            {eachPos.name}
                                        </Typography>
                                        <Typography className={classes.id} color="textSecondary">
                                            Point of Sales ID:
                                        </Typography>
                                        <Typography className={classes.id} color="textSecondary">
                                            {eachPos.id}
                                        </Typography>
                                    </CardContent>
                                    <CardActions className={classes.action}>
                                        {/* image of integrations or payment modes */}
                                    </CardActions>
                                </Card>
                            </Grid>
                        } else {
                            return ''
                        }
                    })
                }
            </Grid>
            </div>
        </div>
        </Container>
    </main>
    )
}

export default PaymentTerminals