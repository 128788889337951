import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganization, getEnumeration, 
    postBalancesSearch, postBalancesSearchAfter, clearBalancesSearch, updateBalancesSearch, 
    createPayout, getPayables, postBillingsSearch, postBillingsSearchAfter, postCreateInvoice } from '../actions'
import BalancesReport from '../components/BalancesReport'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    balancesread: (state.organizations.role && state.organizations.role.balancesread) || false,
    balanceswrite: (state.organizations.role && state.organizations.role.balanceswrite) || false,
    search_after: state.balancesReport.search_after || [],
    hits: (state.balancesReport && state.balancesReport.hits) || [],
    totalHits: (state.balancesReport && state.balancesReport.totalHits) || [],
    environment: state.appstate.environment || 'staging',
    payablesList: (state.balancesReport && state.balancesReport.payables) || [],
    payableCurrencyCount: (state.balancesReport && state.balancesReport.payableCurrencyCount) || 0,
    billings_search_after: state.balancesReport.billings_search_after || [],
    billingHits: (state.balancesReport && state.balancesReport.billingHits) || [],
    billingsNextToken: (state.balancesReport && state.balancesReport.billingsNextToken) || "",
    isLoading: state.isLoading || false,
    balancesNextToken: (state.balancesReport && state.balancesReport.nextToken) || "",
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    postBalancesSearch: (params, reqBody) => dispatch(postBalancesSearch(params, reqBody)),
    postBalancesSearchAfter: (params, reqBody) => dispatch(postBalancesSearchAfter(params, reqBody)),
    clearBalancesSearch: () => dispatch(clearBalancesSearch()),
    updateBalancesSearch: (reqBody) => dispatch(updateBalancesSearch(reqBody)),
    createPayout: (reqBody) => dispatch(createPayout(reqBody)),
    getPayables: (params) => dispatch(getPayables(params)),
    postBillingsSearch: (reqBody) => dispatch(postBillingsSearch(reqBody)),
    postBillingsSearchAfter: (reqBody) => dispatch(postBillingsSearchAfter(reqBody)),
    postCreateInvoice: (reqBody) => dispatch(postCreateInvoice(reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BalancesReport)