import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Typography from '@material-ui/core/Typography';
import { teal } from '@material-ui/core/colors';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom"
import RootRef from '@material-ui/core/RootRef';
import IconButton from '@material-ui/core/IconButton';

import { drawerWidth } from '../app/globals';
import logo from '../logoNname.svg';

const useStyles = makeStyles((theme => ({
    overview: {
      borderRight: '1px solid #ffffff',
      maxWidth: '80%',
      display: 'inline-block'
    },
    homeIcon: {
      paddingRight: '0.2em'
    },
    settingGear: {
      cursor: 'pointer',
      marginLeft: '0.7em'
    },
    settingGearTrans: {
      transform: `translateX(-0.2em) rotate(-270deg)`,
      transition: 'transform .15s ease-in,opacity .075s ease',
      transitionProperty: 'transform, opacity',
      transitionDuration: '0.15s, 0.075s',
      transitionTimingFunction: 'ease-in, ease',
      transitionDelay: '0s, 0s',
    },
    setting: {
      display: 'inline-block',
      // paddingLeft: '0.7em',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    menuPosition:{
      marginLeft: '5em'
    },
    divider: {
      backgroundColor: '#979797',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        background: '#0c3336',
        width: drawerWidth,
        color: 'white',
    },
    drawerHeaderLogo: {
      maxWidth: '100%',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        '&:hover': {
          cursor: 'pointer',
        },
    },
    subItem: {
      '&:hover': {
        backgroundColor: '#365a5d',
        color: '#FFF'
      }
    },
})));

const AppDrawer = (props) => {
    const domRef = React.createRef();
    const [reportDropDown, setReportDropDown] = useState(true)
    const [integrateDropDown, setIntegrateDropDown] = useState(true)
    const [manageDropDown, setManageDropDown] = useState(true)
    const [reportHover, setReportHover] = useState(false)
    const [integrateHover, setIntegrateHover] = useState(false)
    const [manageHover, setManageHover] = useState(false)
    const [settingHover, setSettingHover] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const { 
      toggleAppbarWhiteFont,
      analyticsread,
      balancesread,
      invoicesread,
      checkoutpagesread,
      collectionsread,
      customersread,
      ordersread,
      paymentelementsread,
      paymentterminalsread,
      payoutsread,
      productsvcsread,
      disputesread,
      transactionsread,
      usermgtread,
      clearOrgState,
    } = props
    const { openDrawer, toggleDrawer, organization } = props
    const orgID = (organization && organization.id) || ''
    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const backToMain = () => {
      clearOrgState()
      history.push({
          pathname: `/`,
      })
    }
    const backToOrgMain = () => {
      history.push({
          pathname: `/company/${orgID}`,
      })
    }
    const goToSettings = () => {
      handleCloseMenu()
      toggleAppbarWhiteFont(false)
      history.push({
        pathname: `/company/${orgID}/settings`,
        state: {
          permissionTab: '0'
        },
      })
    }
    const goToPermissions = () => {
      handleCloseMenu()
      toggleAppbarWhiteFont(false)
      history.push({
        pathname: `/company/${orgID}/settings`,
        state: {
          permissionTab: '4'
        },
      })
    }
    const goToSection = (text) => {
      handleCloseMenu()
      toggleAppbarWhiteFont(false)
      // console.log('text is -> ', text);
      let destination = ''
      switch (text) {
        case 'Checkout Pages':
          destination = 'checkoutpages'
          break;
        case 'Payment Elements':
          destination = 'paymentelements'
          break;
        case 'Collections':
          destination = 'collections'
          break;
        case 'Payment Terminals':
          destination = 'paymentterminals'
          break;
        case 'Payments':
          destination = 'payments-report'
          break;
        case 'Balances':
          destination = 'balances-report'
          break;
        case 'Payouts':
          destination = 'payouts-report'
          break;
        case 'Invoices':
          destination = 'invoices-report'
          break;
        case 'Customers':
          destination = 'customers-management'
          break;
        case 'Products & Services':
          destination = 'products-management'
          break;
        case 'Disputes':
          destination = 'disputes-management'
          break;
        default:
          destination = 'paymentterminals'
          break;
      }
      history.push({
        pathname: `/company/${orgID}/${destination}`,
      })
    }
    const classes = useStyles(props)
    const theme = useTheme();
    return (<nav><Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
    >
      <div onClick={() => { backToMain() }} className={classes.drawerHeader}>
        <img className={classes.drawerHeaderLogo} src={logo} alt={"liberalizeLogo"} />
      </div>
      <Divider className={classes.divider}  light={true}/>
      {/* OverView */}
      <List component="nav" style={{paddingTop: 0}}>
        <div className={usermgtread ? classes.overview : ""}>
        <ListItem button className={classes.subItem}>
          <HomeIcon className={classes.homeIcon} />
          <ListItemText onClick={() => { backToOrgMain() }} primary="Company Overview" />
        </ListItem>
        </div>
        {usermgtread && <div className={classes.setting} onMouseEnter={() => {setSettingHover(true)}} onMouseLeave={() => {setSettingHover(false)}}>
          <RootRef rootRef={domRef}>
            <IconButton style={{color: '#ffffff', display: 'block', justifyContent: 'flex-start'}} onClick={handleClickMenu} aria-controls="simple-menu" aria-haspopup="true">
              <SettingsIcon className={settingHover ? classes.settingGearTrans: classes.settingGear } /> {settingHover && <ArrowRightIcon/>}
            </IconButton>
            <Menu
              id="simple-menu"
              className={classes.menuPosition}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              container={domRef.current}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={goToSettings}>Company Settings</MenuItem>
              {<MenuItem onClick={goToPermissions}>Users and Permissions</MenuItem>}
            </Menu>
          </RootRef>
        </div>}
        <Divider className={classes.divider}  light={true}/>
        {/* Report */}
        {(transactionsread || payoutsread || balancesread || invoicesread) &&<ListItem className={classes.subItem} button onMouseEnter={() => {setReportHover(true)}} onMouseLeave={() => {setReportHover(false)}} onClick={() => {setReportDropDown(!reportDropDown)}}>
          <ListItemText ><Typography  variant="h6">Report</Typography></ListItemText>
          {reportHover && (reportDropDown ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>}
        <Collapse in={reportDropDown} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* COMPLETE LIST => ['Payments', 'Payouts', 'Balances', 'Invoices', 'Orders', 'Analytics'] */}
            {['Payments', 'Payouts', 'Invoices', 'Balances'].map((text) => {
              if (text === 'Payments' && !transactionsread) return ''
              if (text === 'Payouts' && !payoutsread) return ''
              if (text === 'Balances' && !balancesread) return ''
              if (text === 'Invoices' && !invoicesread) return ''
              // if (text === 'Orders' && !ordersread) return ''
              // if (text === 'Analytics' && !analyticsread) return ''
              return <ListItem className={classes.subItem} onClick={(e) => { goToSection(text) }} button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            })}
          </List>
        </Collapse>
        <Divider className={classes.divider}  light={true}/>
        {/* Integrate */}
        {(paymentterminalsread || paymentelementsread) &&<ListItem className={classes.subItem} button onMouseEnter={() => {setIntegrateHover(true)}} onMouseLeave={() => {setIntegrateHover(false)}} onClick={() => {setIntegrateDropDown(!integrateDropDown)}}>
          <ListItemText ><Typography  variant="h6">Integrate</Typography></ListItemText>
          {integrateHover && (integrateDropDown ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>}
        <Collapse in={integrateDropDown} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* FULL LIST => ['Payment Terminals', 'Collections', 'Checkout Pages', 'Payment Elements'] */}
            {['Payment Terminals', 'Payment Elements'].map((text) => {
              if (text === 'Payment Terminals' && !paymentterminalsread) return ''
              // if (text === 'Collections' && !collectionsread) return ''
              // if (text === 'Checkout Pages' && !checkoutpagesread) return ''
              if (text === 'Payment Elements' && !paymentelementsread) return ''
              return <ListItem className={classes.subItem} onClick={(e) => { goToSection(text) }} button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            })}
          </List>
        </Collapse>
        <Divider className={classes.divider}  light={true}/>
        {/* Manage */}
        {(disputesread || customersread) && <ListItem className={classes.subItem} button onMouseEnter={() => {setManageHover(true)}} onMouseLeave={() => {setManageHover(false)}} onClick={() => {setManageDropDown(!manageDropDown)}}>
          <ListItemText ><Typography  variant="h6">Manage</Typography></ListItemText>
          {manageHover && (manageDropDown ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>}
        <Collapse in={manageDropDown} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {['Disputes', 'Customers'
            // 'Products & Services'  
          ].map((text) => {
              if (text === 'Disputes' && !disputesread) return ''
              if (text === 'Customers' && !customersread) return ''
              // if (text === 'Products & Services' && !productsvcsread) return ''
              return <ListItem className={classes.subItem} onClick={(e) => { goToSection(text) }} button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            })}
          </List>
        </Collapse>
      </List>
      </Drawer></nav>)
}

export default AppDrawer
