import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import { TrendingUpRounded } from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import {fetchApi} from '../app/utils/callPmtsReportApi';
import CircularProgress from '@material-ui/core/CircularProgress';
import { invoicePaymentMethods, liberalizeBank, liberalizeAddress } from '../app/globals';

const currencyFormatter = require('currency-formatter');

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const axios = require('axios');
const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);


const ButtonMod = withStyles({
    root: {
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);


const DateTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const OutlinedInputPayment = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '11px 14px',
        }
    },
})(OutlinedInput);

const InputGreen = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(OutlinedInput);



const useStyles = makeStyles(theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    label: {
        color: '#7f7f7f'
    },
    balslist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    card: {
        display: 'flex',
        minHeight: '17em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        position: 'absolute',
        bottom: 0,
    },
    add: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        textTransform: 'none'
    },
    imageRight: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: '-1em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    dialogTitle: {
        color: '#0c3336',
        fontSize: '1.7em'
    },
    paymentDetailAccordion: {
        width: '100%',
        marginBottom: '1em'
    },
    txnLogGrid: {
        margin: 0
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    greenMoney: {
        color: '#16a09e',
        verticalAlign: 'bottom',
        fontSize: '2em'
    },
    greenCurrency: {
        color: '#16a09e',
        fontSize: '2em'
    },
    finePrint: {
        fontSize: '1em'
    }
}));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const PayoutsReport = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont, getOrganization,
        getEnumeration, enumeration, organization, search_after, hits, totalHits, environment, 
        postInvoicesSearch, postInvoicesSearchAfter, clearInvoicesSearch, updateInvoice, nextToken
    } = props
    const [searchBankName, setSearchBankName] = useState('')
    const [searchAccountNo, setSearchAccountNo] = useState('')
    const [invoiceDetails, setInvoiceDetails] = useState({})
    const [invoiceDialogIsOpen, setInvoiceDialogIsOpen] = useState(false)
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    // Later just have to Make a button to change the UTC +X
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [page, setPage] = React.useState(1);
    const [loadedPage, setLoadedPage] = React.useState(1);

    const theme = useTheme();
    const constructSearchReqBody = (passedCurrency="", passedPageSize, restartSearch=false) => {
        // console.log('process.env.MATERIAL_LICENSE_KEY -> ', process.env.REACT_APP_MATERIAL_LICENSE_KEY);
        let targetCurrency = currency || passedCurrency
        let initialStruct = {}
        let dateQuery = ""
        if (dateFrom || dateTo) {
            dateQuery += "{"
            if (!dateFrom || !dateTo) {
                if (dateFrom) dateQuery += `ge: "${dateFrom}" `
                if (dateTo) dateQuery += `le: "${dateTo}"`
            } else if (dateFrom && dateTo) {
                dateQuery += `between: ["${dateFrom}", "${dateTo}"]`
            }
            dateQuery += "}"
        }
        let query = 
        `
            query listInvoices($organizationId: String!) {
                queryInvoicesByOrganizationId(
                    queryInput: { 
                        organizationId: { 
                            eq: $organizationId 
                        }
                    }
                    filter: { 
                        ${dateQuery ? `createdAt: ${dateQuery}` : ""}
                        currency: { eq: "${targetCurrency}" }
                    }
                    nextToken: "${(!restartSearch && nextToken) ? nextToken : ""}",
                ) 
                { items { 
                    id
                    organizationId
                    status
                    currency
                    amount
                    fees {
                        amount
                        currency
                        description
                    }
                    billings {
                        accountId
                        id
                        currency
                        amount
                        heldAmount
                        billedAmount
                        refundReversalAmount
                        refundReversalHeldAmount
                        refundReversalBilledAmount
                        rollingReserveReversalAmount
                        rollingReserveReversalHeldAmount
                        rollingReserveReversalBilledAmount
                        isBilled
                        isRefundReversalBilled
                        isRollingReserveReversalBilled
                    }
                    transactionMode
                    transactionReference
                    approvedBy
                    originator {
                        isEntity
                        identification
                        name
                        dob
                        bank {
                            name
                            code
                            branch
                            swift
                            account {
                                number
                                name
                                currency
                            }
                        }
                    }
                    benefactor {
                        isEntity
                        identification
                        name
                        dob
                        bank {
                            name
                            code
                            branch
                            swift
                            account {
                                number
                                name
                                currency
                            }
                        }
                    }
                    processedAt
                    createdAt
                    updatedAt
                }, nextToken } 
            }
        `
        initialStruct.variables = {
            organizationId: organization.id
        }
        initialStruct.query = query
        return initialStruct
    }
    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
        if (appbarIsWhiteFont) toggleAppbarWhiteFont(false)
    },[]);

    const currencyFormat = (currency, amount, symbol=false) => {
        if (!currency || !(amount >= 0)) return ''
        const currencyDetails = currencyFormatter.findCurrency(currency);
        let amountString = `${amount}`
        let amountBeforeDecimal = ''
        let amountAfterDecimal = ''
        if (currencyDetails.decimalDigits > 0 && amount !== '0') {
            let count = 0
            for (var i = amountString.length - 1; i >= 0; i--) {
                if (count < currencyDetails.decimalDigits) {
                    amountAfterDecimal = amountString[i] + amountAfterDecimal
                    count++
                } else {
                    amountBeforeDecimal = amountString[i] + amountBeforeDecimal 
                }
            }
            if (currencyDetails.decimalDigits > amountAfterDecimal.length) {
                for (var i = 0; i < currencyDetails.decimalDigits - amountAfterDecimal.length; i++) {
                    amountAfterDecimal = '0' + amountAfterDecimal
                }
            }
        } else {
            amountBeforeDecimal = amountString
        }
        if (!amountBeforeDecimal.length) amountBeforeDecimal = '0'
        // Add in Seperators to amountBeforeDecimal
        let amountBeforeDecimalReverseArr = amountBeforeDecimal.split('').reverse()
        amountBeforeDecimal = ''
        amountBeforeDecimalReverseArr.forEach((digit, index) => {
            if (((index + 1 ) % 3) === 0) {
                amountBeforeDecimal += `${digit}` + `${currencyDetails.thousandsSeparator}`
            } else {
                amountBeforeDecimal += digit
            }
        })
        amountBeforeDecimal = amountBeforeDecimal.split('').reverse().join('')
        if (amountBeforeDecimal[0] === currencyDetails.thousandsSeparator) {
            amountBeforeDecimal = amountBeforeDecimal.slice(1)
        }
        return `${symbol ? currencyDetails.symbol : ''}${currencyDetails.spaceBetweenAmountAndSymbol === true ? ' ': ''}${amountBeforeDecimal}${currencyDetails.decimalDigits > 0 ? currencyDetails.decimalSeparator : ''}${(amount == '0' && currencyDetails.decimalDigits == 2) ? '00' : amountAfterDecimal}`
    }
    useEffect(() => {
        if (enumeration && enumeration.currency) setCurrencies(enumeration.currency)
    }, [enumeration]);

    useEffect(() => {
        if (organization.id) {
            // console.log(JSON.stringify(organization));
            let targetCurrency = (organization && organization.banks && organization.banks[0].account && organization.banks[0].account.currency) || 'SGD'
            setCurrency(targetCurrency)
            let esSearchReqBody = constructSearchReqBody(targetCurrency)
            // console.log('esSearchReqBody -> ', esSearchReqBody);
            postInvoicesSearch(esSearchReqBody)
            setLoadedPage(1)
        }
    }, [organization.id]);

    useEffect(() => {
        if (!organization.id) {
            const url = window.location.href
            let urlArr = url.split('/')
            let organizationID = urlArr[urlArr.length - 2]
            // console.log('organizationID -> ', organizationID);
            getOrganization({organizationId: organizationID})
        }
        getEnumeration({enumGroup: 'currency'})
        return function cleanup() {
            clearInvoicesSearch()
        };
    },[environment])

    const history = useHistory();

    const closeInvoiceDialog = () => {
        setInvoiceDialogIsOpen(false)
        setInvoiceDetails({})
    }

    useEffect(() => {
        if (nextToken && (hits && hits.length < 1000)) {
            let esSearchReqBody = constructSearchReqBody(currency, searchSize, false);
            postInvoicesSearchAfter(esSearchReqBody)
        }
    }, [nextToken])

    return (
    <main className={classes.topMargin}>
    <Typography variant="h4" className={classes.Title}>Invoices</Typography>
    <AppBarMod position="static" color="default"></AppBarMod>
    <Container>
    <Dialog
            fullWidth
            maxWidth={'xl'}
            open={invoiceDialogIsOpen}
            onClose={closeInvoiceDialog}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">Invoice Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    {/* Invoice ID */}
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>Invoice ID: {invoiceDetails.id}</span> 
                        </Typography>
                    </Grid>
                    {/* Currency / Amount */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>{`${invoiceDetails.currency}`} {currencyFormat(invoiceDetails.currency, invoiceDetails.amount, true)}</span> 
                        </Typography>
                    </Grid>
                    {/* Status */}
                    <Grid item md={2} xs={2} sm={2}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>Status: {invoiceDetails.status}</span> 
                        </Typography>
                    </Grid>
                    {/* Created At */}
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <div className={classes.lastUpdated} >
                            {
                                `${(invoiceDetails && invoiceDetails.createdAt && "Created At: " + new Date(invoiceDetails.createdAt).toString()) || ""}`
                            }
                            </div>
                        </Typography>
                    </Grid>
                    {/* Updated At */}
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <div className={classes.lastUpdated} >
                                {
                                    `${(invoiceDetails && invoiceDetails.updatedAt && "Updated At: " + new Date(invoiceDetails.updatedAt).toString()) || ""}`
                                }
                            </div>
                        </Typography>
                    </Grid>
                    {/* Transaction Mode */}
                    { invoiceDetails.status === "PENDING_PAYMENT" && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Mode</Typography>
                            <Select
                                fullWidth
                                className={classes.select}
                                variant="outlined"
                                value={(invoiceDetails && invoiceDetails.transactionMode) || ""}
                                onChange={(e) => {
                                    const targetDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                    targetDetails.transactionMode = e.target.value
                                    setInvoiceDetails(targetDetails)
                                }}
                                label="Payment Mode"
                                >
                                {invoicePaymentMethods.map((paymentMode) => (
                                    <MenuItem key={paymentMode} value={paymentMode}>{paymentMode}</MenuItem>
                                ))}
                            </Select>
                    </Grid>}
                    { invoiceDetails.status === "PENDING_PAYMENT" && <Grid item md={6} xs={6} sm={6}></Grid>}
                    { invoiceDetails.status !== "PENDING_PAYMENT" && <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Mode</Typography>
                            <div>{(invoiceDetails && invoiceDetails.transactionMode) || ""}</div>
                    </Grid>}
                    {/* Orgiginator Bank Account Mode */}
                    {invoiceDetails.status === "PENDING_PAYMENT" && invoiceDetails.transactionMode === "BANK_TRANSFER" && <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Your Bank Details (From)</Typography>
                        {organization && organization.banks && organization.banks.length < 1 && "Please complete organiaztion's bank details in Settings"}
                        {organization && organization.banks && organization.banks.length > 0 && <Select
                            fullWidth
                            className={classes.select}
                            variant="outlined"
                            renderValue={(value)=> (value.account && value.account.number && value.account.currency && value.name && (`${value.name} ${value.account.currency} ${value.account.number}`)) || ''}
                            value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank) || ""}
                            onChange={(e) => {
                                const originator = (invoiceDetails.originator && JSON.parse(JSON.stringify(invoiceDetails.originator))) || {}
                                originator.bank = e.target.value
                                let targetDetail = JSON.parse(JSON.stringify(invoiceDetails))
                                targetDetail.originator = originator
                                console.log('targetDetail.originator -> ', targetDetail.originator.bank)
                                setInvoiceDetails(targetDetail)
                            }}
                            label="Bank"
                            >
                            {organization && organization.banks.map((eachBank, index) => (
                                <MenuItem 
                                    key={index} 
                                    value={eachBank}
                                >
                                    Bank: {eachBank.name} Account: {(eachBank && eachBank.account && eachBank.account.name)} {(eachBank && eachBank.account && eachBank.account.currency)} {(eachBank && eachBank.account && eachBank.account.number)}
                                </MenuItem>
                            ))}
                        </Select>}
                    </Grid>}
                    {/* Benefactor Bank Account Details */}
                    {invoiceDetails.status === "PENDING_PAYMENT" && invoiceDetails.transactionMode === "BANK_TRANSFER" && <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Liberalize's Bank Details (To)</Typography>
                        {liberalizeBank && liberalizeBank.length > 0 && <Select
                            fullWidth
                            className={classes.select}
                            variant="outlined"
                            renderValue={(value)=> (value.account && value.account.number && value.account.currency && value.name && (`${value.name} ${value.account.currency} ${value.account.number}`)) || ''}
                            value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank) || ""}
                            onChange={(e) => {
                                console.log(e.target)
                                const benefactor = (invoiceDetails.benefactor && JSON.parse(JSON.stringify(invoiceDetails.benefactor))) || {}
                                benefactor.bank = e.target.value
                                let targetDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                targetDetails.benefactor = benefactor
                                setInvoiceDetails(targetDetails)
                            }}
                            label="Bank"
                            >
                            {liberalizeBank && liberalizeBank.map((eachBank, index) => (
                                <MenuItem 
                                    key={'libBank-'+index}
                                    value={eachBank}
                                >
                                    Bank: {eachBank.name} Account: {(eachBank && eachBank.account && eachBank.account.name)} {(eachBank && eachBank.account && eachBank.account.currency)} {(eachBank && eachBank.account && eachBank.account.number)}
                                </MenuItem>
                            ))}
                        </Select>}
                    </Grid>}
                    { invoiceDetails.transactionMode === "BANK_TRANSFER" && <Grid item md={6} xs={6} sm={6}>
                    <div>Please ensure your account details are updated</div><br/>
                        <div>Bank: {(invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.name)}</div>
                        <div>Branch: {(invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.branch)}</div>
                        <div>Swift: {(invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.swift)}</div>
                        <div>Account</div>
                        <div>Name: {(invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.account && invoiceDetails.originator.bank.account.name)}</div>
                        <div>Number: {(invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.account && invoiceDetails.originator.bank.account.number)}</div>
                        <div>Currency: {(invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.account && invoiceDetails.originator.bank.account.currency)}</div>
                    </Grid>}
                    {invoiceDetails.transactionMode === "BANK_TRANSFER" && <Grid item md={6} xs={6} sm={6}>
                    <div>Bank: {(invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.name)}</div>
                        <div>Branch: {(invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.branch)}</div>
                        <div>Swift: {(invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.swift)}</div>
                        <div>Account</div>
                        <div>Name: {(invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.account && invoiceDetails.benefactor.bank.account.name)}</div>
                        <div>Number: {(invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.account && invoiceDetails.benefactor.bank.account.number)}</div>
                        <div>Currency: {(invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.account && invoiceDetails.benefactor.bank.account.currency)}</div>
                    </Grid>}
                    {/* Transaction Reference */}
                    {invoiceDetails.transactionMode === "BANK_TRANSFER" && <Grid item md={6} xs={6} sm={6}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Reference</Typography>
                        {invoiceDetails.status === "PENDING_PAYMENT" && <OutlinedInputMod value={(invoiceDetails.transactionReference) || ""} fullWidth onChange={(e) => {
                            let targetDetails = JSON.parse(JSON.stringify(invoiceDetails))
                            targetDetails.transactionReference = e.target.value
                            setInvoiceDetails(targetDetails)
                            }} />}
                        {!(invoiceDetails.status === "PENDING_PAYMENT") && invoiceDetails.transactionReference}
                    </Grid>}
                    {invoiceDetails.status === "PENDING_PAYMENT" && invoiceDetails.transactionMode === "BANK_TRANSFER" && <Grid item md={6} xs={6} sm={6}>
                    </Grid>}
                    {/* Originator Business Details */}
                    {invoiceDetails.transactionMode === "BANK_TRANSFER" && <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Your Business Details:</Typography>
                        <div>Name: {organization && organization.name}</div>
                        {/* identification */}
                        <div>Business Registration Number: {organization && organization.uen}</div>
                    </Grid>}
                    {/* Originator Business Address */}
                    {invoiceDetails.transactionMode === "BANK_TRANSFER" && <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Your Business Address:</Typography>
                        <div>Line1: {organization && organization.address && organization.address.line1}</div>
                        <div>Line2: {organization && organization.address && organization.address.line2}</div>
                        <div>City: {organization && organization.address && organization.address.city}</div>
                        <div>State: {organization && organization.address && organization.address.state}</div>
                        <div>Country: {organization && organization.country}</div>
                        <div>Postal/Zipcode: {organization && organization.address && organization.address.postal}</div>
                    </Grid>}
                    {/* Update Button */}
                    <Grid item md={12} xs={12} sm={12}>
                        {invoiceDetails.status === "PENDING_PAYMENT" && <ButtonMod onClick={() => {
                            if (!organization || !organization.id) return
                            updateInvoice({
                                organizationId: organization.id,
                                invoiceId: invoiceDetails.id
                            }, invoiceDetails)
                            closeInvoiceDialog()
                        }}>Update Invoice</ButtonMod>}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        <div className={classes.balslist}>
            <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={currency || 'Select Currency'}
                                onChange={(e) => {
                                    // console.log('HELLLOOOOO   -> ', e.target);
                                    // console.log('CURRENCY !!!!! ', e.target.value);
                                    setCurrency(e.target.value)
                                }}
                                label="Currency"
                            >
                                {
                                    currencies && currencies.length > 0 && currencies.map((curr, index) => {
                                        return <MenuItem key={index} value={curr.value.currency_alpha3_code}>{curr.name}</MenuItem>
                                    })
                                }
                                <MenuItem key={'select_currency'} value={''}>Select Currency</MenuItem>
                            </Select>
                        </Grid>
                        {/* TODO */}
                        {/* <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Bank Name</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchBankName(e.target.value)} defaultValue={searchBankName}/>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Account Number</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchAccountNo(e.target.value)} defaultValue={searchAccountNo}/>
                        </Grid> */}
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Created Date From</Typography>
                            <DateTextField
                                id="datefrom"
                                type="datetime-local"
                                onChange={(e) => {
                                    let date = new Date(e.target.value)
                                    setDateFrom(date.toISOString())
                                }}
                                defaultValue={dateFrom || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Created Date To (UTC)</Typography>
                            <DateTextField
                                id="dateTo"
                                type="datetime-local"
                                onChange={(e) => {
                                    let date = new Date(e.target.value)
                                    setDateTo(date.toISOString())
                                }}
                                defaultValue={dateTo || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            </div>
            <ButtonMod onClick={() => {
                setPage(1);
                setLoadedPage(1)
                setSearchFrom(0);
                let esSearchReqBody = constructSearchReqBody(currency, null, true)
                // console.log('PLEASE DONT FIRE! esSearchReqBody : ', esSearchReqBody);
                postInvoicesSearch(esSearchReqBody)
            }}>Search</ButtonMod>
        </div>
        <div className={classes.balslist}>
            <Grid container>
                {/* List of Balances */}
                {/* {`totalHits -> ${totalHits}`}
                {`hits -> ${hits.length}`} */}
                <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                    <XGrid
                        className={classes.xgrid}
                        page={page}
                        onPageChange={(params) => {
                            // console.log('onPageChange FIRED first part !!', params.page , ' ', loadedPage);
                            // console.log('onPageChange params.page -> ', params.page);
                            if (page >= params.page) return
                            setPage(params.page);
                            if (params.page <= loadedPage) return
                            // console.log('onPageChange FIRED second part !!', params.page , ' ', loadedPage);
                            if (!nextToken) {
                                return
                            }
                            setLoadedPage(loadedPage + 1)
                            let esSearchReqBody = constructSearchReqBody()
                            // console.log('esSearchReqBody -> ', esSearchReqBody);
                            postInvoicesSearchAfter(esSearchReqBody)
                        }}
                        onPageSizeChange={(params) =>{
                            // console.log('onPageSizeChange params.pageSize -> ', params.pageSize);
                            if (searchSize === params.pageSize) return
                            setSearchSize(params.pageSize)
                            setPage(1);
                            setLoadedPage(1)
                            let esSearchReqBody = constructSearchReqBody(currency, params.pageSize, true)
                            // console.log('esSearchReqBody -> ', esSearchReqBody);
                            // console.log('PLEASE DONT FIRE!');
                            postInvoicesSearch(esSearchReqBody)
                        }}
                        rowCount={nextToken ? 9999999999999 : (hits.length)}
                        rowsPerPageOptions={[10,25,50,100]}
                        pageSize={10}
                        pagination={true}
                        columns={[
                            { 
                                field: 'id',
                                headerName: 'Invoice ID',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                            },
                            { 
                                field: 'amount',
                                headerName: 'Amount',
                                width: 170, 
                                valueGetter: (params) => {
                                    return (params && params.row && params.row.amount && currencyFormat( params.row.currency,  params.row.amount, true)) || currencyFormat( params.row.currency, 0, true)
                                },
                            },
                            { 
                                field: 'currency',
                                headerName: 'Currency',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.currency) || ""}`,
                            },
                            { 
                                field: 'status',
                                headerName: 'Status',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.status) || ""}`,
                            },
                            { 
                                field: 'originator.bank.name',
                                headerName: 'Your Bank',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.originator && params.row.originator.bank && params.row.originator.bank.name) || ""}`,
                            },
                            { 
                                field: 'originator.bank.account.number',
                                headerName: 'Your Bank Account Number',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.originator && params.row.originator.bank && params.row.originator.bank.account && params.row.originator.bank.account.number) || ""}`,
                            },
                            { 
                                field: 'updatedAt',
                                headerName: 'Last Updated At',
                                width: 170,
                                valueGetter: (params) => {
                                    let dateString = ''
                                    if (params && params.row && params.row.updatedAt) {
                                        let date = new Date(params.row.updatedAt)
                                        dateString = date.toString()
                                    } 
                                    return dateString
                                },
                            },
                            { 
                                field: 'createdAt',
                                headerName: 'Created At',
                                width: 170,
                                valueGetter: (params) => {
                                    let dateString = ''
                                    if (params && params.row && params.row.createdAt) {
                                        let date = new Date(params.row.createdAt)
                                        dateString = date.toString()
                                    } 
                                    return dateString
                                },
                            },
                        ]}
                        rows={hits}
                        onRowClick={async (rowParams) => {
                            let targetDetail = JSON.parse(JSON.stringify(rowParams.row))
                            targetDetail.benefactor.address = JSON.parse(JSON.stringify(liberalizeAddress))
                            targetDetail.originator.address = JSON.parse(JSON.stringify(organization.address))
                            targetDetail.originator.address.country = JSON.parse(JSON.stringify(organization.country))
                            targetDetail.originator.isEntity = true
                            targetDetail.originator.indentification = organization.uen
                            targetDetail.originator.name = organization.name
                            setInvoiceDetails(targetDetail)
                            setInvoiceDialogIsOpen(true)
                        }}
                    />
                </div>
            </Grid>
        </div>
    </Container>
    <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress className={classes.circularProgress} />
    </Backdrop>
    </main>
    )
}

export default PayoutsReport