function apiKeys(state ={isLoading: false, posApiKeys: {count:0}}, action) {
    switch (action.type) {
        case 'GET_APIKEYS_REQUEST':
            // console.log("GET_APIKEYS_REQUEST", action);
            return {...state, isLoading: true} 
        case 'GET_APIKEYS_SUCCESS':
            // console.log("GET_APIKEYS_SUCCESS", action);
            const posApiKeys = {...action.data}
            return {...state, isLoading: false, posApiKeys: posApiKeys} 
        case 'GET_APIKEYS_FAILURE':
            // console.log("GET_APIKEYS_FAILURE", action);
            return {...state, isLoading: false}
        case 'CREATE_APIKEYS_REQUEST':
            // console.log("CREATE_APIKEYS_REQUEST", action);
            return {...state, isLoading: true} 
        case 'CREATE_APIKEYS_SUCCESS':
            // console.log("CREATE_APIKEYS_SUCCESS", action);
            let currPosApiKeys = JSON.parse(JSON.stringify(state.posApiKeys))
            if (currPosApiKeys.count) {
                currPosApiKeys.count += 1
                currPosApiKeys.keys.push(action.data)
            } else {
                currPosApiKeys.count = 1
                currPosApiKeys.keys = [action.data]
            }
            return {...state, isLoading: false, posApiKeys: currPosApiKeys} 
        case 'CREATE_APIKEYS_FAILURE':
            // console.log("CREATE_APIKEYS_FAILURE", action);
            return {...state, isLoading: false}
        case 'DELETE_APIKEYS_REQUEST':
            // console.log("CREATE_APIKEYS_REQUEST", action);
            return {...state, isLoading: true} 
        case 'DELETE_APIKEYS_SUCCESS':
            // console.log("CREATE_APIKEYS_SUCCESS", action);
            let deletedPosApiKeys = JSON.parse(JSON.stringify(state.posApiKeys))
            deletedPosApiKeys.count = 0
            deletedPosApiKeys.keys = []
            return {...state, isLoading: false, posApiKeys: deletedPosApiKeys} 
        case 'DELETE_APIKEYS_FAILURE':
            // console.log("CREATE_APIKEYS_FAILURE", action);
            return {...state, isLoading: false}
      default:
        return state
    }
  }

export default apiKeys