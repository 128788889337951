import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { green, red, grey, teal } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PublishIcon from '@material-ui/icons/Publish';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchApi} from '../app/utils/callProductApi';
import {uuidGenerator} from '../app/utils/uuid'
const currencyFormatter = require('currency-formatter');

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const axios = require('axios');
const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);


const ButtonMod = withStyles({
    root: {
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const ButtonRed = withStyles({
    root: {
        marginTop: '1em',
        backgroundColor: red['A200'],
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: red['A400'] // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);


const DateTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const OutlinedInputPayment = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '11px 14px',
        }
    },
})(OutlinedInput);

const InputGreen = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(OutlinedInput);

const DOBTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        },
    },
})(TextField);

const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);


const useStyles = makeStyles(theme => ({
    editIcon: {
        color: grey['A200'],
        '&:hover': {
            color: 'black',
            cursor: 'pointer'
        },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    label: {
        color: '#7f7f7f'
    },
    poslist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    card: {
        display: 'flex',
        minHeight: '17em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        position: 'absolute',
        bottom: 0,
    },
    add: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        textTransform: 'none'
    },
    imageRight: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: '-1em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    dialogTitle: {
        color: '#0c3336',
        fontSize: '1.7em'
    },
    additionalDetailAccordion: {
        width: '100%',
        marginBottom: '1em'
    },
    txnLogGrid: {
        margin: 0
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    addIcon: {
        color: teal['50'],
        '&:hover': {
            color: teal['A700'],
            cursor: 'pointer'
        },
    },
    circularProgress: {
        color: '#68d0af'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    detailBtns: {
        marginRight: '1em'
    },
    hiddeninput: {
        display: 'none',
    },
}));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const ProductsManagement = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont, getOrganization,
        getEnumeration, enumeration, organization, search_after, hits, totalHits, environment,
        productsvcsread, productsvcswrite, createProduct, getProduct, updateProduct, deleteProduct, 
        clearProductsSearch, updateProductsSearch, removeDeletedProductHit, postProductsSearch, 
        postProductsSearchAfter, productservicetypes
    } = props
    const [loading, SetLoading] = useState(false);
    const suggestions = [{name: 'Apparrels'}, {name: 'Green'}, {name: 'Books'}, {name: "Fiction"}, {name: 'Movies'}, {name: 'Sci-Fi'}]
    // Later just have to Make a button to change the UTC +X
    // Search Product Data
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchName, setSearchName] = useState('');
    const [searchType, setSearchType] = useState('');
    const [searchDescription, setSearchDescription] = useState('');
    const [searchBrand, setSearchBrand] = useState('');
    const [searchQuantityFrom, setSearchQuantityFrom] = useState(0);
    const [searchQuantityTo, setSearchQuantityTo] = useState(0);
    const [searchMinQuantity, setSearchMinQuantity] = useState(0);
    const [searchMulQuantity, setSearchMulQuantity] = useState(0);
    const [searchCategory, setSearchCategory] = useState('');
    const [searchSellingAmountFrom, setSearchSellingAmountFrom] = useState(0);
    const [searchSellingAmountTo, setSearchSellingAmountTo] = useState(0);
    const [searchSellingCurrency, setSearchSellingCurrency] = useState('');
    const [searchProductNumber, setSearchProductNumber] = useState('');
    const [searchSerialNumber, setSearchSerialNumber] = useState('');
    const [searchPdtSize, setSearchPdtSize] = useState('');
    const [searchExpiryFrom, setSearchExpiryFrom] = useState('');
    const [searchExpiryTo, setSearchExpiryTo] = useState('');
    const [searchManufacturedDateFrom, setSearchManufacturedDateFrom] = useState('');
    const [searchManufacturedDateTo, setSearchManufacturedDateTo] = useState('');
    const [searchManuAddLine1, setSearchManuAddLine1] = useState('');
    const [searchManuAddLine2, setSearchManuAddLine2] = useState('');
    const [searchManuCity, setSearchManuCity] = useState('');
    const [searchManuState, setSearchManuState] = useState('');
    const [searchManuCountry, setSearchManuCountry] = useState('');
    const [searchManuPostal, setSearchManuPostal] = useState('');
    const [searchTags, setSearchTags] = useState([]);
    const [searchLocationAddLine1, setSearchLocationAddLine1] = useState('');
    const [searchLocationAddLine2, setSearchLocationAddLine2] = useState('');
    const [searchLocationCity, setSearchLocationCity] = useState('');
    const [searchLocationState, setSearchLocationState] = useState('');
    const [searchLocationCountry, setSearchLocationCountry] = useState('');
    const [searchLocationPostal, setSearchLocationPostal] = useState('');
    // New Product Data
    const [newName, setNewName] = useState('');
    const [newType, setNewType] = useState('product');
    const [newDescription, setNewDescription] = useState('');
    const [newBrand, setNewBrand] = useState('');

    // Edit Product Data
    const [editName, setEditName] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [editBrand, setEditBrand] = useState('');
    const [editQuantity, setEditQuantity] = useState(0);
    const [editMinQuantity, setEditMinQuantity] = useState(0);
    const [editMulQuantity, setEditMulQuantity] = useState(0);
    const [editSellingAmount, setEditSellingAmount] = useState(0);
    const [editCategory, setEditCategory] = useState('');
    const [editProductNumber, setEditProductNumber] = useState('');
    const [editSerialNumber, setEditSerialNumber] = useState('');
    const [editSize, setEditSize] = useState('');
    const [editManuAddLine1, setEditManuAddLine1] = useState('');
    const [editManuAddLine2, setEditManuAddLine2] = useState('');
    const [editManuCity, setEditManuCity] = useState('');
    const [editManuState, setEditManuState] = useState('');
    const [editManuPostal, setEditManuPostal] = useState('');
    const [editTags, setEditTags] = useState([]);
    const [editImageURLs, setEditImageURLs] = useState([]); // Not done, probably only need in update
    const [editDataSheetURLs, setEditDataSheetURLs] = useState([]); // Not done, probably only need in update
    const [editSupplierID, setEditSupplierID] = useState(''); // Not done, probably only need in update
    const [editBuyingAmount, setEditBuyingAmount] = useState(0);
    const [editRefundAmount, setEditRefundAmount] = useState(0); // In consideration
    const [editRefundCurrency, setEditRefundCurrency] = useState(''); // In consideration
    const [editLocationAddLine1, setEditLocationAddLine1] = useState('');
    const [editLocationAddLine2, setEditLocationAddLine2] = useState('');
    const [editLocationCity, setEditLocationCity] = useState('');
    const [editLocationState, setEditLocationState] = useState('');
    const [editLocationPostal, setEditLocationPostal] = useState('');

    // Component Variables 
    const [processImageLoading, setProcessImageLoading] = useState(false);
    const [processFileLoading, setProcessFileLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [page, setPage] = React.useState(1);
    const [loadedPage, setLoadedPage] = React.useState(1);
    const [productDetail, setProductDetail] = React.useState({});
    const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
    const [newProductDialog, setNewProductDialog] = React.useState(false);
    const closeDiaLog = () => {
        setProductDetail({})
        setDialogIsOpen(false)
    }
    const [showEdit, setShowEdit] = useState('');

    const addProduct = () => {
        let reqBody = {}
        if (newName) reqBody.name = newName;
        if (newDescription) reqBody.description = newDescription;
        if (newBrand) reqBody.brand = newBrand;
        if (newType) reqBody.type = newType;
        createProduct({organizationId: organization.id}, reqBody)
    }

    const currencyFormat = (currency, amount) => {
        if (!currency || !amount) return ''
        const currencyDetails = currencyFormatter.findCurrency(currency);
        let amountString = `${amount}`
        let amountBeforeDecimal = ''
        let amountAfterDecimal = ''
        if (currencyDetails.decimalDigits > 0 && amount !== '0') {
            let count = 0
            for (var i = amountString.length - 1; i >= 0; i--) {
                if (count < currencyDetails.decimalDigits) {
                    amountAfterDecimal = amountString[i] + amountAfterDecimal
                    count++
                } else {
                    amountBeforeDecimal = amountString[i] + amountBeforeDecimal 
                }
            }
            if (currencyDetails.decimalDigits > amountAfterDecimal.length) {
                for (var i = 0; i < currencyDetails.decimalDigits - amountAfterDecimal.length; i++) {
                    amountAfterDecimal = '0' + amountAfterDecimal
                }
            }
        } else {
            amountBeforeDecimal = amountString
        }
        if (!amountBeforeDecimal.length) amountBeforeDecimal = '0'
        // Add in Seperators to amountBeforeDecimal
        let amountBeforeDecimalReverseArr = amountBeforeDecimal.split('').reverse()
        amountBeforeDecimal = ''
        amountBeforeDecimalReverseArr.forEach((digit, index) => {
            if (((index + 1 ) % 3) === 0) {
                amountBeforeDecimal += `${digit}` + `${currencyDetails.thousandsSeparator}`
            } else {
                amountBeforeDecimal += digit
            }
        })
        amountBeforeDecimal = amountBeforeDecimal.split('').reverse().join('')
        if (amountBeforeDecimal[0] === currencyDetails.thousandsSeparator) {
            amountBeforeDecimal = amountBeforeDecimal.slice(1)
        }
        return `${currencyDetails.symbol}${currencyDetails.spaceBetweenAmountAndSymbol === true ? ' ': ''}${amountBeforeDecimal}${currencyDetails.decimalDigits > 0 ? currencyDetails.decimalSeparator : ''}${(amount == '0' && currencyDetails.decimalDigits == 2) ? '00' : amountAfterDecimal}`
    }

    const getAmountSelling = (params) => {
        if ((params && params.row && params.row.sellingCurrency) && (params && params.row && params.row.sellingAmount)) {
            return currencyFormat(params.row.sellingCurrency, params.row.sellingAmount)
        } else {
            return ''
        }
    }

    const closeNewProductDialog = () => {
        setNewName('')
        setNewProductDialog(false)
    }

    const theme = useTheme();
    const constructSearchReqBody = (passedPageSize, restartSearch=false) => {
        let initialStruct = {
            from: searchFrom,
            size: passedPageSize || searchSize,
            query: {
                bool: {
                    must: [
                    ]
                },
            },
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }
        if (searchName) {
            initialStruct.query.bool.must.push(
                { "match": { "name": searchName }}
            )
        }
        if (searchType) {
            initialStruct.query.bool.must.push(
                { "match": { "type": searchType }}
            )
        }
        if (searchDescription) {
            initialStruct.query.bool.must.push(
                { "match": { "description": searchDescription }}
            )
        }
        if (searchBrand) {
            initialStruct.query.bool.must.push(
                { "match": { "brand": searchBrand }}
            )
        }
        if (searchQuantityFrom || searchQuantityTo) {
            initialStruct.query.bool.must.push(
                {"range": {"quantity": {"gte": searchQuantityFrom ? parseInt(searchQuantityFrom) : 0, "lte": parseInt(searchQuantityTo) ? searchQuantityTo : 9999999999999 }}},
            )
        }
        if (searchMinQuantity) {
            initialStruct.query.bool.must.push(
                {"term": {"minQuantity.keyword": {"value": parseInt(searchMinQuantity)} }}
            )
        }
        if (searchMulQuantity) {
            initialStruct.query.bool.must.push(
                {"term": {"multiplesQuantity.keyword": {"value": parseInt(searchMulQuantity)} }}
            )
        }
        if (searchCategory) {
            initialStruct.query.bool.must.push(
                { "match": { "category": searchCategory }}
            )
        }
        if (searchSellingAmountFrom || searchSellingAmountTo) {
            initialStruct.query.bool.must.push(
                {"range": {"sellingAmount": {"gte": searchSellingAmountFrom ? parseInt(searchSellingAmountFrom) : 0, "lte": parseInt(searchSellingAmountTo) ? searchSellingAmountTo : 9999999999999999 }}},
            )
        }
        if (searchSellingCurrency) {
            initialStruct.query.bool.must.push(
                { "match": { "sellingCurrency": searchSellingCurrency }}
            )
        }
        if (searchProductNumber) {
            initialStruct.query.bool.must.push(
                { "match": { "productNumber": searchProductNumber }}
            )
        }
        if (searchSerialNumber) {
            initialStruct.query.bool.must.push(
                { "match": { "serialNumber": searchSerialNumber }}
            )
        }
        if (searchPdtSize) {
            initialStruct.query.bool.must.push(
                { "match": { "size": searchPdtSize }}
            )
        }
        if (searchExpiryFrom || searchExpiryTo) {
            const todayDate = new Date(Date.now())
            const stringToday = todayDate.getFullYear() + "-" + (todayDate.getMonth() + 1) + "-" + todayDate.getDate()
            initialStruct.query.bool.must.push(
                {"range": {"expiryDate": {"gte": searchExpiryFrom ? searchExpiryFrom : '1900-01-01', "lte": searchExpiryTo ? searchExpiryTo : stringToday }}},
            )
        }
        if (searchManufacturedDateFrom || searchManufacturedDateTo) {
            const todayDate = new Date(Date.now())
            const stringToday = todayDate.getFullYear() + "-" + (todayDate.getMonth() + 1) + "-" + todayDate.getDate()
            initialStruct.query.bool.must.push(
                {"range": {"manufacturedDate": {"gte": searchManufacturedDateFrom ? searchManufacturedDateFrom : '1900-01-01', "lte": searchManufacturedDateTo ? searchManufacturedDateTo : stringToday }}},
            )
        }
        if (searchManuAddLine1) {
            initialStruct.query.bool.must.push(
                { "match": { "manufacturedAddress.line1": searchManuAddLine1 }}
            )
        }
        if (searchManuAddLine2) {
            initialStruct.query.bool.must.push(
                { "match": { "manufacturedAddress.line2": searchManuAddLine2 }}
            )
        }
        if (searchManuCity) {
            initialStruct.query.bool.must.push(
                { "match": { "manufacturedAddress.city": searchManuCity }}
            )
        }
        if (searchManuState) {
            initialStruct.query.bool.must.push(
                { "match": { "manufacturedAddress.state": searchManuState }}
            )
        }
        if (searchManuCountry) {
            initialStruct.query.bool.must.push(
                { "match": { "manufacturedAddress.country": searchManuCountry }}
            )
        }
        if (searchManuPostal) {
            initialStruct.query.bool.must.push(
                { "match": { "manufacturedAddress.postal": searchManuPostal }}
            )
        }
        if (searchLocationAddLine1) {
            initialStruct.query.bool.must.push(
                { "match": { "location.line1": searchLocationAddLine1 }}
            )
        }
        if (searchLocationAddLine2) {
            initialStruct.query.bool.must.push(
                { "match": { "location.line2": searchLocationAddLine2 }}
            )
        }
        if (searchLocationCity) {
            initialStruct.query.bool.must.push(
                { "match": { "location.city": searchLocationCity }}
            )
        }
        if (searchLocationState) {
            initialStruct.query.bool.must.push(
                { "match": { "location.state": searchLocationState }}
            )
        }
        if (searchLocationCountry) {
            initialStruct.query.bool.must.push(
                { "match": { "location.country": searchLocationCountry }}
            )
        }
        if (searchLocationPostal) {
            initialStruct.query.bool.must.push(
                { "match": { "location.postal": searchLocationPostal }}
            )
        }
        if (searchTags && searchTags.length) {
            searchTags.forEach((singleTag) => {
                initialStruct.query.bool.must.push(
                    { "match": { "tags": singleTag }}
                )
            })
        }
        if (!restartSearch && search_after && search_after.length > 0) {
            initialStruct.from = -1
            initialStruct.search_after = search_after
        }
        return initialStruct
    }
    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
        if (appbarIsWhiteFont) toggleAppbarWhiteFont(false)
    },[]);
    useEffect(() => {
        // Check if data of payment detail is still the same as the data from hits
        hits.forEach((singleHit) => {
            if (singleHit.id === productDetail.id) {
                let newProductDetail = JSON.parse(JSON.stringify(productDetail))
                setProductDetail(newProductDetail);
            }
        })
    },[hits]);
    useEffect(() => {
        if (enumeration && enumeration.country) setCountries(enumeration.country)
        if (enumeration && enumeration.currency) setCurrencies(enumeration.currency)
    }, [enumeration]);
    useEffect(() => {
        if (organization.id) {
            // console.log(JSON.stringify(organization));
            let esSearchReqBody = constructSearchReqBody()
            // console.log('esSearchReqBody -> ', esSearchReqBody);
            postProductsSearch({organizationId: organization.id}, esSearchReqBody)
            setLoadedPage(1)
        }
    }, [organization.id]);
    useLayoutEffect(() => {
        if (!organization.id) {
            const url = window.location.href
            let urlArr = url.split('/')
            let organizationID = urlArr[urlArr.length - 2]
            // console.log('organizationID -> ', organizationID);
            getOrganization({organizationId: organizationID})
            // GetOrgPos({organizationId: organizationID})
        } else {
            // GetOrgPos({organizationId: organization.id})
        }
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'currency'})
        getEnumeration({enumGroup: 'productservicetype'})
        return function cleanup() {
            clearProductsSearch()
        };
    },[])
    const handleShow = (field) => {
        setShowEdit(field)
    }

    const saveFile = (pdtDetails, file, content, loading, fileExtension) => {
        try {
            // console.log('file ...');
            // console.log(file);
            fetchApi(environment,
                '/organizations/:organizationId/file',
                'post',
                {organizationId: organization.id},
                { 
                    key: uuidGenerator(),
                    contentType: file.type,
                    fileExtension: fileExtension
                }
                ).then((result) => {
                    // console.log('presigned result => ', result);
                    const { data } =result
                    if (!data.url) return
                    const xhr = new XMLHttpRequest()
                    let url = data.url
                    
                    xhr.open(data.method, url)
                    xhr.setRequestHeader('x-amz-acl', data.XamzAcl)
                    xhr.setRequestHeader('Content-Type', file.type)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            // Update product's image
                            // console.log(`${data.key ? data.key : ''}`);
                            // console.log(`${JSON.stringify(data)}`);
                            // console.log(`Completed S3 Upload!`);
                            // Check if it's certificates field or previousProcessings field
                            if (loading === 'productImage') {
                                let currentImagesArr = pdtDetails.imageUrls ? pdtDetails.imageUrls : []
                                let currentImages = [...currentImagesArr]
                                if (currentImages[currentImages.length - 1] && currentImages[currentImages.length - 1].url === 'https://' + data.cdnUrl) {
                                    return;
                                }
                                currentImages.push({
                                    url: 'https://' + data.cdnUrl,
                                    arrangement: currentImages.length,
                                    isThumbnail: false
                                })
                                // console.log(`Updating product !`);
                                updateProduct({organizationId: organization.id, productId: pdtDetails.id}, { imageUrls: currentImages })
                                pdtDetails.imageUrls = currentImages
                                setProductDetail(JSON.parse(JSON.stringify(pdtDetails)))
                                setLoading(false, loading)
                            } else if (loading === 'productFile') {
                                let currentFilesArr = pdtDetails.dataSheetUrls ? pdtDetails.dataSheetUrls : []
                                let currentFiles = [...currentFilesArr]
                                if (currentFiles[currentFiles.length - 1] && currentFiles[currentFiles.length - 1].url === 'https://' + data.cdnUrl) {
                                    return;
                                }
                                currentFiles.push({
                                    url: 'https://' + data.cdnUrl,
                                    fileName: file.name,
                                })
                                // console.log(`Updating product !`);
                                updateProduct({organizationId: organization.id, productId: pdtDetails.id}, { dataSheetUrls: currentFiles })
                                pdtDetails.dataSheetUrls = currentFiles
                                setProductDetail(JSON.parse(JSON.stringify(pdtDetails)))
                                setLoading(false, loading)
                            }
                        }
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setLoading(false, loading);
            // console.log('error : ', err);
        }
    }

    const setLoading = (boolean, loading) => {
        if (loading === 'productImage') {
            setProcessImageLoading(boolean)
        } else if (loading === 'productFile') {
            setProcessFileLoading(boolean)
        }
    }

    const fileUpload = (pdtDetails, eventFile, loading) => {
        handleShow('')
        setLoading(true, loading);
        let file = eventFile[0]
        // console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setLoading(false, loading);
            return;
        }
        let fileExtension;
        if (loading === 'productImage') {
            switch (file.type) {
                case 'image/png':
                    matchedFileType = true;
                    fileExtension = 'png'
                    break;
                case 'image/jpeg':
                    matchedFileType = true;
                    fileExtension = 'jpeg'
                    break;
                default:
                    break;
            }
        } else if (loading === 'productFile') {
            switch (file.type) {
                case 'application/pdf':
                    matchedFileType = true;
                    fileExtension = 'pdf'
                    break;
                default:
                    break;
            }
        }
        if(!matchedFileType) {
            setLoading(false, loading);
            if (loading === 'productImage') {
                alert('Only png and jpeg file types allowed')
            } else if (loading === 'productFile') {
                alert('Only pdf file types allowed')
            } else {
                alert('invalid request')
            }
            return;
        }
        const max_file_size = 205600 * 10
        if (file.size <= max_file_size){
            const reader = new FileReader()
            // console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                console.log('content -> ', content);
                console.log('file 2 -> ', file);
                
                saveFile(pdtDetails, file, content, loading, fileExtension)
            }
        }else {
            setLoading(false, loading);
            alert('Maximum size exceeded for file! Please make sure file size is less than 2MB');
        }
    }

    const removeFile = (pdtDetails, index, loading) => {
        let currentPdtDetails = JSON.parse(JSON.stringify(pdtDetails))
        if (loading === 'productImage') {
            currentPdtDetails.imageUrls = currentPdtDetails.imageUrls.filter((image, imgIndex) => {
                return imgIndex != index
            })
        } else if (loading === 'productFile') {
            currentPdtDetails.dataSheetUrls = currentPdtDetails.dataSheetUrls.filter((image, fileIndex) => {
                return fileIndex != index
            })
        }
        updateProduct({organizationId: organization.id, productId: pdtDetails.id}, JSON.parse(JSON.stringify(currentPdtDetails)))
        setProductDetail(JSON.parse(JSON.stringify(currentPdtDetails)))
    }

    const history = useHistory();

    return (
    <main className={classes.topMargin}>
    <Typography variant="h4" className={classes.Title}>Products</Typography>
    <AppBarMod position="static" color="default"></AppBarMod>
    <Container>
        <div className={classes.poslist}>
            <div>
            <Accordion expanded={isExpanded}>
                <AccordionSummary
                    onClick={() => {setIsExpanded(!isExpanded)}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Type</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                defaultValue={searchType}
                                onChange={(e) => setSearchType(e.target.value)}
                                label="Type"
                            >
                                {
                                    productservicetypes.map((theType, index) => {
                                        return <MenuItem key={'type-'+ index} value={theType.name}>{theType.name}</MenuItem>
                                    })
                                }
                                <MenuItem key={'type-0'} value={""}>Select Type</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Name</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchName(e.target.value)} defaultValue={searchName}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Brand</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchBrand(e.target.value)} defaultValue={searchBrand}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Description</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchDescription(e.target.value)} defaultValue={searchDescription}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Available Quantity From</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchQuantityFrom(e.target.value)} defaultValue={searchQuantityFrom}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Available Quantity To</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchQuantityTo(e.target.value)} defaultValue={searchQuantityTo}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}></Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Minimum Quantity</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchMinQuantity(e.target.value)} defaultValue={searchMinQuantity}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Multiples of X Quantity</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchMulQuantity(e.target.value)} defaultValue={searchMulQuantity}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}></Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Category</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchCategory(e.target.value)} defaultValue={searchCategory}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Selling Amount (in lowest denominator e.g. 100 for $1.00)</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchSellingAmountFrom(e.target.value)} defaultValue={searchSellingAmountFrom}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Selling Amount (in lowest denominator e.g. 100 for $1.00)</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchSellingAmountTo(e.target.value)} defaultValue={searchSellingAmountTo}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Selling Currency</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={searchSellingCurrency || 'Select Currency'}
                                onChange={(e) => {
                                    setSearchSellingCurrency(e.target.value)
                                }}
                                label="Currency"
                            >
                                {
                                    currencies && currencies.length > 0 && currencies.map((curr, index) => {
                                        return <MenuItem key={index} value={curr.value.currency_alpha3_code}>{curr.name}</MenuItem>
                                    })
                                }
                                <MenuItem key={'select_currency'} value={'Select Currency'}>Select Currency</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Product Number</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchProductNumber(e.target.value)} defaultValue={searchProductNumber}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Serial Number</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchSerialNumber(e.target.value)} defaultValue={searchSerialNumber}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Size</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchPdtSize(e.target.value)} defaultValue={searchPdtSize}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}></Grid>
                        <Grid item md={4} xs={4} sm={4}></Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Expiry Date From</Typography>
                            <DOBTextField
                                id="date"
                                type="date"
                                onChange={(e) => {
                                    let date = new Date(e.target.value)
                                    setSearchExpiryFrom(date.toISOString())
                                }}
                                defaultValue={(searchExpiryFrom) || "2017-05-24"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Expiry Date To</Typography>
                            <DOBTextField
                                id="date"
                                type="date"
                                onChange={(e) => {
                                    let date = new Date(e.target.value)
                                    setSearchExpiryTo(date.toISOString())
                                }}
                                defaultValue={(searchExpiryTo) || "2017-05-24"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}></Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Manufactured Date From</Typography>
                            <DOBTextField
                                id="date"
                                type="date"
                                onChange={(e) => {
                                    let date = new Date(e.target.value)
                                    setSearchManufacturedDateFrom(date.toISOString())
                                }}
                                defaultValue={(searchManufacturedDateFrom) || "2017-05-24"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Manufactured Date To</Typography>
                            <DOBTextField
                                id="date"
                                type="date"
                                onChange={(e) => {
                                    let date = new Date(e.target.value)
                                    setSearchManufacturedDateTo(date.toISOString())
                                }}
                                defaultValue={(searchManufacturedDateTo) || "2017-05-24"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}></Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture Address Line 1</b></Typography>
                            <OutlinedInputMod placeholder={'Address Line 1'} fullWidth onChange={(e) => setSearchManuAddLine1(e.target.value)} defaultValue={searchManuAddLine1}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture Address Line 2</b></Typography>
                            <OutlinedInputMod placeholder={'Address Line 2'} fullWidth onChange={(e) => setSearchManuAddLine2(e.target.value)} defaultValue={searchManuAddLine2}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture City</b></Typography>
                            <OutlinedInputMod placeholder={'City'} fullWidth onChange={(e) => setSearchManuCity(e.target.value)} defaultValue={searchManuCity}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture State</b></Typography>
                            <OutlinedInputMod placeholder={'State'} fullWidth onChange={(e) => setSearchManuState(e.target.value)} defaultValue={searchManuState}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Manufactured Country</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={(searchManuCountry) || ""}
                                onChange={(e) => {
                                    setSearchManuCountry(e.target.value)
                                }}
                                label="Country"
                            >
                                {
                                    countries && countries.map((country, index) => {
                                        return <MenuItem key={'country-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                    })
                                }
                                <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture Postal</b></Typography>
                            <OutlinedInputMod placeholder={'Postal'} fullWidth onChange={(e) => setSearchManuPostal(e.target.value)} defaultValue={searchManuPostal}/>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Tags</Typography>
                            <GreenAutocomplete
                                multiple
                                id="tags-filled"
                                options={suggestions.map((option) => option.name)}
                                onChange={(e,v) => { 
                                    setSearchTags(v)
                                }}
                                // defaultValue={[top100Films[13].title]}
                                fullWidth
                                freeSolo
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <GreenTextField {...params} variant="filled" placeholder="Tags" />
                                )}
                            />
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom><b>Warehouse Address Line 1</b></Typography>
                            <OutlinedInputMod placeholder={'Address Line 1'} fullWidth onChange={(e) => setSearchLocationAddLine1(e.target.value)} defaultValue={searchLocationAddLine1}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom><b>Warehouse Address Line 2</b></Typography>
                            <OutlinedInputMod placeholder={'Address Line 2'} fullWidth onChange={(e) => setSearchLocationAddLine2(e.target.value)} defaultValue={searchLocationAddLine2}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom><b>Warehouse City</b></Typography>
                            <OutlinedInputMod placeholder={'City'} fullWidth onChange={(e) => setSearchLocationCity(e.target.value)} defaultValue={searchLocationCity}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom><b>Warehouse State</b></Typography>
                            <OutlinedInputMod placeholder={'State'} fullWidth onChange={(e) => setSearchLocationState(e.target.value)} defaultValue={searchLocationState}/>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Warehouse Country</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={(searchLocationCountry) || ""}
                                onChange={(e) => {
                                    setSearchLocationCountry(e.target.value)
                                }}
                                label="Country"
                            >
                                {
                                    countries && countries.map((country, index) => {
                                        return <MenuItem key={'country-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                    })
                                }
                                <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture Postal</b></Typography>
                            <OutlinedInputMod placeholder={'Postal'} fullWidth onChange={(e) => setSearchLocationPostal(e.target.value)} defaultValue={searchLocationPostal}/>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <ButtonMod onClick={() => {
                                setPage(1);
                                setLoadedPage(1)
                                setSearchFrom(0);
                                let esSearchReqBody = constructSearchReqBody(null, true)
                                // console.log('PLEASE DONT FIRE! esSearchReqBody : ', esSearchReqBody);
                                postProductsSearch({organizationId: organization.id}, esSearchReqBody)
                                setSearchName('')
                                setSearchType('')
                                setSearchDescription('')
                                setSearchBrand('')
                                setSearchQuantityFrom(0)
                                setSearchQuantityTo(0)
                                setSearchMinQuantity(0)
                                setSearchMulQuantity(0)
                                setSearchCategory('')
                                setSearchSellingAmountFrom(0)
                                setSearchSellingAmountTo(0)
                                setSearchSellingCurrency('')
                                setSearchProductNumber('')
                                setSearchSerialNumber('')
                                setSearchPdtSize('')
                                setSearchExpiryFrom('')
                                setSearchExpiryTo('')
                                setSearchManufacturedDateFrom('')
                                setSearchManufacturedDateTo('')
                                setSearchManuAddLine1('')
                                setSearchManuAddLine2('')
                                setSearchManuCity('')
                                setSearchManuState('')
                                setSearchManuCountry('')
                                setSearchManuPostal('')
                                setSearchTags('')
                                setSearchLocationAddLine1('')
                                setSearchLocationAddLine2('')
                                setSearchLocationCity('')
                                setSearchLocationState('')
                                setSearchLocationCountry('')
                                setSearchLocationPostal('')
                                setIsExpanded(false)
                            }}>Search</ButtonMod>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            </div>
            <Grid container spacing={3}>
                <Grid item md={12} xs={12} sm={12}>
                    <Box justifyContent="flex-end" display="flex">
                        <Box p={1} padding={0}>
                            <ButtonMod
                                onClick={() => {
                                    setNewProductDialog(true)
                                }}
                            >
                                <AddIcon className={classes.addIcon}/> Add New
                            </ButtonMod>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
        <div className={classes.poslist}>
            <Grid container spacing={3}>
                {/* List of Products */}
                {/* {`totalHits -> ${totalHits} `}
                {`hits -> ${hits.length}`} */}
                <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                    <XGrid
                        className={classes.xgrid}
                        page={page}
                        onPageChange={(params) => {
                            // console.log('onPageChange FIRED first part !!', params.page , ' ', loadedPage);
                            if (page >= params.page) return
                            setPage(params.page);
                            if (params.page <= loadedPage) return
                            // console.log('onPageChange FIRED second part !!', params.page , ' ', loadedPage);
                            setLoadedPage(loadedPage + 1)
                            let esSearchReqBody = constructSearchReqBody()
                            // console.log('esSearchReqBody -> ', esSearchReqBody);
                            postProductsSearchAfter({organizationId: organization.id}, esSearchReqBody)
                        }}
                        onPageSizeChange={(params) =>{
                            // console.log('onPageSizeChange params.pageSize -> ', params.pageSize);
                            if (searchSize === params.pageSize) return
                            setSearchSize(params.pageSize)
                            setPage(1);
                            setLoadedPage(1)
                            let esSearchReqBody = constructSearchReqBody(params.pageSize, true)
                            // console.log('esSearchReqBody -> ', esSearchReqBody);
                            // console.log('PLEASE DONT FIRE!');
                            postProductsSearch({organizationId: organization.id}, esSearchReqBody)
                        }}
                        rowCount={totalHits}
                        rowsPerPageOptions={[10,25,50,100]}
                        pageSize={10}
                        pagination
                        columns={[
                            { 
                                field: 'id',
                                headerName: 'Product ID',
                                width: 350,
                                valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                            },
                            { 
                                field: 'name',
                                headerName: 'Name',
                                width: 170,
                                valueGetter: (params) => {
                                    // console.log('params !@#!@#!@#~!#~!@#~!@#!~@#@ ---> ', params);
                                    return `${(params && params.row && params.row.name) || ""}`
                                },
                            },
                            { 
                                field: 'brand',
                                headerName: 'Brand',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.brand) || ""}`
                                },
                            },
                            { 
                                field: 'description',
                                headerName: 'Description',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.description) || ""}`
                                },
                            },
                            { 
                                field: 'type',
                                headerName: 'Type',
                                width: 110,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.type) || ""}`
                                },
                            },
                            { 
                                field: 'quantity',
                                headerName: 'Available Quantity',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.quantity) || ""}`
                                },
                            },
                            { 
                                field: 'minQuantity',
                                headerName: 'Minimum Quantity',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.minQuantity) || ""}`
                                },
                            },
                            { 
                                field: 'multiplesQuantity',
                                headerName: 'Multiples of X Quantity',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.multiplesQuantity) || ""}`
                                },
                            },
                            { 
                                field: 'category',
                                headerName: 'Category',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.category) || ""}`
                                },
                            },
                            { 
                                field: 'sellingAmount',
                                headerName: 'Selling Amount',
                                width: 170,
                                valueGetter: getAmountSelling,
                            },
                            { 
                                field: 'productNumber',
                                headerName: 'Product Number',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.productNumber) || ""}`
                                },
                            },
                            { 
                                field: 'serialNumber',
                                headerName: 'Serial Number',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.serialNumber) || ""}`
                                },
                            },
                            { 
                                field: 'size',
                                headerName: 'Size',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.size) || ""}`
                                },
                            },
                            {
                                field: 'expiry',
                                headerName: 'Expiry',
                                width: 280,
                                valueGetter: (params) => {
                                    let dateString = ''
                                    if (params && params.row && params.row.expiryDate) {
                                        let date = new Date(params.row.expiryDate)
                                        dateString = date.toUTCString()
                                    } 
                                    return dateString
                                },
                            },
                            {
                                field: 'manufacturedDate',
                                headerName: 'Manufactured Date',
                                width: 280,
                                valueGetter: (params) => {
                                    let dateString = ''
                                    if (params && params.row && params.row.manufacturedDate) {
                                        let date = new Date(params.row.manufacturedDate)
                                        dateString = date.toUTCString()
                                    } 
                                    return dateString
                                },
                            },
                            { 
                                field: 'manufacturedAddress.line1',
                                headerName: 'Manufactured Address Line 1',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.manufacturedAddress && params.row.manufacturedAddress.line1) || ""}`
                                },
                            },
                            { 
                                field: 'manufacturedAddress.line2',
                                headerName: 'Manufactured Address Line 2',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.manufacturedAddress && params.row.manufacturedAddress.line2) || ""}`
                                },
                            },
                            { 
                                field: 'manufacturedAddress.city',
                                headerName: 'Manufactured City',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.manufacturedAddress && params.row.manufacturedAddress.city) || ""}`
                                },
                            },
                            { 
                                field: 'manufacturedAddress.state',
                                headerName: 'Manufactured State',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.manufacturedAddress && params.row.manufacturedAddress.state) || ""}`
                                },
                            },
                            { 
                                field: 'manufacturedAddress.country',
                                headerName: 'Manufactured Country',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.manufacturedAddress && params.row.manufacturedAddress.country) || ""}`
                                },
                            },
                            { 
                                field: 'manufacturedAddress.postal',
                                headerName: 'Manufactured Postal',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.manufacturedAddress && params.row.manufacturedAddress.postal) || ""}`
                                },
                            },
                            { 
                                field: 'location.line1',
                                headerName: 'Location Address Line 1',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.location && params.row.location.line1) || ""}`
                                },
                            },
                            { 
                                field: 'location.line2',
                                headerName: 'Location Address Line 2',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.location && params.row.location.line2) || ""}`
                                },
                            },
                            { 
                                field: 'location.city',
                                headerName: 'Location City',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.location && params.row.location.city) || ""}`
                                },
                            },
                            { 
                                field: 'location.state',
                                headerName: 'Location State',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.location && params.row.location.state) || ""}`
                                },
                            },
                            { 
                                field: 'location.country',
                                headerName: 'Location Country',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.location && params.row.location.country) || ""}`
                                },
                            },
                            { 
                                field: 'location.postal',
                                headerName: 'Location Postal',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.location && params.row.location.postal) || ""}`
                                },
                            },
                        ]}
                        rows={hits}
                        onRowClick={async (rowParams) => {
                            // console.log('FIRED ROW CLICK! -> ', rowParams);
                            SetLoading(true)
                            const productId = (rowParams.row && rowParams.row.id) || ""
                            try {
                                await fetchApi(environment,
                                    '/organizations/:organizationId/products/:productId',
                                    'get',
                                    {organizationId: organization.id, productId: productId},
                                    ).then(async (result) => {
                                        // console.log('productDetailResp result => ', result);
                                        const productDetailResp = result.data || {};
                                        setProductDetail(productDetailResp)
                                })
                            } catch (err) {
                                // console.log(err);
                                return err
                            }
                            SetLoading(false)
                            setDialogIsOpen(true)
                        }}
                    />
                </div>
            </Grid>
        </div>
        <Dialog
            scroll={'body'}
            fullWidth
            maxWidth={'xl'}
            open={newProductDialog}
            onClose={closeNewProductDialog}
            aria-labelledby="max-width-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="max-width-dialog-title">New Product</DialogTitle>
            <DialogContent id="scroll-dialog-description">
                <Grid container spacing={3}>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Name</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewName(e.target.value)} defaultValue={newName}/>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Type</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            defaultValue={newType|| "product"}
                            onChange={(e) => setNewType(e.target.value)}
                            label="Type"
                        >
                            {
                                productservicetypes.map((theType, index) => {
                                    return <MenuItem key={'type-'+ index} value={theType.name}>{theType.name}</MenuItem>
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Brand</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewBrand(e.target.value)} defaultValue={newBrand}/>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Description</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewDescription(e.target.value)} defaultValue={newDescription}/>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <ButtonRed
                            className={classes.detailBtns}
                            onClick={() => {
                                setNewProductDialog(false)
                            }}
                        >
                            Cancel
                        </ButtonRed>
                        <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                addProduct()
                                setNewProductDialog(false)
                            }}
                        >
                            Create
                        </ButtonMod>
                    </Grid>
                    <Grid item md={8} xs={8} sm={8}>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        <Dialog
            fullWidth
            maxWidth={'xl'}
            open={dialogIsOpen}
            onClose={closeDiaLog}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">Product Details</DialogTitle>
            <DialogContent>
                {/* {`${JSON.stringify(productDetail)}`} */}
                <Grid container spacing={3}>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>Product ID</span> {`${productDetail.id}`}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Type</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={productDetail.type || ''}
                            onChange={(e) => {
                                let editProductDetail = {}
                                editProductDetail.type = e.target.value
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.type = e.target.value
                                setProductDetail(updatedProductDetail)
                            }}
                            label="Type"
                        >
                            {
                                productservicetypes.map((theType, index) => {
                                    return <MenuItem key={index} value={theType.name}>{theType.name}</MenuItem>
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Name</b></Typography>
                        {showEdit !== 'editName' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.name) || ""}`}
                            <IconButton onClick={() => handleShow('editName')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editName' && <OutlinedInputMod placeholder={'Name'} fullWidth onChange={(e) => setEditName(e.target.value)} defaultValue={editName}/>}
                        {showEdit === 'editName' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editName' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.name = editName
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.name = editName
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Brand</b></Typography>
                        {showEdit !== 'editBrand' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.brand) || ""}`}
                            <IconButton onClick={() => handleShow('editBrand')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editBrand' && <OutlinedInputMod placeholder={'Brand'} fullWidth onChange={(e) => setEditBrand(e.target.value)} defaultValue={editBrand}/>}
                        {showEdit === 'editBrand' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editBrand' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.brand = editBrand
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.brand = editBrand
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Description</b></Typography>
                        {showEdit !== 'editDescription' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.description) || ""}`}
                            <IconButton onClick={() => handleShow('editDescription')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editDescription' && <OutlinedInputMod placeholder={'Description'} fullWidth onChange={(e) => setEditDescription(e.target.value)} defaultValue={editDescription}/>}
                        {showEdit === 'editDescription' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editDescription' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.description = editDescription
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.description = editDescription
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Available Quantity</b></Typography>
                        {showEdit !== 'editQuantity' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.quantity) || ""}`}
                            <IconButton onClick={() => handleShow('editQuantity')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editQuantity' && <OutlinedInputMod placeholder={'Available Quantity'} fullWidth onChange={(e) => setEditQuantity(e.target.value)} defaultValue={editQuantity}/>}
                        {showEdit === 'editQuantity' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editQuantity' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.quantity = parseInt(editQuantity)
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.quantity = editQuantity
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Minimum Quantity</b></Typography>
                        {showEdit !== 'editMinQuantity' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.minQuantity) || ""}`}
                            <IconButton onClick={() => handleShow('editMinQuantity')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editMinQuantity' && <OutlinedInputMod placeholder={'Minimum Quantity'} fullWidth onChange={(e) => setEditMinQuantity(e.target.value)} defaultValue={editMinQuantity}/>}
                        {showEdit === 'editMinQuantity' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editMinQuantity' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.minQuantity = parseInt(editMinQuantity)
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.minQuantity = editMinQuantity
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Multiples of X Quantity</b></Typography>
                        {showEdit !== 'editMulQuantity' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.multiplesQuantity) || ""}`}
                            <IconButton onClick={() => handleShow('editMulQuantity')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editMulQuantity' && <OutlinedInputMod placeholder={'Multiples of X Quantity'} fullWidth onChange={(e) => setEditMulQuantity(e.target.value)} defaultValue={editMulQuantity}/>}
                        {showEdit === 'editMulQuantity' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editMulQuantity' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.multiplesQuantity = parseInt(editMulQuantity)
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.multiplesQuantity = editMulQuantity
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Selling Amount (In lowest denominator e.g. input 100 for $1.00)</b></Typography>
                        {showEdit !== 'editSellingAmount' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.sellingAmount) || ""}`}
                            <IconButton onClick={() => handleShow('editSellingAmount')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editSellingAmount' && <OutlinedInputMod placeholder={'Selling Amount'} fullWidth onChange={(e) => setEditSellingAmount(e.target.value)} defaultValue={editSellingAmount}/>}
                        {showEdit === 'editSellingAmount' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editSellingAmount' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.sellingAmount = parseInt(editSellingAmount)
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.sellingAmount = editSellingAmount
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Selling Currency</Typography>
                        <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={productDetail.sellingCurrency || 'Select Currency'}
                                onChange={(e) => {
                                    let editProductDetail = {}
                                    editProductDetail.sellingCurrency = e.target.value
                                    updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                    const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                    updatedProductDetail.sellingCurrency = e.target.value
                                    setProductDetail(updatedProductDetail)
                                }}
                                label="Currency"
                            >
                                {
                                    currencies && currencies.length > 0 && currencies.map((curr, index) => {
                                        return <MenuItem key={index} value={curr.value.currency_alpha3_code}>{curr.name}</MenuItem>
                                    })
                                }
                                <MenuItem key={'select_currency'} value={'Select Currency'}>Select Currency</MenuItem>
                            </Select>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}></Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography component={'span'} className={classes.label}>Product Images</Typography>
                        <input className={classes.hiddeninput} onChange={(e) => {fileUpload(productDetail, e.target.files, 'productImage')}} id={`image-button-file`} type="file" />
                        <label htmlFor="image-button-file">
                            <IconButton disabled={processImageLoading} color="primary" aria-label="upload file" component="span">
                                <PublishIcon className={classes.editIcon}/>
                            </IconButton>
                        </label>
                        {processImageLoading ? <div><CircularProgress className={classes.circularProgress} /></div> :  <Grid container>
                        {productDetail && productDetail.imageUrls && productDetail.imageUrls.map((eachImageFile, fileIndex) => {
                            return <Grid item md={2} xs={12} sm={12} key={fileIndex}>
                                    <div style={{backgroundImage: `url(${eachImageFile.url})`, width: '100%', height: '210px', backgroundSize: 'cover'}}> </div>
                                    <IconButton onClick={() => { removeFile(productDetail, fileIndex, 'productImage') }} component="span">
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                        })}
                        </Grid>}
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography component={'span'} className={classes.label}>Product DataSheet</Typography>
                        <input className={classes.hiddeninput} onChange={(e) => {fileUpload(productDetail, e.target.files, 'productFile')}} id={`datasheet-button-file`} type="file" />
                        <label htmlFor="datasheet-button-file">
                            <IconButton disabled={processFileLoading} color="primary" aria-label="upload file" component="span">
                                <PublishIcon className={classes.editIcon}/>
                            </IconButton>
                        </label>
                        {processFileLoading ? <div><CircularProgress className={classes.circularProgress} /></div> :  <Grid container>
                        {productDetail && productDetail.dataSheetUrls && productDetail.dataSheetUrls.map((eachDataFile, fileIndex) => {
                            return <Grid item md={12} xs={12} sm={12} key={fileIndex}>
                                    <Typography component={'span'} className={classes.label}>{eachDataFile.fileName}</Typography>
                                    <IconButton onClick={() => {window.open(eachDataFile.url, "_blank") }} component="span">
                                        <FolderIcon />
                                    </IconButton>
                                    <IconButton onClick={() => { removeFile(productDetail, fileIndex, 'productFile') }} component="span">
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                        })}
                        </Grid>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Category</b></Typography>
                        {showEdit !== 'editCategory' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.category) || ""}`}
                            <IconButton onClick={() => handleShow('editCategory')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editCategory' && <OutlinedInputMod placeholder={'Category'} fullWidth onChange={(e) => setEditCategory(e.target.value)} defaultValue={editCategory}/>}
                        {showEdit === 'editCategory' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editCategory' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.category = editCategory
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.category = editCategory
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Product Number</b></Typography>
                        {showEdit !== 'editProductNumber' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.productNumber) || ""}`}
                            <IconButton onClick={() => handleShow('editProductNumber')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editProductNumber' && <OutlinedInputMod placeholder={'Product Number'} fullWidth onChange={(e) => setEditProductNumber(e.target.value)} defaultValue={editProductNumber}/>}
                        {showEdit === 'editProductNumber' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editProductNumber' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.productNumber = editProductNumber
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.productNumber = editProductNumber
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Serial Number</b></Typography>
                        {showEdit !== 'editSerialNumber' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.serialNumber) || ""}`}
                            <IconButton onClick={() => handleShow('editSerialNumber')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editSerialNumber' && <OutlinedInputMod placeholder={'Serial Number'} fullWidth onChange={(e) => setEditSerialNumber(e.target.value)} defaultValue={editSerialNumber}/>}
                        {showEdit === 'editSerialNumber' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editSerialNumber' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.serialNumber = editSerialNumber
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.serialNumber = editSerialNumber
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Expiry Date</Typography>
                        <DOBTextField
                            id="date"
                            type="date"
                            onChange={(e) => {
                                let editProductDetail = {}
                                let date = new Date(e.target.value)
                                editProductDetail.expiryDate = date.toISOString()
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.expiryDate = date.toISOString()
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                            defaultValue={(productDetail.expiryDate && new Date(productDetail.expiryDate).getUTCFullYear() + "-" + ((new Date(productDetail.expiryDate).getUTCMonth() + 1) < 10 ? "0" : "") + (new Date(productDetail.expiryDate).getUTCMonth() + 1) + "-" + (new Date(productDetail.expiryDate).getUTCDate())) || "2017-05-24"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Size</b></Typography>
                        {showEdit !== 'editSize' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.size) || ""}`}
                            <IconButton onClick={() => handleShow('editSize')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editSize' && <OutlinedInputMod placeholder={'Size'} fullWidth onChange={(e) => setEditSize(e.target.value)} defaultValue={editSize}/>}
                        {showEdit === 'editSize' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editSize' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.size = editSize
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.size = editSize
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Manufactured Date</Typography>
                        <DOBTextField
                            id="date"
                            type="date"
                            onChange={(e) => {
                                let editProductDetail = {}
                                let date = new Date(e.target.value)
                                editProductDetail.manufacturedDate = date.toISOString()
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.manufacturedDate = date.toISOString()
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                            defaultValue={(productDetail.manufacturedDate && new Date(productDetail.manufacturedDate).getUTCFullYear() + "-" + ((new Date(productDetail.manufacturedDate).getUTCMonth() + 1) < 10 ? "0" : "") + (new Date(productDetail.manufacturedDate).getUTCMonth() + 1) + "-" + (new Date(productDetail.manufacturedDate).getUTCDate())) || "2017-05-24"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>}
                    {productDetail.type !== 'product' && <Grid item md={4} xs={4} sm={4}></Grid>}
                    {productDetail.type !== 'product' && <Grid item md={4} xs={4} sm={4}></Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture Address Line 1</b></Typography>
                        {showEdit !== 'editManuAddLine1' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.manufacturedAddress && productDetail.manufacturedAddress.line1) || ""}`}
                            <IconButton onClick={() => handleShow('editManuAddLine1')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editManuAddLine1' && <OutlinedInputMod placeholder={'Address Line 1'} fullWidth onChange={(e) => setEditManuAddLine1(e.target.value)} defaultValue={editManuAddLine1}/>}
                        {showEdit === 'editManuAddLine1' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editManuAddLine1' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = (productDetail.manufacturedAddress && JSON.parse(JSON.stringify({...productDetail, manufacturedAddress: productDetail.manufacturedAddress}))) || {...productDetail, manufacturedAddress: {}}
                                editProductDetail.manufacturedAddress = { ...editProductDetail.manufacturedAddress, line1: editManuAddLine1}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {manufacturedAddress: editProductDetail.manufacturedAddress})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture Address Line 2</b></Typography>
                        {showEdit !== 'editManuAddLine2' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.manufacturedAddress && productDetail.manufacturedAddress.line2) || ""}`}
                            <IconButton onClick={() => handleShow('editManuAddLine2')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editManuAddLine2' && <OutlinedInputMod placeholder={'Address Line 2'} fullWidth onChange={(e) => setEditManuAddLine2(e.target.value)} defaultValue={editManuAddLine2}/>}
                        {showEdit === 'editManuAddLine2' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editManuAddLine2' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = (productDetail.manufacturedAddress && JSON.parse(JSON.stringify({...productDetail, manufacturedAddress: productDetail.manufacturedAddress}))) || {...productDetail, manufacturedAddress: {}}
                                editProductDetail.manufacturedAddress = { ...editProductDetail.manufacturedAddress, line2: editManuAddLine2}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {manufacturedAddress: editProductDetail.manufacturedAddress})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture City</b></Typography>
                        {showEdit !== 'editManuCity' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.manufacturedAddress && productDetail.manufacturedAddress.city) || ""}`}
                            <IconButton onClick={() => handleShow('editManuCity')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editManuCity' && <OutlinedInputMod placeholder={'City'} fullWidth onChange={(e) => setEditManuCity(e.target.value)} defaultValue={editManuCity}/>}
                        {showEdit === 'editManuCity' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editManuCity' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = (productDetail.manufacturedAddress && JSON.parse(JSON.stringify({...productDetail, manufacturedAddress: productDetail.manufacturedAddress}))) || {...productDetail, manufacturedAddress: {}}
                                editProductDetail.manufacturedAddress = { ...editProductDetail.manufacturedAddress, city: editManuCity}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {manufacturedAddress: editProductDetail.manufacturedAddress})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture State</b></Typography>
                        {showEdit !== 'editManuState' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.manufacturedAddress && productDetail.manufacturedAddress.state) || ""}`}
                            <IconButton onClick={() => handleShow('editManuState')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editManuState' && <OutlinedInputMod placeholder={'State'} fullWidth onChange={(e) => setEditManuState(e.target.value)} defaultValue={editManuState}/>}
                        {showEdit === 'editManuState' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editManuState' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = (productDetail.manufacturedAddress && JSON.parse(JSON.stringify({...productDetail, manufacturedAddress: productDetail.manufacturedAddress}))) || {...productDetail, manufacturedAddress: {}}
                                editProductDetail.manufacturedAddress = { ...editProductDetail.manufacturedAddress, state: editManuState}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {manufacturedAddress: editProductDetail.manufacturedAddress})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Manufactured Country</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(productDetail.manufacturedAddress && productDetail.manufacturedAddress.country) || ""}
                            onChange={(e) => {
                                let editProductDetail = (productDetail.manufacturedAddress && JSON.parse(JSON.stringify({...productDetail, manufacturedAddress: productDetail.manufacturedAddress}))) || {...productDetail, manufacturedAddress: {}}
                                editProductDetail.manufacturedAddress = { ...productDetail.manufacturedAddress, country: e.target.value}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {manufacturedAddress: editProductDetail.manufacturedAddress})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                            label="Country"
                        >
                            {
                                countries && countries.map((country, index) => {
                                    return <MenuItem key={'country-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                })
                            }
                            <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                        </Select>
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Manufacture Postal</b></Typography>
                        {showEdit !== 'editManuPostal' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.manufacturedAddress && productDetail.manufacturedAddress.postal) || ""}`}
                            <IconButton onClick={() => handleShow('editManuPostal')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editManuPostal' && <OutlinedInputMod placeholder={'Postal'} fullWidth onChange={(e) => setEditManuPostal(e.target.value)} defaultValue={editManuPostal}/>}
                        {showEdit === 'editManuPostal' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editManuPostal' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = (productDetail.manufacturedAddress && JSON.parse(JSON.stringify({...productDetail, manufacturedAddress: productDetail.manufacturedAddress}))) || {...productDetail, manufacturedAddress: {}}
                                editProductDetail.manufacturedAddress = { ...editProductDetail.manufacturedAddress, postal: editManuPostal}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {manufacturedAddress: editProductDetail.manufacturedAddress})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Tags</Typography>
                        {showEdit !== 'editTags' && <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {`${(productDetail && productDetail.tags && productDetail.tags.map((eachTag) => {
                                return eachTag + ' '
                            }) || "No Tags Found")}`}
                            <IconButton onClick={() => handleShow('editTags')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editTags' && <GreenAutocomplete
                            multiple
                            id="tags-filled"
                            options={suggestions.map((option) => option.name)}
                            onChange={(e,v) => { 
                                setEditTags(v)
                            }}
                            // defaultValue={[top100Films[13].title]}
                            fullWidth
                            freeSolo
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <GreenTextField {...params} variant="filled" placeholder="Tags" />
                            )}
                        />}
                        {showEdit === 'editTags' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editTags' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.tags = editTags
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                let newProductDetail = JSON.parse(JSON.stringify(productDetail))
                                newProductDetail.tags = editTags
                                setProductDetail(newProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Bought At Amount (In lowest denominator e.g. input 100 for $1.00)</b></Typography>
                        {showEdit !== 'editBuyingAmount' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.buyingAmount) || ""}`}
                            <IconButton onClick={() => handleShow('editBuyingAmount')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editBuyingAmount' && <OutlinedInputMod placeholder={'Selling Amount'} fullWidth onChange={(e) => setEditBuyingAmount(e.target.value)} defaultValue={editBuyingAmount}/>}
                        {showEdit === 'editBuyingAmount' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editBuyingAmount' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = {}
                                editProductDetail.buyingAmount = parseInt(editBuyingAmount)
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.buyingAmount = editBuyingAmount
                                setProductDetail(updatedProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Bought At Currency</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={productDetail.buyingCurrency || 'Select Currency'}
                            onChange={(e) => {
                                let editProductDetail = {}
                                editProductDetail.buyingCurrency = e.target.value
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, editProductDetail)
                                const updatedProductDetail = JSON.parse(JSON.stringify(productDetail))
                                updatedProductDetail.buyingCurrency = e.target.value
                                setProductDetail(updatedProductDetail)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((curr, index) => {
                                    return <MenuItem key={index} value={curr.value.currency_alpha3_code}>{curr.name}</MenuItem>
                                })
                            }
                            <MenuItem key={'select_currency'} value={'Select Currency'}>Select Currency</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}></Grid>
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Warehouse Address Line 1</b></Typography>
                        {showEdit !== 'editLocationAddLine1' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.location && productDetail.location.line1) || ""}`}
                            <IconButton onClick={() => handleShow('editLocationAddLine1')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editLocationAddLine1' && <OutlinedInputMod placeholder={'Address Line 1'} fullWidth onChange={(e) => setEditLocationAddLine1(e.target.value)} defaultValue={editLocationAddLine1}/>}
                        {showEdit === 'editLocationAddLine1' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editLocationAddLine1' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = (productDetail.location && JSON.parse(JSON.stringify({...productDetail, location: productDetail.location}))) || {...productDetail, location: {}}
                                editProductDetail.location = { ...editProductDetail.location, line1: editLocationAddLine1}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {location: editProductDetail.location})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Warehouse Address Line 2</b></Typography>
                        {showEdit !== 'editLocationAddLine2' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.location && productDetail.location.line2) || ""}`}
                            <IconButton onClick={() => handleShow('editLocationAddLine2')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editLocationAddLine2' && <OutlinedInputMod placeholder={'Address Line 2'} fullWidth onChange={(e) => setEditLocationAddLine2(e.target.value)} defaultValue={editLocationAddLine2}/>}
                        {showEdit === 'editLocationAddLine2' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editLocationAddLine2' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = (productDetail.location && JSON.parse(JSON.stringify({...productDetail, location: productDetail.location}))) || {...productDetail, location: {}}
                                editProductDetail.location = { ...editProductDetail.location, line2: editLocationAddLine2}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {location: editProductDetail.location})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Warehouse City</b></Typography>
                        {showEdit !== 'editLocationCity' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.location && productDetail.location.city) || ""}`}
                            <IconButton onClick={() => handleShow('editLocationCity')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editLocationCity' && <OutlinedInputMod placeholder={'City'} fullWidth onChange={(e) => setEditLocationCity(e.target.value)} defaultValue={editLocationCity}/>}
                        {showEdit === 'editLocationCity' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editLocationCity' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = (productDetail.location && JSON.parse(JSON.stringify({...productDetail, location: productDetail.location}))) || {...productDetail, location: {}}
                                editProductDetail.location = { ...editProductDetail.location, city: editLocationCity}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {location: editProductDetail.location})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Warehouse State</b></Typography>
                        {showEdit !== 'editLocationState' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.location && productDetail.location.state) || ""}`}
                            <IconButton onClick={() => handleShow('editLocationState')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editLocationState' && <OutlinedInputMod placeholder={'State'} fullWidth onChange={(e) => setEditLocationState(e.target.value)} defaultValue={editLocationState}/>}
                        {showEdit === 'editLocationState' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editLocationState' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = (productDetail.location && JSON.parse(JSON.stringify({...productDetail, location: productDetail.location}))) || {...productDetail, location: {}}
                                editProductDetail.location = { ...editProductDetail.location, state: editLocationState}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {location: editProductDetail.location})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Warehouse Country</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(productDetail.location && productDetail.location.country) || ""}
                            onChange={(e) => {
                                let editProductDetail = (productDetail.location && JSON.parse(JSON.stringify({...productDetail, location: productDetail.location}))) || {...productDetail, location: {}}
                                editProductDetail.location = { ...productDetail.location, country: e.target.value}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {location: editProductDetail.location})
                                // console.log('editProductDetail ----> ', editProductDetail);
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                            label="Country"
                        >
                            {
                                countries && countries.map((country, index) => {
                                    return <MenuItem key={'country-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                })
                            }
                            <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                        </Select>
                    </Grid>}
                    {productDetail.type === 'product' && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Warehouse Postal</b></Typography>
                        {showEdit !== 'editLocationPostal' && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(productDetail.location && productDetail.location.postal) || ""}`}
                            <IconButton onClick={() => handleShow('editLocationPostal')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>}
                        {showEdit === 'editLocationPostal' && <OutlinedInputMod placeholder={'Postal'} fullWidth onChange={(e) => setEditLocationPostal(e.target.value)} defaultValue={editLocationPostal}/>}
                        {showEdit === 'editLocationPostal' && <ButtonMod
                                className={classes.detailBtns}
                                onClick={() => {
                                    setEditName('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>}
                        {showEdit === 'editLocationPostal' && <ButtonMod
                            className={classes.detailBtns}
                            onClick={() => {
                                let editProductDetail = (productDetail.location && JSON.parse(JSON.stringify({...productDetail, location: productDetail.location}))) || {...productDetail, location: {}}
                                editProductDetail.location = { ...editProductDetail.location, postal: editLocationPostal}
                                updateProduct({organizationId: organization.id, productId: productDetail.id}, {location: editProductDetail.location})
                                setProductDetail(editProductDetail)
                                setEditName('')
                                handleShow('')
                            }}
                        >
                            Update
                        </ButtonMod>}
                    </Grid>}
                    <Grid item md={12} xs={12} sm={12}>
                        <ButtonRed
                            onClick={() => {
                                handleShow('')
                                setProductDetail({})
                                deleteProduct({organizationId: organization.id, productId: productDetail.id})
                                removeDeletedProductHit(productDetail)
                                setDialogIsOpen(false)
                            }}
                        >
                            Delete
                        </ButtonRed>
                    </Grid>
                </Grid>
            </DialogContent>
            <Backdrop className={classes.backdrop} open={loading} >
                <CircularProgress className={classes.circularProgress} />
            </Backdrop>
        </Dialog>
    </Container>
    <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress className={classes.circularProgress} />
    </Backdrop>
    </main>
    )
}

export default ProductsManagement