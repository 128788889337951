import { combineReducers } from 'redux'
import navDrawers from './navDrawers'
import appBars from './appBars'
import users from './users'
import organizations from './organizations'
import appstate from './appstate'
import misc from './misc'
import s3 from './s3'
import apiKeys from './apiKeys'
import paymentsReport from './paymentsReport'
import balancesReport from './balancesReport'
import payoutsReport from './payoutsReport'
import invoicesReport from './invoicesReport'
import customerManagement from './customerManagement'
import productManagement from './productManagement'
import disputeManagement from './disputeManagement'

const mainApp = combineReducers({
    navDrawers,
    appBars,
    users,
    organizations,
    paymentsReport,
    balancesReport,
    payoutsReport,
    invoicesReport,
    customerManagement,
    productManagement,
    appstate,
    misc,
    s3,
    apiKeys,
    disputeManagement
  })

export default mainApp