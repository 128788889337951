import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganization, getEnumeration, CreatePos, GetOrgPos, GetPos } from '../actions'
import CheckoutPages from '../components/CheckoutPages'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    checkoutpagesread: (state.organizations.role && state.organizations.role.checkoutpagesread) || false,
    checkoutpageswrite: (state.organizations.role && state.organizations.role.checkoutpageswrite) || false,
    organizationPos: state.organizations.organizationPos || [],
    detailedPos: state.organizations.detailedPos || {},
    detailedPosRoutes: state.organizations.detailedPos || {}
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    CreatePos: (params, reqBody) => dispatch(CreatePos(params, reqBody)),
    GetOrgPos: (params) => dispatch(GetOrgPos(params)),
    GetPos: (params) => dispatch(GetPos(params)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutPages)