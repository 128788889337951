function organizations(state ={isLoading: false, organizations: [], organization: {}}, action) {
    switch (action.type) {
        case 'GET_USER_ORGANIZATIONS_REQUEST':
            return {
                ...state,
                isLoading: true
            }
        case 'GET_USER_ORGANIZATIONS_SUCCESS':
            // console.log('GET_USER_ORGANIZATIONS_SUCCESS' ,action.data);
            return {
                ...state,
                isLoading: false,
                organizations: (action && action.data && action.data) || []
            } 
        case 'GET_USER_ORGANIZATIONS_FAILURE':
            // console.log('GET_USER_ORGANIZATIONS_FAILURE' ,action.data);
            return {
                ...state,
                isLoading: false,
                organizations: (action && action && action.data && action.data) || []
            } 
        case 'CREATE_ORGANIZATION_REQUEST':
            // console.log('CREATE_ORGANIZATION_REQUEST');
            return {
                ...state,
                isLoading: true
            }
        case 'CREATE_ORGANIZATION_SUCCESS':
            // console.log('CREATE_ORGANIZATION_SUCCESS', action);
            let newOrganization = action.data
            let modifiedNewOganization = {
                createdAt: newOrganization.createdAt,
                isPrimaryOwner: true,
                organizationId: newOrganization.id,
                organizationName: newOrganization.name,
                updatedAt: newOrganization.updatedAt
            }
            // console.log('state => ', state);
            // console.log('state.organizations => ', state.organizations);
            let organizations = []
            if (state.organizations) {
                organizations = [...state.organizations,modifiedNewOganization]
            } else {
                organizations = [modifiedNewOganization]
            }
            return {
                ...state,
                isLoading: false,
                organization: newOrganization,
                organizations: organizations,
            } 
        case 'CREATE_ORGANIZATION_FAILURE':
            // console.log('CREATE_ORGANIZATION_FAILURE', action);
            return {...state, isLoading: false} 
        case 'GET_ORGANIZATION_REQUEST':
            // console.log('GET_ORGANIZATION_REQUEST', action);
            return {...state, isLoading: true} 
        case 'GET_ORGANIZATION_SUCCESS':
            // console.log('GET_ORGANIZATION_SUCCESS', action);
            const organization = (action && action && action.data && action.data.organization) || {}
            let role = (action && action.data && action.data.role) || {}
            if (role.scopes) {
                let scopeArr = role.scopes.split(' ')
                scopeArr.map((scope) => {
                    let stringConcat = scope.replace(':', '')
                    role[stringConcat] = true
                    return scope
                })
            }
            return {
                ...state,
                isLoading: false,
                organization: organization,
                role: role,
            }
        case 'GET_ORGANIZATION_FAILURE':
            // console.log('GET_ORGANIZATION_FAILURE', action);
            return {...state, isLoading: false}
        case 'CLEAR_ORG_STATE':
            return {...state, organization: {}}
        case 'PATCH_ORGANIZATION_REQUEST':
            return {...state, isLoading: true}
        case 'PATCH_ORGANIZATION_SUCCESS':
            // console.log('PATCH_ORGANIZATION_SUCCESS', action);
            // console.log('state', state);
            let newOrg = (action && action.data) || state.organization
            return {...state, isLoading: false, organization: JSON.parse(JSON.stringify(newOrg))}
        case 'PATCH_ORGANIZATION_FAILURE':
            return {...state, isLoading: false}
        case 'PATCH_ORGANIZATION_IS_READY_REQUEST':
            return {...state, isLoading: true}
        case 'PATCH_ORGANIZATION_IS_READY_SUCCESS':
            console.log('PATCH_ORGANIZATION_IS_READY_SUCCESS', action);
            let readyOrg = (action && action.data) || state.organization
            return {...state, isLoading: false, organization: JSON.parse(JSON.stringify(readyOrg))}
        case 'PATCH_ORGANIZATION_IS_READY_FAILURE':
            return {...state, isLoading: false}
        case 'GET_ROLES_REQUEST':
            // console.log('GET_ROLES_REQUEST', action);
            return {...state, isLoading: true} 
        case 'GET_ROLES_SUCCESS':
            // console.log('GET_ROLES_SUCCESS', action);
            const allRoles = (action && action.data && action.data.organizationRoles) || []
            return {
                ...state,
                organizationRoles: allRoles,
                isLoading: false,
            }
        case 'GET_ROLES_FAILURE':
            // console.log('GET_ROLES_FAILURE', action);
            return {...state, isLoading: false}
        case 'CREATE_ROLE_REQUEST':
            // console.log('CREATE_ROLE_REQUEST', action);
            return {...state, isLoading: true} 
        case 'CREATE_ROLE_SUCCESS':
            // console.log('CREATE_ROLE_SUCCESS', action);
            const newRole = (action && action.data) || {}
            const newOrganizationRoles = [...state.organizationRoles, newRole]
            return {...state,
                organizationRoles: newOrganizationRoles,
                isLoading: false
            }
        case 'CREATE_ROLE_FAILURE':
            // console.log('CREATE_ROLE_FAILURE', action);
            return {...state, isLoading: false}
        case 'UPDATE_ROLE_REQUEST':
            // console.log('UPDATE_ROLE_REQUEST', action);
            return {...state, isLoading: true} 
        case 'UPDATE_ROLE_SUCCESS':
            // console.log('UPDATE_ROLE_SUCCESS', action);
            if (action && action.data && action.data.id) {
                const newRoles = state.organizationRoles.map((role) => {
                    if (role.id === action.data.id) {
                        return action.data
                    }
                    return role
                })
                return {
                    ...state,
                    organizationRoles: newRoles,
                    isLoading: false
                }
            } else {
                return {
                    ...state,
                    isLoading: false
                }
            }
        case 'UPDATE_ROLE_FAILURE':
            // console.log('UPDATE_ROLE_FAILURE', action);
            return {...state, isLoading: false}
        case 'DELETE_ROLE_REQUEST':
            // console.log('DELETE_ROLE_REQUEST', action);
            return {...state, isLoading: true} 
        case 'DELETE_ROLE_SUCCESS':
            console.log('DELETE_ROLE_SUCCESS', action);
            console.log('state.organizationRoles -> ', state.organizationRoles)
            const newListOfRoles = state.organizationRoles.filter((singleRole)=> {
                if (action && action.data) {
                    return singleRole.id !== action.data.roleId
                }
            })
            return {...state,
                organizationRoles: newListOfRoles,
                isLoading: false
            }
        case 'DELETE_ROLE_FAILURE':
            // console.log('DELETE_ROLE_FAILURE', action);
            return {...state, isLoading: false}
        case 'GET_ORG_USERS_REQUEST':
            // console.log('GET_ORG_USERS_REQUEST', action);
            return {...state, isLoading: true} 
        case 'GET_ORG_USERS_SUCCESS':
            console.log('GET_ORG_USERS_SUCCESS', action);
            const allUsers = (action && action.data) || []
            console.log('allUsers : ', allUsers);
            return {
                ...state,
                organizationUsers: allUsers,
                isLoading: false,
            }
        case 'GET_ORG_USERS_FAILURE':
            // console.log('GET_ORG_USERS_FAILURE', action);
            return {...state, isLoading: false}
        case 'UPDATE_ORG_USER_REQUEST':
            // console.log('UPDATE_ORG_USER_REQUEST', action);
            return {...state, isLoading: true} 
        case 'UPDATE_ORG_USER_SUCCESS':
            // console.log('UPDATE_ORG_USER_SUCCESS', action);
            const newUserOrg = (action && action.data) || {}
            const newUserOrgList = state.organizationUsers.map((eachRelation) => {
                if (eachRelation.userId === newUserOrg.userId) {
                    return newUserOrg
                }
                return eachRelation
            })
            return {
                ...state,
                organizationUsers: newUserOrgList,
                isLoading: false,
            }
        case 'UPDATE_ORG_USER_FAILURE':
            // console.log('UPDATE_ORG_USER_FAILURE', action);
            return {...state, isLoading: false}
        case 'CREATE_INVITE_ORG_USER_REQUEST':
            console.log('CREATE_INVITE_ORG_USER_REQUEST', action);
            return {...state, isLoading: true} 
        case 'CREATE_INVITE_ORG_USER_SUCCESS':
            console.log('CREATE_INVITE_ORG_USER_SUCCESS', action);
            return {
                ...state,
                isLoading: false,
                userInviteSuccess: true
            }
        case 'CREATE_INVITE_ORG_USER_FAILURE':
            console.log('CREATE_INVITE_ORG_USER_FAILURE', action);
            return {...state, isLoading: false}
        case 'RESET_INVITE_ORG_USER_SUCCESS':
            console.log('CREATE_INVITE_ORG_USER_SUCCESS', action);
            return {
                ...state,
                userInviteSuccess: false
            }
        case 'DELETE_ORG_USER_REQUEST':
            // console.log('DELETE_ORG_USER_REQUEST', action);
            return {...state, isLoading: true} 
        case 'DELETE_ORG_USER_SUCCESS':
            // console.log('DELETE_ORG_USER_SUCCESS', action);
            const deletedUser = (action && action.data) || ""
            const newAllUser = state.organizationUsers.filter((eachUser) => {
                return eachUser.userId !== deletedUser
            })
            return {
                ...state,
                organizationUsers: newAllUser,
                isLoading: false,
            }
        case 'DELETE_ORG_USER_FAILURE':
            // console.log('DELETE_ORG_USER_FAILURE', action);
            return {...state, isLoading: false}
        case 'CREATE_POS_REQUEST':
            // console.log('CREATE_POS_REQUEST', action);
            return {...state, isLoading: true}
        case 'CREATE_POS_SUCCESS':
            // console.log('CREATE_POS_SUCCESS', action);
            const createdPos = (action && action.data) || {}
            const currentPos = (state && state.organizationPos) || []
            console.log('currentPos -> ', currentPos);
            const newOrganizationPos = [...currentPos, createdPos]
            return {...state,
                organizationPos: newOrganizationPos,
                detailedPos: createdPos,
                isLoading: false
            }
        case 'CREATE_POS_FAILURE':
            // console.log('CREATE_POS_FAILURE', action);
            return {...state, isLoading: false}
        case 'GET_ORG_POS_REQUEST':
            // console.log('GET_ORG_POS_REQUEST', action);
            return {...state, isLoading: true}
        case 'GET_ORG_POS_SUCCESS':
            console.log('GET_ORG_POS_SUCCESS', action);
            const orgPos = (action && action.data && action.data.pointOfSales) || []
            return {...state,
                organizationPos: orgPos,
                isLoading: false
            }
        case 'GET_ORG_POS_FAILURE':
            // console.log('GET_ORG_POS_FAILURE', action);
            return {...state, isLoading: false}
        case 'GET_POS_REQUEST':
            // console.log('GET_POS_REQUEST', action);
            return {...state, isLoading: true}
        case 'GET_POS_SUCCESS':
            // console.log('GET_POS_SUCCESS', action);
            const detailedPos = (action && action.data) || {}
            return {...state,
                detailedPos: detailedPos,
                isLoading: false
            }
        case 'GET_POS_FAILURE':
            // console.log('GET_POS_FAILURE', action);
            return {...state, isLoading: false}
        case 'PATCH_POS_REQUEST':
            // console.log('PATCH_POS_REQUEST', action);
            return {...state, isLoading: true}
        case 'PATCH_POS_SUCCESS':
            // console.log('PATCH_POS_SUCCESS', action);
            const patchedPos = (action && action.data) || {}
            const newPosList = state.organizationPos.map((eachPos) => {
                if (eachPos.id == patchedPos.id) return patchedPos;
                return eachPos;
            })
            return {...state,
                organizationPos: newPosList,
                detailedPos: patchedPos,
                isLoading: false
            }
        case 'PATCH_POS_FAILURE':
            // console.log('PATCH_POS_FAILURE', action);
            return {...state, isLoading: false}
        case 'DELETE_POS_REQUEST':
            // console.log('DELETE_POS_REQUEST', action);
            return {...state, isLoading: true}
        case 'DELETE_POS_SUCCESS':
            console.log('DELETE_POS_SUCCESS', action);
            const deletedPos = (action && action.data) || {}
            const remainingPosList = state.organizationPos.map((eachPos) => {
                if (eachPos.id == deletedPos.id) return '';
                return eachPos;
            }).filter((eachPos) => eachPos)
            return {...state,
                organizationPos: remainingPosList,
                isLoading: false
            }
        case 'DELETE_POS_FAILURE':
            // console.log('DELETE_POS_FAILURE', action);
            return {...state, isLoading: false}
        case 'GET_POS_ROUTE_REQUEST':
            // console.log('GET_POS_ROUTE_REQUEST', action);
            return {...state, isLoading: true}
        case 'GET_POS_ROUTE_SUCCESS':
            // console.log('GET_POS_ROUTE_SUCCESS', action);
            const posRoutes = (action && action.data) || {}
            if (!posRoutes.rules) {
                posRoutes.rules = []
            }
            return {...state,
                detailedPosRoutes: posRoutes,
                isLoading: false
            }
        case 'GET_POS_ROUTE_FAILURE':
            // console.log('GET_POS_ROUTE_FAILURE', action);
            return {...state, isLoading: false}
        case 'GET_POS_PMTSVCACC_REQUEST':
            // console.log('GET_POS_PMTSVCACC_REQUEST', action);
            return {...state, isLoading: true}
        case 'GET_POS_PMTSVCACC_SUCCESS':
            // console.log('GET_POS_PMTSVCACC_SUCCESS', action);
            const posPmtSvcAcc = (action && action.data) || {}
            return {...state,
                detailedPosPmtSvcAcc: posPmtSvcAcc,
                isLoading: false
            }
        case 'GET_POS_PMTSVCACC_FAILURE':
            // console.log('GET_POS_PMTSVCACC_FAILURE', action);
            return {...state, isLoading: false}
        case 'UPDATE_POS_ROUTE_RULES_REQUEST':
            // console.log('UPDATE_POS_ROUTE_RULES_REQUEST', action);
            return {...state, isLoading: true}
        case 'UPDATE_POS_ROUTE_RULES_SUCCESS':
            // console.log('UPDATE_POS_ROUTE_RULES_SUCCESS', action);
            const updatedRoute = (action && action.data) || {}
            if (!updatedRoute.rules) {
                updatedRoute.rules = []
            }
            return {...state,
                detailedPosRoutes: updatedRoute,
                isLoading: false
            }
        case 'UPDATE_POS_ROUTE_RULES_FAILURE':
            // console.log('UPDATE_POS_ROUTE_RULES_FAILURE', action);
            return {...state, isLoading: false}
        case 'GET_ORG_PMTSVCACC_REQUEST':
            // console.log('GET_ORG_PMTSVCACC_REQUEST', action);
            return {...state, isLoading: true}
        case 'GET_ORG_PMTSVCACC_SUCCESS':
            // console.log('GET_ORG_PMTSVCACC_SUCCESS', action);
            const orgPmtSvcAccs = (action && action.data) || {}
            return {...state,
                orgPmtSvcAccs: orgPmtSvcAccs,
                isLoading: false
            }
        case 'GET_ORG_PMTSVCACC_FAILURE':
            // console.log('GET_ORG_PMTSVCACC_FAILURE', action);
            return {...state, isLoading: false}
        case 'SET_CLEAR_POS_DETAILS':
            return {...state, isLoading: false, detailedPos: {}}
        case 'SET_CLEAR_ORGANIZATION_POS':
            return {...state, isLoading: false, organizationPos: []}
        default:
            return {...state, isLoading: false} 
    }
  }

export default organizations