function disputeManagement(state ={isLoading: false, hits: [], search_after: [], totalHits: 0 }, action) {
    switch (action.type) {
        case 'POST_DISPUTE_SEARCH_REQUEST':
            // console.log('POST_DISPUTE_SEARCH_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_DISPUTE_SEARCH_SUCCESS':
            // console.log('POST_DISPUTE_SEARCH_SUCCESS', action);
            const listDisputes = (action.data && action.data.data && action.data.data.queryDisputesByOrganizationId) || null
            let hits = (listDisputes && listDisputes.items) || []
            const nextToken = listDisputes ? listDisputes.nextToken : undefined
            return {...state,
                hits: hits,
                nextToken: nextToken,
                isLoading: false
            }
        case 'POST_DISPUTE_SEARCH_FAILURE':
            // console.log('POST_DISPUTE_SEARCH_FAILURE', action);
            return {...state, isLoading: false}
        case 'POST_DISPUTE_SEARCH_AFTER_REQUEST':
            // console.log('POST_DISPUTE_SEARCH_AFTER_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_DISPUTE_SEARCH_AFTER_SUCCESS':
            // console.log('POST_DISPUTE_SEARCH_AFTER_SUCCESS', action);
            const nextListDisputes = (action.data && action.data.data && action.data.data.queryDisputesByOrganizationId) || null
            const nextHits = nextListDisputes ? nextListDisputes.items : nextListDisputes.items ? nextListDisputes.items : []
            const nextNextToken = nextListDisputes ? nextListDisputes.nextToken : undefined
            const combinedHits = state.hits.concat(nextHits);
            return {...state,
                hits: combinedHits,
                nextToken: nextNextToken,
                isLoading: false
            }
        case 'POST_DISPUTE_SEARCH_AFTER_FAILURE':
            // console.log('POST_DISPUTE_SEARCH_AFTER_FAILURE', action);
            return {...state, isLoading: false}
        case 'CLEAR_DISPUTE_SEARCH':
            return {...state,
                hits: [],
                totalHits: 0,
                search_after: [],
            }
        case 'PATCH_DISPUTE_REQUEST':
            // console.log('PATCH_DISPUTE_REQUEST', action);
            return {...state, isLoading: true}
        case 'PATCH_DISPUTE_SUCCESS':
            console.log('PATCH_DISPUTE_SUCCESS', action.data);
            let targetPatchDispute = (action && action.data && action.data) || {}
            const patchHits = state.hits.map((eachHit) => {
                if (eachHit.id === targetPatchDispute.id) return targetPatchDispute
                return eachHit
            })
            return {...state, hits: patchHits, isLoading: false }
        case 'PATCH_DISPUTE_FAILURE':
            // console.log('PATCH_DISPUTE_FAILURE', action);
            return {...state, isLoading: false}
        default:
            return {...state, isLoading: false} 
    }
  }

export default disputeManagement