import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getUserOrganizations, createOrganization, 
    getOrganization, getEnumeration } from '../actions'
import CompanyDashboard from '../components/CompanyDashboard'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users.user,
    organization: state.organizations.organization,
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    role: state.organizations.role,
    environment: state.appstate.environment || 'staging'
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getUserOrganizations: () => dispatch(getUserOrganizations()),
    createOrganization: (reqBody) => dispatch(createOrganization(reqBody)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyDashboard)