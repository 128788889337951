import React, { useEffect, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import rocket from '../../src/rocket.png';
import Slide from '@material-ui/core/Slide';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import { TrendingUpRounded } from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchApi} from '../app/utils/callPayoutsReportApi';
import { fetchApi as fetchInvoiceApi } from '../app/utils/callInvoiceApi';
import GoogleMaps from './GoogleMaps'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
const currencyFormatter = require('currency-formatter');


LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const axios = require('axios');
const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);


const ButtonMod = withStyles({
    root: {
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);


const DateTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const OutlinedInputPayment = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '11px 14px',
        }
    },
})(OutlinedInput);

const InputGreen = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(OutlinedInput);

const TabsMod = withStyles({
    root: {
        '& .MuiTabs-indicator': {
            backgroundColor: '#1fa595',
        },
    },
})(Tabs);

const TabMod = withStyles({
    root: {
        '&.Mui-selected': {
            color: '#1fa595',
        },
    },
})(Tab);

const useStyles = makeStyles(theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    label: {
        color: '#7f7f7f'
    },
    balslist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    card: {
        display: 'flex',
        minHeight: '17em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        position: 'absolute',
        bottom: 0,
    },
    add: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        textTransform: 'none'
    },
    imageRight: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: '-1em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    dialogTitle: {
        color: '#0c3336',
        fontSize: '1.7em'
    },
    balanceDetailAccordion: {
        width: '100%',
        marginBottom: '1em'
    },
    txnLogGrid: {
        margin: 0
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    greenMoney: {
        color: '#16a09e',
        verticalAlign: 'bottom',
        fontSize: '2em'
    },
    greenCurrency: {
        color: '#16a09e',
        fontSize: '2em'
    },
    finePrint: {
        fontSize: '1em'
    },
    lastUpdated: {
        alignSelf: 'center'
    },
    closeBtn: {
        '&:hover': {
            cursor: 'pointer'
        },
    }
}));
  const txnStates = ['CREATED', 'DECLINED', 'REVERSED', 'EXPIRED', 'PENDING_CAPTURE', 'PENDING_SETTLEMENT', 'SETTLED', 'PENDING_REFUND', 'REFUNDED']
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const BalancesReport = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont, getOrganization,
        getEnumeration, enumeration, organization, organizationPos, orgPmtSvcAccs, GetOrgAccounts, 
        postBalancesSearch, hits, balancesNextToken, billingsNextToken, postBalancesSearchAfter, clearBalancesSearch,
        environment, updateBalancesSearch, createPayout, getPayables, balancesread, payablesList, payableCurrencyCount,
        postBillingsSearch, postBillingsSearchAfter, billingHits, isLoading, postCreateInvoice
    } = props
    const [totalAmount, setTotalAmount] = useState(0);
    const [currencies, setCurrencies] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    // Later just have to Make a button to change the UTC +X
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [billingsSearchFrom, setBillingsSearchFrom] = useState(0);
    const [billingsSearchSize, setBillingsSearchSize] = useState(10);
    const [billingDetail, setBillingDetail] = useState({});
    const [billingsSelected, setBillingsSelected] = useState([]);
    const [searchSizePayable, setSearchSizePayable] = useState(10);
    const [page, setPage] = React.useState(1); // Balance
    const [loadedPage, setLoadedPage] = React.useState(1); // Balance
    const [payablePage, setPayablePage] = React.useState(1); // Payable
    const [loadedPayablePage, setLoadedPayablePage] = React.useState(1); // Payable
    const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
    const [billingDialogIsOpen, setBillingDialogIsOpen] = React.useState(false);
    const [responseDialogIsOpen, setResponseDialogIsOpen] = React.useState(false);
    const [responseMsg, setResponseMsg] = React.useState({});
    const [balanceDetail, setBalanceDetail] = React.useState(false);
    const [allTotal, setAllTotal] = React.useState(0);
    const [allAvailable, setAllAvailable] = React.useState(0);
    const [allHeld, setAllHeld] = React.useState(0);
    const [balanceType, setBalanceType] = React.useState(0);
    const [totalAmountPayable, setTotalAmountPayable] = React.useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);

    const closeDiaLog = () => {
        setBalanceDetail({})
        setDialogIsOpen(false)
        setWithdrawAmount(0)
    }
    const theme = useTheme();
    const constructSearchReqBody = (passedCurrency="", restartSearch=false) => {
        // console.log('process.env.MATERIAL_LICENSE_KEY -> ', process.env.REACT_APP_MATERIAL_LICENSE_KEY);
        let targetCurrency = passedCurrency || currency
        let initialStruct = {}
        // ${dateQuery ? `createdAt: ${dateQuery}` : ""}
        let query = `
        query listBalances {
            listBalances(
                organizationId: "${organization.id}", 
                ${passedCurrency ? `currency: "${passedCurrency}"` : ""},
                nextToken: "${(!restartSearch && balancesNextToken) ? balancesNextToken : ""}",
            ) {
              items {
                available
                createdAt
                currency
                held
                organizationId
                rollingReserve
                rollingReserveHeld
                rollingReverseAvailable
                updatedAt
              }
            }
          }
        `
        initialStruct.variables = {
            organizationId: organization.id
        }
        initialStruct.query = query
        return initialStruct
    }
    const constructBillingsSearchReqBody = (passedCurrency, restartSearch=false) => {
        let initialStruct = {}
        let query = `query listBillings ($organizationId: String!) {
                queryBillingsByOrganizationID (
                    organizationId: $organizationId,
                    filter: {
                        ${passedCurrency ? `currency: { eq: "${passedCurrency}" }` : ""}
                        heldAmount: { eq: 0 }
                        isBilled: { eq: ${false} }
                    }
                    nextToken: "${(!restartSearch && billingsNextToken) ? billingsNextToken : ""}",
                ) {
                items {
                    end
                    id
                    organizationAccountId
                    organizationId
                    billedAt
                    createdAt
                    dueDate
                    start
                    updatedAt
                    accountId
                    amount
                    heldAmount
                    billedAmount
                    refundReversalAmount
                    rollingReserveReversalAmount
                    currency
                    isBilling
                    isBilled
                }
                }
            }
        `
        initialStruct.variables = {
            organizationId: organization.id
        }
        initialStruct.query = query
        return initialStruct
    }

    
    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
        if (appbarIsWhiteFont) toggleAppbarWhiteFont(false)
    },[]);

    const convert = (amount, currency) => {
        if (currency == 'SGD') {
            return amount
        } else {
            return 0
        }
    }
    useEffect(() => {
        let totalConvertedSgd = 0
        hits.map((individualHit) => {
            let convertedSgd = convert(individualHit.available + individualHit.held + individualHit.rollingReserve, individualHit.currency)
            totalConvertedSgd += convertedSgd
        })
        setTotalAmount(totalConvertedSgd)
    },[hits]);
    useEffect(() => {
        let totalConvertedSgd = 0
        payablesList.map((individualCurrency) => {
            let convertedSgd = convert(individualCurrency.available + individualCurrency.held, individualCurrency.currency)
            totalConvertedSgd += convertedSgd
        })
        setTotalAmountPayable(totalConvertedSgd)
    },[payablesList]);
    useEffect(() => {
        if (enumeration && enumeration.currency) setCurrencies(enumeration.currency)
    }, [enumeration]);
    useEffect(() => {
        if (organization.id) {
            let targetCurrency = (organization && organization.banks && organization.banks[0].account && organization.banks[0].account.currency) || ''
            setCurrency(targetCurrency)
            let esSearchReqBody = constructSearchReqBody(targetCurrency)
            // console.log('esSearchReqBody -> ', esSearchReqBody);
            postBalancesSearch(esSearchReqBody)
            let esBillingsSearchReqBody = constructBillingsSearchReqBody(targetCurrency)
            if (esBillingsSearchReqBody) postBillingsSearch(esBillingsSearchReqBody)
            getPayables({organizationId: organization.id})
            setLoadedPage(1)
        }
    }, [organization.id]);
    useEffect(() => {
        if (!organization.id) {
            const url = window.location.href
            let urlArr = url.split('/')
            let organizationID = urlArr[urlArr.length - 2]
            // console.log('organizationID -> ', organizationID);
            getOrganization({organizationId: organizationID})
        }
        getEnumeration({enumGroup: 'currency'})
        return function cleanup() {
            clearBalancesSearch()
        };
    },[environment])

    const history = useHistory();
    const currencyFormat = (currency, amount, symbol=false) => {
        if (!currency || !(amount >= 0)) return ''
        const currencyDetails = currencyFormatter.findCurrency(currency);
        let amountString = `${amount}`
        let amountBeforeDecimal = ''
        let amountAfterDecimal = ''
        if (currencyDetails.decimalDigits > 0 && amount !== '0') {
            let count = 0
            for (var i = amountString.length - 1; i >= 0; i--) {
                if (count < currencyDetails.decimalDigits) {
                    amountAfterDecimal = amountString[i] + amountAfterDecimal
                    count++
                } else {
                    amountBeforeDecimal = amountString[i] + amountBeforeDecimal 
                }
            }
            if (currencyDetails.decimalDigits > amountAfterDecimal.length) {
                for (var i = 0; i < currencyDetails.decimalDigits - amountAfterDecimal.length; i++) {
                    amountAfterDecimal = '0' + amountAfterDecimal
                }
            }
        } else {
            amountBeforeDecimal = amountString
        }
        if (!amountBeforeDecimal.length) amountBeforeDecimal = '0'
        // Add in Seperators to amountBeforeDecimal
        let amountBeforeDecimalReverseArr = amountBeforeDecimal.split('').reverse()
        amountBeforeDecimal = ''
        amountBeforeDecimalReverseArr.forEach((digit, index) => {
            if (((index + 1 ) % 3) === 0) {
                amountBeforeDecimal += `${digit}` + `${currencyDetails.thousandsSeparator}`
            } else {
                amountBeforeDecimal += digit
            }
        })
        amountBeforeDecimal = amountBeforeDecimal.split('').reverse().join('')
        if (amountBeforeDecimal[0] === currencyDetails.thousandsSeparator) {
            amountBeforeDecimal = amountBeforeDecimal.slice(1)
        }
        return `${symbol ? currencyDetails.symbol : ''}${currencyDetails.spaceBetweenAmountAndSymbol === true ? ' ': ''}${amountBeforeDecimal}${currencyDetails.decimalDigits > 0 ? currencyDetails.decimalSeparator : ''}${(amount == '0' && currencyDetails.decimalDigits == 2) ? '00' : amountAfterDecimal}`
    }

    const totalAmountCalculator = (balance) => {
        if (!balance.currency) return ''
        let theAvailable = (balance && balance.available) || 0
        let theHeld = (balance && balance.held) || 0
        let theRollingReserve = (balance && balance.rollingReserve) || 0
        return currencyFormat(balance.currency, theAvailable + theHeld + theRollingReserve, true)
    }

    const totalAvailableCalculator = (balance) => {
        let theAvailable = (balance && balance.available) || 0
        return currencyFormat(balance.currency, theAvailable, true)
    }

    const totalHeldCalculator = (balance) => {
        let theHeld = (balance && balance.held) || 0
        return currencyFormat(balance.currency, theHeld, true)
    }

    const withdraw = (currency, amount) => {
        SetLoading(true)
        closeDiaLog()
        try {
            let reqBody = {
                currency: currency,
                organizationId: organization.id,
                amount: parseInt(amount)
            }
            // if (fundingIds) reqBody.fundingIds = fundingIds
            // if (rollingReserveIds) reqBody.rollingReserveIds = rollingReserveIds
            fetchApi(
                environment,
                '/payouts',
                'post',
                {},
                reqBody
            ).then((result) => {
                const { data } = result
                if (data.status === 'PENDING_APPROVAL') {
                    setResponseMsg({ title: 'SUCCESS: Payout Created!', msg: 'Withdrawal payout is created and is PENDING_APPROVAL. Do check the Payouts page for more details.'})
                    setResponseDialogIsOpen(true)
                    SetLoading(false)
                } else {
                    SetLoading(false)
                    setResponseMsg({ title: 'ERROR: Payout Creation Error', msg: 'Do reach our to us for help at support@liberalize.io'})
                    setResponseDialogIsOpen(true)
                }
            })
        } catch (err) {
            SetLoading(false)
            setResponseMsg({ title: 'ERROR: Payout Creation Error', msg: 'Do reach our to us for help at support@liberalize.io'})
            setResponseDialogIsOpen(true)
        }
    }

    const createInvoice = () => {
        SetLoading(true)
        closeDiaLog()
        try {
            if (!billingDetail.currency) throw new Error('no billing currency')
            if (!billingsSelected.length) throw new Error('no billing selected')
            let reqBody = {
                organizationId: organization.id,
                currency: billingDetail.currency
            }
            console.log('billingsSelected -> ', billingsSelected)
            // SetLoading(false)
            // return
            let totalAmount = 0
            let targetBillings = billingsSelected.map((eachBill) => {
                totalAmount += eachBill.amount
                return {
                    id: eachBill.id,
                    accountId: eachBill.accountId,
                    amount: eachBill.amount
                }
            })
            reqBody.billings = targetBillings
            reqBody.amount = totalAmount;
            reqBody.transactionMode = "ACCOUNT_BALANCE"
            fetchInvoiceApi(
                environment,
                '/invoices',
                'post',
                {},
                reqBody
            ).then((result) => {
                const { data } = result
                if (result.status === 200) {
                    setResponseMsg({ title: 'SUCCESS: Invoice Created!', msg: 'Invoice created an awaiting your action to input billing details. Please check the Invoice section.'})
                    setResponseDialogIsOpen(true)
                    SetLoading(false)
                    closeBillingDialog()
                } else {
                    SetLoading(false)
                    setResponseMsg({ title: 'ERROR: Invoice Creation Error', msg: 'Do reach our to us for help at support@liberalize.io'})
                    setResponseDialogIsOpen(true)
                    closeBillingDialog()
                }
            })
        } catch (err) {
            SetLoading(false)
            setResponseMsg({ title: 'ERROR: Invoice Creation Error', msg: 'Do reach our to us for help at support@liberalize.io'})
            setResponseDialogIsOpen(true)
            closeBillingDialog()
        }
    }

    const closeBillingDialog = () => {
        setBillingDialogIsOpen(false)
        setBillingDetail({})
    }

    const closeResponseDialog = () => {
        setPage(1);
        setLoadedPage(1)
        setSearchFrom(0);
        setBillingsSearchFrom(0)
        let esSearchReqBody = constructSearchReqBody(currency, null, true)
        postBalancesSearch(esSearchReqBody)
        getPayables({organizationId: organization.id})
        setResponseDialogIsOpen(false)
        setBillingsSelected([])
    }

    return (
    <main className={classes.topMargin}>
    <Typography variant="h4" className={classes.Title}>Balances</Typography>
    <AppBarMod position="static" color="default"></AppBarMod>
    <Container>
        <div className={classes.balslist}>
            <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={currency || 'Select Currency'}
                                onChange={(e) => {
                                    setCurrency(e.target.value)
                                }}
                                label="Currency"
                            >
                                {
                                    currencies && currencies.length > 0 && currencies.map((curr, index) => {
                                        return <MenuItem key={index} value={curr.value.currency_alpha3_code}>{curr.name}</MenuItem>
                                    })
                                }
                                <MenuItem key={'select_currency'} value={''}>Select Currency</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            </div>
            <ButtonMod onClick={() => {
                setPage(1);
                setLoadedPage(1)
                setSearchFrom(0);
                setBillingsSearchFrom(0)
                let esSearchReqBody = constructSearchReqBody(currency, null, true)
                // console.log('PLEASE DONT FIRE! esSearchReqBody : ', esSearchReqBody);
                postBalancesSearch(esSearchReqBody)
                getPayables({organizationId: organization.id})
            }}>Search</ButtonMod>
        </div>
        <div className={classes.balslist}>
            <Paper square>
                <TabsMod
                    value={balanceType}
                    onChange={(e, newValue) => { setBalanceType(newValue)}}
                >
                    <TabMod label="WHAT YOU HAVE" />
                    <TabMod label="WHAT YOU OWE" />
                </TabsMod>
            </Paper>
        </div>
        {/* Unauthorized */}
        {!balancesread && <div className={classes.balslist}> 
            <i className={classes.finePrint}>You do not have access to this section</i>
        </div>}
        {/* Balance */}
        {balanceType === 0 && balancesread && <div className={classes.balslist}> 
            <b className={classes.greenCurrency}>SGD</b> <MonetizationOnIcon className={classes.greenMoney}/> <b className={classes.greenCurrency}>{currencyFormat('SGD', totalAmount) || '0'}</b><br/><br/>
            <i className={classes.finePrint}>Note: the above amount is only taking into account Singapore Dollars.</i>
        </div>}
        {/* Payable */}
        {balanceType === 1 && balancesread && <div className={classes.balslist}> 
            <b className={classes.greenCurrency}>SGD</b> <MonetizationOnIcon className={classes.greenMoney}/> <b className={classes.greenCurrency}>{currencyFormat('SGD', totalAmountPayable) || '0'}</b><br/><br/>
            <i className={classes.finePrint}>Note: the above amount is only taking into account Singapore Dollars.</i>
        </div>}
        {/* Balance */}
        {balanceType === 0 && balancesread && <div className={classes.balslist}>
            <Grid container >
                {/* List of Balances */}
                {/* {`totalHits -> ${totalHits}`}
                {`hits -> ${hits.length}`} */}
                <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                    <XGrid
                        className={classes.xgrid}
                        page={page}
                        onPageChange={(params) => {
                            // console.log('onPageChange FIRED first part !!', params.page , ' ', loadedPage);
                            // console.log('onPageChange params.page -> ', params.page);
                            if (page >= params.page) return
                            setPage(params.page);
                            if (params.page <= loadedPage) return
                            // console.log('onPageChange FIRED second part !!', params.page , ' ', loadedPage);
                            setLoadedPage(loadedPage + 1)
                            let esSearchReqBody = constructSearchReqBody()
                            // console.log('esSearchReqBody -> ', esSearchReqBody);
                            postBalancesSearchAfter(esSearchReqBody)
                        }}
                        hideFooterRowCount
                        rowsPerPageOptions={[10]}
                        pageSize={13}
                        pagination={true}
                        rowCount={balancesNextToken ? 9999999999999 : (hits.length)}
                        columns={[
                            { 
                                field: 'id',
                                headerName: 'Currency',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.currency) || ""}`,
                            },
                            { 
                                field: 'totalAmount',
                                headerName: 'Total Amount',
                                width: 170, 
                                valueGetter: (params) => {
                                    let theAvailable = (params && params.row && params.row.available) || 0
                                    let theHeld = (params && params.row && params.row.held) || 0
                                    let theRollingReserve = (params && params.row && params.row.rollingReserve) || 0
                                    return currencyFormat(params.row.currency, theAvailable + theHeld + theRollingReserve, true)
                                },
                            },
                            { 
                                field: 'availableAmount',
                                headerName: 'Available Amount',
                                width: 170,
                                valueGetter: (params) => {
                                    let theAvailable = (params && params.row && params.row.available && currencyFormat(params.row.currency, params.row.available, true)) || currencyFormat(params.row.currency, 0, true)
                                    return theAvailable
                                },
                            },
                            { 
                                field: 'heldAmount',
                                headerName: 'Held Amount',
                                width: 170,
                                valueGetter: (params) => {
                                    let theHeld = (params && params.row && params.row.held && currencyFormat(params.row.currency, params.row.held, true)) || currencyFormat(params.row.currency, 0, true)
                                    return theHeld
                                },
                            },
                            { 
                                field: 'updatedAt',
                                headerName: 'Last Updated At',
                                width: 480,
                                valueGetter: (params) => {
                                    let dateString = ''
                                    if (params && params.row && params.row.updatedAt) {
                                        let date = new Date(params.row.updatedAt)
                                        dateString = date.toString()
                                    } 
                                    return dateString
                                },
                            },
                        ]}
                        rows={hits}
                        onRowClick={async (rowParams) => {
                            SetLoading(true)
                            let balanceDetail = (rowParams.row && rowParams.row) || {}
                            if (!balanceDetail.currency) return;
                            setAllTotal(totalAmountCalculator(balanceDetail))
                            setAllAvailable(totalAvailableCalculator(balanceDetail))
                            setAllHeld(totalHeldCalculator(balanceDetail))
                            setBalanceDetail(balanceDetail)
                            SetLoading(false)
                            setDialogIsOpen(true)
                            // Search payables
                            let esBillingsSearchReqBody = constructBillingsSearchReqBody(balanceDetail.currency, true)
                            postBillingsSearch(esBillingsSearchReqBody)
                        }}
                    />
                </div>
            </Grid>
        </div>}
        {/* Payable */}
        {balanceType === 1 && balancesread && <div className={classes.balslist}>
            <Grid container>
                {/* List of Payables */}
                <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                    <XGrid
                        className={classes.xgrid}
                        page={page}
                        onPageChange={(params) => {
                            // console.log('onPageChange FIRED first part !!', params.page , ' ', loadedPage);
                            // console.log('onPageChange params.page -> ', params.page);
                            if (page >= params.page) return
                            setPayablePage(params.page);
                            if (params.page <= loadedPage) return
                            // console.log('onPageChange FIRED second part !!', params.page , ' ', loadedPage);
                            setLoadedPayablePage(loadedPage + 1)
                            // let esSearchReqBody = constructSearchReqBody()
                            // // console.log('esSearchReqBody -> ', esSearchReqBody);
                            // postBalancesSearchAfter({organizationId: organization.id}, esSearchReqBody)
                        }}
                        onPageSizeChange={(params) =>{
                            // console.log('onPageSizeChange params.pageSize -> ', params.pageSize);
                            if (searchSize === params.pageSize) return
                            setSearchSizePayable(params.pageSize)
                            setPayablePage(1);
                            setLoadedPayablePage(1)
                            // let esSearchReqBody = constructSearchReqBody(currency, params.pageSize, true)
                            // // console.log('esSearchReqBody -> ', esSearchReqBody);
                            // // console.log('PLEASE DONT FIRE!');
                            // postBalancesSearch({organizationId: organization.id}, esSearchReqBody)
                        }}
                        rowCount={payableCurrencyCount}
                        rowsPerPageOptions={[10,25,50,100]}
                        pageSize={10}
                        pagination
                        columns={[
                            { 
                                field: 'id',
                                headerName: 'Currency',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.currency) || ""}`,
                            },
                            { 
                                field: 'totalAmount',
                                headerName: 'Total Amount',
                                width: 170, 
                                valueGetter: (params) => {
                                    let theAvailable = (params && params.row && params.row.available) || 0
                                    let theHeld = (params && params.row && params.row.held) || 0
                                    return currencyFormat(params.row.currency, theAvailable + theHeld, true)
                                },
                            },
                            { 
                                field: 'availableAmount',
                                headerName: 'Available Amount',
                                width: 170,
                                valueGetter: (params) => {
                                    let theAvailable = (params && params.row && params.row.available && currencyFormat(params.row.currency, params.row.available, true)) || currencyFormat(params.row.currency, 0, true)
                                    return theAvailable
                                },
                            },
                            { 
                                field: 'heldAmount',
                                headerName: 'Held Amount',
                                width: 170,
                                valueGetter: (params) => {
                                    let theHeld = (params && params.row && params.row.held && currencyFormat(params.row.currency, params.row.held, true)) || currencyFormat(params.row.currency, 0, true)
                                    return theHeld
                                },
                            },
                            { 
                                field: 'updatedAt',
                                headerName: 'Last Updated At',
                                width: 480,
                                valueGetter: (params) => {
                                    let dateString = ''
                                    if (params && params.row && params.row.updatedAt) {
                                        let date = new Date(params.row.updatedAt)
                                        dateString = date.toString()
                                    } 
                                    return dateString
                                },
                            },
                        ]}
                        rows={payablesList}
                        onRowClick={async (rowParams) => {
                            let targetCurrency = (rowParams && rowParams.row && rowParams.row.currency) || ""
                            if (!targetCurrency) return
                            setBillingDetail(rowParams.row)
                            let esBillingsSearchReqBody = constructBillingsSearchReqBody(targetCurrency, true)
                            postBillingsSearch(esBillingsSearchReqBody)
                            setBillingDialogIsOpen(true)
                        }}
                    />
                </div>
            </Grid>
        </div>}
        {/* Balance */}
        <Dialog
            fullWidth
            maxWidth={'xl'}
            open={dialogIsOpen}
            onClose={closeDiaLog}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">Balance Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>Currency</span> 
                            <span className={classes.greenCurrency}> {`${balanceDetail.currency}`} </span>
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>
                            Account Balance</span> <span className={classes.greenCurrency}>{`${balanceDetail && allTotal}` || currencyFormat(balanceDetail.currency, 0, true)}</span>
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <div className={classes.lastUpdated} >
                                {
                                    `${(balanceDetail && balanceDetail.updatedAt && "Updated At: " + new Date(balanceDetail.updatedAt).toString()) || ""}`
                                }
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        Available: {`${allAvailable}` || "0"}
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        Held: {`${allHeld}` || "0"}
                    </Grid>
                    {/* Fundings */}
                    <Accordion className={classes.balanceDetailAccordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2a-header" 
                        >
                            &nbsp;&nbsp;<Typography className={classes.dialogTitle}>Fundings</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.balanceDetailAccordion}>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12} sm={12}>
                                    The funding amounts excludes the rolling reserves.
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>Available: {`${currencyFormat(balanceDetail.currency, balanceDetail.available ? balanceDetail.available : 0, true)}` || "0"}</Grid>
                                <Grid item md={6} xs={6} sm={6}>Held: {`${currencyFormat(balanceDetail.currency, balanceDetail.held ? balanceDetail.held : 0, true)}` || "0"}</Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {/* Rolling Reserves */}
                    <Accordion className={classes.balanceDetailAccordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2a-header" 
                        >
                            &nbsp;&nbsp;<Typography className={classes.dialogTitle}>Rolling Reserves</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.balanceDetailAccordion}>
                            <Grid container spacing={3}>
                                <Grid item md={11} xs={11} sm={11}>
                                    Rolling reserves are amounts that are held for release on a later date. These are only related to special term contracts during a personalized onboarding and does not apply to the general default contracts.
                                </Grid>
                                <Grid item md={11} xs={11} sm={11}>Total: {`${currencyFormat(balanceDetail.currency, balanceDetail.rollingReserve ? balanceDetail.rollingReserve : 0, true)}`}</Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {/* Withdraw Button */}
                    <Grid item md={10} xs={10} sm={10}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>Withdraw Amount (In Lowest denominator e.g. 100 for $1.00)</Typography>
                        <OutlinedInputMod 
                            fullWidth
                            type={'number'}
                            onChange={(e) => {
                                setWithdrawAmount(e.target.value)
                            }} 
                            defaultValue={withdrawAmount}
                        />
                    </Grid>
                    <Grid item md={2} xs={2} sm={2}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>&nbsp;</Typography>
                        <ButtonMod 
                            disabled={isLoading || loading}
                            style={{marginTop: 0}}
                            onClick={() => {
                                console.log('billingHits: ', billingHits);
                                if (!withdrawAmount) {
                                    alert('Enter Amount')
                                    return
                                }
                                if (!Number.isInteger(Number(withdrawAmount))) {
                                    alert('Error: Not A Number')
                                    return
                                }
                                if (billingHits && billingHits.length > 0) {
                                    alert('Please make payment for payables')
                                    return
                                }
                                withdraw(balanceDetail.currency, parseInt(withdrawAmount))
                            }}
                        >WITHDRAW AND CREATE PAYOUT</ButtonMod>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        {/* Billing */}
        <Dialog
            fullWidth
            maxWidth={'xl'}
            open={billingDialogIsOpen}
            onClose={closeBillingDialog}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">Billings Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>Currency</span> 
                            <span className={classes.greenCurrency}> {`${billingDetail && billingDetail.currency}`} </span>
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>
                            Payable Balance</span> <span className={classes.greenCurrency}>{`${billingDetail && billingDetail.available && currencyFormat(billingDetail.currency, ((billingDetail.available || 0) + (billingDetail.held || 0)) , true)}` || currencyFormat(billingDetail.currency, 0, true)}</span>
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <div className={classes.lastUpdated} >{`${billingDetail && billingDetail.updatedAt && "Last Updated: " + `${new Date(billingDetail.updatedAt).toString()}`}`}</div>
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        Available: {`${billingDetail && billingDetail.available && currencyFormat(billingDetail.currency, billingDetail.available, true)}` || currencyFormat(billingDetail.currency, 0, true)}
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        Held: {`${billingDetail && billingDetail.held && currencyFormat(billingDetail.currency, billingDetail.held, true)}` || currencyFormat(billingDetail.currency, 0, true)}
                    </Grid>
                    {/* XGrid */}
                    <div style={{ height: 520, width: '100%' }}>
                        <XGrid
                        className={classes.xgrid}
                        page={page}
                        loading={isLoading || loading}
                        onPageChange={(params) => {
                            if (page >= params.page) return
                            setPage(params.page);
                            if (params.page <= loadedPage) return
                            setLoadedPage(loadedPage + 1)
                            if (!billingsNextToken) {
                                return
                            }
                            let esSearchReqBody = constructBillingsSearchReqBody(billingDetail.currency)
                            postBillingsSearchAfter(esSearchReqBody)
                        }}
                        hideFooterRowCount
                        rowsPerPageOptions={[10]}
                        pageSize={13}
                        pagination={true}
                        rowCount={billingsNextToken ? 9999999999999 : (billingHits.length)}
                        columns={[
                            { 
                                field: 'id',
                                headerName: 'Billing ID',
                                width: 280,
                                valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                            },
                            { 
                                field: 'currency',
                                headerName: 'Currency',
                                width: 170, 
                                valueGetter: (params) => `${(params && params.row && params.row.currency) || "unknown"}`,
                            },
                            { 
                                field: 'amount',
                                headerName: 'Amount Owing',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.amount && currencyFormat(params.row.currency, params.row.amount, true)) || currencyFormat(params.row.currency, 0, true)}`,
                            },
                            { 
                                field: 'billedAt',
                                headerName: 'Billed At',
                                width: 210,
                                valueGetter: (params) => {
                                    let dateString = 'unknown'
                                    if (params && params.row && params.row.billedAt) {
                                        let date = new Date(params.row.billedAt)
                                        dateString = date.toString()
                                    } 
                                    return dateString
                                },
                            },
                            { 
                                field: 'dueDate',
                                headerName: 'Due Date At',
                                width: 210,
                                valueGetter: (params) => {
                                    let dateString = 'unknown'
                                    if (params && params.row && params.row.dueDate) {
                                        let date = new Date(params.row.dueDate)
                                        dateString = date.toString()
                                    } 
                                    return dateString
                                },
                            },
                            {
                                field: 'createdAt',
                                headerName: 'Created At',
                                width: 480,
                                valueGetter: (params) =>{
                                     let dateString = 'unknown'
                                     if (params && params.row && params.row.createdAt) {
                                         let date = new Date(params.row.createdAt)
                                         dateString = date.toString()
                                     } 
                                     return dateString
                                },
                            },
                        ]}
                        rows={billingHits}
                        rowHeight={38}
                        checkboxSelection
                        onSelectionChange={async (selectedState) => {
                            const selectedIds = selectedState.rowIds
                            const selected = billingHits.filter((eachBilling) => {
                                return selectedIds.includes(eachBilling.id)
                            })
                            setBillingsSelected(selected)
                        }}
                        />
                    </div>
                    {/* Withdraw Button */}
                    <Grid item md={12} xs={12} sm={12}>
                        <ButtonMod  disabled={isLoading} onClick={() => {
                            createInvoice()
                        }}>Make Payment and Create Invoice</ButtonMod>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        {/* Response Dialog */}
        <Dialog
            maxWidth={'md'}
            open={responseDialogIsOpen}
            onClose={closeResponseDialog}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">
                <Grid container spacing={3}>
                    <Grid item md={10} xs={10} sm={10}>
                    {responseMsg && (responseMsg.title || "No Title Available")}
                    </Grid>
                    <Grid item md={2} xs={2} sm={2}>
                        <CloseIcon className={classes.closeBtn} onClick={()=> {closeResponseDialog()}}/>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>{responseMsg && (responseMsg.msg || "No message available")} </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </Container>
    <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress className={classes.circularProgress} />
    </Backdrop>
    </main>
    )
}

export default BalancesReport