import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import { Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Slide from '@material-ui/core/Slide';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInput } from '@material-ui/core';
import RootRef from "@material-ui/core/RootRef";
import { grey } from '@material-ui/core/colors';
import Collapse from '@material-ui/core/Collapse';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {fetchApi} from '../app/utils/callPosApi';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CircularProgress from '@material-ui/core/CircularProgress';

const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);

const ButtonMod = withStyles({
    root: {
        backgroundColor: '#68d0af',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
    },
})(Button);

const PaperMod = withStyles({
    root: {
        marginTop: '1em',
        padding: '1em',
    },
})(Paper);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        },
    },
})(OutlinedInput);

const useStyles = makeStyles(theme => ({
    circularProgress: {
        color: '#68d0af'
    },
    dialogTitle: {
        color: '#0c3336'
    },
    dialogBtn: {
        color: '#1fa595'
    },
    posLogo: {
        height: '100px',
    },
    camera: {
        color: '#68d0af',
    },
    hiddeninput: {
        display: 'none',
    },
    dropItem: {
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    posDetails: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    editIcon: {
        color: grey['A200'],
        '&:hover': {
            color: 'black',
            cursor: 'pointer'
        },
    },
    generateKey: {
        backgroundColor: "#68d0af",
        color: 'white'
    },
    card: {
        padding: '1em',
        zIndex: 50,
    },
    btn: {
        color: '#1fa595'
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    copyBtns:{
        textTransform: 'none',
        margin: '0.5em',
        color: '#1fa595',
        backgroundColor: '#ffffff',
        '&.MuiButton-root:hover': {
            color: '#68d0af',
            backgroundColor: '#ffffff',
        },
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const Checkoutpages = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont,
        getEnumeration, enumeration, organization, detailedPos, PatchPos, detailedPosRoutes,
        GetPosRoute, GetPosAccounts, detailedPosPmtSvcAcc, UpdatePosRoute, environment, posApiKeys,
        getApiKeys, createApiKeys, deleteApiKeys
    } = props
    const theme = useTheme();
    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
        if (appbarIsWhiteFont) toggleAppbarWhiteFont(false)
    },[]);
    const [currencies, setCurrencies] = useState([]);
    const [posName, setPosName] = useState('');
    const [iinMin, setIinMin] = useState('');
    const [iinMax, setIinMax] = useState('');
    const [amountMin, setAmountMin] = useState('');
    const [amountMax, setAmountMax] = useState('');
    const [accCurrency, setAccCurrency] = useState('');
    const [newRouteAccId, setNewRouteAccID] = useState('');
    const [posRouteRules, setPosRouteRules] = useState([]);
    const [imageLoading, setImageLoading] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    useEffect(() => {
        if (enumeration && enumeration.currency) setCurrencies(enumeration.currency)
        if (detailedPos && detailedPos.name) setPosName(detailedPos.name)
        if (detailedPos && detailedPos.id) {
            GetPosRoute({posId: detailedPos.id})
            GetPosAccounts({posId: detailedPos.id})
        }
        if (detailedPosRoutes && detailedPosRoutes.id) {
            setPosRouteRules(detailedPosRoutes.rules)
            getApiKeys({posId: detailedPos.id})
        }
    }, [enumeration, detailedPos.id, detailedPosRoutes.id, detailedPosRoutes.rules ? detailedPosRoutes.rules.length : 0]);
    const history = useHistory();
    useLayoutEffect(() => {
        if (!organization.id) {
            history.push({pathname: `/`,})
        }
        getEnumeration({enumGroup: 'currency'})
    },[])
    const [showEdit, setShowEdit] = useState('');
    const handleShow = (field) => {
        setShowEdit(field)
    }
    const handlePatch = (reqBody) => {
        PatchPos({posId: detailedPos.id}, reqBody)
    }
    const handleAddNewRoute = () => {
        let detailedPosRoutesCopy = JSON.parse(JSON.stringify(detailedPosRoutes));
        let newRoute = {
            accountId: newRouteAccId,
            filter: {
                iin: {},
                amount: {},
                currency: '',
                source: ''
            }
        };
        if (iinMin) newRoute.filter.iin['min'] = `${iinMin}`
        if (iinMax) newRoute.filter.iin['max'] = `${iinMax}`
        if (accCurrency) newRoute.filter.currency = accCurrency
        if (newRouteAccId) newRoute.accountId = newRouteAccId
        if (!isNaN(amountMin)) newRoute.filter.amount['min'] = parseFloat(amountMin)
        if (!isNaN(amountMax)) newRoute.filter.amount['max'] = parseFloat(amountMax)
        // TODO: Source logic required
        if (detailedPosRoutesCopy && !detailedPosRoutesCopy.rules) {
            detailedPosRoutesCopy.rules = [newRoute]
        } else {
            detailedPosRoutesCopy.rules.push(newRoute);
        }
        UpdatePosRoute({routeId: detailedPosRoutesCopy.id}, detailedPosRoutesCopy)
    }

    const handleEditRoute = (index) => {
        let newRule = [...posRouteRules];
        let newRoute = {
            accountId: newRouteAccId,
            filter: {
                iin: {},
                amount: {},
                currency: '',
                source: ''
            }
        };
        // Do checks before submitting as this function cannot fail
        if (iinMin && iinMin.length != 6) return
        if (iinMin && iinMax.length != 6) return
        if (amountMin && isNaN(amountMin)) return
        if (amountMax && isNaN(amountMax)) return

        newRoute.filter.iin['min'] = `${iinMin}`;
        newRoute.filter.iin['max'] = `${iinMax}`;
        newRoute.filter.currency = accCurrency;
        newRoute.accountId = newRouteAccId;
        newRoute.filter.amount['min'] = parseFloat(amountMin);
        newRoute.filter.amount['max'] = parseFloat(amountMax);
        newRule[index] = newRoute;
        setPosRouteRules(newRule);
        const newRules = { rules: newRule };
        // console.log('newRules ---> ');
        // console.log(newRules);
        UpdatePosRoute({routeId: detailedPosRoutes.id}, newRules)
    }

    const handleDeleteRule = (index) => {
        let newRules = posRouteRules.filter((item, i) => i != index)
        // console.log('delete new rules -> ', newRules);
        setPosRouteRules(newRules);
        const newRouteRules = { rules: newRules };
        UpdatePosRoute({routeId: detailedPosRoutes.id}, newRouteRules)
    }

    const getListStyle = isDraggingOver => ({
        //background: isDraggingOver ? 'lightblue' : 'lightgrey',
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        border: '1px solid #aaaaaa',
        marginBottom: '0.2em',
        ...(isDragging && {
          background: "rgb(235,235,235)"
        })
    });

    const reorder = (list, startIndex, endIndex) => {
        // a function to help us with reordering the result
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };

    const onDragEnd = (result) => {
        let newRule = [...posRouteRules];
        //destructure
        const { destination, source, draggableId} = result;
        // if no destination we return out
        if (!destination) return
        // if location of the destination and source id's are the same, we return out
        if (destination.droppableId === source.droppableId && destination.index === source.index) return

        const from = (source && source.index) || null;
        const to = (destination && destination.index) || null;
        newRule = reorder(newRule, from, to);
        setPosRouteRules(newRule);
        const newRules = { rules: newRule };
        UpdatePosRoute({routeId: detailedPosRoutes.id },newRules);
    }

    const generateKeyPair = () => {
        if (posApiKeys.count === 0) {
            // Generate Public Key
            const publicKey = {
                name: `${detailedPos.id} public key`,
                posId: detailedPos.id,
                organizationId: organization.id,
                type: 'public'
            }
            createApiKeys({posId: detailedPos.id}, publicKey)
            // Generate Private Key
            const privateKey = {
                name: `${detailedPos.id} private key`,
                posId: detailedPos.id,
                organizationId: organization.id,
                type: 'private'
            }
            createApiKeys({posId: detailedPos.id}, privateKey)

        }
        handleShow('pos apikeys')
    }

    const deleteKeys = (apiKeys) => {
        if (apiKeys.count < 2) return
        if (!apiKeys.keys) return
        if (apiKeys.keys.length < 2) return
        apiKeys.keys.forEach(key => {
            // console.log('key ---> ', key);
            deleteApiKeys({posId: key.posId, keyId: key.id})
        });
        handleShow('')
    }

    const copyText = (targetElementId, targetValue) => {
        let textInput = document.createElement("input");
        let wrapper = document.getElementById(targetElementId)
        // console.log('textInput - > ', textInput);
        // Place in top-left corner of screen regardless of scroll position.
        textInput.style.position = 'fixed';
        textInput.style.top = 0;
        textInput.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textInput.style.width = '2em';
        textInput.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textInput.style.padding = 0;

        // Clean up any borders.
        textInput.style.border = 'none';
        textInput.style.outline = 'none';
        textInput.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textInput.style.background = 'transparent';

        textInput.value = targetValue
         
        wrapper.appendChild(textInput);
        textInput.focus();
        textInput.select();
        textInput.setSelectionRange(0, 99999);

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            // console.log('Copying text command was ' + msg);
        } catch (err) {
            // console.log('Oops, unable to copy');
        }
        // console.log('textInput - > ', textInput);
        // console.log('textInput.value - > ', textInput.value);
        setCopiedText(targetElementId)
        setTimeout(() => {
            setCopiedText('')
        }, 3500);
        wrapper.removeChild(textInput);
    }

    const imageUpload = (eventFile) => {
        handleShow('')
        setImageLoading(true);
        let file = eventFile[0]
        // console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setImageLoading(false);
            return;
        }
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setImageLoading(false);
            alert('Invalid file type')
            // this.imageUploading = false
            return;
        }
        const max_file_size = 102400 * 3
        if (file.size <= max_file_size){
            const reader = new FileReader()
            // console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                // console.log('content -> ', content);
                // console.log('file 2 -> ', file);
                saveLogoImage(file, content)
            }
        }else {
            setImageLoading(false);
            alert('Maximum size exceeded for file! Please make sure file size is less than 300KB');
            // this.imageUploading = false
        }
    }

    const saveLogoImage = (file, content) => {
        try {
            let fileNameArr = file.name.split('.')
            const postData = {
                key: 'pos/'+ detailedPos.id + '.' + fileNameArr[fileNameArr.length - 1],
            }
            fetchApi(environment,
                '/pointofsales/:posId/image',
                'post',
                {posId: detailedPos.id},
                postData
                ).then((result) => {
                    // console.log('presigned result => ', result);
                    const { data } =result
                    if (!data.url) return
                    const xhr = new XMLHttpRequest()
                    let url = data.url
                    
                    xhr.open(data.method, url)
                    xhr.setRequestHeader('x-amz-acl', data.XamzAcl)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            // Update Pos Logo
                            handlePatch(
                                {
                                    logo: 'https://' + data.cdnUrl
                                }
                            );
                            setImageLoading(false);
                        }
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setImageLoading(false);
            // console.log('error : ', err);
        }
    }

    return (
    <main className={classes.topMargin}>
    <Typography variant="h4" className={classes.Title}>Checkout Page</Typography>
    <AppBarMod position="static" color="default">
      </AppBarMod>
        <Container>
        <div className={classes.posDetails}>
        <PaperMod elevation={3} >
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Checkout Page's Displayed Logo</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <input accept="image/*" className={classes.hiddeninput} onChange={(e) => {imageUpload(e.target.files)}} id="icon-button-file" type="file" />
                    <label htmlFor="icon-button-file">
                        {detailedPos && detailedPos.logo && imageLoading ? <CircularProgress className={classes.circularProgress} /> : <img alt={detailedPos.logo} className={classes.posLogo} src={detailedPos.logo}/>}
                        <IconButton disabled={imageLoading} color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera  className={classes.camera}/>
                        </IconButton>
                    </label>
                    <IconButton disabled={imageLoading} onClick={() => { handlePatch({ logo: ""}) }} component="span">
                        <DeleteOutlineIcon />
                    </IconButton>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Name</Typography>
                </Grid>
                {/* POS NAME */}
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{detailedPos && detailedPos.name}
                        <IconButton>
                            <EditIcon onClick={() => {
                                handleShow('pos name')
                            }} className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'pos name' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Name
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setPosName(e.target.value)
                        }} defaultValue={posName}/>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                const pName = (detailedPos && detailedPos.name) || ''
                                handleShow('')
                                setPosName(pName)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                handlePatch({
                                    name: posName,
                                })
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
                {/* API KEYS */}
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>API Keys</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <ButtonMod 
                        size={'large'} 
                        className={classes.generateKey} 
                        onClick={()=> { generateKeyPair() }}
                    >
                        {posApiKeys.count === 0 ? 'Generate API Keys' : 'View API Keys'}
                    </ButtonMod>
                </Grid>
                <Dialog
                    maxWidth="md"
                    open={showEdit === 'pos apikeys'}
                    onClose={() => { handleShow('') }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">{"API Keys"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            posApiKeys && posApiKeys.keys && posApiKeys.keys.map((eachKey) => {
                                return <div id={eachKey.id}>
                                    <Button
                                        className={classes.copyBtns}
                                        fullWidth={true}
                                        size="large"
                                        variant="contained"
                                        startIcon={<FileCopyIcon/>}
                                        onClick={() => { copyText(eachKey.id, eachKey.value)}}
                                    >
                                        {eachKey.type === 'public' ? copiedText === eachKey.id ? 'COPIED TEXT!' : `PUBLIC KEY: ${eachKey.value}`: ''}
                                        {eachKey.type === 'private' ? eachKey.value ? copiedText === eachKey.id ? 'COPIED TEXT!' : `PRIVATE KEY: ${eachKey.value} THIS WILL ONLY BE AVAILABLE ONCE. PLEASE COPY.`: 'THE PRIVATE KEY WILL ONLY BE AVAILABLE WHEN CREATED. DELETE AND REGENERATE WHEN REQUIRED.' : ''}
                                    </Button>
                                </div>
                            })
                        }
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => { deleteKeys(posApiKeys) }} color="secondary">
                        Delete Keys
                    </Button>
                    <Button onClick={() => { handleShow('') }} className={classes.dialogBtn}  autoFocus>
                        Done
                    </Button>
                    </DialogActions>
                </Dialog>
                {/* POS PAYMENT ROUTE */}
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Payment Routes</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <IconButton>
                        <AddIcon onClick={() => { 
                            handleShow('pos route add')
                            setIinMin('')
                            setIinMax('')
                            setAmountMax('')
                            setAmountMin('')
                            setAccCurrency('')
                        }} className={classes.editIcon}/>
                    </IconButton>
                </Grid>
                {showEdit === 'pos route add' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        {/* ACCOUNT SELECTION */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Payment Service Account
                        </Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={newRouteAccId}
                            onChange={(e) => {
                                setNewRouteAccID(e.target.value)
                            }}
                            label="Payment Service Account"
                        >
                            {
                                detailedPosPmtSvcAcc && detailedPosPmtSvcAcc.accounts && detailedPosPmtSvcAcc.accounts.map((account, index) => {
                                    return <MenuItem key={index} value={account.id}>{account.name}</MenuItem>
                                })
                            }
                        </Select>
                        {/* IIN */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Issuer Identification Network (FROM)
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setIinMin(e.target.value)
                        }} defaultValue={iinMin}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Issuer Identification Network (TO)
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setIinMax(e.target.value)
                        }} defaultValue={iinMax}/>
                        {/* Currency */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Currency
                        </Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={accCurrency}
                            onChange={(e) => {
                                setAccCurrency(e.target.value)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.map((currency, index) => {
                                    return <MenuItem key={index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                })
                            }
                        </Select>
                        {/* AMOUNT */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Minimum Amount (Optional)
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setAmountMin(e.target.value)
                        }} defaultValue={amountMin}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Maximum Amount (Optional)
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setAmountMax(e.target.value)
                        }} defaultValue={amountMax}/>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                const pName = (detailedPos && detailedPos.name) || ''
                                handleShow('')
                                setIinMin('')
                                setIinMax('')
                                setAmountMax('')
                                setAmountMin('')
                                setAccCurrency('')
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                handleAddNewRoute()
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
                <Grid item md={12} xs={12} sm={12}>
                    {detailedPosRoutes && detailedPosRoutes.rules && <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable1">
                            {(provided, snapshot) => (
                                <RootRef rootRef={provided.innerRef}>
                                    <List style={getListStyle(snapshot.isDraggingOver)}>
                                        {posRouteRules && posRouteRules.map((eachRule, index) => {
                                            const targetAcc = (detailedPosPmtSvcAcc && detailedPosPmtSvcAcc.count && detailedPosPmtSvcAcc.accounts.filter((acc) => acc.id == eachRule.accountId))
                                            if (!targetAcc || targetAcc.length < 1) return '';
                                            return <Draggable key={targetAcc[0].id + index} draggableId={targetAcc[0].id + index} index={index}>
                                                {(provided, snapshot) => (
                                                <div>
                                                    <ListItem
                                                        ContainerComponent="li"
                                                        ContainerProps={{ ref: provided.innerRef }}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    >
                                                        <ListItemIcon>
                                                            <DragHandleIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={targetAcc[0].name}
                                                            secondary={ `processor : ${targetAcc[0].processor}, ${eachRule.filter.iin && eachRule.filter.iin.min && 'iin : '  + eachRule.filter.iin.min + ' to ' + eachRule.filter.iin.max}`}
                                                        />
                                                        <ListItemSecondaryAction>
                                                        <IconButton 
                                                            onClick={() => { handleDeleteRule(index) }}
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                        <IconButton 
                                                            onClick={() => {
                                                                handleShow('rule ' + index)
                                                                setIinMin((eachRule.filter && eachRule.filter.iin && eachRule.filter.iin.min) || '')
                                                                setIinMax((eachRule.filter && eachRule.filter.iin && eachRule.filter.iin.max) || '')
                                                                setAmountMax((eachRule.filter && eachRule.filter.amount && eachRule.filter.amount.max) || '')
                                                                setAmountMin((eachRule.filter && eachRule.filter.amount && eachRule.filter.amount.min) || '')
                                                                setAccCurrency((eachRule.filter && eachRule.filter.currency) || '')
                                                                setNewRouteAccID((eachRule.accountId) || '')
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Grid item md={12} xs={12} sm={12}><Collapse in={showEdit === 'rule ' + index} timeout="auto" unmountOnExit><Card className={classes.card}>
                                                                {/* ACCOUNT SELECTION */}
                                                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Payment Service Account
                                                                </Typography>
                                                                <Select
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    className={classes.select}
                                                                    value={newRouteAccId}
                                                                    onChange={(e) => {
                                                                        setNewRouteAccID(e.target.value)
                                                                    }}
                                                                    label="Payment Service Account"
                                                                >
                                                                    {
                                                                        detailedPosPmtSvcAcc && detailedPosPmtSvcAcc.accounts && detailedPosPmtSvcAcc.accounts.map((account, index) => {
                                                                            return <MenuItem key={index} value={account.id}>{account.name}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                                {/* IIN */}
                                                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Issuer Identification Network (FROM)
                                                                </Typography>
                                                                {`${iinMin}`}
                                                                <OutlinedInputMod fullWidth onChange={(e) => {
                                                                    setIinMin(e.target.value)
                                                                }} defaultValue={iinMin}/>
                                                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Issuer Identification Network (TO)
                                                                </Typography>
                                                                {`${iinMax}`}
                                                                <OutlinedInputMod fullWidth onChange={(e) => {
                                                                    setIinMax(e.target.value)
                                                                }} defaultValue={iinMax}/>
                                                                {/* Currency */}
                                                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Currency
                                                                </Typography>
                                                                <Select
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    className={classes.select}
                                                                    value={accCurrency}
                                                                    onChange={(e) => {
                                                                        setAccCurrency(e.target.value)
                                                                    }}
                                                                    label="Currency"
                                                                >
                                                                    {
                                                                        currencies && currencies.map((currency, index) => {
                                                                            return <MenuItem key={index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                                {/* AMOUNT */}
                                                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Minimum Amount (Optional)
                                                                </Typography>
                                                                <OutlinedInputMod fullWidth onChange={(e) => {
                                                                    setAmountMin(e.target.value)
                                                                }} defaultValue={amountMin}/>
                                                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Maximum Amount (Optional)
                                                                </Typography>
                                                                <OutlinedInputMod fullWidth onChange={(e) => {
                                                                    setAmountMax(e.target.value)
                                                                }} defaultValue={amountMax}/>
                                                                {<Button 
                                                                    className={classes.btn} 
                                                                    onClick={() => {
                                                                        const pName = (detailedPos && detailedPos.name) || ''
                                                                        handleShow('')
                                                                        setIinMin('')
                                                                        setIinMax('')
                                                                        setAmountMax('')
                                                                        setAmountMin('')
                                                                        setAccCurrency('')
                                                                    }} 
                                                                    color="primary"
                                                                >
                                                                    Cancel
                                                                </Button>}
                                                                <Button 
                                                                    className={classes.btn} 
                                                                    onClick={(e) => {
                                                                        handleEditRoute(index)
                                                                        handleShow('')
                                                                    }} 
                                                                    color="primary"
                                                                >
                                                                    Save
                                                                </Button>
                                                    </Card></Collapse></Grid>
                                                </div>
                                                )}
                                            </Draggable>
                                        })}
                                        {provided.placeholder}
                                    </List>
                                </RootRef>
                            )}
                        </Droppable>
                    </DragDropContext>}
                </Grid>
            </Grid>
        </PaperMod>
        </div>
        </Container>
    </main>
    )
}

export default Checkoutpages