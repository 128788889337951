function misc(state ={isLoading: false}, action) {
    switch (action.type) {
        case 'GET_ENUMERATION_REQUEST':
            return {...state, isLoading: true} 
        case 'GET_ENUMERATION_SUCCESS':
            // console.log("GET_ENUMERATION_SUCCESS", action);
            // const newEnum = {...state.enum, ...action.response.data}
            const newEnum = {...state.enum, ...action.data}
            return {...state, isLoading: false, enum: newEnum} 
        case 'GET_ENUMERATION_FAILURE':
            // console.log("GET_ENUMERATION_FAILURE", action);
            return {...state, isLoading: false} 
      default:
        return state
    }
  }

export default misc