function invoicesReport(state ={isLoading: false, hits: [], search_after: [], totalHits: 0 }, action) {
    switch (action.type) {
        case 'POST_SEARCH_INVOICES_REQUEST':
            // console.log('POST_PAYOUTS_SEARCH_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_SEARCH_INVOICES_SUCCESS':
            // console.log('POST_PAYOUTS_SEARCH_SUCCESS', action);
            const queryInvoicesByOrganizationId = (action.data && action.data.data && action.data.data.queryInvoicesByOrganizationId) || null
            const hits = queryInvoicesByOrganizationId ? queryInvoicesByOrganizationId.items ? queryInvoicesByOrganizationId.items : [] : []
            const nextToken = queryInvoicesByOrganizationId ? queryInvoicesByOrganizationId.nextToken : undefined
            return {...state,
                hits: hits,
                nextToken: nextToken,
                isLoading: false
            }
        case 'POST_SEARCH_INVOICES_FAILURE':
            return {...state, isLoading: false}
        case 'POST_SEARCH_AFTER_INVOICES_REQUEST':
            return {...state, isLoading: true}
        case 'POST_SEARCH_AFTER_INVOICES_SUCCESS':
            const nextQueryInvoicesByOrganizationId = (action.data && action.data.data && action.data.data.queryPayoutsByOrganizationId) || null
            const nextHits = nextQueryInvoicesByOrganizationId ? nextQueryInvoicesByOrganizationId.items : nextQueryInvoicesByOrganizationId.items ? nextQueryInvoicesByOrganizationId.items : []
            const nextNextToken = nextQueryInvoicesByOrganizationId ? nextQueryInvoicesByOrganizationId.nextToken : undefined
            const combinedHits = state.hits.concat(nextHits);
            return {...state,
                hits: combinedHits,
                nextToken: nextNextToken,
                isLoading: false
            }
        case 'POST_SEARCH_AFTER_INVOICES_FAILURE':
            // console.log('POST_PAYOUTS_SEARCH_AFTER_FAILURE', action);
            return {...state, isLoading: false}
        case 'CLEAR_INVOICES_SEARCH':
            return {...state,
                hits: [],
                totalHits: 0,
                search_after: [],
                isLoading: false
            }
        case 'PATCH_INVOICE_REQUEST':
            return {...state, isLoading: true}
        case 'PATCH_INVOICE_SUCCESS':
            console.log('PATCH_INVOICE_SUCCESS', action);
            let targetPatchInvoice = (action && action.data && action.data) || {}
            const patchHits = state.hits.map((eachHit) => {
                if (eachHit.id === targetPatchInvoice.id) return targetPatchInvoice
                return eachHit
            })
            return {...state, hits: patchHits, isLoading: false }
        case 'PATCH_INVOICE_FAILURE':
            return {...state, isLoading: false}
        default:
            return {...state, isLoading: false} 
    }
  }

export default invoicesReport