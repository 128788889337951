import React, { useState, useEffect, useLayoutEffect } from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInput } from '@material-ui/core';
import { green, red, grey } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import {fetchApi} from '../app/utils/callApi';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider , KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
    hiddeninput: {
        display: 'none',
    },
    circularProgress: {
        color: '#68d0af'
    },
    editIcon: {
        color: grey['A200'],
        '&:hover': {
            color: 'black',
            cursor: 'pointer'
        },
    },
    card: {
        padding: '1em',
        zIndex: 50,
    },
    btn: {
        color: '#1fa595'
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        },
        '&:after': {
            borderBottom: '2px solid #68d0af', // 1fa595 
        }
    },
}));

const PaperMod = withStyles({
    root: {
        marginTop: '1em',
        padding: '1em',
    },
})(Paper);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        },
    },
})(OutlinedInput);

const DOBTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);

const GreenSwitch = withStyles({
    switchBase: {
      color: green[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const Owners = (props) => {
    const classes = useStyles(props)
    const theme = useTheme();
    const { value, organization, patchOrganization, countries, nationalities, ownersread, ownerswrite, environment, entities, mcc } = props
    // console.log("ORGAN => ", organization);
    const [open, setOpen] = useState(false);
    const [showEdit, setShowEdit] = useState('');
    const [organizationID, setOrganizationID] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [fileLoading, setFileLoading] = useState(false);
    const [sharePercentage, setSharePercentage] = useState('');
    const [idFileLoading, setIdFileLoading] = useState(false);
    const [addFileLoading, setAddFileLoading] = useState(false);
    const [pOwnerCon3, setPOwnerCon3] = useState('');
    const [beneficialOwners, setBeneficialOwners] = useState([]);
    const [preBeneficialOwners, setPreBeneficialOwners] = useState([]);
    const [entityTypes, setEntityTypes] = useState([]);
    const [organizationType, setOrganizationType] = useState('');
    const [organizationMcc, setOrganizationMcc] = useState('');
    const [primaryShare, setPrimaryShare] = useState('');
    const [primaryGender, setPrimaryGender] = useState('');
    const [primaryNationality, setPrimaryNationality] = useState('');
    const [primaryID, setPrimaryID] = useState('');
    const [orgArticleLoading, setOrgArticleLoading] = useState(false);
    const [idProofLoading, setIdProofLoading] = useState(false);
    const [addProofLoading, setAddProofLoading] = useState(false);
    const [primaryState, setPrimaryState] = useState('');
    const [primaryCity, setPrimaryCity] = useState('');
    const [primaryPostal, setPrimaryPostal] = useState('');
    const [primaryAdd1, setPrimaryAdd1] = useState('');
    const [primaryAdd2, setPrimaryAdd2] = useState('');
    const [boIdProofLoading, setBoIdProofLoading] = useState(null);
    const [boAddProofLoading, setBoAddProofLoading] = useState(null);
    const [bizRegFileLoading, setBizRegFileLoading] = useState(null);
    const [bizAaFileLoading, setBizAaFileLoading] = useState(null);
    const [financeFileLoading, setFinanceFileLoading] = useState(null);
    useEffect(() => {
        if (organization && organization.id) setOrganizationID(organization.id)
        if (organization && organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.state) setPrimaryState(organization.primaryOwner.address.state)
        if (organization && organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.city) setPrimaryCity(organization.primaryOwner.address.city)
        if (organization && organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.postal) setPrimaryPostal(organization.primaryOwner.address.postal)
        if (organization && organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.line1) setPrimaryAdd1(organization.primaryOwner.address.line1)
        if (organization && organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.line2) setPrimaryAdd2(organization.primaryOwner.address.line2)
        if (organization && organization.primaryOwner && organization.primaryOwner.firstName ) setFirstName(organization.primaryOwner.firstName)
        if (organization && organization.primaryOwner && organization.primaryOwner.lastName ) setLastName(organization.primaryOwner.lastName)
        if (organization && organization.primaryOwner && organization.primaryOwner.sharePercentage ) setPrimaryShare(organization.primaryOwner.sharePercentage)
        if (organization && organization.primaryOwner && organization.primaryOwner.gender ) setPrimaryGender(organization.primaryOwner.gender)
        if (organization && organization.primaryOwner && organization.primaryOwner.nationality ) setPrimaryNationality(organization.primaryOwner.nationality)
        if (organization && organization.primaryOwner && organization.primaryOwner.country ) setPOwnerCon3(organization.primaryOwner.country)
        if (organization && organization.primaryOwner && organization.primaryOwner.identification) setPrimaryID(organization.primaryOwner.identification)
        if (organization && organization.beneficialOwners) setPreBeneficialOwners(organization.beneficialOwners)
        if (organization && organization.beneficialOwners) setBeneficialOwners(organization.beneficialOwners)
    },[organization.id, organization])
    const handleShow = (field) => {
        setShowEdit(field)
    }
    const handleSubmit = (reqBody) => {
        // console.log('submitting');
        if (!organizationID) return
        // console.log('submitting executed');
        const params = { organizationId: organizationID }
        patchOrganization(params, reqBody)
    }

    const updateBO = (index, updates) => {
        // console.log('updates -----> ', updates);
        let currBO = [...beneficialOwners];
        let targetBO = currBO[index];
        let updatedBO = {
            ...targetBO,
            ...updates
        };
        currBO[index] = updatedBO;
        // console.log('currBO before request sent ---> ', currBO);
        setBeneficialOwners(currBO);
        handleSubmit({beneficialOwners: currBO});
    }

    const setPreBO = (index, updates) => {
        let currBO = [...beneficialOwners];
        let targetBO = currBO[index];
        let updatedBO = {
            ...targetBO,
            ...updates
        };
        currBO[index] = updatedBO;
        setPreBeneficialOwners(currBO);
    }

    const removeBeneficialOwner = (index) => {
        let updateBody;
        let currBO = beneficialOwners.filter((owner, indexCount) => {
            return indexCount != index
        });
        setBeneficialOwners(currBO);
        let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
        updateBody = {beneficialOwners: tempBO.filter((eachOwner, idx) => {
            // Remove deleted beneficial owner
            return idx != index
        })}
        handleSubmit(updateBody);
    }

    const saveFile = (file, content, loading) => {
        try {
            let fileNameArr = file.name.split('.')
            fetchApi(environment,
                '/organizations/:organizationId/kycdoc?section=bankdetails',
                'post',
                {organizationId: organization.id},
                {}
                ).then((result) => {
                    // console.log('presigned result => ', result);
                    const { data } =result
                    if (!data.url) return
                    const xhr = new XMLHttpRequest()
                    let url = data.url
                    
                    xhr.open(data.method, url)
                    xhr.setRequestHeader('x-amz-acl', data.XamzAcl)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            // Update organization company registration documents
                            // Check which document it belongs to
                            // console.log(`${data.key ? data.key : ''}`);
                            // console.log(`${data}`);
                            let updateBody = {}
                            if (loading === 'orgArticleLoading') {
                                updateBody.articleOfAssociation = {
                                    name: file.name,
                                    key: data.key
                                }
                            } else if (loading === 'idProofLoading') {
                                let currPO = {...organization.primaryOwner} || {};
                                // console.log('currPO1 -> ', currPO);
                                currPO.proofOfIdentification = {
                                    name: file.name,
                                    key: data.key
                                };
                                // console.log('currPO2 -> ', currPO);
                                updateBody.primaryOwner = currPO;
                            } else if (loading === 'addProofLoading') {
                                let currPO = {...organization.primaryOwner} || {};
                                // console.log('currPO3 -> ', currPO);
                                currPO.proofOfAddress = {
                                    name: file.name,
                                    key: data.key
                                };
                                // console.log('currPO4 -> ', currPO);
                                updateBody.primaryOwner = currPO;
                            } else if (loading.includes('bo-id-')) {
                                const len = loading.length
                                const boIndex = parseInt(loading[len - 1])
                                let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
                                updateBody = {beneficialOwners: tempBO.map((eachOwner, idx) => {
                                    if (idx === boIndex) {
                                        eachOwner.proofOfIdentification = {
                                            name: file.name,
                                            key: data.key
                                        }
                                    }
                                    // This is to make sure nested arrays are also immutable
                                    return JSON.parse(JSON.stringify(eachOwner))
                                })}
                            } else if (loading.includes('bo-add-')) {
                                const len = loading.length
                                const boIndex = parseInt(loading[len - 1])
                                let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
                                updateBody = {beneficialOwners: tempBO.map((eachOwner, idx) => {
                                    if (idx === boIndex) {
                                        eachOwner.proofOfAddress = {
                                            name: file.name,
                                            key: data.key
                                        }
                                    }
                                    // This is to make sure nested arrays are also immutable
                                    return JSON.parse(JSON.stringify(eachOwner))
                                })}
                            } else if (loading.includes('biz-id-')) {
                                const len = loading.length
                                const boIndex = parseInt(loading[len - 1])
                                let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
                                updateBody = {beneficialOwners: tempBO.map((eachOwner, idx) => {
                                    if (idx === boIndex) {
                                        eachOwner.bizRegCert = {
                                            name: file.name,
                                            key: data.key
                                        }
                                    }
                                    // This is to make sure nested arrays are also immutable
                                    return JSON.parse(JSON.stringify(eachOwner))
                                })}
                            } else if (loading.includes('biz-aa-')) {
                                const len = loading.length
                                const boIndex = parseInt(loading[len - 1])
                                let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
                                updateBody = {beneficialOwners: tempBO.map((eachOwner, idx) => {
                                    if (idx === boIndex) {
                                        eachOwner.articleOfAssociation = {
                                            name: file.name,
                                            key: data.key
                                        }
                                    }
                                    // This is to make sure nested arrays are also immutable
                                    return JSON.parse(JSON.stringify(eachOwner))
                                })}
                            } else if (loading.includes('biz-finance-')) {
                                const len = loading.length
                                const boIndex = parseInt(loading[len - 1])
                                let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
                                updateBody = {beneficialOwners: tempBO.map((eachOwner, idx) => {
                                    let currStatements = (eachOwner && eachOwner.financialStatements) || []
                                    if (idx === boIndex) {
                                        eachOwner.financialStatements = [{
                                            name: file.name,
                                            key: data.key
                                        },...currStatements]
                                    }
                                    // This is to make sure nested arrays are also immutable
                                    return JSON.parse(JSON.stringify(eachOwner))
                                })}
                            }
                            // This is to make sure nested arrays are also immutable
                            let stringified = JSON.stringify(updateBody)
                            handleSubmit(JSON.parse(stringified));
                            setLoading(false, loading);
                        }
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setLoading(false, loading);
            // console.log('error : ', err);
        }
    }

    const setLoading = (boolean, loading) => {
        if (loading === 'orgArticleLoading') {
            setOrgArticleLoading(boolean)
        } else if (loading === 'idProofLoading') {
            setIdProofLoading(boolean)
        } else if (loading === 'addProofLoading') {
            setAddProofLoading(boolean)
        } else if (loading.includes('bo-id-')) {
            const len = loading.length
            let index = loading[len - 1]
            if (boolean === false) index = false
            setBoIdProofLoading(index)
        } else if (loading.includes('bo-add-')) {
            const len = loading.length
            let index = loading[len - 1]
            if (boolean === false) index = false
            setBoAddProofLoading(index)
        } else if (loading.includes('biz-id-')) {
            const len = loading.length
            let index = loading[len - 1]
            if (boolean === false) index = false
            setBizRegFileLoading(index)
        } else if (loading.includes('biz-finance-')) {
            const len = loading.length
            let index = loading[len - 1]
            if (boolean === false) index = false
            setFinanceFileLoading(index)
        } else if (loading.includes('biz-aa-')) {
            const len = loading.length
            let index = loading[len - 1]
            if (boolean === false) index = false
            setBizAaFileLoading(index)
        }
    }

    const fileUpload = (eventFile, loading) => {
        handleShow('')
        setLoading(true, loading);
        let file = eventFile[0]
        // console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setLoading(false, loading);
            return;
        }
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
            case 'application/pdf':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setLoading(false, loading);
            alert('Only png, jpeg and pdf file types allowed')
            // this.imageUploading = false
            return;
        }
        const max_file_size = 102400 * 10
        if (file.size <= max_file_size){
            const reader = new FileReader()
            // console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                // console.log('content -> ', content);
                // console.log('file 2 -> ', file);

                saveFile(file, content, loading)
            }
        }else {
            setLoading(false, loading);
            alert('Maximum size exceeded for file! Please make sure file size is less than 1MB');
            // this.imageUploading = false
        }
    }

    const removeFile = (loading) => {
        const blankFile = { name: '', key: ''};
        switch (loading) {
            case 'orgArticleLoading':
                handleSubmit({ articleOfAssociation: blankFile })
                break;
            case 'idProofLoading':
                let priOwner = (organization && organization.primaryOwner) || {}
                priOwner = JSON.parse(JSON.stringify(priOwner))
                priOwner.proofOfIdentification = blankFile
                handleSubmit({ primaryOwner: priOwner })
                break;
            case 'addProofLoading':
                let pOwner = (organization && organization.primaryOwner) || {}
                pOwner = JSON.parse(JSON.stringify(pOwner))
                pOwner.proofOfAddress = blankFile
                handleSubmit({ primaryOwner: pOwner })
                break;
            default:
                let updateBody
                if (loading.includes('bo-id-')) {
                    const len = loading.length
                    const boIndex = parseInt(loading[len - 1])
                    let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
                    updateBody = {beneficialOwners: tempBO.map((eachOwner, idx) => {
                        if (idx === boIndex) {
                            eachOwner.proofOfIdentification = blankFile
                        }
                        // This is to make sure nested arrays are also immutable
                        return JSON.parse(JSON.stringify(eachOwner))
                    })}
                } else if (loading.includes('bo-add-')) {
                    const len = loading.length
                    const boIndex = parseInt(loading[len - 1])
                    let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
                    updateBody = {beneficialOwners: tempBO.map((eachOwner, idx) => {
                        if (idx === boIndex) {
                            eachOwner.proofOfAddress = blankFile
                        }
                        // This is to make sure nested arrays are also immutable
                        return JSON.parse(JSON.stringify(eachOwner))
                    })}
                    // handleSubmit(updateBody)
                } else if (loading.includes('biz-id-')) {
                    const len = loading.length
                    const boIndex = parseInt(loading[len - 1])
                    let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
                    updateBody = {beneficialOwners: tempBO.map((eachOwner, idx) => {
                        if (idx === boIndex) {
                            eachOwner.bizRegCert = blankFile
                        }
                        // This is to make sure nested arrays are also immutable
                        return JSON.parse(JSON.stringify(eachOwner))
                    })}
                } else if (loading.includes('biz-aa-')) {
                    const len = loading.length
                    const boIndex = parseInt(loading[len - 1])
                    let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
                    updateBody = {beneficialOwners: tempBO.map((eachOwner, idx) => {
                        if (idx === boIndex) {
                            eachOwner.articleOfAssociation = blankFile
                        }
                        // This is to make sure nested arrays are also immutable
                        return JSON.parse(JSON.stringify(eachOwner))
                    })}
                } else if (loading.includes('biz-finance-')) {
                    const loadingArr = loading.split('-')
                    const docuIdx = loadingArr[loadingArr.length - 1]
                    const boIndex = loadingArr[loadingArr.length - 2]
                    let tempBO = JSON.parse(JSON.stringify(beneficialOwners))
                    // console.log('docuIdx -> ', docuIdx);
                    // console.log('boIndex -> ', boIndex);
                    updateBody = {beneficialOwners: tempBO.map((eachOwner, idx) => {
                        // Remove deleted document
                        if (idx == boIndex) {
                            // console.log('correct beneficial owner !');
                            // console.log('eachOwner.financialStatements1   ---> ', eachOwner.financialStatements);
                            eachOwner.financialStatements = eachOwner.financialStatements.filter((singleDoc, dIdx) => {
                                return dIdx != docuIdx
                            })
                            // console.log('eachOwner.financialStatements2   ---> ', eachOwner.financialStatements);
                        }
                        return JSON.parse(JSON.stringify(eachOwner))
                    })}
                }
                handleSubmit(updateBody)
                break;
        }
    }

    return (
        <TabPanel value={value} index={1} dir={theme.direction}>
        <span className={classes.Title}>Owner(s) details</span>
        <div>
            This account must be activated by someone authorized to sign on behalf of the organization. If you aren't,
            please invite an authorized person to become the company's account owner and complete the activation
        </div>
        {/* Article of Association / Proof of Ownership */}
        <PaperMod elevation={3} >
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Article of Association / Proof of Ownership</Typography>
                    <Typography >(Beneficial Owner(s) with at least 20% shares)</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    {orgArticleLoading ? <CircularProgress className={classes.circularProgress} /> : <div>
                        <Typography component={'span'}> {organization && ((organization.articleOfAssociation && organization.articleOfAssociation.name) || '')}</Typography>
                            <IconButton onClick={() => { removeFile('orgArticleLoading') }} component="span">
                                <DeleteOutlineIcon />
                            </IconButton>
                    </div>}
                    <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, 'orgArticleLoading')}} id="association-main" type="file" />
                    <label htmlFor="association-main">
                        <IconButton disabled={addFileLoading} color="primary" aria-label="upload file" component="span">
                            <PublishIcon className={classes.editIcon}/>
                        </IconButton>
                    </label>
                </Grid>
            </Grid>
        </PaperMod>
        <br/>
        <span className={classes.Title}>Primary Owner details</span>
        <PaperMod elevation={3} >
            {/* First name */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>First name</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                        <Typography component={'span'}>
                            {(organization && organization.primaryOwner && organization.primaryOwner.firstName) || ''}
                            <IconButton onClick={() => handleShow('first name')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>
                </Grid>
                {showEdit === 'first name' && <Grid item md={12} xs={12} sm={12}>
                        <Card className={classes.card}>
                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                First name
                            </Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName}/>
                            {<Button 
                                className={classes.btn} 
                                onClick={() => {
                                    const poFirstName = (organization && organization.primaryOwner && organization.primaryOwner.firstName) || ''
                                    handleShow('')
                                    setFirstName(poFirstName)
                                }} 
                                color="primary"
                            >
                                Cancel
                            </Button>}
                            <Button 
                                className={classes.btn} 
                                onClick={(e) => {
                                    let currPO = {...organization.primaryOwner} || {}
                                    currPO.firstName = firstName
                                    handleSubmit({ primaryOwner: currPO})
                                    handleShow('')
                                }} 
                                color="primary"
                            >
                                Save
                            </Button>
                        </Card>
                    </Grid>}
            </Grid>
            {/* Last Name */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Last name</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                        <Typography component={'span'}>
                            {(organization && organization.primaryOwner && organization.primaryOwner.lastName) || ''}
                            <IconButton onClick={() => handleShow('last name')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>
                </Grid>
                {showEdit === 'last name' && <Grid item md={12} xs={12} sm={12}>
                        <Card className={classes.card}>
                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                Last name
                            </Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setLastName(e.target.value)} defaultValue={lastName}/>
                            {<Button 
                                className={classes.btn} 
                                onClick={() => {
                                    const poLastName = (organization && organization.primaryOwner && organization.primaryOwner.lastName) || ''
                                    handleShow('')
                                    setFirstName(poLastName)
                                }} 
                                color="primary"
                            >
                                Cancel
                            </Button>}
                            <Button 
                                className={classes.btn} 
                                onClick={(e) => {
                                    let currPO = {...organization.primaryOwner} || {}
                                    currPO.lastName = lastName
                                    handleSubmit({ primaryOwner: currPO})
                                    handleShow('')
                                }} 
                                color="primary"
                            >
                                Save
                            </Button>
                        </Card>
                    </Grid>}
            </Grid>
            {/* Percentage */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>What percentage of the business do you own?</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                        <Typography component={'span'}>
                            {(organization && organization.primaryOwner && organization.primaryOwner.sharePercentage) || ''}
                            <IconButton onClick={() => handleShow('sharePercentage')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>
                </Grid>
                {showEdit === 'sharePercentage' && <Grid item md={12} xs={12} sm={12}>
                        <Card className={classes.card}>
                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                What percentage of the business do you own?
                            </Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setPrimaryShare(e.target.value)} defaultValue={primaryShare}/>
                            {<Button 
                                className={classes.btn} 
                                onClick={() => {
                                    const poShare = (organization && organization.primaryOwner && organization.primaryOwner.sharePercentage) || ''
                                    handleShow('')
                                    setPrimaryShare(poShare)
                                }} 
                                color="primary"
                            >
                                Cancel
                            </Button>}
                            <Button 
                                className={classes.btn} 
                                onClick={(e) => {
                                    let currPO = {...organization.primaryOwner} || {}
                                    let targetFloat = primaryShare
                                    try {
                                        targetFloat = parseFloat(targetFloat)
                                    } catch (err) {
                                        console.error(err);
                                        handleShow('')
                                        return
                                    }
                                    currPO.sharePercentage = targetFloat
                                    handleSubmit({ primaryOwner: currPO})
                                    handleShow('')
                                }} 
                                color="primary"
                            >
                                Save
                            </Button>
                        </Card>
                    </Grid>}
            </Grid>
            {/* Gender */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Gender</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Select
                        className={classes.select}
                        value={primaryGender}
                        onChange={(e) => {
                            let currPO = {...organization.primaryOwner} || {}
                            currPO.gender = e.target.value
                            handleSubmit({ primaryOwner: currPO })
                        }}
                        label="Country"
                    >
                        {
                            ['male', 'female'].map((gender, index) => {
                                return <MenuItem key={'gender-'+ index} value={gender}>{gender}</MenuItem>
                            })
                        }
                        <MenuItem key={'gender-none'} value={""}>Please Select a Gender</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            {/* Nationality */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Nationality</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Select
                        className={classes.select}
                        value={primaryNationality}
                        onChange={(e) => {
                            let currPO = {...organization.primaryOwner} || {}
                            currPO.nationality = e.target.value
                            handleSubmit({ primaryOwner: currPO })
                        }}
                        label="Country"
                    >
                        {
                            nationalities && nationalities.map((nationality, index) => {
                                return <MenuItem key={'nationality-'+ index} value={nationality.name}>{nationality.name}</MenuItem>
                            })
                        }
                        <MenuItem key={'nationality-none'} value={""}>Please Select a Nationality</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            {/* DOB */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Date of Birth</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <DOBTextField
                        id="date"
                        type="date"
                        onChange={(e) => {
                            let currPO = {...organization.primaryOwner} || {}
                            currPO.dob = e.target.value
                            handleSubmit({ primaryOwner: currPO})
                        }}
                        defaultValue={(organization && organization.primaryOwner && organization.primaryOwner.dob) || "2017-05-24"}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
            {/* Identification Number */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Identification Number</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                        <Typography component={'span'}>{(organization && organization.primaryOwner && organization.primaryOwner.identification) || ''}
                            <IconButton onClick={() => handleShow('idNumber')} >
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>
                </Grid>
                {showEdit === 'idNumber' && <Grid item md={12} xs={12} sm={12}>
                        <Card className={classes.card}>
                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                Identification Number
                            </Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setPrimaryID(e.target.value)} defaultValue={primaryID}/>
                            {<Button 
                                className={classes.btn} 
                                onClick={() => {
                                    const poID = (organization && organization.primaryOwner && organization.primaryOwner.identification) || ''
                                    handleShow('')
                                    setPrimaryID(poID)
                                }} 
                                color="primary"
                            >
                                Cancel
                            </Button>}
                            <Button 
                                className={classes.btn} 
                                onClick={(e) => {
                                    let currPO = {...organization.primaryOwner} || {}
                                    currPO.identification = primaryID
                                    handleSubmit({ primaryOwner: currPO})
                                    handleShow('')
                                }} 
                                color="primary"
                            >
                                Save
                            </Button>
                        </Card>
                    </Grid>}
            </Grid>
            {/* Proof of ID */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Proof of Identity</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                        {idProofLoading ? <CircularProgress className={classes.circularProgress} /> : <div><Typography component={'span'}> 
                            {(organization && organization.primaryOwner && organization.primaryOwner.proofOfIdentification && organization.primaryOwner.proofOfIdentification.name) || ''}
                            </Typography>
                            <IconButton onClick={() => { removeFile('idProofLoading') }} component="span">
                                <DeleteOutlineIcon />
                            </IconButton>
                        </div>}
                    <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, 'idProofLoading')}} id="id-button-file" type="file" />
                    <label htmlFor="id-button-file">
                        <IconButton disabled={idProofLoading} color="primary" aria-label="upload file" component="span">
                            <PublishIcon className={classes.editIcon}/>
                        </IconButton>
                    </label>
                </Grid>
            </Grid>
            {/* Address */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Addresss</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.line1}, {organization && organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.line2}
                        <IconButton onClick={() => handleShow('address')}>
                            <EditIcon className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'address' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            State
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setPrimaryState(e.target.value)
                        }} defaultValue={primaryState}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            City
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setPrimaryCity(e.target.value)
                        }} defaultValue={primaryCity}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Postal
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setPrimaryPostal(e.target.value)
                        }} defaultValue={primaryPostal}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Address Line 1
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setPrimaryAdd1(e.target.value)
                        }} defaultValue={primaryAdd1}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Address Line 2
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => { 
                            setPrimaryAdd2(e.target.value)
                        }} defaultValue={primaryAdd2}/>
                        {organization && <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('')
                                setPrimaryState(((organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.state) || ''))
                                setPrimaryCity(((organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.city) || ''))
                                setPrimaryPostal(((organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.postal) || ''))
                                setPrimaryAdd1(((organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.line1) || ''))
                                setPrimaryAdd2(((organization.primaryOwner && organization.primaryOwner.address && organization.primaryOwner.address.line2) || ''))
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={() => {
                                let currPO = {...organization.primaryOwner} || {}
                                // console.log('PREVIOUS -> ', currPO);
                                currPO.address = {
                                    state: primaryState,
                                    city: primaryCity,
                                    postal: primaryPostal,
                                    line1: primaryAdd1,
                                    line2: primaryAdd2,
                                }
                                // console.log('AFTER -> ', currPO);
                                handleSubmit({ primaryOwner: currPO })
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            {/* Proof of address */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Proof of Address</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                        {false && addProofLoading ? <CircularProgress className={classes.circularProgress} /> : <div>
                            <Typography component={'span'}>
                                {(organization.primaryOwner && organization.primaryOwner.proofOfAddress && organization.primaryOwner.proofOfAddress.name) || ''}
                            </Typography>
                            <IconButton onClick={() => { removeFile('addProofLoading') }} component="span">
                                <DeleteOutlineIcon />
                            </IconButton>
                        </div>}
                    <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, 'addProofLoading')}} id="add-button-file2" type="file" />
                    <label htmlFor="add-button-file2">
                        <IconButton disabled={addProofLoading} color="primary" aria-label="upload file" component="span">
                            <PublishIcon className={classes.editIcon}/>
                        </IconButton>
                    </label>
                </Grid>
            </Grid>
            {/* Country */}
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Country based in</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Select
                        className={classes.select}
                        value={pOwnerCon3}
                        onChange={(e) => {
                            let currPO = {...organization.primaryOwner} || {}
                            currPO.country = e.target.value
                            handleSubmit({ primaryOwner: currPO })
                        }}
                        label="Country"
                    >
                        {
                            countries && countries.map((country, index) => {
                                return <MenuItem key={'country-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                            })
                        }
                        <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </PaperMod>
        <br/>
        <span className={classes.Title}>Additional beneficial owners</span>
        <IconButton
            onClick={() => { 
                const newArr = [{
                    isEntity: false,
                    firstName: '',
                    lastName: '',
                    email: '',
                    identification: '',
                    sharePercentage: parseFloat(0),
                    country: '',
                    address: {},
                    entityName: '',
                    uen: '',
                    type: '',
                    entityType: '',
                    mcc: '',
                    description: '',
                },...beneficialOwners]
                setBeneficialOwners(newArr)
                handleSubmit({ 
                    beneficialOwners: newArr
                })
            }}
        >
            <AddIcon className={classes.editIcon} />
        </IconButton>
        <div>
            Please list all other beneficial owners. Beneficial owners are individuals who own more than 20% of the business, or are in the
            position to exercise significant control over the business's finances and operations (CEO, CFO, COO, or similar). 
            <b>Learn More</b>
        </div>
        <br/>
        {
            beneficialOwners.map((eachOwner, index) => {
                const targetEntityType = entities.filter((eachEntity) => {
                    if (eachEntity && eachEntity.name === eachOwner.country) return true
                    return false
                })
                return (<PaperMod elevation={3} key={'bo-' + index} >
                        {/* Remove Beneficial Owner */}
                        <IconButton onClick={() => { removeBeneficialOwner(index) }} component="span">
                            <DeleteOutlineIcon />
                        </IconButton>
                        {/* if it's an individual */}
                        {
                            eachOwner && !eachOwner.isEntity && (<Grid container spacing={3}>
                                {/* isEntity ? */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Is this beneficial owner a company?</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                {`${eachOwner.isEntity ? 'Yes':'No'}`}<GreenSwitch checked={(eachOwner && eachOwner.isEntity) || false} onChange={() => { updateBO(index, {isEntity: !eachOwner.isEntity})}} name="checkedA" />
                                </Grid>
                                {/* First Name */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>First name</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                        <Typography component={'span'}>
                                            {(eachOwner && eachOwner.firstName) || ''}
                                            <IconButton onClick={() => handleShow(`first name bo-${index}`)}>
                                                <EditIcon className={classes.editIcon}/>
                                            </IconButton>
                                        </Typography>
                                </Grid>
                                {showEdit === `first name bo-${index}` && <Grid item md={12} xs={12} sm={12}>
                                        <Card className={classes.card}>
                                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                First name
                                            </Typography>
                                            <OutlinedInputMod fullWidth onChange={(e) => setPreBO(index, {firstName: e.target.value})} defaultValue={(eachOwner && eachOwner.firstName) || ''}/>
                                            {<Button 
                                                className={classes.btn} 
                                                onClick={() => {
                                                    handleShow('')
                                                    setPreBeneficialOwners(beneficialOwners)
                                                }} 
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>}
                                            <Button 
                                                className={classes.btn} 
                                                onClick={(e) => {
                                                    handleSubmit({ 
                                                        beneficialOwners: [...preBeneficialOwners]
                                                    })
                                                    handleShow('')
                                                }} 
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </Card>
                                </Grid>}
                                {/* Last Name */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Last name</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                        <Typography component={'span'}>
                                            {(eachOwner && eachOwner.lastName) || ''}
                                            <IconButton onClick={() => handleShow(`last name bo-${index}`)}>
                                                <EditIcon className={classes.editIcon}/>
                                            </IconButton>
                                        </Typography>
                                </Grid>
                                {showEdit === `last name bo-${index}` && <Grid item md={12} xs={12} sm={12}>
                                        <Card className={classes.card}>
                                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                Last name
                                            </Typography>
                                            <OutlinedInputMod fullWidth onChange={(e) => setPreBO(index, {lastName: e.target.value})} defaultValue={(eachOwner && eachOwner.lastName) || ''}/>
                                            {<Button 
                                                className={classes.btn} 
                                                onClick={() => {
                                                    handleShow('')
                                                    setPreBeneficialOwners(beneficialOwners)
                                                }} 
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>}
                                            <Button 
                                                className={classes.btn} 
                                                onClick={(e) => {
                                                    handleSubmit({ 
                                                        beneficialOwners: [...preBeneficialOwners]
                                                    })
                                                    handleShow('')
                                                }} 
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </Card>
                                </Grid>}
                                {/* Email */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Email</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                        <Typography component={'span'}>
                                            {(eachOwner && eachOwner.email) || ''}
                                            <IconButton onClick={() => handleShow(`email bo-${index}`)}>
                                                <EditIcon className={classes.editIcon}/>
                                            </IconButton>
                                        </Typography>
                                </Grid>
                                {showEdit === `email bo-${index}` && <Grid item md={12} xs={12} sm={12}>
                                        <Card className={classes.card}>
                                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                Email
                                            </Typography>
                                            <OutlinedInputMod fullWidth onChange={(e) => setPreBO(index, {email: e.target.value})} defaultValue={(eachOwner && eachOwner.email) || ''}/>
                                            {<Button 
                                                className={classes.btn} 
                                                onClick={() => {
                                                    handleShow('')
                                                    setPreBeneficialOwners(beneficialOwners)
                                                }} 
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>}
                                            <Button 
                                                className={classes.btn} 
                                                onClick={(e) => {
                                                    handleSubmit({ 
                                                        beneficialOwners: [...preBeneficialOwners]
                                                    })
                                                    handleShow('')
                                                }} 
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </Card>
                                </Grid>}
                                {/* DOB */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Date Of Birth</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <DOBTextField
                                        id="date"
                                        type="date"
                                        onChange={(e) => {
                                            updateBO(index, { dob: e.target.value })
                                        }}
                                        defaultValue={(eachOwner && eachOwner.dob) || "2017-05-24"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                {/* Gender */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Gender</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Select
                                        className={classes.select}
                                        value={(eachOwner && eachOwner.gender) || ''}
                                        onChange={(e) => { 
                                            updateBO(index, { gender: e.target.value })
                                        }}
                                        label="Gender"
                                    >
                                        {
                                            ['male', 'female'].map((gender, idx) => {
                                                return <MenuItem key={'gender-bo-' + index + '-' + idx} value={gender}>{gender}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'bo-gender-none'} value={""}>Please Select a Gender</MenuItem>
                                    </Select>
                                </Grid>
                                {/* Nationality */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Nationality</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Select
                                        className={classes.select}
                                        value={(eachOwner && eachOwner.nationality) || ''}
                                        onChange={(e) => { 
                                            updateBO(index, { nationality: e.target.value })
                                        }}
                                        label="Nationality"
                                    >
                                        {
                                            nationalities && nationalities.map((nationality, idx) => {
                                                return <MenuItem key={'nationality-bo-' + index + '-' + idx} value={nationality.name}>{nationality.name}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'bo-nationality-none'} value={""}>Please Select a Nationality</MenuItem>
                                    </Select>
                                </Grid>
                                {/* Percentage of ownership */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>What percentage does he/she own?</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                        <Typography component={'span'}>
                                            {(eachOwner && eachOwner.sharePercentage) || ''}
                                            <IconButton onClick={() => handleShow(`percentage bo-${index}`)}>
                                                <EditIcon className={classes.editIcon}/>
                                            </IconButton>
                                        </Typography>
                                </Grid>
                                {showEdit === `percentage bo-${index}` && <Grid item md={12} xs={12} sm={12}>
                                        <Card className={classes.card}>
                                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                What percentage does he/she own?
                                            </Typography>
                                            <OutlinedInputMod fullWidth onChange={(e) => setPreBO(index, {sharePercentage: parseFloat(e.target.value)})} defaultValue={(eachOwner && eachOwner.sharePercentage) || ''}/>
                                            {<Button 
                                                className={classes.btn} 
                                                onClick={() => {
                                                    handleShow('')
                                                    setPreBeneficialOwners(beneficialOwners)
                                                }} 
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>}
                                            <Button 
                                                className={classes.btn} 
                                                onClick={(e) => {
                                                    handleSubmit({ 
                                                        beneficialOwners: [...preBeneficialOwners]
                                                    })
                                                    handleShow('')
                                                }} 
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </Card>
                                </Grid>}
                                {/* country */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Country based in</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Select
                                        className={classes.select}
                                        value={(eachOwner && eachOwner.country) || ''}
                                        onChange={(e) => { 
                                            updateBO(index, { country: e.target.value })
                                        }}
                                        label="Country"
                                    >
                                        {
                                            countries && countries.map((country, idx) => {
                                                return <MenuItem key={'country-bo-' + index + '-' + idx} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'bo-country-none'} value={""}>Please Select a Country</MenuItem>
                                    </Select>
                                </Grid>
                                {/* ID NUMBER */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Identification Number</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                        <Typography component={'span'}>
                                            {(eachOwner && eachOwner.identification) || ''}
                                            <IconButton onClick={() => handleShow(`idNum bo-${index}`)}>
                                                <EditIcon className={classes.editIcon}/>
                                            </IconButton>
                                        </Typography>
                                </Grid>
                                {showEdit === `idNum bo-${index}` && <Grid item md={12} xs={12} sm={12}>
                                        <Card className={classes.card}>
                                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                Identification Number
                                            </Typography>
                                            <OutlinedInputMod fullWidth onChange={(e) => setPreBO(index, {identification: e.target.value})} defaultValue={(eachOwner && eachOwner.identification) || ''}/>
                                            {<Button 
                                                className={classes.btn} 
                                                onClick={() => {
                                                    handleShow('')
                                                    setPreBeneficialOwners(beneficialOwners)
                                                }} 
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>}
                                            <Button 
                                                className={classes.btn} 
                                                onClick={(e) => {
                                                    handleSubmit({ 
                                                        beneficialOwners: [...preBeneficialOwners]
                                                    })
                                                    handleShow('')
                                                }} 
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </Card>
                                </Grid>}
                                {/* Proof of ID NUMBER */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Proof of Identity</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {boIdProofLoading === index ? <CircularProgress className={classes.circularProgress} /> : <div>
                                        <Typography component={'span'}> {(eachOwner && eachOwner.proofOfIdentification && eachOwner.proofOfIdentification.name) || ''}</Typography>
                                        <IconButton onClick={() => { removeFile(`bo-id-${index}`) }} component="span">
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </div>}
                                    <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, `bo-id-${index}`)}} id={`bo-id-icon-button-file-${index}`} type="file" />
                                    <label htmlFor={`bo-id-icon-button-file-${index}`}>
                                        <IconButton disabled={boIdProofLoading === index} color="primary" aria-label="upload file" component="span">
                                            <PublishIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </label>
                                </Grid>
                                {/* Address */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Addresss</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                        <Typography component={'span'}>
                                            {eachOwner && eachOwner.address && eachOwner.address.line1}, {eachOwner && eachOwner.address && eachOwner.address.line2}
                                            <IconButton onClick={() => handleShow(`bo-address-${index}`)}>
                                                <EditIcon className={classes.editIcon}/>
                                            </IconButton>
                                        </Typography>
                                </Grid>
                                {showEdit === `bo-address-${index}` && <Grid item md={12} xs={12} sm={12}>
                                    <Card className={classes.card}>
                                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                            State
                                        </Typography>
                                        <OutlinedInputMod fullWidth onChange={(e) => {
                                            let targetUpdate = preBeneficialOwners[index]
                                            targetUpdate = {
                                                ...targetUpdate.address,
                                                state: e.target.value
                                            }
                                            setPreBO(index, {address: targetUpdate})
                                        }} defaultValue={(eachOwner && eachOwner.address && eachOwner.address.state) || ''}/>
                                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                            City
                                        </Typography>
                                        <OutlinedInputMod fullWidth onChange={(e) => {
                                            let targetUpdate = preBeneficialOwners[index]
                                            targetUpdate = {
                                                ...targetUpdate.address,
                                                city: e.target.value
                                            }
                                            setPreBO(index, {address: targetUpdate})
                                        }} defaultValue={(eachOwner && eachOwner.address && eachOwner.address.city) || ''}/>
                                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                            Postal
                                        </Typography>
                                        <OutlinedInputMod fullWidth onChange={(e) => {
                                            let targetUpdate = preBeneficialOwners[index]
                                            targetUpdate = {
                                                ...targetUpdate.address,
                                                postal: e.target.value
                                            }
                                            setPreBO(index, {address: targetUpdate})
                                        }} defaultValue={(eachOwner && eachOwner.address && eachOwner.address.postal) || ''}/>
                                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                            Address Line 1
                                        </Typography>
                                        <OutlinedInputMod fullWidth onChange={(e) => {
                                                let targetUpdate = preBeneficialOwners[index]
                                                targetUpdate = {
                                                    ...targetUpdate.address,
                                                    line1: e.target.value
                                                }
                                                setPreBO(index, {address: targetUpdate})
                                            }} defaultValue={(eachOwner && eachOwner.address && eachOwner.address.line1) || ''}
                                        />
                                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                            Address Line 2
                                        </Typography>
                                        <OutlinedInputMod fullWidth onChange={(e) => { 
                                            let targetUpdate = preBeneficialOwners[index]
                                            targetUpdate = {
                                                ...targetUpdate.address,
                                                line2: e.target.value
                                            }
                                            setPreBO(index, {address: targetUpdate})
                                        }} defaultValue={(eachOwner && eachOwner.address && eachOwner.address.line2) || ''}/>
                                        {organization && <Button 
                                            className={classes.btn} 
                                            onClick={() => {
                                                handleShow('')
                                                setPreBeneficialOwners(beneficialOwners)
                                            }} 
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>}
                                        <Button 
                                            className={classes.btn} 
                                            onClick={() => {
                                                handleSubmit({ 
                                                    beneficialOwners: [...preBeneficialOwners]
                                                })
                                                handleShow('')
                                            }} 
                                            color="primary"
                                        >
                                            Save
                                        </Button>
                                    </Card>
                                </Grid>}
                                {/* Proof of address */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Proof of Address</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {boAddProofLoading === index ? <CircularProgress className={classes.circularProgress} /> : <div>
                                        <Typography component={'span'}> {(eachOwner && eachOwner.proofOfAddress && eachOwner.proofOfAddress.name) || ''}</Typography>
                                        <IconButton onClick={() => { removeFile(`bo-add-${index}`) }} component="span">
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </div>}
                                    <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, `bo-add-${index}`)}} id={`bo-add-icon-button-file-${index}`} type="file" />
                                    <label htmlFor={`bo-add-icon-button-file-${index}`}>
                                        <IconButton disabled={boAddProofLoading === index} color="primary" aria-label="upload file" component="span">
                                            <PublishIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </label>
                                </Grid>
                            </Grid>)
                        }
                        {/* If it's an entity */}
                        {
                            eachOwner && eachOwner.isEntity && (<Grid container spacing={3}>
                                {/* isEntity ? */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Is this beneficial owner an entity? (not a person)</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {`${eachOwner.isEntity ? 'Yes':'No'}`}<GreenSwitch checked={(eachOwner && eachOwner.isEntity) || false} onChange={() => { updateBO(index, {isEntity: !eachOwner.isEntity})}} name="checkedA" />
                                </Grid>
                                {/* Percentage of ownership */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>What percentage does it own?</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'}>
                                        {(eachOwner && eachOwner.sharePercentage) || ''}
                                        <IconButton onClick={() => handleShow(`percentage bo-${index}`)}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>
                                </Grid>
                                {showEdit === `percentage bo-${index}` && <Grid item md={12} xs={12} sm={12}>
                                        <Card className={classes.card}>
                                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                What percentage does it own?
                                            </Typography>
                                            <OutlinedInputMod fullWidth onChange={(e) => setPreBO(index, {sharePercentage: parseFloat(e.target.value)})} defaultValue={(eachOwner && eachOwner.sharePercentage) || ''}/>
                                            {<Button 
                                                className={classes.btn} 
                                                onClick={() => {
                                                    handleShow('')
                                                    setPreBeneficialOwners(beneficialOwners)
                                                }} 
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>}
                                            <Button 
                                                className={classes.btn} 
                                                onClick={(e) => {
                                                    handleSubmit({ 
                                                        beneficialOwners: [...preBeneficialOwners]
                                                    })
                                                    handleShow('')
                                                }} 
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </Card>
                                </Grid>}
                                {/* Entity Name */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Entity Name</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'}>
                                        {(eachOwner && eachOwner.entityName) || ''}
                                        <IconButton onClick={() => handleShow(`entity-name bo-${index}`)}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>
                                </Grid>
                                {showEdit === `entity-name bo-${index}` && <Grid item md={12} xs={12} sm={12}>
                                        <Card className={classes.card}>
                                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                Entity Name
                                            </Typography>
                                            <OutlinedInputMod fullWidth onChange={(e) => setPreBO(index, {entityName: e.target.value})} defaultValue={(eachOwner && eachOwner.entityName) || ''}/>
                                            {<Button 
                                                className={classes.btn} 
                                                onClick={() => {
                                                    handleShow('')
                                                    setPreBeneficialOwners(beneficialOwners)
                                                }} 
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>}
                                            <Button 
                                                className={classes.btn} 
                                                onClick={(e) => {
                                                    handleSubmit({ 
                                                        beneficialOwners: [...preBeneficialOwners]
                                                    })
                                                    handleShow('')
                                                }} 
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </Card>
                                </Grid>}
                                {/* UEN */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>UEN (unique entity number provided by an authority)</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'}>
                                        {(eachOwner && eachOwner.uen) || ''}
                                        <IconButton onClick={() => handleShow(`entity-uen bo-${index}`)}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>
                                </Grid>
                                {showEdit === `entity-uen bo-${index}` && <Grid item md={12} xs={12} sm={12}>
                                        <Card className={classes.card}>
                                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                Company uen
                                            </Typography>
                                            <OutlinedInputMod fullWidth onChange={(e) => setPreBO(index, {uen: e.target.value})} defaultValue={(eachOwner && eachOwner.uen) || ''}/>
                                            {<Button 
                                                className={classes.btn} 
                                                onClick={() => {
                                                    handleShow('')
                                                    setPreBeneficialOwners(beneficialOwners)
                                                }} 
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>}
                                            <Button 
                                                className={classes.btn} 
                                                onClick={(e) => {
                                                    handleSubmit({ 
                                                        beneficialOwners: [...preBeneficialOwners]
                                                    })
                                                    handleShow('')
                                                }} 
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </Card>
                                </Grid>}
                                {/* Business Registration Certificate */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Business Registration Certificate</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {bizRegFileLoading === index ? <CircularProgress className={classes.circularProgress} /> : <div>
                                        <Typography component={'span'}> {(eachOwner && eachOwner.bizRegCert && eachOwner.bizRegCert.name) || ''}</Typography>
                                        <IconButton onClick={() => { removeFile(`biz-id-${index}`) }} component="span">
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </div>}
                                    <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, `biz-id-${index}`)}} id={`biz-reg-icon-button-file-${index}`} type="file" />
                                    <label htmlFor={`biz-reg-icon-button-file-${index}`}>
                                        <IconButton disabled={bizRegFileLoading == index} color="primary" aria-label="upload file" component="span">
                                            <PublishIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </label>
                                </Grid>
                                {/* Article of Association / Proof of Ownership */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Article of Association / Proof of Ownership</Typography>
                                    <Typography >(Beneficial Owner(s) with at least 20% shares)</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {bizAaFileLoading === index ? <CircularProgress className={classes.circularProgress} /> : <div>
                                        <Typography component={'span'}> {(eachOwner && eachOwner.articleOfAssociation && eachOwner.articleOfAssociation.name) || ''}</Typography>
                                        <IconButton onClick={() => { removeFile(`biz-aa-${index}`) }} component="span">
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </div>}
                                    <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, `biz-aa-${index}`)}} id={`bo-association-icon-button-file-${index}`} type="file" />
                                    <label htmlFor={`bo-association-icon-button-file-${index}`}>
                                        <IconButton disabled={bizAaFileLoading == index} color="primary" aria-label="upload file" component="span">
                                            <PublishIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </label>
                                </Grid>
                                {/* country */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Country of Registration</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Select
                                        className={classes.select}
                                        value={(eachOwner && eachOwner.country) || ""}
                                        onChange={(e) => {
                                            updateBO(index, { country: e.target.value })
                                        }}
                                        label="Country"
                                    >
                                        {
                                            countries && countries.map((country, idx) => {
                                                return <MenuItem key={'country-bo-' + index + '-' + idx} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'bo-country-none'} value={""}>Please Select a Country</MenuItem>
                                    </Select>
                                </Grid>
                                {/* Address */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Addresss</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                        <Typography component={'span'}>{eachOwner && eachOwner.address && eachOwner.address.line1}, {eachOwner && eachOwner.address && eachOwner.address.line2}
                                            <IconButton onClick={() => handleShow(`bo-address-${index}`)}>
                                                <EditIcon className={classes.editIcon}/>
                                            </IconButton>
                                        </Typography>
                                </Grid>
                                {showEdit === `bo-address-${index}` && <Grid item md={12} xs={12} sm={12}>
                                    <Card className={classes.card}>
                                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                            State
                                        </Typography>
                                        <OutlinedInputMod fullWidth onChange={(e) => {
                                            let targetUpdate = preBeneficialOwners[index]
                                            targetUpdate = {
                                                ...targetUpdate.address,
                                                state: e.target.value
                                            }
                                            setPreBO(index, {address: targetUpdate})
                                        }} defaultValue={(eachOwner && eachOwner.address && eachOwner.address.state) || ''}/>
                                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                            City
                                        </Typography>
                                        <OutlinedInputMod fullWidth onChange={(e) => {
                                            let targetUpdate = preBeneficialOwners[index]
                                            targetUpdate = {
                                                ...targetUpdate.address,
                                                city: e.target.value
                                            }
                                            setPreBO(index, {address: targetUpdate})
                                        }} defaultValue={(eachOwner && eachOwner.address && eachOwner.address.city) || ''}/>
                                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                            Postal
                                        </Typography>
                                        <OutlinedInputMod fullWidth onChange={(e) => {
                                            let targetUpdate = preBeneficialOwners[index]
                                            targetUpdate = {
                                                ...targetUpdate.address,
                                                postal: e.target.value
                                            }
                                            setPreBO(index, {address: targetUpdate})
                                        }} defaultValue={(eachOwner && eachOwner.address && eachOwner.address.postal) || ''}/>
                                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                            Address Line 1
                                        </Typography>
                                        <OutlinedInputMod fullWidth onChange={(e) => {
                                            let targetUpdate = preBeneficialOwners[index]
                                            targetUpdate = {
                                                ...targetUpdate.address,
                                                line1: e.target.value
                                            }
                                            setPreBO(index, {address: targetUpdate})
                                        }} defaultValue={(eachOwner && eachOwner.address && eachOwner.address.line1) || ''}/>
                                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                            Address Line 2
                                        </Typography>
                                        <OutlinedInputMod fullWidth onChange={(e) => { 
                                            let targetUpdate = preBeneficialOwners[index]
                                            targetUpdate = {
                                                ...targetUpdate.address,
                                                line2: e.target.value
                                            }
                                            setPreBO(index, {address: targetUpdate})
                                        }} defaultValue={(eachOwner && eachOwner.address && eachOwner.address.line2) || ''}/>
                                        {organization && <Button 
                                            className={classes.btn} 
                                            onClick={() => {
                                                handleShow('')
                                                setPreBeneficialOwners(beneficialOwners)
                                            }} 
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>}
                                        <Button 
                                            className={classes.btn} 
                                            onClick={() => {
                                                handleSubmit({ 
                                                    beneficialOwners: [...preBeneficialOwners]
                                                })
                                                handleShow('')
                                            }} 
                                            color="primary"
                                        >
                                            Save
                                        </Button>
                                    </Card>
                                </Grid>}
                                {/* Proof of address */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Proof of Address</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {boAddProofLoading === index ? <CircularProgress className={classes.circularProgress} /> : <div>
                                        <Typography component={'span'}> {(eachOwner && eachOwner.proofOfAddress && eachOwner.proofOfAddress.name) || ''}</Typography>
                                        <IconButton onClick={() => { removeFile(`bo-add-${index}`) }} component="span">
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </div>}
                                    <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, `bo-add-${index}`)}} id={`bo-add-icon-button-file-${index}`} type="file" />
                                    <label htmlFor={`bo-add-icon-button-file-${index}`}>
                                        <IconButton disabled={boAddProofLoading === index} color="primary" aria-label="upload file" component="span">
                                            <PublishIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </label>
                                </Grid>
                                {/* Entity Type */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Company Type</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Select
                                            className={classes.select}
                                            value={(eachOwner && eachOwner.type) || ''}
                                            onChange={(e) => {
                                                updateBO(index, { type: e.target.value })
                                            }}
                                            label="Type"
                                        >
                                            {
                                                targetEntityType && targetEntityType[0] && targetEntityType[0].value && targetEntityType[0].value.entity_types && targetEntityType[0].value.entity_types.map((entity, idx) => {
                                                    return <MenuItem key={'entity-bo-' + index + '-' + idx} value={entity}>{entity}</MenuItem>
                                                })
                                            }
                                            <MenuItem key={'bo-entity-none'} value={""}>Please Select a Company Type</MenuItem>
                                    </Select>
                                </Grid>
                                {/* Industry */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Industry</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Select
                                            className={classes.select}
                                            value={(eachOwner && eachOwner.mcc) || ''}
                                            onChange={(e) => {
                                                updateBO(index, { mcc: e.target.value })
                                            }}
                                            label="Industry"
                                        >
                                            {
                                                mcc && mcc.length > 0 && mcc.map((category, idx) => {
                                                    if (category.value && category.value.code) {
                                                        return <MenuItem key={'mcc-bo-' + index + '-' + idx} value={category.value.code}>{category.name}</MenuItem>
                                                    }
                                                })
                                            }
                                            <MenuItem key={'bo-mcc-none'} value={""}>Please Select an Industry</MenuItem>
                                    </Select>
                                </Grid>
                                {/* Company Description */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Business Description</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'}>
                                        {(eachOwner && eachOwner.description) || ''}
                                        <IconButton onClick={() => handleShow(`biz-description bo-${index}`)}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>
                                </Grid>
                                {showEdit === `biz-description bo-${index}` && <Grid item md={12} xs={12} sm={12}>
                                        <Card className={classes.card}>
                                            <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                Business Description
                                            </Typography>
                                            <OutlinedInputMod fullWidth onChange={(e) => setPreBO(index, {description: e.target.value})} defaultValue={(eachOwner && eachOwner.description) || ''}/>
                                            {<Button 
                                                className={classes.btn} 
                                                onClick={() => {
                                                    handleShow('')
                                                    setPreBeneficialOwners(beneficialOwners)
                                                }} 
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>}
                                            <Button 
                                                className={classes.btn} 
                                                onClick={(e) => {
                                                    handleSubmit({ 
                                                        beneficialOwners: [...preBeneficialOwners]
                                                    })
                                                    handleShow('')
                                                }} 
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </Card>
                                </Grid>}
                                {/* Financial Statements */}
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography component={'span'} className={classes.label}>Financial Statements</Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {financeFileLoading === index ? <CircularProgress className={classes.circularProgress} /> : <div>
                                        {eachOwner && eachOwner.financialStatements && eachOwner && eachOwner.financialStatements.map((eachDoc,docIdx) => {
                                            return <div><Typography component={'span'}>
                                                    {(eachDoc && eachDoc.name) || ''}
                                                </Typography><IconButton onClick={() => { removeFile(`biz-finance-${index}-${docIdx}`) }} component="span">
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                            </div>
                                        })}
                                    </div>}
                                    <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, `biz-finance-${index}`)}} id={`bo-finance-icon-button-file-${index}`} type="file" />
                                    <label htmlFor={`bo-finance-icon-button-file-${index}`}>
                                        <IconButton disabled={financeFileLoading === index} color="primary" aria-label="upload file" component="span">
                                            <PublishIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </label>
                                </Grid>
                            </Grid>)
                        }
                </PaperMod>)
            })
        }
    </TabPanel>
    )
}

export default Owners