import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getUserOrganizations, createOrganization } from '../actions'
import Main from '../components/Main'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users.user,
    organization: state.organizations.organization,
    organizations: state.organizations.organizations,
})
  
const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getUserOrganizations: () => dispatch(getUserOrganizations()),
    createOrganization: (reqBody) => dispatch(createOrganization(reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main)