function paymentsReport(state ={isLoading: false, hits: [], search_after: [], totalHits: 0 }, action) {
    switch (action.type) {
        case 'POST_PAYMENTS_SEARCH_REQUEST':
            // console.log('POST_PAYMENTS_SEARCH_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_PAYMENTS_SEARCH_SUCCESS':
            const queryPaymentsByOrganizationId = (action.data && action.data.data && action.data.data.queryPaymentsByOrganizationId) || null
            const getPayments = (action.data && action.data.data && action.data.data.getPayments) || null
            const hits = queryPaymentsByOrganizationId ? queryPaymentsByOrganizationId.items : getPayments ? [getPayments] : []
            const nextToken = queryPaymentsByOrganizationId ? queryPaymentsByOrganizationId.nextToken : undefined
            return {...state,
                hits: hits,
                nextToken: nextToken,
                isLoading: false
            }
        case 'POST_PAYMENTS_SEARCH_FAILURE':
            // console.log('POST_PAYMENTS_SEARCH_FAILURE', action);
            return {...state, isLoading: false}
        case 'POST_PAYMENTS_SEARCH_AFTER_REQUEST':
            // console.log('POST_PAYMENTS_SEARCH_AFTER_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_PAYMENTS_SEARCH_AFTER_SUCCESS':
            console.log('POST_PAYMENTS_SEARCH_AFTER_SUCCESS', action);
            const nextQueryPaymentsByOrganizationId = (action.data && action.data.data && action.data.data.queryPaymentsByOrganizationId) || null
            const getNextPayments = (action.data && action.data.data && action.data.data.getPayments) || null
            const nextHits = nextQueryPaymentsByOrganizationId ? nextQueryPaymentsByOrganizationId.items : getNextPayments ? [getNextPayments] : []
            const nextNextToken = nextQueryPaymentsByOrganizationId ? nextQueryPaymentsByOrganizationId.nextToken : undefined
            const combinedHits = state.hits.concat(nextHits);
            return {...state,
                hits: combinedHits,
                nextToken: nextNextToken,
                isLoading: false
            }
        case 'POST_PAYMENTS_SEARCH_AFTER_FAILURE':
            // console.log('POST_PAYMENTS_SEARCH_AFTER_FAILURE', action);
            return {...state, isLoading: false}
        case 'CLEAR_PAYMENTS_SEARCH':
            return {...state,
                hits: [],
                totalHits: 0,
                search_after: [],
            }
        case 'UPDATE_PAYMENTS_SEARCH':
            // console.log('UPDATE_PAYMENTS_SEARCH', action);
            let targetUpdatePaymentId = (action && action.reqBody && action.reqBody.payment && action.reqBody.payment.id) || null
            const newHits = state.hits.map((eachHit) => {
                if (eachHit.id === targetUpdatePaymentId) {
                    // console.log('target eachHit -> ', eachHit);
                    // console.log('intended update payment -> ', action.reqBody.payment);
                    return action.reqBody.payment
                }
                return eachHit
            })
            return {...state,
                hits: newHits
            }
        default:
            return {...state, isLoading: false} 
    }
  }

export default paymentsReport