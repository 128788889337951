import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { green, red, grey, teal } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchApi} from '../app/utils/callCustomerApi';

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const axios = require('axios');
const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);


const ButtonMod = withStyles({
    root: {
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const ButtonRed = withStyles({
    root: {
        marginTop: '1em',
        backgroundColor: red['A200'],
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: red['A400'] // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);


const DateTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const OutlinedInputPayment = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '11px 14px',
        }
    },
})(OutlinedInput);

const InputGreen = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(OutlinedInput);

const DOBTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);



const useStyles = makeStyles(theme => ({
    editIcon: {
        color: grey['A200'],
        '&:hover': {
            color: 'black',
            cursor: 'pointer'
        },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    label: {
        color: '#7f7f7f'
    },
    poslist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    card: {
        display: 'flex',
        minHeight: '17em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        position: 'absolute',
        bottom: 0,
    },
    add: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        textTransform: 'none'
    },
    imageRight: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: '-1em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    dialogTitle: {
        color: '#0c3336',
        fontSize: '1.7em'
    },
    additionalDetailAccordion: {
        width: '100%',
        marginBottom: '1em'
    },
    txnLogGrid: {
        margin: 0
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    addIcon: {
        color: teal['50'],
        '&:hover': {
            color: teal['A700'],
            cursor: 'pointer'
        },
    },
    circularProgress: {
        color: '#68d0af'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    editCustomerBtns: {
        marginRight: '1em'
    }
}));
  const txnStates = ['CREATED', 'DECLINED', 'REVERSED', 'EXPIRED', 'PENDING_CAPTURE', 'PENDING_SETTLEMENT', 'SETTLED', 'PENDING_REFUND', 'REFUNDED']
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const CustomersManagement = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont, getOrganization,
        getEnumeration, enumeration, organization, search_after, hits, totalHits, environment, postCustomersSearch, postCustomersSearchAfter,
        createCustomer, getCustomer, updateCustomer, deleteCustomer, clearCustomersSearch, updateCustomersSearch, customerswrite, customersread,
        removeDeletedCustomerHit
    } = props
    const [loading, SetLoading] = useState(false);
    // Later just have to Make a button to change the UTC +X
    const allReligions = ["Christianity", "Islam", "Secular/Nonreligious/Agnostic/Atheist", "Hinduism", "Buddhism", "Chinese traditional religion", "African traditional religions", "Sikhism", "Spiritism", "Judaism", "Baháʼí", "Jainism", "Shinto", "Cao Dai", "Zoroastrianism", "Tenrikyo", "Animism", "Neo-Paganism", "Unitarian Universalism", "Rastafari"]
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchName, setSearchName] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [searchPhone, setSearchPhone] = useState('');
    const [searchId, setSearchId] = useState('');
    const [searchDobFrom, setSearchDobFrom] = useState('');
    const [searchDobTo, setSearchDobTo] = useState('');
    const [searchNationality, setSearchNationality] = useState('');
    const [searchReligion, setSearchReligion] = useState('');
    const [searchOccupation, setSearchOccupation] = useState('');
    const [searchCompany, setSearchCompany] = useState('');
    const [searchReference, setSearchReference] = useState('');
    const [searchAddLine1, setSearchAddLine1] = useState('');
    const [searchAddLine2, setSearchAddLine2] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [searchState, setSearchState] = useState('');
    const [searchCountry, setSearchCountry] = useState('');
    const [searchPostal, setSearchPostal] = useState('');
    // New Customer Data
    const [newName, setNewName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newPhone, setNewPhone] = useState('');
    const [newDob, setNewDob] = useState(''); // 
    const [newAddLine1, setNewAddLine1] = useState('');
    const [newAddLine2, setNewAddLine2] = useState('');
    const [newCity, setNewCity] = useState('');
    const [newState, setNewState] = useState('');
    const [newCountry, setNewCountry] = useState('');
    const [newPostal, setNewPostal] = useState('');
    const [newNationality, setNewNationality] = useState('');
    const [newReligion, setNewReligion] = useState('');
    const [newOccupation, setNewOccupation] = useState('');
    const [newCompany, setNewCompany] = useState('');
    const [newReference, setNewReference] = useState('');
    // Edit Customer Data
    const [editName, setEditName] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [editDob, setEditDob] = useState(''); // 
    const [editAddLine1, setEditAddLine1] = useState('');
    const [editAddLine2, setEditAddLine2] = useState('');
    const [editCity, setEditCity] = useState('');
    const [editState, setEditState] = useState('');
    const [editCountry, setEditCountry] = useState('');
    const [editPostal, setEditPostal] = useState('');
    const [editNationality, setEditNationality] = useState('');
    const [editReligion, setEditReligion] = useState('');
    const [editOccupation, setEditOccupation] = useState('');
    const [editCompany, setEditCompany] = useState('');
    const [editReference, setEditReference] = useState('');

    const [countries, setCountries] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [occupations, setOccupations] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [page, setPage] = React.useState(1);
    const [loadedPage, setLoadedPage] = React.useState(1);
    const [customerDetail, setCustomerDetail] = React.useState({});
    const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
    const [newCustomerDialog, setNewCustomerDialog] = React.useState(false);
    const closeDiaLog = () => {
        setCustomerDetail({})
        setDialogIsOpen(false)
    }
    const [showEdit, setShowEdit] = useState('');

    const addCustomer = () => {
        let reqBody = {}
        if (newName) reqBody.name = newName
        if (newEmail) reqBody.email = newEmail
        if (newPhone) reqBody.phone = newPhone
        if (newDob) reqBody.dob = newDob
        let address = {}
        if (newAddLine1) address.line1 = newAddLine1
        if (newAddLine2) address.line2 = newAddLine2
        if (newCity) address.city = newCity
        if (newState) address.state = newState
        if (newCountry) address.country = newCountry
        if (newPostal) address.postal = newPostal
        if (newAddLine1 || newAddLine2 || newCity || newState || newCountry || newPostal) reqBody.address = address
        if (newNationality) reqBody.nationality = newNationality
        if (newReligion) reqBody.religion = newReligion
        if (newOccupation) reqBody.occupation = newOccupation
        if (newCompany) reqBody.company = newCompany
        if (newReference) reqBody.reference = newReference
        // console.log('reqBody -> ', reqBody);
        createCustomer({organizationId: organization.id}, reqBody)
    }

    const closeNewCustomerDialog = () => {
        setNewName('')
        setNewEmail('')
        setNewPhone('')
        setNewDob('')
        setNewAddLine1('')
        setNewAddLine2('')
        setNewCity('')
        setNewState('')
        setNewCountry('')
        setNewPostal('')
        setNewCustomerDialog(false)
    }
    const theme = useTheme();
    const constructSearchReqBody = (passedPageSize, restartSearch=false) => {
        let initialStruct = {
            from: searchFrom,
            size: passedPageSize || searchSize,
            query: {
                bool: {
                    must: [
                    ]
                },
            },
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }
        if (searchName) {
            initialStruct.query.bool.must.push(
                { "match": { "name": searchName }}
            )
        }
        if (searchEmail) {
            initialStruct.query.bool.must.push(
                { "match": { "email": searchEmail }}
            )
        }
        if (searchPhone) {
            initialStruct.query.bool.must.push(
                { "match": { "phone": searchPhone }}
            )
        }
        if (searchId) {
            initialStruct.query.bool.must.push(
                { "match": { "id": searchId }}
            )
        }
        if (searchDobFrom || searchDobTo) {
            const todayDate = new Date(Date.now())
            const stringToday = todayDate.getFullYear() + "-" + (todayDate.getMonth() + 1) + "-" + todayDate.getDate()
            initialStruct.query.bool.must.push(
                {"range": {"dob": {"gte": searchDobFrom ? searchDobFrom : '1900-01-01', "lte": searchDobTo ? searchDobTo : stringToday }}},
            )
        }
        if (searchNationality) {
            initialStruct.query.bool.must.push(
                { "match": { "nationality": searchNationality }}
            )
        }
        if (searchReligion) {
            initialStruct.query.bool.must.push(
                { "match": { "religion": searchReligion }}
            )
        }
        if (searchOccupation) {
            initialStruct.query.bool.must.push(
                { "match": { "occupation": searchOccupation }}
            )
        }
        if (searchCompany) {
            initialStruct.query.bool.must.push(
                { "match": { "company": searchCompany }}
            )
        }
        if (searchReference) {
            initialStruct.query.bool.must.push(
                { "match": { "reference": searchReference }}
            )
        }
        if (searchAddLine1) {
            initialStruct.query.bool.must.push(
                { "match": { "address.line1": searchAddLine1 }}
            )
        }
        if (searchAddLine2) {
            initialStruct.query.bool.must.push(
                { "match": { "address.line2": searchAddLine2 }}
            )
        }
        if (searchCity) {
            initialStruct.query.bool.must.push(
                { "match": { "address.city": searchCity }}
            )
        }
        if (searchState) {
            initialStruct.query.bool.must.push(
                { "match": { "address.state": searchState }}
            )
        }
        if (searchCountry) {
            initialStruct.query.bool.must.push(
                { "match": { "address.country": searchCountry }}
            )
        }
        if (searchPostal) {
            initialStruct.query.bool.must.push(
                { "match": { "address.postal": searchPostal }}
            )
        }
        if (!restartSearch && search_after && search_after.length > 0) {
            initialStruct.from = -1
            initialStruct.search_after = search_after
        }
        return initialStruct
    }
    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
        if (appbarIsWhiteFont) toggleAppbarWhiteFont(false)
    },[]);
    useEffect(() => {
        // Check if data of payment detail is still the same as the data from hits
        hits.forEach((singleHit) => {
            if (singleHit.id === customerDetail.id) {
                let newCustomerDetail = JSON.parse(JSON.stringify(customerDetail))
                setCustomerDetail(newCustomerDetail);
            }
        })
    },[hits]);
    useEffect(() => {
        if (enumeration && enumeration.country) setCountries(enumeration.country)
        if (enumeration && enumeration.nationality) setNationalities(enumeration.nationality)
        if (enumeration && enumeration.occupation) setOccupations(enumeration.occupation)
    }, [enumeration]);
    useEffect(() => {
        if (organization.id) {
            // console.log(JSON.stringify(organization));
            let esSearchReqBody = constructSearchReqBody()
            // console.log('esSearchReqBody -> ', esSearchReqBody);
            postCustomersSearch({organizationId: organization.id}, esSearchReqBody)
            setLoadedPage(1)
        }
    }, [organization.id]);

    useEffect(() => {
        if (!organization.id) {
            const url = window.location.href
            let urlArr = url.split('/')
            let organizationID = urlArr[urlArr.length - 2]
            // console.log('organizationID -> ', organizationID);
            getOrganization({organizationId: organizationID})
            // GetOrgPos({organizationId: organizationID})
        } else {
            // GetOrgPos({organizationId: organization.id})
        }
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'nationality'})
        getEnumeration({enumGroup: 'occupation'})
        return function cleanup() {
            clearCustomersSearch()
        };
    },[environment])

    const handleShow = (field) => {
        setShowEdit(field)
    }

    const history = useHistory();

    return (
    <main className={classes.topMargin}>
    <Typography variant="h4" className={classes.Title}>Customers</Typography>
    <AppBarMod position="static" color="default"></AppBarMod>
    <Container>
        <div className={classes.poslist}>
            <div>
            <Accordion expanded={isExpanded}>
                <AccordionSummary
                    onClick={() => {setIsExpanded(!isExpanded)}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Name</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchName(e.target.value)} defaultValue={searchName}/>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Email</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchEmail(e.target.value)} defaultValue={searchEmail}/>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Phone</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchPhone(e.target.value)} defaultValue={searchPhone}/>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>ID</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchId(e.target.value)} defaultValue={searchId}/>
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Date Of Birth (From)</Typography>
                            <DOBTextField
                                id="dobfrom"
                                type="date"
                                onChange={(e) => {
                                    setSearchDobFrom(e.target.value)
                                }}
                                defaultValue={searchDobFrom || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Date Of Birth (To)</Typography>
                            <DOBTextField
                                id="dobTo"
                                type="date"
                                onChange={(e) => {
                                    setSearchDobTo(e.target.value)
                                }}
                                defaultValue={searchDobTo || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Address Line 1</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setSearchAddLine1(e.target.value)} defaultValue={searchAddLine1}/>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Address Line 2</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setSearchAddLine2(e.target.value)} defaultValue={searchAddLine2}/>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>City</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setSearchCity(e.target.value)} defaultValue={searchCity}/>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>State</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setSearchState(e.target.value)} defaultValue={searchState}/>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(searchCountry || "")}
                            onChange={(e) => {
                                // console.log('e.target.value -> ', e.target.value);
                                setSearchCountry(e.target.value)
                            }}
                            label="Country"
                        >
                            {
                                countries && countries.map((country, index) => {
                                    return <MenuItem key={'country-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                })
                            }
                            <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Postal</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setSearchPostal(e.target.value)} defaultValue={searchPostal}/>
                    </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Nationality</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={(searchNationality || "")}
                                onChange={(e) => {
                                    // console.log('e.target.value -> ', e.target.value);
                                    setSearchNationality(e.target.value)
                                }}
                                label="Nationality"
                            >
                                {
                                    nationalities && nationalities.map((singleNationality, index) => {
                                        return <MenuItem key={'nationality-'+ index} value={singleNationality.name}>{singleNationality.name}</MenuItem>
                                    })
                                }
                                <MenuItem key={'religion-none'} value={""}>Nil, Please Select a Nationality</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Religion</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={(searchReligion || "")}
                                onChange={(e) => {
                                    // console.log('e.target.value -> ', e.target.value);
                                    setSearchReligion(e.target.value)
                                }}
                                label="Country"
                            >
                                {
                                    allReligions && allReligions.map((religion, index) => {
                                        return <MenuItem key={'religion-'+ index} value={religion}>{religion}</MenuItem>
                                    })
                                }
                                <MenuItem key={'religion-none'} value={""}>Nil, Please Select a Religion</MenuItem>
                            </Select>
                        </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Occupation</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(searchOccupation || "")}
                            onChange={(e) => {
                                // console.log('e.target.value -> ', e.target.value);
                                setSearchOccupation(e.target.value)
                            }}
                            label="Country"
                        >
                            {
                                occupations && occupations.map((occupation, index) => {
                                    return <MenuItem key={'occupation-'+ index} value={occupation.name}>{occupation.name}</MenuItem>
                                })
                            }
                            <MenuItem key={'occupation-none'} value={""}>Nil, Please Select a Occupation</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Company Name</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setSearchCompany(e.target.value)} defaultValue={searchCompany}/>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Reference</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setSearchReference(e.target.value)} defaultValue={searchReference}/>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                    <ButtonMod onClick={() => {
                        setPage(1);
                        setLoadedPage(1)
                        setSearchFrom(0);
                        let esSearchReqBody = constructSearchReqBody(null, true)
                        // console.log('PLEASE DONT FIRE! esSearchReqBody : ', esSearchReqBody);
                        postCustomersSearch({organizationId: organization.id}, esSearchReqBody)
                        setIsExpanded(false)
                    }}>Search</ButtonMod>
                </Grid>
                </Grid>
                </AccordionDetails>
            </Accordion>
            </div>
            <Grid container spacing={3}>
                <Grid item md={12} xs={12} sm={12}>
                    <Box justifyContent="flex-end" display="flex">
                        <Box p={1} padding={0}>
                            <ButtonMod
                                onClick={() => {
                                    setNewCustomerDialog(true)
                                }}
                            >
                                <AddIcon className={classes.addIcon}/> Add New
                            </ButtonMod>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
        <div className={classes.poslist}>
            <Grid container>
                {/* List of Payments */}
                {/* {`totalHits -> ${totalHits} `}
                {`hits -> ${hits.length}`} */}
                <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                    <XGrid
                        className={classes.xgrid}
                        page={page}
                        onPageChange={(params) => {
                            // console.log('onPageChange FIRED first part !!', params.page , ' ', loadedPage);
                            if (page >= params.page) return
                            setPage(params.page);
                            if (params.page <= loadedPage) return
                            // console.log('onPageChange FIRED second part !!', params.page , ' ', loadedPage);
                            setLoadedPage(loadedPage + 1)
                            let esSearchReqBody = constructSearchReqBody()
                            // console.log('esSearchReqBody -> ', esSearchReqBody);
                            postCustomersSearchAfter({organizationId: organization.id}, esSearchReqBody)
                        }}
                        onPageSizeChange={(params) =>{
                            // console.log('onPageSizeChange params.pageSize -> ', params.pageSize);
                            if (searchSize === params.pageSize) return
                            setSearchSize(params.pageSize)
                            setPage(1);
                            setLoadedPage(1)
                            let esSearchReqBody = constructSearchReqBody(params.pageSize, true)
                            // console.log('esSearchReqBody -> ', esSearchReqBody);
                            // console.log('PLEASE DONT FIRE!');
                            postCustomersSearch({organizationId: organization.id}, esSearchReqBody)
                        }}
                        rowCount={totalHits}
                        rowsPerPageOptions={[10]}
                        pageSize={13}
                        pagination
                        columns={[
                            { 
                                field: 'id',
                                headerName: 'Customer ID',
                                width: 350,
                                valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                            },
                            { 
                                field: 'name',
                                headerName: 'Name',
                                width: 170,
                                valueGetter: (params) => {
                                    // console.log('params !@#!@#!@#~!#~!@#~!@#!~@#@ ---> ', params);
                                    return `${(params && params.row && params.row.name && params.row.name) || ""}`
                                },
                            },
                            { 
                                field: 'email',
                                headerName: 'Email',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.email && params.row.email) || ""}`
                                },
                            },
                            { 
                                field: 'phone',
                                headerName: 'Phone',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.phone) || ""}`
                                },
                            },
                            { 
                                field: 'dob',
                                headerName: 'Date of Birth',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.dob) || ""}`
                                },
                            },
                            { 
                                field: 'nationality',
                                headerName: 'Nationality',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.nationality) || ""}`
                                },
                            },
                            { 
                                field: 'occupation',
                                headerName: 'Occupation',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.occupation) || ""}`
                                },
                            },
                            { 
                                field: 'company',
                                headerName: 'Company',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.company) || ""}`
                                },
                            },
                            { 
                                field: 'religion',
                                headerName: 'Religion',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.religion) || ""}`
                                },
                            },
                            { 
                                field: 'address.line1',
                                headerName: 'Address Line 1',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.address && params.row.address.line1) || ""}`
                                },
                            },
                            { 
                                field: 'address.line2',
                                headerName: 'Address Line 2',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.address && params.row.address.line2) || ""}`
                                },
                            },
                            { 
                                field: 'address.city',
                                headerName: 'Address City',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.address && params.row.address.city) || ""}`
                                },
                            },
                            { 
                                field: 'address.state',
                                headerName: 'Address State',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.address && params.row.address.state) || ""}`
                                },
                            },
                            { 
                                field: 'address.country',
                                headerName: 'Address Country',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.address && params.row.address.country) || ""}`
                                },
                            },
                            { 
                                field: 'address.postal',
                                headerName: 'Address Postal',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.address && params.row.address.postal) || ""}`
                                },
                            },
                            { 
                                field: 'reference',
                                headerName: 'Reference',
                                width: 170,
                                valueGetter: (params) => {
                                    return `${(params && params.row && params.row.reference) || ""}`
                                },
                            },
                        ]}
                        rows={hits}
                        onRowClick={async (rowParams) => {
                            // console.log('FIRED ROW CLICK! -> ', rowParams);
                            SetLoading(true)
                            const customerId = (rowParams.row && rowParams.row.id) || ""
                            try {
                                await fetchApi(environment,
                                    '/organizations/:organizationId/customers/:customerId',
                                    'get',
                                    {organizationId: organization.id, customerId: customerId},
                                    ).then(async (result) => {
                                        // console.log('customerDetail result => ', result);
                                        const customerDetailResp = result.data || {};
                                        setCustomerDetail(customerDetailResp)
                                })
                            } catch (err) {
                                // console.log(err);
                                return err
                            }
                            SetLoading(false)
                            setDialogIsOpen(true)
                        }}
                    />
                </div>
            </Grid>
        </div>
        <Dialog
            scroll={'body'}
            fullWidth
            maxWidth={'xl'}
            open={newCustomerDialog}
            onClose={closeNewCustomerDialog}
            aria-labelledby="max-width-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="max-width-dialog-title">New Customer</DialogTitle>
            <DialogContent id="scroll-dialog-description">
                <Grid container spacing={3}>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Name</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewName(e.target.value)} defaultValue={newName}/>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Email</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewEmail(e.target.value)} defaultValue={newEmail}/>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Phone</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewPhone(e.target.value)} defaultValue={newPhone}/>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Date of Birth</Typography>
                        <DOBTextField
                            id="date"
                            type="date"
                            onChange={(e) => {
                                setNewDob(e.target.value)
                            }}
                            defaultValue={(newDob) || "2017-05-24"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Address Line 1</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewAddLine1(e.target.value)} defaultValue={newAddLine1}/>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Address Line 2</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewAddLine2(e.target.value)} defaultValue={newAddLine2}/>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>City</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewCity(e.target.value)} defaultValue={newCity}/>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>State</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewState(e.target.value)} defaultValue={newState}/>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(newCountry || "")}
                            onChange={(e) => {
                                // console.log('e.target.value -> ', e.target.value);
                                setNewCountry(e.target.value)
                            }}
                            label="Country"
                        >
                            {
                                countries && countries.map((country, index) => {
                                    return <MenuItem key={'country-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                })
                            }
                            <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Postal</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewPostal(e.target.value)} defaultValue={newPostal}/>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>Nationality</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(newNationality || "")}
                            onChange={(e) => {
                                // console.log('e.target.value -> ', e.target.value);
                                setNewNationality(e.target.value)
                            }}
                            label="Country"
                        >
                            {
                                nationalities && nationalities.map((singleNationality, index) => {
                                    return <MenuItem key={'nationality-'+ index} value={singleNationality.name}>{singleNationality.name}</MenuItem>
                                })
                            }
                            <MenuItem key={'religion-none'} value={""}>Nil, Please Select a Nationality</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Religion</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(newReligion || "")}
                            onChange={(e) => {
                                // console.log('e.target.value -> ', e.target.value);
                                setNewReligion(e.target.value)
                            }}
                            label="Country"
                        >
                            {
                                allReligions && allReligions.map((religion, index) => {
                                    return <MenuItem key={'religion-'+ index} value={religion}>{religion}</MenuItem>
                                })
                            }
                            <MenuItem key={'religion-none'} value={""}>Nil, Please Select a Religion</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Occupation</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(newOccupation || "")}
                            onChange={(e) => {
                                // console.log('e.target.value -> ', e.target.value);
                                setNewOccupation(e.target.value)
                            }}
                            label="Country"
                        >
                            {
                                occupations && occupations.map((occupation, index) => {
                                    return <MenuItem key={'occupation-'+ index} value={occupation.name}>{occupation.name}</MenuItem>
                                })
                            }
                            <MenuItem key={'occupation-none'} value={""}>Nil, Please Select a Occupation</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Company Name</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewCompany(e.target.value)} defaultValue={newCompany}/>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Reference</Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setNewReference(e.target.value)} defaultValue={newReference}/>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <ButtonMod
                                onClick={() => {
                                    addCustomer()
                                    setNewCustomerDialog(false)
                                }}
                            >
                            Create
                        </ButtonMod>
                    </Grid>
                    <Grid item md={8} xs={8} sm={8}>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        <Dialog
            fullWidth
            maxWidth={'xl'}
            open={dialogIsOpen}
            onClose={closeDiaLog}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">Customer Details</DialogTitle>
            <DialogContent>
                {/* {`${JSON.stringify(customerDetail)}`} */}
                <Grid container spacing={3}>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>Customer ID</span> {`${customerDetail.id}`}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>

                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Name</b></Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.name) || ""}`}
                            <IconButton onClick={() => handleShow('editName')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Email</b></Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.email) || ""}`}
                            <IconButton onClick={() => handleShow('editEmail')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Phone</b></Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.phone) || ""}`}
                            <IconButton onClick={() => handleShow('editPhone')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>
                    </Grid>
                    {(showEdit === 'editName' || showEdit === 'editEmail' || showEdit === 'editPhone') && <Grid item md={12} xs={12} sm={12}>
                        {showEdit === 'editName' && <OutlinedInputMod placeholder={'Name'} fullWidth onChange={(e) => setEditName(e.target.value)} defaultValue={editName}/>}
                        {showEdit === 'editEmail' && <OutlinedInputMod  placeholder={'Email'} fullWidth onChange={(e) => setEditEmail(e.target.value)} defaultValue={editEmail}/>}
                        {showEdit === 'editPhone' && <OutlinedInputMod  placeholder={'Phone'} fullWidth onChange={(e) => setEditPhone(e.target.value)} defaultValue={editPhone}/>}
                        {<ButtonMod
                                className={classes.editCustomerBtns}
                                onClick={() => {
                                    setEditName('')
                                    setEditEmail('')
                                    setEditPhone('')
                                    handleShow('')
                                }}
                        >
                            Cancel
                        </ButtonMod>} 
                        <ButtonMod
                            onClick={() => {
                                let editCustomerDetail = {}
                                switch (showEdit) {
                                    case 'editName':
                                        editCustomerDetail.name = editName
                                        break;
                                    case 'editEmail':
                                        editCustomerDetail.email = editEmail
                                        break;
                                    case 'editPhone':
                                        editCustomerDetail.phone = editPhone
                                        break;
                                    default:
                                        break;
                                }
                                updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, editCustomerDetail)
                                setEditName('')
                                setEditEmail('')
                                setEditPhone('')
                                handleShow('')
                                setDialogIsOpen(false)
                            }}
                        >
                            Update
                        </ButtonMod>
                    </Grid>}
                    {/* Customer Extra Details */}
                    <Accordion className={classes.additionalDetailAccordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2a-header"
                        >
                            &nbsp;&nbsp;<Typography className={classes.dialogTitle}>Additional Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.additionalDetailAccordion}>
                            {/* Additional Details that require pdpa and gdpr */}
                            <Grid container spacing={3}>
                                {/* <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom><b>Name</b></Typography>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.name) || ""}`}
                                        <IconButton>
                                            <EditIcon onClick={() => handleShow('editName')} className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>
                                </Grid> */}
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Date Of Birth</Typography>
                                    <DOBTextField
                                        id="dobfrom"
                                        type="date"
                                        onChange={(e) => {
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, {dob: e.target.value})
                                        }}
                                        defaultValue={customerDetail.dob || "2017-05-24"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {showEdit !== "editAddress1" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`Address Line 1`}</Typography>}
                                    {showEdit !== "editAddress1" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.address && customerDetail.address.line1) || "None"}`}
                                        <IconButton onClick={() => handleShow('editAddress1')}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>}
                                    {showEdit === "editAddress1" && <OutlinedInputMod fullWidth placeholder={'Address Line 1'} onChange={(e) => setEditAddLine1(e.target.value)} defaultValue={editAddLine1}/>}
                                    {showEdit === "editAddress1" && <ButtonMod
                                        className={classes.editCustomerBtns}
                                        onClick={() => {
                                            setEditAddLine1('')
                                            handleShow('')
                                        }}
                                    >
                                        Cancel
                                    </ButtonMod>}
                                    {showEdit === "editAddress1" && <ButtonMod
                                        onClick={() => {
                                            let editCustomerDetail = { address: (customerDetail.address ? customerDetail.address : {}) }
                                            editCustomerDetail.address.line1 = editAddLine1
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, editCustomerDetail)
                                            setEditAddLine1('')
                                            handleShow('')
                                            setCustomerDetail({...customerDetail, address: editCustomerDetail.address})
                                        }}
                                    >
                                        Update
                                    </ButtonMod>}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {showEdit !== "editAddress2" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`Address Line 2`}</Typography>}
                                    {showEdit !== "editAddress2" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.address && customerDetail.address.line2) || "None"}`}
                                        <IconButton onClick={() => handleShow('editAddress2')}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>}
                                    {showEdit === "editAddress2" && <OutlinedInputMod fullWidth placeholder={'Address Line 2'} onChange={(e) => setEditAddLine2(e.target.value)} defaultValue={editAddLine2}/>}
                                    {showEdit === "editAddress2" && <ButtonMod
                                        className={classes.editCustomerBtns}
                                        onClick={() => {
                                            setEditAddLine2('')
                                            handleShow('')
                                        }}
                                    >
                                        Cancel
                                    </ButtonMod>}
                                    {showEdit === "editAddress2" && <ButtonMod
                                        onClick={() => {
                                            let editCustomerDetail = { address: (customerDetail.address ? customerDetail.address : {}) }
                                            editCustomerDetail.address.line2 = editAddLine2
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, editCustomerDetail)
                                            setEditAddLine2('')
                                            handleShow('')
                                            setCustomerDetail({...customerDetail, address: editCustomerDetail.address})
                                        }}
                                    >
                                        Update
                                    </ButtonMod>}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {showEdit !== "editCity" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`City`}</Typography>}
                                    {showEdit !== "editCity" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.address && customerDetail.address.city) || "None"}`}
                                        <IconButton onClick={() => handleShow('editCity')}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>}
                                    {showEdit === "editCity" && <OutlinedInputMod fullWidth placeholder={'City'} onChange={(e) => setEditCity(e.target.value)} defaultValue={editCity}/>}
                                    {showEdit === "editCity" && <ButtonMod
                                        className={classes.editCustomerBtns}
                                        onClick={() => {
                                            setEditCity('')
                                            handleShow('')
                                        }}
                                    >
                                        Cancel
                                    </ButtonMod>}
                                    {showEdit === "editCity" && <ButtonMod
                                        onClick={() => {
                                            let editCustomerDetail = { address: (customerDetail.address ? customerDetail.address : {}) }
                                            editCustomerDetail.address.city = editCity
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, editCustomerDetail)
                                            setEditCity('')
                                            handleShow('')
                                            setCustomerDetail({...customerDetail, address: editCustomerDetail.address})
                                        }}
                                    >
                                        Update
                                    </ButtonMod>}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {showEdit !== "editState" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`State`}</Typography>}
                                    {showEdit !== "editState" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.address && customerDetail.address.state) || "None"}`}
                                        <IconButton onClick={() => handleShow('editState')}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>}
                                    {showEdit === "editState" && <OutlinedInputMod fullWidth placeholder={'State'} onChange={(e) => setEditState(e.target.value)} defaultValue={editState}/>}
                                    {showEdit === "editState" && <ButtonMod
                                        className={classes.editCustomerBtns}
                                        onClick={() => {
                                            setEditState('')
                                            handleShow('')
                                        }}
                                    >
                                        Cancel
                                    </ButtonMod>}
                                    {showEdit === "editState" && <ButtonMod
                                        onClick={() => {
                                            let editCustomerDetail = { address: (customerDetail.address ? customerDetail.address : {}) }
                                            editCustomerDetail.address.state = editState
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, editCustomerDetail)
                                            setEditState('')
                                            handleShow('')
                                            setCustomerDetail({...customerDetail, address: editCustomerDetail.address})
                                        }}
                                    >
                                        Update
                                    </ButtonMod>}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={((customerDetail && customerDetail.address && customerDetail.address.country) || "")}
                                        onChange={(e) => {
                                            let editCustomerDetail = { address: (customerDetail.address ? customerDetail.address : {}) }
                                            editCustomerDetail.address.country = e.target.value
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, editCustomerDetail)
                                            setCustomerDetail({...customerDetail, address: editCustomerDetail.address})
                                        }}
                                        label="Country"
                                    >
                                        {
                                            countries && countries.map((country, index) => {
                                                return <MenuItem key={'country-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {showEdit !== "editPostal" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`Postal`}</Typography>}
                                    {showEdit !== "editPostal" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.address && customerDetail.address.postal) || "None"}`}
                                        <IconButton onClick={() => handleShow('editPostal')}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>}
                                    {showEdit === "editPostal" && <OutlinedInputMod fullWidth placeholder={'Postal'} onChange={(e) => setEditState(e.target.value)} defaultValue={editPostal}/>}
                                    {showEdit === "editPostal" && <ButtonMod
                                        className={classes.editCustomerBtns}
                                        onClick={() => {
                                            setEditState('')
                                            handleShow('')
                                        }}
                                    >
                                        Cancel
                                    </ButtonMod>}
                                    {showEdit === "editPostal" && <ButtonMod
                                        onClick={() => {
                                            let editCustomerDetail = { address: (customerDetail.address ? customerDetail.address : {}) }
                                            editCustomerDetail.address.postal = editPostal
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, editCustomerDetail)
                                            setEditState('')
                                            handleShow('')
                                            setCustomerDetail({...customerDetail, address: editCustomerDetail.address})
                                        }}
                                    >
                                        Update
                                    </ButtonMod>}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Nationality</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={((customerDetail && customerDetail.nationality) || "")}
                                        onChange={(e) => {
                                            let editCustomerDetail = { }
                                            editCustomerDetail.nationality = e.target.value
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, editCustomerDetail)
                                            setCustomerDetail({...customerDetail, nationality: editCustomerDetail.nationality})
                                        }}
                                        label="Nationality"
                                    >
                                        {
                                            nationalities && nationalities.map((singleNationality, index) => {
                                                return <MenuItem key={'nationality-'+ index} value={singleNationality.name}>{singleNationality.name}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'nationality-none'} value={""}>Nil, Please Select a Nationality</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Religion</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(customerDetail.religion || "")}
                                        onChange={(e) => {
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, {religion: e.target.value})
                                            setCustomerDetail({...customerDetail, religion: e.target.value})
                                        }}
                                        label="Religion"
                                    >
                                        {
                                            allReligions && allReligions.map((religion, index) => {
                                                return <MenuItem key={'religion-'+ index} value={religion}>{religion}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'religion-none'} value={""}>Nil, Please Select a Religion</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Occupation</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(customerDetail.occupation || "")}
                                        onChange={(e) => {
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, {occupation: e.target.value})
                                            setCustomerDetail({...customerDetail, occupation: e.target.value})
                                        }}
                                        label="Occupation"
                                    >
                                        {
                                            occupations && occupations.map((occupation, index) => {
                                                return <MenuItem key={'occupation-'+ index} value={occupation.name}>{occupation.name}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'occupation-none'} value={""}>Nil, Please Select a Occupation</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    {showEdit !== "editCompany" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`Company`}</Typography>}
                                    {showEdit !== "editCompany" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.company) || "None"}`}
                                        <IconButton onClick={() => handleShow('editCompany')}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>}
                                    {showEdit === "editCompany" && <OutlinedInputMod fullWidth placeholder={'Company Name'} onChange={(e) => setEditCompany(e.target.value)} defaultValue={editCompany}/>}
                                    {showEdit === "editCompany" && <ButtonMod
                                        className={classes.editCustomerBtns}
                                        onClick={() => {
                                            setEditCompany('')
                                            handleShow('')
                                        }}
                                    >
                                        Cancel
                                    </ButtonMod>}
                                    {showEdit === "editCompany" && <ButtonMod
                                        onClick={() => {
                                            let editCustomerDetail = { company: editCompany }
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, editCustomerDetail)
                                            setEditCompany('')
                                            handleShow('')
                                            setCustomerDetail({...customerDetail, company: editCompany})
                                        }}
                                    >
                                        Update
                                    </ButtonMod>}
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    {showEdit !== "editReference" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`Reference`}</Typography>}
                                    {showEdit !== "editReference" && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(customerDetail.reference) || "None"}`}
                                        <IconButton onClick={() => handleShow('editReference')}>
                                            <EditIcon className={classes.editIcon}/>
                                        </IconButton>
                                    </Typography>}
                                    {showEdit === "editReference" && <OutlinedInputMod fullWidth placeholder={'Reference'} onChange={(e) => setEditReference(e.target.value)} defaultValue={editReference}/>}
                                    {showEdit === "editReference" && <ButtonMod
                                        className={classes.editCustomerBtns}
                                        onClick={() => {
                                            setEditReference('')
                                            handleShow('')
                                        }}
                                    >
                                        Cancel
                                    </ButtonMod>}
                                    {showEdit === "editReference" && <ButtonMod
                                        onClick={() => {
                                            let editCustomerDetail = { reference: editReference }
                                            updateCustomer({organizationId: organization.id, customerId: customerDetail.id}, editCustomerDetail)
                                            setEditReference('')
                                            handleShow('')
                                            setCustomerDetail({...customerDetail, reference: editReference})
                                        }}
                                    >
                                        Update
                                    </ButtonMod>}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {/* Payment Methods */}
                    <Accordion className={classes.additionalDetailAccordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2a-header" 
                        >
                            &nbsp;&nbsp;<Typography className={classes.dialogTitle}>Payment Methods</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.additionalDetailAccordion}>
                            {customerDetail && customerDetail.paymentMethods && customerDetail.paymentMethods.map((eachPmtMethod, index) => {
                                if (eachPmtMethod && eachPmtMethod.type === "card") {
                                    return <Grid container spacing={3}>
                                        <Grid item md={12} xs={12} sm={12}>Payment Method {`${index + 1}`}</Grid>
                                        <Grid item md={12} xs={12} sm={12}>Type: Card</Grid>
                                        {eachPmtMethod.card && eachPmtMethod.card.name && <Grid item md={3} xs={3} sm={3}>Cardholder: {`${eachPmtMethod.card.name}`}</Grid>}
                                        {eachPmtMethod.card && eachPmtMethod.card.data && <Grid item md={2} xs={2} sm={2}>Issuer Identification Number: {`${eachPmtMethod.card.data.iin}`}</Grid>}
                                        {eachPmtMethod.card && eachPmtMethod.card.data && <Grid item md={2} xs={2} sm={2}>Last Four: {`${eachPmtMethod.card.data.last4}`}</Grid>}
                                        {eachPmtMethod.card && eachPmtMethod.card.data && <Grid item md={2} xs={2} sm={2}>{`${eachPmtMethod.card.data.scheme ? `Scheme: ${eachPmtMethod.card.data.scheme}`: ''}`}</Grid>}
                                        {eachPmtMethod.card && eachPmtMethod.card.expiry && <Grid item md={2} xs={2} sm={2}>Expiry (MM/YY): {`${eachPmtMethod.card.expiry.month}`}/{`${eachPmtMethod.card.expiry.year}`}</Grid>}
                                        <Grid item md={1} xs={1} sm={1}></Grid>
                                        {eachPmtMethod.ttl && <Grid item md={12} xs={12} sm={12}>Time To Leave (TTL, the expiration of this data in EPOCH timestamp): {`${eachPmtMethod.ttl}`}</Grid>}
                                    </Grid>
                                }
                            }) }
                        </AccordionDetails>
                    </Accordion>
                    <Grid item md={12} xs={12} sm={12}>
                        <ButtonRed
                                onClick={() => {
                                    handleShow('')
                                    setCustomerDetail({})
                                    deleteCustomer({organizationId: organization.id, customerId: customerDetail.id})
                                    removeDeletedCustomerHit(customerDetail)
                                    setDialogIsOpen(false)
                                }}
                            >
                            Delete
                        </ButtonRed>
                    </Grid>
                </Grid>
            </DialogContent>
            <Backdrop className={classes.backdrop} open={loading} >
                <CircularProgress className={classes.circularProgress} />
            </Backdrop>
        </Dialog>
    </Container>
    <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress className={classes.circularProgress} />
    </Backdrop>
    </main>
    )
}

export default CustomersManagement