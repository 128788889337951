import React, { useState, useEffect, useLayoutEffect } from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInput } from '@material-ui/core';
import { green, red, grey } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchApi} from '../app/utils/callApi';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles(theme => ({
    hiddeninput: {
        display: 'none',
    },
    circularProgress: {
        color: '#68d0af'
    },
    editIcon: {
        color: grey['A200'],
        '&:hover': {
            color: 'black',
            cursor: 'pointer'
        },
    },
    card: {
        padding: '1em',
        zIndex: 50,
    },
    btn: {
        color: '#1fa595'
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    }
}));

const PaperMod = withStyles({
    root: {
        marginTop: '1em',
        padding: '1em',
    },
})(Paper);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        },
    },
})(OutlinedInput);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const BankDetails = (props) => {
    const classes = useStyles(props)
    const theme = useTheme();
    const { value, organization, patchOrganization, countries, bankdetailsread, bankdetailswrite, currencies, environment } = props
    // console.log("ORGAN => ", organization);
    const [open, setOpen] = useState(false);
    const [showEdit, setShowEdit] = useState('');
    const [organizationID, setOrganizationID] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankCode, setBankCode] = useState('');
    const [bankBranch, setBankBranch] = useState('');
    const [bankSwift, setBankSwift] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [bankAccountName, setBankAccountName] = useState('');
    const [bankAccountCurrency, setBankAccountCurrency] = useState('');
    const [fileLoading, setFileLoading] = useState(false);
    useEffect(() => {
        if (organization && organization.banks && organization.banks[0].name) setBankName(organization.banks[0].name)
        if (organization && organization.banks && organization.banks[0].code) setBankCode(organization.banks[0].code)
        if (organization && organization.banks && organization.banks[0].branch) setBankBranch(organization.banks[0].branch)
        if (organization && organization.banks && organization.banks[0].swift) setBankSwift(organization.banks[0].swift)
        if (organization && organization.banks && organization.banks[0].account && organization.banks[0].account.number) setBankAccountNumber(organization.banks[0].account.number)
        if (organization && organization.banks && organization.banks[0].account && organization.banks[0].account.name) setBankAccountName(organization.banks[0].account.name)
        if (organization && organization.banks && organization.banks[0].account && organization.banks[0].account.currency) setBankAccountCurrency(organization.banks[0].account.currency)
        if (organization && organization.id) setOrganizationID(organization.id)
    },[organization.id, organization])
    const handleShow = (field) => {
        setShowEdit(field)
    }
    const handleSubmit = (reqBody) => {
        if (!organizationID) return
        const params = { organizationId: organizationID }
        patchOrganization(params, reqBody)
    }

    const saveFile = (file, content) => {
        try {
            let fileNameArr = file.name.split('.')
            let currBankDetails = (organization.banks && organization.banks[0] && {...organization.banks[0]}) || {}
            fetchApi(environment,
                '/organizations/:organizationId/kycdoc?section=bankdetails',
                'post',
                {organizationId: organization.id},
                {}
                ).then((result) => {
                    // console.log('presigned result => ', result);
                    const { data } =result
                    if (!data.url) return
                    const xhr = new XMLHttpRequest()
                    let url = data.url
                    
                    xhr.open(data.method, url)
                    xhr.setRequestHeader('x-amz-acl', data.XamzAcl)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            // Update organization company registration documents
                            // console.log(`${data.key ? data.key : ''}`);
                            // console.log(`${data}`);
                            currBankDetails.bankStatement = {
                                name: file.name,
                                key: data.key
                            }
                            handleSubmit({ banks: [currBankDetails] });
                            setFileLoading(false);
                        }
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setFileLoading(false);
            // console.log('error : ', err);
        }
    }

    const fileUpload = (eventFile) => {
        handleShow('')
        setFileLoading(true);
        let file = eventFile[0]
        // console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setFileLoading(false);
            return;
        }
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
            case 'application/pdf':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setFileLoading(false);
            alert('Only png, jpeg and pdf file types allowed')
            // this.imageUploading = false
            return;
        }
        const max_file_size = 102400 * 10
        if (file.size <= max_file_size){
            const reader = new FileReader()
            // console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                // console.log('content -> ', content);
                // console.log('file 2 -> ', file);

                saveFile(file, content)
            }
        }else {
            setFileLoading(false);
            alert('Maximum size exceeded for file! Please make sure file size is less than 1MB');
            // this.imageUploading = false
        }
    }
    return (
        <TabPanel value={value} index={2} dir={theme.direction}>
        <span className={classes.Title}>Your bank details</span>
        <PaperMod elevation={3} >
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Bank name</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.banks && organization.banks[0].name}
                    <IconButton onClick={() => handleShow('bank name')}>
                        <EditIcon className={classes.editIcon}/>
                    </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'bank name' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Bank name
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setBankName(e.target.value)} defaultValue={bankName}/>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                const bName = (organization && organization.banks && organization.banks[0].name) || ''
                                handleShow('')
                                setBankName(bName)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                console.log('organization ', organization.banks)
                                let currBankDetails = (organization.banks && organization.banks[0] && {...organization.banks[0]}) || {}
                                currBankDetails.name = bankName
                                handleSubmit({ banks: [currBankDetails]})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Bank Code</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.banks && organization.banks[0].code}
                    <IconButton onClick={() => handleShow('bank code')}>
                        <EditIcon className={classes.editIcon}/>
                    </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'bank code' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Bank code
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setBankCode(e.target.value)} defaultValue={bankCode}/>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                const bCode = (organization && organization.banks && organization.banks[0].code) || ''
                                handleShow('')
                                setBankCode(bCode)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                console.log('organization ', organization.banks)
                                let currBankDetails = (organization.banks && organization.banks[0] && {...organization.banks[0]}) || {}
                                currBankDetails.code = bankCode
                                handleSubmit({ banks: [currBankDetails]})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Bank branch code</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.banks && organization.banks[0].branch}
                        <IconButton onClick={() => handleShow('branch')}>
                            <EditIcon className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'branch' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Bank branch code
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setBankBranch(e.target.value)} defaultValue={bankBranch}/>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                const bBranch = (organization && organization.banks && organization.banks[0].branch) || ''
                                handleShow('')
                                setBankName(bBranch)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                let currBankDetails = (organization.banks && organization.banks[0] && {...organization.banks[0]}) || {}
                                currBankDetails.branch = bankBranch
                                handleSubmit({ banks: [currBankDetails]})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Bank swift code</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.banks && organization.banks[0].swift}
                        <IconButton onClick={() => handleShow('swift')}>
                            <EditIcon className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'swift' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Bank swift code
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setBankSwift(e.target.value)} defaultValue={bankSwift}/>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                const bSwift = (organization && organization.banks && organization.banks[0].swift) || ''
                                handleShow('')
                                setBankName(bSwift)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                let currBankDetails = (organization.banks && organization.banks[0] && {...organization.banks[0]}) || {}
                                currBankDetails.swift = bankSwift
                                handleSubmit({ banks: [currBankDetails]})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Account</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.banks && organization.banks[0].account && organization.banks[0].account.name}, {organization && organization.banks && organization.banks[0].account && organization.banks[0].account.number}, {organization && organization.banks && organization.banks[0].account && organization.banks[0].account.currency} 
                        <IconButton onClick={() => handleShow('account')}>
                            <EditIcon className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'account' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Bank account name
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setBankAccountName(e.target.value)} defaultValue={bankAccountName}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Bank account number
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setBankAccountNumber(e.target.value)} defaultValue={bankAccountNumber}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Bank account currency
                        </Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={bankAccountCurrency}
                            onChange={(e) => {
                                setBankAccountCurrency(e.target.value)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.map((currency, index) => {
                                    return <MenuItem key={index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                })
                            }
                        </Select>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                const aName = (organization && organization.banks && organization.banks[0].account && organization.banks[0].account.name) || ''
                                const aNumber = (organization && organization.banks && organization.banks[0].account && organization.banks[0].account.number) || ''
                                const aCurrency = (organization && organization.banks && organization.banks[0].account && organization.banks[0].account.currency) || ''
                                handleShow('')
                                setBankAccountName(aName)
                                setBankAccountNumber(aNumber)
                                setBankAccountCurrency(aCurrency)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                let currBankDetails = (organization.banks && organization.banks[0] && {...organization.banks[0]}) || {}
                                currBankDetails.account = {
                                    name: bankAccountName,
                                    number: bankAccountNumber,
                                    currency: bankAccountCurrency,
                                }
                                handleSubmit({ banks: [currBankDetails] })
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Bank Statement</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                {organization && organization.banks && fileLoading ? <CircularProgress className={classes.circularProgress} /> : <div>
                    <Typography component={'span'}> {organization && organization.banks && organization.banks[0].bankStatement && organization.banks[0].bankStatement.name}</Typography>
                    <IconButton onClick={() => {
                        let currBankDetails = (organization.banks && organization.banks[0] && {...organization.banks[0]}) || {}
                        currBankDetails.bankStatement = {
                            name: '',
                            key: ''
                        }
                        handleSubmit({ 
                            banks: [currBankDetails]
                        })
                        }} component="span">
                        <DeleteOutlineIcon />
                    </IconButton>
                </div>}
                <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files)}} id="icon-button-file" type="file" />
                <label htmlFor="icon-button-file">
                    <IconButton disabled={fileLoading} color="primary" aria-label="upload file" component="span">
                        <PublishIcon className={classes.editIcon}/>
                    </IconButton>
                </label>
                </Grid>
            </Grid>
        </PaperMod>
    </TabPanel>
    )
}

export default BankDetails