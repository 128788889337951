import { CALL_PRODUCT_API } from '../app/middleware/callProductApi.js';

export const createProduct = (params, reqBody) => ({
    types: ['POST_PRODUCT_REQUEST', 'POST_PRODUCT_SUCCESS', 'POST_PRODUCT_FAILURE'],
    [CALL_PRODUCT_API]: '/organizations/:organizationId/products',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const getProduct = (params) => ({
    types: ['GET_PRODUCT_REQUEST', 'GET_PRODUCT_SUCCESS', 'GET_PRODUCT_FAILURE'],
    [CALL_PRODUCT_API]: '/organizations/:organizationId/products/:productId',
    method: 'get',
    params: params
})

export const updateProduct = (params, reqBody) => ({
    types: ['PATCH_PRODUCT_REQUEST', 'PATCH_PRODUCT_SUCCESS', 'PATCH_PRODUCT_FAILURE'],
    [CALL_PRODUCT_API]: '/organizations/:organizationId/products/:productId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const deleteProduct = (params) => ({
    types: ['DELETE_PRODUCT_REQUEST', 'DELETE_PRODUCT_SUCCESS', 'DELETE_PRODUCT_FAILURE'],
    [CALL_PRODUCT_API]: '/organizations/:organizationId/products/:productId',
    method: 'delete',
    params: params
})

export const postProductsSearch = (params, reqBody) => ({
    types: ['POST_PRODUCTS_SEARCH_REQUEST', 'POST_PRODUCTS_SEARCH_SUCCESS', 'POST_PRODUCTS_SEARCH_FAILURE'],
    [CALL_PRODUCT_API]: '/organizations/:organizationId/products-search',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const postProductsSearchAfter = (params, reqBody) => ({
    types: ['POST_PRODUCTS_SEARCH_AFTER_REQUEST', 'POST_PRODUCTS_SEARCH_AFTER_SUCCESS', 'POST_PRODUCTS_SEARCH_AFTER_FAILURE'],
    [CALL_PRODUCT_API]: '/organizations/:organizationId/products-search',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const clearProductsSearch = () => ({
    type: 'CLEAR_PRODUCTS_SEARCH'
})

export const updateProductsSearch = (reqBody) => ({
    type: 'UPDATE_PRODUCTS_SEARCH',
    reqBody: reqBody
})

export const removeDeletedProductHit = (reqBody) => ({
    type: 'REMOVE_DELETED_PRODUCT_HIT',
    reqBody: reqBody
})