import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont } from '../actions'
import UserProfile from '../components/UserProfile'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfile)