

// Action key that carries API call info interpreted by this Redux middleware.
const jwtExpiryCheck = store => next => async (action) => {

    const jwtExpiry = window.localStorage.getItem('libJwtExp')
    if (jwtExpiry) {
        let expiaryDate = new Date(jwtExpiry)
        expiaryDate.setHours(expiaryDate.getHours() - 22);

        const now = new Date()

        console.log(now >= expiaryDate);

        if (now >= expiaryDate) {
            window.localStorage.setItem('libJwt', '')
            window.localStorage.setItem('libJwtExp', '')
            window.localStorage.setItem('libJwtAccess', '')
            window.location.reload(true);
        }
    }

    return next(action)
}

export default jwtExpiryCheck