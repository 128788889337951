export const uuidGenerator = () => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let uuid = ''
    for (let index = 0; index < 36; index++) {
        let selected = chars[getRandomInt(chars.length -1)]
        if (((index + 1) % 4 === 0) && index !== 0 && index !== 35) {
            uuid += selected + "-"
        } else {
            uuid += selected
        }
    }

    return uuid
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}