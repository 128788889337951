import monitorReducersEnhancer from './enhancers/monitorReducers'
import loggerMiddleware from './middleware/logger'
import callApiMiddleware from './middleware/callApi'
import jwtExpiryCheck from './middleware/jwtExpiryCheck'
import callPosApiMiddleware from './middleware/callPosApi'
import callPmtsReportApiMiddleware from './middleware/callPmtsReportApi'
import callPaymentAppSyncApiMiddleware from './middleware/callPaymentAppSync'
import callPayoutsAppSyncApiMiddleware from './middleware/callPayoutsAppSync'
import callInvoicesAppSyncApiMiddleware from './middleware/callInvoicesAppSync'
import callBalsAppSyncApiMiddleware from './middleware/callBalsAppSync'
import callBillingsAppSyncApiMiddleware from './middleware/callBillingsAppSync'
import callDisputeAppSyncApiMiddleware from './middleware/callDisputeAppSync'
import callBalsReportApiMiddleware from './middleware/callBalsReportApi'
import callPayoutsReportApiMiddleware from './middleware/callPayoutsReportApi'
import callInvoicesApiMiddleware from './middleware/callInvoiceApi'
import callCustomerApiMiddleware from './middleware/callCustomerApi'
import callProductApiMiddleware from './middleware/callProductApi'
import callDisputeApiMiddleware from './middleware/callDisputeApi'
import rootReducer from './reducers'

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

export default function configureAppStore(preloadedState) {
  // console.log('preloadedState : ', preloadedState);
  
  const store = configureStore({
    reducer: rootReducer,
    middleware: process.env.NODE_ENV === 'development' ? [loggerMiddleware, jwtExpiryCheck, callPosApiMiddleware, callApiMiddleware, callPmtsReportApiMiddleware, callPaymentAppSyncApiMiddleware,callPayoutsAppSyncApiMiddleware,callInvoicesAppSyncApiMiddleware, callBalsAppSyncApiMiddleware,callBillingsAppSyncApiMiddleware, callDisputeAppSyncApiMiddleware, callBalsReportApiMiddleware, callPayoutsReportApiMiddleware, callCustomerApiMiddleware, callProductApiMiddleware, callInvoicesApiMiddleware,callDisputeApiMiddleware, ...getDefaultMiddleware()] : [
      jwtExpiryCheck, callPosApiMiddleware, callApiMiddleware, callPmtsReportApiMiddleware,callPaymentAppSyncApiMiddleware,callPayoutsAppSyncApiMiddleware,callInvoicesAppSyncApiMiddleware, callBalsAppSyncApiMiddleware,callBillingsAppSyncApiMiddleware, callDisputeAppSyncApiMiddleware, callBalsReportApiMiddleware, callPayoutsReportApiMiddleware, callCustomerApiMiddleware, callProductApiMiddleware, callInvoicesApiMiddleware, callDisputeApiMiddleware, ...getDefaultMiddleware()
    ],
    preloadedState,
    enhancers: process.env.NODE_ENV === 'development' ? [monitorReducersEnhancer] : []
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }
  // console.log('process.env.NODE_ENV -> ', process.env.NODE_ENV);
  // console.log('store state : ', store.getState());
  return store
}