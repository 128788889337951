import { CALL_DISPUTE_API } from '../app/middleware/callDisputeApi.js';
import { CALL_DISPUTE_APPSYNC_API } from '../app/middleware/callDisputeAppSync.js';

export const getDispute = (params) => ({
    types: ['GET_DISPUTE_REQUEST', 'GET_DISPUTE_SUCCESS', 'GET_DISPUTE_FAILURE'],
    [CALL_DISPUTE_API]: '/disputes/:disputeId',
    method: 'get',
    params: params
})

export const updateDispute = (params, reqBody) => ({
    types: ['PATCH_DISPUTE_REQUEST', 'PATCH_DISPUTE_SUCCESS', 'PATCH_DISPUTE_FAILURE'],
    [CALL_DISPUTE_API]: '/disputes/:disputeId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const postDisputesSearch = (reqBody) => ({
    types: ['POST_DISPUTE_SEARCH_REQUEST', 'POST_DISPUTE_SEARCH_SUCCESS', 'POST_DISPUTE_SEARCH_FAILURE'],
    [CALL_DISPUTE_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postDisputesSearchAfter = (reqBody) => ({
    types: ['POST_DISPUTE_SEARCH_AFTER_REQUEST', 'POST_DISPUTE_SEARCH_AFTER_SUCCESS', 'POST_DISPUTE_SEARCH_AFTER_FAILURE'],
    [CALL_DISPUTE_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const clearDisputesSearch = () => ({
    type: 'CLEAR_DISPUTE_SEARCH'
})

export const updateDisputesSearch = (reqBody) => ({
    type: 'UPDATE_DISPUTE_SEARCH',
    reqBody: reqBody
})