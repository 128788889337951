function productManagement(state ={isLoading: false, hits: [], search_after: [], totalHits: 0 }, action) {
    switch (action.type) {
        case 'POST_PRODUCTS_SEARCH_REQUEST':
            // console.log('POST_PRODUCTS_SEARCH_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_PRODUCTS_SEARCH_SUCCESS':
            // console.log('POST_PRODUCTS_SEARCH_SUCCESS', action);
            const hits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const search_after = hits.length > 0 ? hits[hits.length - 1].sort : [];
            const totalHits = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const hitsSource = hits.map((eachHit) => eachHit['_source'])
            return {...state,
                hits: hitsSource,
                totalHits: totalHits,
                search_after: search_after,
                isLoading: false
            }
        case 'POST_PRODUCTS_SEARCH_FAILURE':
            // console.log('POST_PRODUCTS_SEARCH_FAILURE', action);
            return {...state, isLoading: false}
        case 'POST_PRODUCTS_SEARCH_AFTER_REQUEST':
            // console.log('POST_PRODUCTS_SEARCH_AFTER_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_PRODUCTS_SEARCH_AFTER_SUCCESS':
            // console.log('POST_PRODUCTS_SEARCH_AFTER_SUCCESS', action);
            const nexthits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const totalHitsAfter = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const next_search_after = nexthits.length > 0 ? nexthits[nexthits.length - 1].sort : [];
            const nextHitsSource = nexthits.map((eachHit) => eachHit['_source'])
            const combinedHits = [...state.hits]
            const combinedIDs = {};
            combinedHits.forEach((eachHit) => combinedIDs[eachHit.id] = true)
            // console.log('combinedIDs -> ', combinedIDs);
            nextHitsSource.forEach((newHit) => {
                if (!combinedIDs[newHit.id]) {
                    combinedHits.push(newHit)
                }
            })
            return {...state,
                hits: combinedHits,
                totalHits: totalHitsAfter,
                search_after: next_search_after,
                isLoading: false
            }
        case 'POST_PRODUCTS_SEARCH_AFTER_FAILURE':
            // console.log('POST_PRODUCTS_SEARCH_AFTER_FAILURE', action);
            return {...state, isLoading: false}
        case 'CLEAR_PRODUCTS_SEARCH':
            return {...state,
                hits: [],
                totalHits: 0,
                search_after: [],
            }
        case 'POST_PRODUCT_REQUEST':
            // console.log('POST_PRODUCT_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_PRODUCT_SUCCESS':
            // console.log('POST_PRODUCT_SUCCESS', action);
            const newProductHits = [action.data,...state.hits]
            return {...state, hits: newProductHits, isLoading: false }
        case 'POST_PRODUCT_FAILURE':
            // console.log('POST_PRODUCT_FAILURE', action);
            return {...state, isLoading: false}
        case 'PATCH_PRODUCT_REQUEST':
            // console.log('PATCH_PRODUCT_REQUEST', action);
            return {...state, isLoading: true}
        case 'PATCH_PRODUCT_SUCCESS':
            // console.log('PATCH_PRODUCT_SUCCESS', action);
            let targetPatchProduct = (action && action.data && action.data) || {}
            const patchHits = state.hits.map((eachHit) => {
                if (eachHit.id === targetPatchProduct.id) return targetPatchProduct
                return eachHit
            })
            return {...state, hits: patchHits, isLoading: false }
        case 'PATCH_PRODUCT_FAILURE':
            // console.log('PATCH_PRODUCT_FAILURE', action);
            return {...state, isLoading: false}
        case 'DELETE_PRODUCT_REQUEST':
            // console.log('DELETE_PRODUCT_REQUEST', action);
            return {...state, isLoading: true}
        case 'DELETE_PRODUCT_SUCCESS':
            // console.log('DELETE_PRODUCT_SUCCESS', action);
            return {...state, isLoading: false }
        case 'DELETE_PRODUCT_FAILURE':
            // console.log('DELETE_PRODUCT_FAILURE', action);
            return {...state, isLoading: false}
        case 'REMOVE_DELETED_PRODUCT_HIT':
            // console.log('REMOVE_DELETED_PRODUCT_HIT :', action);
            const productDelete = action.reqBody;
            const remainingHits = state.hits.filter((eachHit) => {
                return eachHit.id != productDelete.id
            })
            return {...state, hits: remainingHits, isLoading: false}
        default:
            return {...state, isLoading: false} 
    }
  }

export default productManagement