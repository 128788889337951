import React, { useState, useEffect, useLayoutEffect } from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInput } from '@material-ui/core';
import { green, red, grey } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchApi} from '../app/utils/callApi';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
const useStyles = makeStyles(theme => ({
    hiddeninput: {
        display: 'none',
    },
    circularProgress: {
        color: '#68d0af'
    },
    editIcon: {
        color: grey['A200'],
        '&:hover': {
            color: 'black',
            cursor: 'pointer'
        },
    },
    card: {
        padding: '1em',
        zIndex: 50,
    },
    btn: {
        color: '#1fa595'
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    }
}));

const PaperMod = withStyles({
    root: {
        marginTop: '1em',
        padding: '1em',
    },
})(Paper);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        },
    },
})(OutlinedInput);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const OtherKycDocs = (props) => {
    const classes = useStyles(props)
    const theme = useTheme();
    const { value, organization, patchOrganization, environment } = props
    // console.log("ORGAN => ", organization);
    const [showEdit, setShowEdit] = useState('');
    const [organizationID, setOrganizationID] = useState('');
    const [processFileLoading, setProcessFileLoading] = useState(false);
    const [certFileLoading, setCertFileLoading] = useState(false);
    useEffect(() => {
        if (organization && organization.id) setOrganizationID(organization.id)
    },[organization.id, organization])
    const handleShow = (field) => {
        setShowEdit(field)
    }
    const handleSubmit = (reqBody) => {
        if (!organizationID) return
        const params = { organizationId: organizationID }
        patchOrganization(params, reqBody)
    }

    const saveFile = (file, content, loading) => {
        try {
            let fileNameArr = file.name.split('.')
            fetchApi(environment,
                '/organizations/:organizationId/kycdoc?section=otherdocs',
                'post',
                {organizationId: organization.id},
                {}
                ).then((result) => {
                    // console.log('presigned result => ', result);
                    const { data } =result
                    if (!data.url) return
                    const xhr = new XMLHttpRequest()
                    let url = data.url
                    
                    xhr.open(data.method, url)
                    xhr.setRequestHeader('x-amz-acl', data.XamzAcl)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            // Update organization company registration documents
                            // console.log(`${data.key ? data.key : ''}`);
                            // console.log(`${data}`);
                            // Check if it's certificates field or previousProcessings field
                            let currentOtherDocs = {...organization.otherSupportingDocuments}
                            if (loading === 'processFileLoading') {
                                const targetProcessings = (currentOtherDocs && currentOtherDocs.previousProcessings) || []
                                const targetCerts = (currentOtherDocs && currentOtherDocs.certificates) || []
                                let currentProcessings = [...targetProcessings]
                                currentProcessings.push({
                                    name: file.name,
                                    key: data.key
                                })
                                currentOtherDocs.previousProcessings = currentProcessings
                                currentOtherDocs.certificates = targetCerts
                            } else if (loading === 'certFileLoading') {
                                const targetCerts = (currentOtherDocs && currentOtherDocs.certificates) || []
                                const targetProcessings = (currentOtherDocs && currentOtherDocs.previousProcessings) || []
                                let currentCerts = [...targetCerts]
                                currentCerts.push({
                                    name: file.name,
                                    key: data.key
                                })
                                currentOtherDocs.certificates = currentCerts
                                currentOtherDocs.previousProcessings = targetProcessings
                            }
                            handleSubmit({otherSupportingDocuments: currentOtherDocs});
                            setLoading(false, loading);
                        }
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setLoading(false, loading);
            // console.log('error : ', err);
        }
    }

    const setLoading = (boolean, loading) => {
        if (loading === 'processFileLoading') {
            setProcessFileLoading(boolean)
        } else if (loading === 'certFileLoading') {
            setCertFileLoading(boolean)
        }
    }

    const fileUpload = (eventFile, loading) => {
        handleShow('')
        setLoading(true, loading);
        let file = eventFile[0]
        // console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setLoading(false, loading);
            return;
        }
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
            case 'application/pdf':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setLoading(false, loading);
            alert('Only png, jpeg and pdf file types allowed')
            // this.imageUploading = false
            return;
        }
        const max_file_size = 102400 * 10
        if (file.size <= max_file_size){
            const reader = new FileReader()
            // console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                // console.log('content -> ', content);
                // console.log('file 2 -> ', file);

                saveFile(file, content, loading)
            }
        }else {
            setLoading(false, loading);
            alert('Maximum size exceeded for file! Please make sure file size is less than 1MB');
            // this.imageUploading = false
        }
    }

    const removeFile = (index, loading) => {
        // Check if it's certificates field or previousProcessings field
        let currentOtherDocs = {...organization.otherSupportingDocuments}
        if (loading === 'processFileLoading') {
            const targetProcessings = (currentOtherDocs && currentOtherDocs.previousProcessings) || []
            // console.log('currentProcessings --> 1', targetProcessings);
            const targetCerts = (currentOtherDocs && currentOtherDocs.certificates) || []
            let currentProcessings = targetProcessings.filter((eachProcesing, idx) => {
                if (idx == index) return false
                return true
            })
            // console.log('currentProcessings --> 2', currentProcessings);
            currentOtherDocs.previousProcessings = currentProcessings
            currentOtherDocs.certificates = targetCerts
        } else if (loading === 'certFileLoading') {
            const targetCerts = (currentOtherDocs && currentOtherDocs.certificates) || []
            const targetProcessings = (currentOtherDocs && currentOtherDocs.previousProcessings) || []
            let currentCerts = targetCerts.filter((eachCert, idx) => {
                if (idx == index) return false
                return true
            })
            currentOtherDocs.certificates = currentCerts
            currentOtherDocs.previousProcessings = targetProcessings
        }
        handleSubmit({otherSupportingDocuments: currentOtherDocs});
    }

    return (<TabPanel value={value} index={3} dir={theme.direction}>
        <span className={classes.Title}>Other Supporting Documents</span>
        <PaperMod elevation={3} >
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Previous Processing Statements</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                {organization && organization.otherSupportingDocuments && processFileLoading ? <CircularProgress className={classes.circularProgress} /> :  <div>
                        {organization && organization.otherSupportingDocuments && organization.otherSupportingDocuments.previousProcessings && organization.otherSupportingDocuments.previousProcessings.map((eachFile, fileIndex) => {
                            return <div>
                                    <Typography component={'span'}>{eachFile.name}</Typography>
                                    <IconButton onClick={() => { removeFile(fileIndex, 'processFileLoading') }} component="span">
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </div>
                        })}
                </div>}
                <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, 'processFileLoading')}} id={`processing-statements-button-file`} type="file" />
                <label htmlFor="processing-statements-button-file">
                    <IconButton disabled={processFileLoading} color="primary" aria-label="upload file" component="span">
                        <PublishIcon className={classes.editIcon}/>
                    </IconButton>
                </label>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>License of Certificates required to operate business</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                {organization && organization.coregfilename && certFileLoading ? <CircularProgress className={classes.circularProgress} /> : <div>
                        {organization && organization.otherSupportingDocuments && organization.otherSupportingDocuments.certificates && organization.otherSupportingDocuments.certificates.map((eachFile, certIndex) => {
                            return <div>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                <IconButton onClick={() => { removeFile(certIndex, 'certFileLoading') }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </div>
                        })}
                </div>}
                <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files, 'certFileLoading')}} id={`license-button-file`} type="file" />
                <label htmlFor="license-button-file">
                    <IconButton disabled={certFileLoading} color="primary" aria-label="upload file" component="span">
                        <PublishIcon className={classes.editIcon}/>
                    </IconButton>
                </label>
                </Grid>
            </Grid>
        </PaperMod>
    </TabPanel>
    )
}

export default OtherKycDocs