import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import { Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Slide from '@material-ui/core/Slide';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInput } from '@material-ui/core';
import RootRef from "@material-ui/core/RootRef";
import { grey } from '@material-ui/core/colors';
import Collapse from '@material-ui/core/Collapse';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {fetchApi} from '../app/utils/callPosApi';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CircularProgress from '@material-ui/core/CircularProgress';

const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);

const ButtonMod = withStyles({
    root: {
        backgroundColor: '#68d0af',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
    },
})(Button);

const PaperMod = withStyles({
    root: {
        marginTop: '1em',
        padding: '1em',
    },
})(Paper);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        },
    },
})(OutlinedInput);

const useStyles = makeStyles(theme => ({
    circularProgress: {
        color: '#68d0af'
    },
    dialogTitle: {
        color: '#0c3336'
    },
    dialogBtn: {
        color: '#1fa595'
    },
    posLogo: {
        height: '100px',
    },
    camera: {
        color: '#68d0af',
    },
    hiddeninput: {
        display: 'none',
    },
    dropItem: {
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    posDetails: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    editIcon: {
        color: grey['A200'],
        '&:hover': {
            color: 'black',
            cursor: 'pointer'
        },
    },
    generateKey: {
        backgroundColor: "#68d0af",
        color: 'white'
    },
    card: {
        padding: '1em',
        zIndex: 50,
    },
    btn: {
        color: '#1fa595'
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    copyBtns:{
        textTransform: 'none',
        margin: '0.5em',
        color: '#1fa595',
        backgroundColor: '#ffffff',
        '&.MuiButton-root:hover': {
            color: '#68d0af',
            backgroundColor: '#ffffff',
        },
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const PaymentTerminal = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont,
        getEnumeration, enumeration, organization, detailedPos, PatchPos, detailedPosRoutes,
        GetPosRoute, GetPosAccounts, detailedPosPmtSvcAcc, UpdatePosRoute, environment, posApiKeys,
        getApiKeys, createApiKeys, deleteApiKeys, clearPOSDetail, paymentsourcetypes, DeletePos
    } = props
    const theme = useTheme();
    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
        if (appbarIsWhiteFont) toggleAppbarWhiteFont(false)
        return function cleanup() {
            clearPOSDetail()
        };
    },[]);
    const [currencies, setCurrencies] = useState([]);
    const [posName, setPosName] = useState('');
    const [iinMin, setIinMin] = useState('');
    const [iinMax, setIinMax] = useState('');
    const [amountMin, setAmountMin] = useState('');
    const [amountMax, setAmountMax] = useState('');
    const [accCurrency, setAccCurrency] = useState('');
    const [newRouteAccId, setNewRouteAccID] = useState('');
    const [posRouteRules, setPosRouteRules] = useState([]);
    const [copiedText, setCopiedText] = useState('');
    const [newPaymentSourceType, setNewPaymentSourceType] = useState('');
    const [newPaymentSourceUI, setNewPaymentSourceUI] = useState([]);
    const [createWebHook, setCreateWebHook] = useState('');
    const [authorizationWebHook, setAuthorizationWebHook] = useState('');
    const [captureWebHook, setCaptureWebHook] = useState('');
    const [refundWebHook, setRefundWebHook] = useState('');
    const [voidWebHook, setVoidWebHook] = useState('');

    useEffect(() => {
        if (enumeration && enumeration.currency) setCurrencies(enumeration.currency)
        if (detailedPos && detailedPos.name) setPosName(detailedPos.name)
        if (detailedPos && detailedPos.id) {
            GetPosRoute({posId: detailedPos.id})
            GetPosAccounts({posId: detailedPos.id})
        }
        if (detailedPosRoutes && detailedPosRoutes.id) {
            setPosRouteRules(detailedPosRoutes.rules)
            getApiKeys({posId: detailedPos.id})
        }
    }, [enumeration, detailedPos.id, detailedPosRoutes.id, detailedPosRoutes.rules ? detailedPosRoutes.rules.length : 0]);
    const history = useHistory();
    useLayoutEffect(() => {
        if (!organization.id) {
            history.push({pathname: `/`,})
        }
        getEnumeration({enumGroup: 'currency'})
        getEnumeration({enumGroup: 'paymentsourcetype'})
    },[])
    const [showEdit, setShowEdit] = useState('');
    const handleShow = (field) => {
        setShowEdit(field)
    }
    const handlePatch = (reqBody) => {
        console.log('detailedPos.id : ', detailedPos.id);
        PatchPos({posId: detailedPos.id}, reqBody)
    }
    const handleAddNewRoute = () => {
        let detailedPosRoutesCopy = JSON.parse(JSON.stringify(detailedPosRoutes));
        let newRoute = {
            accountId: newRouteAccId,
            filter: {
                iin: {},
                amount: {},
                currency: '',
                source: newPaymentSourceType
            }
        };
        if (iinMin) newRoute.filter.iin['min'] = `${iinMin}`
        if (iinMax) newRoute.filter.iin['max'] = `${iinMax}`
        if (accCurrency) newRoute.filter.currency = accCurrency
        if (newRouteAccId) newRoute.accountId = newRouteAccId
        if (newPaymentSourceType) newRoute.filter.source = newPaymentSourceType;
        if (!isNaN(amountMin)) newRoute.filter.amount['min'] = parseFloat(amountMin)
        if (!isNaN(amountMax)) newRoute.filter.amount['max'] = parseFloat(amountMax)
        // TODO: Source logic required
        if (detailedPosRoutesCopy && !detailedPosRoutesCopy.rules) {
            detailedPosRoutesCopy.rules = [newRoute]
        } else {
            detailedPosRoutesCopy.rules.push(newRoute);
        }
        UpdatePosRoute({routeId: detailedPosRoutesCopy.id}, detailedPosRoutesCopy)
    }

    const handleEditRoute = (index) => {
        let newRule = [...posRouteRules];
        let newRoute = {
            accountId: newRouteAccId,
            filter: {
                iin: {},
                amount: {},
                currency: '',
                source: newPaymentSourceType
            }
        };
        // Do checks before submitting as this function cannot fail
        if (iinMin && iinMin.length != 6) return
        if (iinMin && iinMax.length != 6) return
        if (amountMin && isNaN(amountMin)) return
        if (amountMax && isNaN(amountMax)) return

        newRoute.filter.iin['min'] = `${iinMin}`;
        newRoute.filter.iin['max'] = `${iinMax}`;
        newRoute.filter.currency = accCurrency;
        newRoute.accountId = newRouteAccId;
        newRoute.filter.source = newPaymentSourceType;
        newRoute.filter.amount['min'] = parseFloat(amountMin);
        newRoute.filter.amount['max'] = parseFloat(amountMax);
        newRule[index] = newRoute;
        setPosRouteRules(newRule);
        const newRules = { rules: newRule };
        // console.log('newRules ---> ');
        // console.log(newRules);
        UpdatePosRoute({routeId: detailedPosRoutes.id}, newRules)
    }

    const handleDeleteRule = (index) => {
        let newRules = posRouteRules.filter((item, i) => i != index)
        // console.log('delete new rules -> ', newRules);
        setPosRouteRules(newRules);
        const newRouteRules = { rules: newRules };
        UpdatePosRoute({routeId: detailedPosRoutes.id}, newRouteRules)
    }

    const getListStyle = isDraggingOver => ({
        //background: isDraggingOver ? 'lightblue' : 'lightgrey',
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
        border: '1px solid #aaaaaa',
        marginBottom: '0.2em',
        ...(isDragging && {
          background: "rgb(235,235,235)"
        })
    });

    const reorder = (list, startIndex, endIndex) => {
        // a function to help us with reordering the result
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
    
        return result;
    };

    const onDragEnd = (result) => {
        let newRule = [...posRouteRules];
        //destructure
        const { destination, source, draggableId} = result;
        // if no destination we return out
        if (!destination) return
        // if location of the destination and source id's are the same, we return out
        if (destination.droppableId === source.droppableId && destination.index === source.index) return

        const from = (source && source.index) || null;
        const to = (destination && destination.index) || null;
        newRule = reorder(newRule, from, to);
        setPosRouteRules(newRule);
        const newRules = { rules: newRule };
        UpdatePosRoute({routeId: detailedPosRoutes.id },newRules);
    }

    const generateKeyPair = () => {
        if (posApiKeys.count === 0) {
            // Generate Public Key
            const publicKey = {
                name: `${detailedPos.id} public key`,
                posId: detailedPos.id,
                posType: 'terminals',
                organizationId: organization.id,
                type: 'public'
            }
            createApiKeys({posId: detailedPos.id}, publicKey)
            // Generate Private Key
            const privateKey = {
                name: `${detailedPos.id} private key`,
                posId: detailedPos.id,
                posType: 'terminals',
                organizationId: organization.id,
                type: 'private'
            }
            createApiKeys({posId: detailedPos.id}, privateKey)
        }
        handleShow('pos apikeys')
    }

    const deleteKeys = (apiKeys) => {
        if (apiKeys.count < 2) return
        if (!apiKeys.keys) return
        if (apiKeys.keys.length < 2) return
        apiKeys.keys.forEach(key => {
            // console.log('key ---> ', key);
            deleteApiKeys({posId: key.posId, keyId: key.id})
        });
        handleShow('')
    }

    const handleUpdatedWebhooks = () => {
        let newWebhookUrls = {
            create: "",
            authorization: "",
            capture: "",
            refund: "",
            void: "",
        }
        if (createWebHook) {
            if (!isValidUrl(createWebHook)) {
                alert('Invalida CREATE webhook')
                return
            }
            newWebhookUrls["create"] = createWebHook
        } else {
            newWebhookUrls["create"] = ""
        }
        if (authorizationWebHook) {
            if (!isValidUrl(authorizationWebHook)) {
                alert('Invalida AUTHORIZATION webhook')
                return
            }
            newWebhookUrls["authorization"] = authorizationWebHook
        } else {
            newWebhookUrls["authorization"] = ""
        }
        if (captureWebHook) {
            if (!isValidUrl(captureWebHook)) {
                alert('Invalida CAPTURE webhook')
                return
            }
            newWebhookUrls["capture"] = captureWebHook
        } else {
            newWebhookUrls["capture"] = ""
        }
        if (refundWebHook) {
            if (!isValidUrl(refundWebHook)) {
                alert('Invalida REFUND webhook')
                return
            }
            newWebhookUrls["refund"] = refundWebHook
        } else {
            newWebhookUrls["refund"] = ""
        }
        if (voidWebHook) {
            if (!isValidUrl(voidWebHook)) {
                alert('Invalida VOID webhook')
                return
            }
            newWebhookUrls["void"] = voidWebHook
        } else {
            newWebhookUrls["void"] = ""
        }
        handlePatch({ webhookUrls: newWebhookUrls})
    }


    const copyText = (targetElementId, targetValue) => {
        let textInput = document.createElement("input");
        let wrapper = document.getElementById(targetElementId)
        // console.log('textInput - > ', textInput);
        // Place in top-left corner of screen regardless of scroll position.
        textInput.style.position = 'fixed';
        textInput.style.top = 0;
        textInput.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textInput.style.width = '2em';
        textInput.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textInput.style.padding = 0;

        // Clean up any borders.
        textInput.style.border = 'none';
        textInput.style.outline = 'none';
        textInput.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textInput.style.background = 'transparent';

        textInput.value = targetValue
         
        wrapper.appendChild(textInput);
        textInput.focus();
        textInput.select();
        textInput.setSelectionRange(0, 99999);

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            // console.log('Copying text command was ' + msg);
        } catch (err) {
            // console.log('Oops, unable to copy');
        }
        // console.log('textInput - > ', textInput);
        // console.log('textInput.value - > ', textInput.value);
        setCopiedText(targetElementId)
        setTimeout(() => {
            setCopiedText('')
        }, 3500);
        wrapper.removeChild(textInput);
    }

	const isValidUrl = urlString=> {
        var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
        '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    return !!urlPattern.test(urlString);
  }

    return (
    <main className={classes.topMargin}>
    <Typography variant="h4" className={classes.Title}>Payment Terminal</Typography>
    <AppBarMod position="static" color="default">
      </AppBarMod>
        <Container>
        <div className={classes.posDetails}>
        <PaperMod elevation={3} >
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Name</Typography>
                </Grid>
                {/* POS NAME */}
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{detailedPos && detailedPos.name}
                        {/* <IconButton>
                            <EditIcon onClick={() => {
                                handleShow('pos name')
                            }} className={classes.editIcon}/>
                        </IconButton> */}
                    </Typography>
                </Grid>
                {showEdit === 'pos name' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Name
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setPosName(e.target.value)
                        }} defaultValue={posName}/>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                const pName = (detailedPos && detailedPos.name) || ''
                                handleShow('')
                                setPosName(pName)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                handlePatch({
                                    name: posName,
                                })
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
                {/* API KEYS */}
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>API Keys</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <ButtonMod 
                        size={'large'} 
                        className={classes.generateKey} 
                        onClick={()=> { generateKeyPair() }}
                    >
                        {posApiKeys.count === 0 ? 'Generate API Keys' : 'View API Keys'}
                    </ButtonMod>
                </Grid>
                <Dialog
                    maxWidth="md"
                    open={showEdit === 'pos apikeys'}
                    onClose={() => { handleShow('') }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">{"API Keys"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            posApiKeys && posApiKeys.keys && posApiKeys.keys.map((eachKey) => {
                                return <div id={eachKey.id}>
                                    <Button
                                        className={classes.copyBtns}
                                        fullWidth={true}
                                        size="large"
                                        variant="contained"
                                        startIcon={<FileCopyIcon/>}
                                        onClick={() => { copyText(eachKey.id, eachKey.value)}}
                                    >
                                        {eachKey.type === 'public' ? copiedText === eachKey.id ? 'COPIED TEXT!' : `PUBLIC KEY: ${eachKey.value}`: ''}
                                        {eachKey.type === 'private' ? eachKey.value ? copiedText === eachKey.id ? 'COPIED TEXT!' : `PRIVATE KEY: ${eachKey.value} THIS WILL ONLY BE AVAILABLE ONCE. PLEASE COPY.`: 'THE PRIVATE KEY WILL ONLY BE AVAILABLE WHEN CREATED. DELETE AND REGENERATE WHEN REQUIRED.' : ''}
                                    </Button>
                                </div>
                            })
                        }
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => { deleteKeys(posApiKeys) }} color="secondary">
                        Delete Keys
                    </Button>
                    <Button onClick={() => { handleShow('') }} className={classes.dialogBtn}  autoFocus>
                        Done
                    </Button>
                    </DialogActions>
                </Dialog>
                {/* POS PAYMENT ROUTE */}
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Payment Routes</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <IconButton>
                        <AddIcon onClick={() => { 
                            handleShow('pos route add')
                            setIinMin('')
                            setIinMax('')
                            setAmountMax('')
                            setAmountMin('')
                            setAccCurrency('')
                        }} className={classes.editIcon}/>
                    </IconButton>
                </Grid>
                {showEdit === 'pos route add' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        {/* ACCOUNT SELECTION */}
                        {detailedPosPmtSvcAcc && detailedPosPmtSvcAcc.accounts && detailedPosPmtSvcAcc.accounts.length < 1 && <Typography component={'span'} className={classes.title} color="error" gutterBottom>
                            Your company profile needs to be completed and verified in order to have an payment account before you can start transacting.
                        </Typography>}<br/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Payment Service Account
                        </Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={newRouteAccId}
                            onChange={(e) => {
                                setNewRouteAccID(e.target.value)
                            }}
                            label="Payment Service Account"
                        >
                            {
                                detailedPosPmtSvcAcc && detailedPosPmtSvcAcc.accounts && detailedPosPmtSvcAcc.accounts.map((account, index) => {
                                    if (account && account.posType === 'terminals') return <MenuItem key={index} value={account.id}>{account.name}</MenuItem>
                                    return ''
                                })
                            }
                        </Select>
                        {/* Payment Source Type */}
                        {newRouteAccId && <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Payment Source Type
                        </Typography>}
                        {newRouteAccId && <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={newPaymentSourceType}
                            onChange={(e) => {
                                setNewPaymentSourceType(e.target.value)
                                paymentsourcetypes.map((eachSource) => {
                                    if (eachSource.value.value === e.target.value) {
                                        // SET UI
                                        setNewPaymentSourceUI((eachSource.value && eachSource.value.merchant_routes_ui) || [])
                                    }
                                })
                            }}
                            label="Payment Source Type"
                        >
                            {
                                detailedPosPmtSvcAcc && detailedPosPmtSvcAcc.accounts.filter((eachAccount) => eachAccount.id === newRouteAccId).map((eachAccount) => {
                                    let availableSourceTypes = (eachAccount && eachAccount.sourceTypes && Object.keys(eachAccount.sourceTypes)) || []
                                    let allMenuItems = paymentsourcetypes.map((sourceType, index) => {
                                        if (availableSourceTypes.indexOf(sourceType.value.value)> -1) {
                                            return <MenuItem key={index} value={(sourceType.value && sourceType.value.value)}>{sourceType.name}</MenuItem>
                                        }
                                    })
                                    return allMenuItems
                                })[0]
                            }
                        </Select>}
                        {/* IIN */}
                        {newPaymentSourceUI.includes("iin") && <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Issuer Identification Network (FROM)
                        </Typography>}
                        {newPaymentSourceUI.includes("iin") && <OutlinedInputMod fullWidth onChange={(e) => {
                            setIinMin(e.target.value)
                        }} defaultValue={iinMin}/>}
                        {newPaymentSourceUI.includes("iin") && <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Issuer Identification Network (TO)
                        </Typography>}
                        {newPaymentSourceUI.includes("iin") && <OutlinedInputMod fullWidth onChange={(e) => {
                            setIinMax(e.target.value)
                        }} defaultValue={iinMax}/>}
                        {/* Currency */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Currency
                        </Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={accCurrency}
                            onChange={(e) => {
                                setAccCurrency(e.target.value)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.map((currency, index) => {
                                    return <MenuItem key={index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                })
                            }
                        </Select>
                        {/* AMOUNT */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Minimum Amount (Optional)
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setAmountMin(e.target.value)
                        }} defaultValue={amountMin}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Maximum Amount (Optional)
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => {
                            setAmountMax(e.target.value)
                        }} defaultValue={amountMax}/>
                        {<Button 
                            className={classes.btn} 
                            onClick={() => {
                                const pName = (detailedPos && detailedPos.name) || ''
                                handleShow('')
                                setIinMin('')
                                setIinMax('')
                                setAmountMax('')
                                setAmountMin('')
                                setAccCurrency('')
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                handleAddNewRoute()
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
                <Grid item md={12} xs={12} sm={12}>
                    {detailedPosRoutes && detailedPosRoutes.rules && <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable1">
                            {(provided, snapshot) => (
                                <RootRef rootRef={provided.innerRef}>
                                    <List style={getListStyle(snapshot.isDraggingOver)}>
                                        {posRouteRules && posRouteRules.map((eachRule, index) => {
                                            const targetAcc = (detailedPosPmtSvcAcc && detailedPosPmtSvcAcc.count && detailedPosPmtSvcAcc.accounts.filter((acc) => acc.id == eachRule.accountId))
                                            if (!targetAcc || targetAcc.length < 1) return '';
                                            return <Draggable key={targetAcc[0].id + index} draggableId={targetAcc[0].id + index} index={index}>
                                                {(provided, snapshot) => (
                                                <div>
                                                    <ListItem
                                                        ContainerComponent="li"
                                                        ContainerProps={{ ref: provided.innerRef }}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    >
                                                        <ListItemIcon>
                                                            <DragHandleIcon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={targetAcc[0].name}
                                                            secondary={ `source : ${eachRule && eachRule.filter && eachRule.filter.source}, currency: ${eachRule && eachRule.filter && eachRule.filter.currency} ${(eachRule && eachRule.filter && eachRule.filter.source && eachRule.filter.source === 'card' && eachRule.filter.iin && eachRule.filter.iin.min && ', iin: '  + eachRule.filter.iin.min + ' to ' + eachRule.filter.iin.max) || ""}`}
                                                        />
                                                        <ListItemSecondaryAction>
                                                        <IconButton 
                                                            onClick={() => { handleDeleteRule(index) }}
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                        <IconButton 
                                                            onClick={() => {
                                                                handleShow('rule ' + index)
                                                                setIinMin((eachRule.filter && eachRule.filter.iin && eachRule.filter.iin.min) || '')
                                                                setIinMax((eachRule.filter && eachRule.filter.iin && eachRule.filter.iin.max) || '')
                                                                setAmountMax((eachRule.filter && eachRule.filter.amount && eachRule.filter.amount.max) || '')
                                                                setAmountMin((eachRule.filter && eachRule.filter.amount && eachRule.filter.amount.min) || '')
                                                                setAccCurrency((eachRule.filter && eachRule.filter.currency) || '')
                                                                setNewRouteAccID((eachRule.accountId) || '')
                                                                setNewPaymentSourceType((eachRule.filter && eachRule.filter.source) || "")
                                                                paymentsourcetypes.map((eachSource) => {
                                                                    if (eachSource.value.value === (eachRule.filter && eachRule.filter.source)) {
                                                                        // SET UI
                                                                        setNewPaymentSourceUI((eachSource.value && eachSource.value.merchant_routes_ui) || [])
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Grid item md={12} xs={12} sm={12}><Collapse in={showEdit === 'rule ' + index} timeout="auto" unmountOnExit><Card className={classes.card}>
                                                                {/* ACCOUNT SELECTION */}
                                                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Payment Service Account
                                                                </Typography>
                                                                <Select
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    className={classes.select}
                                                                    value={newRouteAccId}
                                                                    onChange={(e) => {
                                                                        setNewRouteAccID(e.target.value)
                                                                    }}
                                                                    label="Payment Service Account"
                                                                >
                                                                    {
                                                                        detailedPosPmtSvcAcc && detailedPosPmtSvcAcc.accounts && detailedPosPmtSvcAcc.accounts.map((account, index) => {
                                                                            if (account && account.posType === 'terminals') return <MenuItem key={index} value={account.id}>{account.name}</MenuItem>
                                                                            return ''
                                                                        })
                                                                    }
                                                                </Select>
                                                                {/* Payment Source Type */}
                                                                {newRouteAccId && <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Payment Source Type
                                                                </Typography>}
                                                                {newRouteAccId && <Select
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    className={classes.select}
                                                                    value={newPaymentSourceType}
                                                                    onChange={(e) => {
                                                                        setNewPaymentSourceType(e.target.value)
                                                                        paymentsourcetypes.map((eachSource) => {
                                                                            if (eachSource.value.value === (eachRule.filter && e.target.value)) {
                                                                                // SET UI
                                                                                setNewPaymentSourceUI((eachSource.value && eachSource.value.merchant_routes_ui) || [])
                                                                            }
                                                                        })
                                                                    }}
                                                                    label="Payment Source Type"
                                                                >
                                                                    {
                                                                        detailedPosPmtSvcAcc && detailedPosPmtSvcAcc.accounts.filter((eachAccount) => eachAccount.id === newRouteAccId).map((eachAccount) => {
                                                                            let availableSourceTypes = (eachAccount && eachAccount.sourceTypes && Object.keys(eachAccount.sourceTypes)) || []
                                                                            let allMenuItems = paymentsourcetypes.map((sourceType, index) => {
                                                                                if (availableSourceTypes.indexOf(sourceType.value.value)> -1) {
                                                                                    return <MenuItem key={index} value={(sourceType.value && sourceType.value.value)}>{sourceType.name}</MenuItem>
                                                                                }
                                                                            })
                                                                            return allMenuItems
                                                                        })[0]
                                                                    }
                                                                </Select>}
                                                                {/* IIN */}
                                                                {newPaymentSourceUI.includes("iin") && <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Issuer Identification Network (FROM)
                                                                </Typography>}
                                                                {newPaymentSourceUI.includes("iin") && `${iinMin}`}
                                                                {newPaymentSourceUI.includes("iin") && <OutlinedInputMod fullWidth onChange={(e) => {
                                                                    setIinMin(e.target.value)
                                                                }} defaultValue={iinMin}/>}
                                                                {newPaymentSourceUI.includes("iin") && <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Issuer Identification Network (TO)
                                                                </Typography>}
                                                                {newPaymentSourceUI.includes("iin") && `${iinMax}`}
                                                                {newPaymentSourceUI.includes("iin") && <OutlinedInputMod fullWidth onChange={(e) => {
                                                                    setIinMax(e.target.value)
                                                                }} defaultValue={iinMax}/>}
                                                                {/* Currency */}
                                                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Currency
                                                                </Typography>
                                                                <Select
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    className={classes.select}
                                                                    value={accCurrency}
                                                                    onChange={(e) => {
                                                                        setAccCurrency(e.target.value)
                                                                    }}
                                                                    label="Currency"
                                                                >
                                                                    {
                                                                        currencies && currencies.map((currency, index) => {
                                                                            return <MenuItem key={index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                                {/* AMOUNT */}
                                                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Minimum Amount (Optional)
                                                                </Typography>
                                                                <OutlinedInputMod fullWidth onChange={(e) => {
                                                                    setAmountMin(e.target.value)
                                                                }} defaultValue={amountMin}/>
                                                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                                                    Maximum Amount (Optional)
                                                                </Typography>
                                                                <OutlinedInputMod fullWidth onChange={(e) => {
                                                                    setAmountMax(e.target.value)
                                                                }} defaultValue={amountMax}/>
                                                                {<Button 
                                                                    className={classes.btn} 
                                                                    onClick={() => {
                                                                        const pName = (detailedPos && detailedPos.name) || ''
                                                                        handleShow('')
                                                                        setIinMin('')
                                                                        setIinMax('')
                                                                        setAmountMax('')
                                                                        setAmountMin('')
                                                                        setAccCurrency('')
                                                                    }} 
                                                                    color="primary"
                                                                >
                                                                    Cancel
                                                                </Button>}
                                                                <Button 
                                                                    className={classes.btn} 
                                                                    onClick={(e) => {
                                                                        handleEditRoute(index)
                                                                        handleShow('')
                                                                    }} 
                                                                    color="primary"
                                                                >
                                                                    Save
                                                                </Button>
                                                    </Card></Collapse></Grid>
                                                </div>
                                                )}
                                            </Draggable>
                                        })}
                                        {provided.placeholder}
                                    </List>
                                </RootRef>
                            )}
                        </Droppable>
                    </DragDropContext>}
                </Grid>
                {/* WEBHOOKS */}
                <Grid item md={12} xs={12} sm={12}>
                    <Paper elevation={3} style={{padding: '1em'}}>
                        <Typography component={'span'} className={classes.label}>
                            Webhooks
                            <IconButton>
                                <EditIcon onClick={() => { 
                                    setCreateWebHook("")
                                    setAuthorizationWebHook("")
                                    setCaptureWebHook("")
                                    setRefundWebHook("")
                                    setVoidWebHook("")
                                    if (detailedPos && detailedPos.webhookUrls && detailedPos.webhookUrls.create) setCreateWebHook(detailedPos.webhookUrls.create)
                                    if (detailedPos && detailedPos.webhookUrls && detailedPos.webhookUrls.authorization) setAuthorizationWebHook(detailedPos.webhookUrls.authorization)
                                    if (detailedPos && detailedPos.webhookUrls && detailedPos.webhookUrls.capture) setCaptureWebHook(detailedPos.webhookUrls.capture)
                                    if (detailedPos && detailedPos.webhookUrls && detailedPos.webhookUrls.refund) setRefundWebHook(detailedPos.webhookUrls.refund)
                                    if (detailedPos && detailedPos.webhookUrls && detailedPos.webhookUrls.void) setVoidWebHook(detailedPos.webhookUrls.void)
                                    handleShow('pos webhook edit')
                                }} className={classes.editIcon}/>
                            </IconButton>
                        </Typography>
                        {
                            showEdit !== 'pos webhook edit' && detailedPos && detailedPos.webhookUrls && Object.keys(detailedPos.webhookUrls).map((eachKey) => {
                                return <Typography>{`${eachKey.toUpperCase()}: ${detailedPos.webhookUrls[eachKey]}`}</Typography>
                            })
                        }
                        {showEdit === 'pos webhook edit' && <Grid item md={12} xs={12} sm={12}>
                            <Card className={classes.card}>
                                {/* CREATE */}
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Webhook address for transaction type CREATE</Typography>
                                <OutlinedInputMod 
                                    fullWidth 
                                    onChange={(e) => {
                                        setCreateWebHook(e.target.value)
                                    }} 
                                    defaultValue={createWebHook}
                                />
                                {/* AUTHORIZATION */}
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Webhook address for transaction type AUTHORIZATION</Typography>
                                <OutlinedInputMod 
                                    fullWidth 
                                    onChange={(e) => {
                                        setAuthorizationWebHook(e.target.value)
                                    }} 
                                    defaultValue={authorizationWebHook}
                                />
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Webhook address for transaction type CAPTURE</Typography>
                                <OutlinedInputMod 
                                    fullWidth 
                                    onChange={(e) => {
                                        setCaptureWebHook(e.target.value)
                                    }} 
                                    defaultValue={captureWebHook}
                                />
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Webhook address for transaction type REFUND</Typography>
                                <OutlinedInputMod 
                                    fullWidth 
                                    onChange={(e) => {
                                        setRefundWebHook(e.target.value)
                                    }} 
                                    defaultValue={refundWebHook}
                                />
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Webhook address for transaction type VOID</Typography>
                                <OutlinedInputMod 
                                    fullWidth 
                                    onChange={(e) => {
                                        setVoidWebHook(e.target.value)
                                    }} 
                                    defaultValue={voidWebHook}
                                />
                                <Button 
                                    className={classes.btn} 
                                    onClick={() => {
                                        handleShow('')
                                        setCreateWebHook("")
                                        setAuthorizationWebHook("")
                                        setCaptureWebHook("")
                                        setRefundWebHook("")
                                        setVoidWebHook("")
                                    }} 
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button 
                                    className={classes.btn} 
                                    onClick={(e) => {
                                        handleUpdatedWebhooks()
                                        handleShow('')
                                    }} 
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Card>
                        </Grid>}
                    </Paper>
                </Grid>
                <Grid item md={12} xs={12} sm={12}>
                    <Button
                        variant="contained" 
                        color="secondary"
                        fullWidth 
                        onClick={(e) => {
                            if (posApiKeys && posApiKeys.keys && posApiKeys.keys.length) {
                                alert("Please Delete API Keys Before Deleting This Point of Sales")
                                return
                            }
                            DeletePos({posId: detailedPos.id})
                            history.push({pathname: `/company/${organization.id}/paymentterminals`})
                            // TO DO DELETE POS RESPONSE AT LEAST SOME ID
                        }}
                    >DELETE PAYMENT TERMINAL</Button>
                </Grid>
            </Grid>
        </PaperMod>
        </div>
        </Container>
    </main>
    )
}

export default PaymentTerminal