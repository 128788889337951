import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganization, patchOrganization, getEnumeration, getRoles,
    getOrgUsers, updateRole, deleteRole, createRole, updateOrgUserRole, inviteOrgUser, deleteOrgUser, 
    updateOrganizationReady, resetUserInviteSuccess } from '../actions'
import Settings from '../components/Settings'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    usermgtread: (state.organizations.role && state.organizations.role.usermgtread) || false,
    bankdetailsread: (state.organizations.role && state.organizations.role.bankdetailsread) || false,
    ownersread: (state.organizations.role && state.organizations.role.ownersread) || false,
    generalread: (state.organizations.role && state.organizations.role.generalread) || false,
    usermgtwrite: (state.organizations.role && state.organizations.role.usermgtwrite) || false,
    bankdetailswrite: (state.organizations.role && state.organizations.role.bankdetailswrite) || false,
    ownerswrite: (state.organizations.role && state.organizations.role.ownerswrite) || false,
    generalwrite: (state.organizations.role && state.organizations.role.generalwrite) || false,
    organizationUsers: (state.organizations.organizationUsers) || [],
    organizationRoles: (state.organizations.organizationRoles) || [],
    environment: state.appstate.environment || 'staging',
    userInviteSuccess: state.organizations.userInviteSuccess || false,
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    patchOrganization: (params, reqBody) => dispatch(patchOrganization(params, reqBody)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    getRoles: (params) => dispatch(getRoles(params)),
    createRole: (params, reqBody) => dispatch(createRole(params, reqBody)),
    updateRole: (params, reqBody) => dispatch(updateRole(params, reqBody)),
    deleteRole: (params, reqBody) => dispatch(deleteRole(params, reqBody)),
    getOrgUsers: (params) => dispatch(getOrgUsers(params)),
    updateOrgUserRole: (params) => dispatch(updateOrgUserRole(params)),
    inviteOrgUser: (params, reqBody) => dispatch(inviteOrgUser(params, reqBody)),
    deleteOrgUser: (params) => dispatch(deleteOrgUser(params)),
    updateOrganizationReady: (params) => dispatch(updateOrganizationReady(params)),
    resetUserInviteSuccess: () => dispatch(resetUserInviteSuccess()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings)