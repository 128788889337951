import React, { useLayoutEffect, useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { makeStyles, createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { LiberalizeSSO } from 'liberalize-sso-js-sdk';
import Appbar from './containers/Appbar';
import { drawerWidth } from './app/globals';
import Main from './containers/Main';
import CompanyDashboard from './containers/CompanyDashboard';
import SettingsState from './containers/SettingsState';
import CheckoutPages from './containers/CheckoutPages';
import CheckoutPage from './containers/CheckoutPage';
import PaymentElements from './containers/PaymentElements';
import PaymentElement from './containers/PaymentElement';
import PaymentTerminals from './containers/PaymentTerminals';
import PaymentTerminal from './containers/PaymentTerminal';
import PaymentsReport from './containers/PaymentsReport';
import BalancesReport from './containers/BalancesReport';
import PayoutsReport from './containers/PayoutsReport';
import InvoicesReport from './containers/InvoicesReport';
import UserProfile from './containers/UserProfile';
import CustomersManagement from './containers/CustomersManagement';
import ProductsManagement from './containers/ProductsManagement';
import DisputesManagement from './containers/DisputesManagement';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  circularProgress: {
    color: '#68d0af'
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function App(props) {
  const classes = useStyles(props)
  const { openDrawer, toggleDrawer, setUser, user, getUserOrganizations, setSSO, openBackdrop, appEnv } = props
  const [libsso, setLibSso] = React.useState(null);
  if (process.env.REACT_APP_LOCAL_DEVELOPMENT !== 'true') {
    console.log = function () {};
  } else {}
  useLayoutEffect(() => {
    const ssoInstance = new LiberalizeSSO("1234-1234-1234-1234")
    setLibSso(ssoInstance)
  }, [])
  useEffect(() => {
    if (libsso) {
      libsso.getUser().then((result)=> {
        if (result === undefined || (result && result.message)) {
          libsso.signIn(process.env.REACT_APP_PRODUCTION_MANAGEMENT_CONSOLE_FRONTEND_URL)
        } else {
          setUser(result)
        }
      })
    }
  },[libsso])
  useEffect(() => {
    getUserOrganizations()
  },[user, appEnv])
  return (
    <main 
    className={clsx(classes.content, {
      [classes.contentShift]: !openDrawer,
    })}>
      <Appbar/>
      <Switch>
        <Route path="/" exact component={Main}/>
        <Route path="/user/:userId" exact component={UserProfile}/>
        <Route path="/company/:companyId" exact component={CompanyDashboard}/>
        <Route path="/company/:companyId/settings" exact component={SettingsState}/>
        <Route path="/company/:companyId/checkoutpages" exact component={CheckoutPages}/>
        <Route path="/company/:companyId/paymentelements" exact component={PaymentElements}/>
        <Route path="/company/:companyId/paymentterminals" exact component={PaymentTerminals}/>
        <Route path="/company/:companyId/payments-report" exact component={PaymentsReport}/>
        <Route path="/company/:companyId/balances-report" exact component={BalancesReport}/>
        <Route path="/company/:companyId/payouts-report" exact component={PayoutsReport}/>
        <Route path="/company/:companyId/invoices-report" exact component={InvoicesReport}/>
        <Route path="/company/:companyId/customers-management" exact component={CustomersManagement}/>
        <Route path="/company/:companyId/products-management" exact component={ProductsManagement}/>
        <Route path="/company/:companyId/disputes-management" exact component={DisputesManagement}/>
        <Route path="/checkoutpage/:posId" exact component={CheckoutPage}/>
        <Route path="/paymentelement/:posId" exact component={PaymentElement}/>
        <Route path="/paymentterminal/:posId" exact component={PaymentTerminal}/>
        {/* <Route path="/company" exact component={OurCompany}/>
        <Route component={NoMatch} /> */}
      </Switch>
      <Backdrop className={classes.backdrop} open={openBackdrop} >
        <CircularProgress className={classes.circularProgress} />
      </Backdrop>
    </main>
  );
}

export default App;
