import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import { TrendingUpRounded } from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import {fetchApi} from '../app/utils/callPmtsReportApi';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Divider } from '@material-ui/core';
import { locationTimeZones, scheduleType } from '../app/globals'

const currencyFormatter = require('currency-formatter');

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const axios = require('axios');
const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);


const ButtonMod = withStyles({
    root: {
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);


const DateTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const OutlinedInputPayment = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '11px 14px',
        }
    },
})(OutlinedInput);

const InputGreen = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(OutlinedInput);



const useStyles = makeStyles(theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    label: {
        color: '#7f7f7f'
    },
    balslist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    card: {
        display: 'flex',
        minHeight: '17em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        position: 'absolute',
        bottom: 0,
    },
    add: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        textTransform: 'none'
    },
    imageRight: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: '-1em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    dialogTitle: {
        color: '#0c3336',
        fontSize: '1.7em'
    },
    paymentDetailAccordion: {
        width: '100%',
        marginBottom: '1em'
    },
    txnLogGrid: {
        margin: 0
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    greenMoney: {
        color: '#16a09e',
        verticalAlign: 'bottom',
        fontSize: '2em'
    },
    greenCurrency: {
        color: '#16a09e',
        fontSize: '2em'
    },
    finePrint: {
        fontSize: '1em'
    }
}));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const PayoutsReport = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont, getOrganization,
        getEnumeration, enumeration, organization, hits, environment, 
        postPayoutsSearch, postPayoutsSearchAfter, clearPayoutsSearch, updatePayoutsSearch,
        getPayoutSchedules, createPayoutSchedule, updatePayoutSchedule, payoutSchedules,
        nextToken
    } = props
    const [searchBankName, setSearchBankName] = useState('')
    const [searchAccountNo, setSearchAccountNo] = useState('')
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    // Later just have to Make a button to change the UTC +X
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [page, setPage] = React.useState(1);
    const [loadedPage, setLoadedPage] = React.useState(1);

    // payout schedules
    const [scheduleBox, setScheduleBox] = useState(false);
    const [createScheduleCurrency, setCreateScheduleCurrency] = useState('');
    const [createScheduleTimeZone, setCreateScheduleTimeZone] = useState('');
    const [createScheduleType, setCreateScheduleType] = useState('');
    const [createScheduleDay, setCreateScheduleDay] = useState(0);

    const closeDiaLog = () => {
        setScheduleBox(false)
    }

    const theme = useTheme();
    const constructSearchReqBody = (passedCurrency="", passedPageSize, restartSearch=false) => {
        // console.log('process.env.MATERIAL_LICENSE_KEY -> ', process.env.REACT_APP_MATERIAL_LICENSE_KEY);
        let targetCurrency = currency || passedCurrency
        let initialStruct = {}
        let bankNameQuery = ""
        if (searchBankName.length > 0) {
            bankNameQuery += `"${searchBankName}"`
        }
        let accNoQuery = ""
        if (searchAccountNo.length > 0) {
            accNoQuery += `"${searchAccountNo}"`
        }
        let dateQuery = ""
        if (dateFrom || dateTo) {
            dateQuery += "{"
            if (!dateFrom || !dateTo) {
                if (dateFrom) dateQuery += `ge: "${dateFrom}" `
                if (dateTo) dateQuery += `le: "${dateTo}"`
            } else if (dateFrom && dateTo) {
                dateQuery += `between: ["${dateFrom}", "${dateTo}"]`
            }
            dateQuery += "}"
        }
        let query = 
        `
            query listPayouts($organizationId: String!) {
                queryPayoutsByOrganizationId(
                    organizationId: $organizationId 
                    currency: "${targetCurrency}"
                    ${bankNameQuery ? `bankName: ${bankNameQuery}` : ""}
                    ${accNoQuery ? `accountNumber: ${accNoQuery}` : ""}
                    filter: { 
                        ${dateQuery ? `createdAt: ${dateQuery}` : ""}
                    }
                    nextToken: "${(!restartSearch && nextToken) ? nextToken : ""}",
                ) 
                { items { 
                    id
                    organizationId
                    createdAt
                    amount
                    benefactor {
                        isEntity
                        identification
                        name
                        dob
                        bank {
                            name
                            code
                            branch
                            swift
                            account {
                                number
                                name
                                currency
                            }
                        }
                    }
                    fundings {
                        accountId
                        id
                        amount
                        rollingReserveAmount
                    }
                    originator {
                        isEntity
                        identification
                        name
                        dob
                        bank {
                            name
                            code
                            branch
                            swift
                            account {
                                number
                                name
                                currency
                            }
                        }
                    }
                    transactionMode
                    currency
                    status
                    processedAt
                    updatedAt
                }, nextToken } 
            }
        `
        initialStruct.variables = {
            organizationId: organization.id
        }
        initialStruct.query = query
        return initialStruct
    }
    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
        if (appbarIsWhiteFont) toggleAppbarWhiteFont(false)
    },[]);

    const convert = (amount, currency) => {
        if (currency == 'SGD') {
            return amount
        } else {
            return 0
        }
    }

    useEffect(() => {
        if (enumeration && enumeration.currency) setCurrencies(enumeration.currency)
    }, [enumeration]);

    useEffect(() => {
        if (organization.id) {
            // console.log(JSON.stringify(organization));
            let targetCurrency = (organization && organization.banks && organization.banks[0].account && organization.banks[0].account.currency) || 'SGD'
            setCurrency(targetCurrency)
            let esSearchReqBody = constructSearchReqBody(targetCurrency)
            // console.log('esSearchReqBody -> ', esSearchReqBody);
            // postPayoutsSearch({organizationId: organization.id}, esSearchReqBody)
            postPayoutsSearch(esSearchReqBody)
            getPayoutSchedules({organizationId: organization.id})
            setLoadedPage(1)
        }
    }, [organization.id]);

    useEffect(() => {
        if (!organization.id) {
            const url = window.location.href
            let urlArr = url.split('/')
            let organizationID = urlArr[urlArr.length - 2]
            // console.log('organizationID -> ', organizationID);
            getOrganization({organizationId: organizationID})
        }
        getEnumeration({enumGroup: 'currency'})
        return function cleanup() {
            clearPayoutsSearch()
        };
    },[environment])

    const history = useHistory();

    const currencyFormat = (currency, amount, symbol=false) => {
        if (!currency || !(amount >= 0)) return ''
        const currencyDetails = currencyFormatter.findCurrency(currency);
        let amountString = `${amount}`
        let amountBeforeDecimal = ''
        let amountAfterDecimal = ''
        if (currencyDetails.decimalDigits > 0 && amount !== '0') {
            let count = 0
            for (var i = amountString.length - 1; i >= 0; i--) {
                if (count < currencyDetails.decimalDigits) {
                    amountAfterDecimal = amountString[i] + amountAfterDecimal
                    count++
                } else {
                    amountBeforeDecimal = amountString[i] + amountBeforeDecimal 
                }
            }
            if (currencyDetails.decimalDigits > amountAfterDecimal.length) {
                for (var i = 0; i < currencyDetails.decimalDigits - amountAfterDecimal.length; i++) {
                    amountAfterDecimal = '0' + amountAfterDecimal
                }
            }
        } else {
            amountBeforeDecimal = amountString
        }
        if (!amountBeforeDecimal.length) amountBeforeDecimal = '0'
        // Add in Seperators to amountBeforeDecimal
        let amountBeforeDecimalReverseArr = amountBeforeDecimal.split('').reverse()
        amountBeforeDecimal = ''
        amountBeforeDecimalReverseArr.forEach((digit, index) => {
            if (((index + 1 ) % 3) === 0) {
                amountBeforeDecimal += `${digit}` + `${currencyDetails.thousandsSeparator}`
            } else {
                amountBeforeDecimal += digit
            }
        })
        amountBeforeDecimal = amountBeforeDecimal.split('').reverse().join('')
        if (amountBeforeDecimal[0] === currencyDetails.thousandsSeparator) {
            amountBeforeDecimal = amountBeforeDecimal.slice(1)
        }
        return `${symbol ? currencyDetails.symbol : ''}${currencyDetails.spaceBetweenAmountAndSymbol === true ? ' ': ''}${amountBeforeDecimal}${currencyDetails.decimalDigits > 0 ? currencyDetails.decimalSeparator : ''}${(amount == '0' && currencyDetails.decimalDigits == 2) ? '00' : amountAfterDecimal}`
    }

    useEffect(() => {
        if (nextToken && (hits && hits.length < 1000)) {
            let esSearchReqBody = constructSearchReqBody(currency, searchSize, false);
            postPayoutsSearchAfter(esSearchReqBody)
        }
    }, [nextToken])

    return (
    <main className={classes.topMargin}>
    <Typography variant="h4" className={classes.Title}>Payouts</Typography>
    <AppBarMod position="static" color="default"></AppBarMod>
    <Container>
        <div className={classes.balslist}>
            <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={currency || 'Select Currency'}
                                onChange={(e) => {
                                    setCurrency(e.target.value)
                                }}
                                label="Currency"
                            >
                                {
                                    currencies && currencies.length > 0 && currencies.map((curr, index) => {
                                        return <MenuItem key={index} value={curr.value.currency_alpha3_code}>{curr.name}</MenuItem>
                                    })
                                }
                                <MenuItem key={'select_currency'} value={''}>Select Currency</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Bank Name</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchBankName(e.target.value)} defaultValue={searchBankName}/>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Account Number</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setSearchAccountNo(e.target.value)} defaultValue={searchAccountNo}/>
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Created Date From</Typography>
                            <DateTextField
                                id="datefrom"
                                type="datetime-local"
                                onChange={(e) => {
                                    let date = new Date(e.target.value)
                                    setDateFrom(date.toISOString())
                                }}
                                defaultValue={dateFrom || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Created Date To</Typography>
                            <DateTextField
                                id="dateTo"
                                type="datetime-local"
                                onChange={(e) => {
                                    let date = new Date(e.target.value)
                                    setDateTo(date.toISOString())
                                }}
                                defaultValue={dateTo || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            </div>
            <ButtonMod onClick={() => {
                setPage(1);
                setLoadedPage(1)
                setSearchFrom(0);
                let esSearchReqBody = constructSearchReqBody(currency, null, true)
                // console.log('PLEASE DONT FIRE! esSearchReqBody : ', esSearchReqBody);
                // postPayoutsSearch({organizationId: organization.id}, esSearchReqBody)
                postPayoutsSearch(esSearchReqBody)
            }}>Search</ButtonMod>
            <Button
                variant='outlined'
                style={{
                    color: '#16a09e',
                    borderColor: '#16a09e',
                    float: 'right',
                    marginTop: '1em'
                }}
                onClick={() => {
                    setScheduleBox(true)
                }}
            >View Payout Schedules</Button>
        </div>
        <div className={classes.balslist}>
            <Grid container>
                {/* List of Balances */}
                {/* {`totalHits -> ${totalHits}`}
                {`hits -> ${hits.length}`} */}
                <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                    <XGrid
                        className={classes.xgrid}
                        page={page}
                        onPageChange={(params) => {
                            // console.log('onPageChange FIRED first part !!', params.page , ' ', loadedPage);
                            // console.log('onPageChange params.page -> ', params.page);
                            if (page >= params.page) return
                            setPage(params.page);
                            if (params.page <= loadedPage) return
                            if (!nextToken) {
                                return
                            }
                            // console.log('onPageChange FIRED second part !!', params.page , ' ', loadedPage);
                            setLoadedPage(loadedPage + 1)
                            let esSearchReqBody = constructSearchReqBody()
                            // console.log('esSearchReqBody -> ', esSearchReqBody);
                            // postPayoutsSearchAfter({organizationId: organization.id}, esSearchReqBody)
                            postPayoutsSearchAfter(esSearchReqBody)
                        }}
                        hideFooterRowCount
                        rowsPerPageOptions={[10]}
                        pageSize={13}
                        pagination={true}
                        columns={[
                            { 
                                field: 'id',
                                headerName: 'Currency',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.currency) || ""}`,
                            },
                            { 
                                field: 'totalAmount',
                                headerName: 'Total Amount (Lowest denomenator e.g. cents)',
                                width: 170, 
                                valueGetter: (params) => {
                                    let theRollingReserveAmount = (params && params.row && params.row.rollingReserveAmount) || 0
                                    let theAmount = (params && params.row && params.row.amount) || 0
                                    return currencyFormat(params.row.currency, theRollingReserveAmount + theAmount, true)
                                },
                            },
                            { 
                                field: 'status',
                                headerName: 'Status',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.status) || ""}`,
                            },
                            { 
                                field: 'transactionMode',
                                headerName: 'Transaction Mode',
                                width: 210,
                                valueGetter: (params) => `${(params && params.row && params.row.transactionMode) || ""}`,
                            },
                            { 
                                field: 'benefactor.bank.account.number',
                                headerName: 'Bank Account Number',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.benefactor && params.row.benefactor.bank && params.row.benefactor.bank.account && params.row.benefactor.bank.account.number) || ""}`,
                            },
                            { 
                                field: 'benefactor.bank.name',
                                headerName: 'Bank',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.benefactor && params.row.benefactor.bank && params.row.benefactor.bank.name) || ""}`,
                            },
                            { 
                                field: 'updatedAt',
                                headerName: 'Last Updated At',
                                width: 170,
                                valueGetter: (params) => {
                                    let dateString = ''
                                    if (params && params.row && params.row.updatedAt) {
                                        let date = new Date(params.row.updatedAt)
                                        dateString = date.toString()
                                    } 
                                    return dateString
                                },
                            },
                            { 
                                field: 'createdAt',
                                headerName: 'Created At',
                                width: 170,
                                valueGetter: (params) => {
                                    let dateString = ''
                                    if (params && params.row && params.row.createdAt) {
                                        let date = new Date(params.row.createdAt)
                                        dateString = date.toString()
                                    } 
                                    return dateString
                                },
                            },
                        ]}
                        rows={hits}
                        rowCount={nextToken ? 9999999999999 : (hits.length)}
                    />
                </div>
            </Grid>
        </div>
        <Dialog
            fullWidth
            maxWidth={'xl'}
            open={scheduleBox}
            onClose={closeDiaLog}
            aria-labelledby="payout-schedules-dialog"
        >
            <DialogTitle id="payout-schedules-dialog">Payout Schedules</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    {
                        payoutSchedules && payoutSchedules.length && payoutSchedules.map((eachPayoutSchedule) => {
                            return <Grid item md={12} xs={12} sm={12}><Paper
                                style={{padding: '1em'}}
                                elevation={4}
                            >
                                <Grid container>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography variant='h5'>{eachPayoutSchedule.currency}</Typography>
                                    </Grid>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>TimeZone</Typography>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            value={eachPayoutSchedule.location || ''}
                                            onChange={(e) => {
                                                updatePayoutSchedule(
                                                    {
                                                        organizationId: organization.id,
                                                        currency: eachPayoutSchedule.currency,
                                                    }, 
                                                    {
                                                        location: e.target.value
                                                    }
                                                )
                                            }}
                                            label="TimeZone"
                                        >
                                            {
                                                locationTimeZones && locationTimeZones.length > 0 && locationTimeZones.map((timeZone, index) => {
                                                    return <MenuItem key={timeZone} value={timeZone}>{timeZone}</MenuItem>
                                                })
                                            }
                                            <MenuItem key={'select_timezone'} value={''}>Select Time Zone</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Type</Typography>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            value={eachPayoutSchedule.type || ''}
                                            onChange={(e) => {
                                                updatePayoutSchedule(
                                                    {
                                                        organizationId: organization.id,
                                                        currency: eachPayoutSchedule.currency,
                                                    }, 
                                                    {
                                                        type: e.target.value
                                                    }
                                                )
                                            }}
                                            label="Type"
                                        >
                                            {
                                                scheduleType && scheduleType.length > 0 && scheduleType.map((scheduleType, index) => {
                                                    return <MenuItem key={scheduleType} value={scheduleType}>{scheduleType}</MenuItem>
                                                })
                                            }
                                            <MenuItem key={'select_type'} value={''}>Select Type</MenuItem>
                                        </Select>
                                    </Grid>
                                    {eachPayoutSchedule && eachPayoutSchedule.type && ((eachPayoutSchedule.type === 'DAY_OF_WEEK') || (eachPayoutSchedule.type === 'DAY_OF_MONTH')) && <Grid item md={12} xs={12} sm={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Day</Typography>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            value={eachPayoutSchedule.day || ''}
                                            onChange={(e) => {
                                                // setCreateScheduleDay(e.target.value)
                                            }}
                                            label="Day"
                                        >
                                            {
                                                eachPayoutSchedule.type === 'DAY_OF_WEEK' && Array.from(Array(5).keys()).map((dayNum, index) => {
                                                    return <MenuItem key={dayNum + 1} value={dayNum + 1}>
                                                        {dayNum + 1 === 1 && 'Monday'}
                                                        {dayNum + 1 === 2 && 'Tuesday'}
                                                        {dayNum + 1 === 3 && 'Wednesday'}
                                                        {dayNum + 1 === 4 && 'Thursday'}
                                                        {dayNum + 1 === 5 && 'Friday'}
                                                    </MenuItem>
                                                })
                                            }
                                            {
                                                eachPayoutSchedule.type === 'DAY_OF_MONTH' && Array.from(Array(31).keys()).map((dayNum, index) => {
                                                    return <MenuItem key={dayNum + 1} value={dayNum + 1}>
                                                        {dayNum+ 1}
                                                    </MenuItem>
                                                })
                                            }
                                            <MenuItem key={'select_type'} value={''}>Select Day</MenuItem>
                                        </Select>
                                        { eachPayoutSchedule.type === 'DAY_OF_MONTH' && <Typography>Payouts nominally scheduled between the 29th and 31st of the month are instead sent on the last day of a shorter month.</Typography>}
                                    </Grid>}
                                </Grid>
                            </Paper></Grid>
                        })
                    }
                    <Grid item md={12} xs={12} sm={12}>
                        <Divider/>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Add New Schedule (Based on currency)</Typography>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={createScheduleCurrency || ''}
                            onChange={(e) => {
                                setCreateScheduleCurrency(e.target.value)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((curr, index) => {
                                    return <MenuItem key={index} value={curr.value.currency_alpha3_code}>{curr.name}</MenuItem>
                                })
                            }
                            <MenuItem key={'select_currency'} value={''}>Select Currency</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>TimeZone</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={createScheduleTimeZone || ''}
                            onChange={(e) => {
                                setCreateScheduleTimeZone(e.target.value)
                            }}
                            label="TimeZone"
                        >
                            {
                                locationTimeZones && locationTimeZones.length > 0 && locationTimeZones.map((timeZone, index) => {
                                    return <MenuItem key={timeZone} value={timeZone}>{timeZone}</MenuItem>
                                })
                            }
                            <MenuItem key={'select_timezone'} value={''}>Select Time Zone</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Type</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={createScheduleType || ''}
                            onChange={(e) => {
                                setCreateScheduleType(e.target.value)
                            }}
                            label="Type"
                        >
                            {
                                scheduleType && scheduleType.length > 0 && scheduleType.map((scheduleType, index) => {
                                    return <MenuItem key={scheduleType} value={scheduleType}>{scheduleType}</MenuItem>
                                })
                            }
                            <MenuItem key={'select_type'} value={''}>Select Type</MenuItem>
                        </Select>
                    </Grid>
                    {createScheduleType && ((createScheduleType === 'DAY_OF_WEEK') || (createScheduleType === 'DAY_OF_MONTH')) && <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Day</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={createScheduleDay || ''}
                            onChange={(e) => {
                                setCreateScheduleDay(e.target.value)
                            }}
                            label="Day"
                        >
                            {
                                createScheduleType === 'DAY_OF_WEEK' && Array.from(Array(5).keys()).map((dayNum, index) => {
                                    return <MenuItem key={dayNum + 1} value={dayNum + 1}>
                                        {dayNum + 1 === 1 && 'Monday'}
                                        {dayNum + 1 === 2 && 'Tuesday'}
                                        {dayNum + 1 === 3 && 'Wednesday'}
                                        {dayNum + 1 === 4 && 'Thursday'}
                                        {dayNum + 1 === 5 && 'Friday'}
                                    </MenuItem>
                                })
                            }
                            {
                                createScheduleType === 'DAY_OF_MONTH' && Array.from(Array(31).keys()).map((dayNum, index) => {
                                    return <MenuItem key={dayNum + 1} value={dayNum + 1}>
                                        {dayNum+ 1}
                                    </MenuItem>
                                })
                            }
                            <MenuItem key={'select_type'} value={''}>Select Day</MenuItem>
                        </Select>
                        { createScheduleType === 'DAY_OF_MONTH' && <Typography>Payouts nominally scheduled between the 29th and 31st of the month are instead sent on the last day of a shorter month.</Typography>}
                    </Grid>}
                    <Grid item md={12} xs={12} sm={12}>
                    <ButtonMod 
                        onClick={() => {
                            if (!createScheduleType || !createScheduleTimeZone || !createScheduleCurrency) {
                                window.alert('Missing Values')
                                return
                            }
                            createPayoutSchedule(
                                {
                                    organizationId: organization.id
                                },
                                {
                                    organizationId: organization.id,
                                    currency: createScheduleCurrency,
                                    location: createScheduleTimeZone,
                                    type: createScheduleType,
                                    day: createScheduleDay ? createScheduleDay : 0
                                }
                            )
                            setCreateScheduleType('')
                            setCreateScheduleTimeZone('')
                            setCreateScheduleCurrency('')
                            setCreateScheduleDay('')
                        }}
                    >
                        Create Payout Schedule
                    </ButtonMod>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </Container>
    <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress className={classes.circularProgress} />
    </Backdrop>
    </main>
    )
}

export default PayoutsReport