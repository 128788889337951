import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganization, getEnumeration, GetPos, clearPOSDetail,
    PatchPos, GetPosRoute, GetPosAccounts, UpdatePosRoute, getApiKeys, createApiKeys, deleteApiKeys,
    DeletePos
} from '../actions'
import PaymentElement from '../components/PaymentElement'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    paymentsourcetypes: (state.misc && state.misc.enum && state.misc.enum.paymentsourcetype) || [],
    paymentelementsread: (state.organizations.role && state.organizations.role.paymentelementsread) || false,
    paymentelementswrite: (state.organizations.role && state.organizations.role.paymentelementswrite) || false,
    organizationPos: state.organizations.organizationPos || [],
    detailedPos: state.organizations.detailedPos || {},
    detailedPosRoutes: state.organizations.detailedPosRoutes || {},
    detailedPosPmtSvcAcc: state.organizations.detailedPosPmtSvcAcc || {},
    detailedPosRoutesRules: state.organizations.detailedPosRoutes || [],
    environment: state.appstate.environment || 'staging',
    posApiKeys: state.apiKeys.posApiKeys || {},
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    GetPos: (params) => dispatch(GetPos(params)),
    PatchPos: (params, reqBody) => dispatch(PatchPos(params, reqBody)),
    DeletePos: (params) => dispatch(DeletePos(params)),
    GetPosRoute: (params) => dispatch(GetPosRoute(params)),
    GetPosAccounts: (params) => dispatch(GetPosAccounts(params)),
    UpdatePosRoute: (params, reqBody) => dispatch(UpdatePosRoute(params, reqBody)),
    getApiKeys: (params) => dispatch(getApiKeys(params)),
    createApiKeys: (params, reqBody) => dispatch(createApiKeys(params, reqBody)),
    deleteApiKeys: (params) => dispatch(deleteApiKeys(params)),
    clearPOSDetail: () => dispatch(clearPOSDetail()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentElement)