import React, {useLayoutEffect, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Chart,
    Settings,
    // AreaSeries,
    Axis,
    timeFormatter,
    niceTimeFormatByDay,
    BarSeries,
    LineSeries,
    Position, 
    ScaleType,
    // DARK_THEME,
    // LIGHT_THEME
  } from '@elastic/charts'
//  import { formatDate, dateFormatAliases }  from '@elastic/eui/lib/services/format/format_date'
// import { EUI_CHARTS_THEME_DARK, EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme';
import "@elastic/charts/dist/theme_light.css";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {fetchApi} from '../app/utils/callPmtsReportApi';
import { paymentStates } from '../app/globals';
import './styles/CompanyDashboard.css';


const classNames = require('classnames');
const currencyFormatter = require('currency-formatter');
const elasticSearchAmountCurrencyConverter = (currency, amount) => {
    const currencyDetails = currencyFormatter.findCurrency(currency);
    const amountString = `${amount}`.split('.')[0]
    let amountBeforeDecimal = ''
    let amountAfterDecimal = ''
    if (currencyDetails.decimalDigits > 0 && amount != 0) {
        let firstIndexAfterDecimal = amountString.length - currencyDetails.decimalDigits
        amountBeforeDecimal = amountString.slice(0,firstIndexAfterDecimal)
        amountAfterDecimal = amountString.slice(firstIndexAfterDecimal)
    } else {
        amountBeforeDecimal = amountString
    }
    const toalAmount = amountBeforeDecimal + "." + amountAfterDecimal
    return parseFloat(toalAmount)
}
const currencyFormat = (currency, amount, symbol=false) => {
    if (!currency || !amount) return ''
    const currencyDetails = currencyFormatter.findCurrency(currency);
    let amountString = `${amount}`
    let amountBeforeDecimal = ''
    let amountAfterDecimal = ''
    if (currencyDetails.decimalDigits > 0 && amount !== '0') {
        let firstIndexAfterDecimal = amountString.length - currencyDetails.decimalDigits
        amountBeforeDecimal = amountString.slice(0,firstIndexAfterDecimal)
        amountAfterDecimal = amountString.slice(firstIndexAfterDecimal)
    } else {
        amountBeforeDecimal = amountString
    }
    // Add in Seperators to amountBeforeDecimal
    let amountBeforeDecimalReverseArr = amountBeforeDecimal.split('').reverse()
    amountBeforeDecimal = ''
    amountBeforeDecimalReverseArr.forEach((digit, index) => {
        if (((index + 1 ) % 3) === 0) {
            amountBeforeDecimal += `${digit}` + `${currencyDetails.thousandsSeparator}`
        } else {
            amountBeforeDecimal += digit
        }
    })
    amountBeforeDecimal = amountBeforeDecimal.split('').reverse().join('')
    if (amountBeforeDecimal[0] === currencyDetails.thousandsSeparator) {
        amountBeforeDecimal = amountBeforeDecimal.slice(1)
    }
    return parseInt(`${amountBeforeDecimal}${currencyDetails.decimalDigits > 0 ? currencyDetails.decimalSeparator : ''}${(amount == '0' && currencyDetails.decimalDigits == 2) ? 0 : amountAfterDecimal}`)
}
const useStyles = makeStyles(theme => ({
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    colorBanner: {
        background: `linear-gradient(210deg,#8CD987 0%,#1FA595 100%)`,
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'initial',
        minHeight: '22em',
        marginBottom: '-14em'
    },
    mainFrame: {
        margin: '0 auto',
        position: 'relative',
        maxWidth: '87%'
    },
    title: {
        fontWeight: 560,
        fontSize: '2em',
        color: '#ffffff',
        marginBottom: '7em',
    },
    subTitle: {
        fontWeight: 420,
        marginBottom: '1em',
        color: '#16a09e'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
    circularProgress: {
        color: '#68d0af'
    },
}));


const CompanyDashboard = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, openDrawer, 
        toggleDrawer, getOrganization, organization, environment,
        currencies, getEnumeration } = props
    const urlArr = window.location.href.split('/')
    const orgID = urlArr[urlArr.length - 1]
    const [chartData, setChartData] = useState({});
    const [chartDays, setChartDays] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('SGD');


    useEffect(() => {
        window.scrollTo(0, 0);
        // console.log('useEffect on dashboard !');
        if (!openDrawer) toggleDrawer() // close it if its open
    },[])
    useEffect(() => {
        // console.log('use Effect on dashboard');
        getOrganization({organizationId: orgID})
    },[orgID])
    const daysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    }
    const getPastThreeMonths = () => {
        let values = [{},{},{}]
        let d = new Date();
        values[2].year = d.getFullYear().toString()
        values[2].month = (d.getMonth() + 1) > 9 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1)
        values[2].days = d.getDate()
        d.setMonth(d.getMonth() - 1);
        values[1].year = d.getFullYear().toString()
        values[1].month = (d.getMonth() + 1) > 9 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1)
        values[1].days = daysInMonth(d.getMonth() + 1,d.getFullYear())
        d.setMonth(d.getMonth() - 1);
        values[0].year = d.getFullYear().toString()
        values[0].month = (d.getMonth() + 1) > 9 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1)
        values[0].days = daysInMonth(d.getMonth() + 1,d.getFullYear())
        d.setMonth(d.getMonth() - 1);
        return values
    }

    const getDaysArray = function(start, end) {
        for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
            arr.push(new Date(dt));
        }
        return arr;
    };

    const callPaymentSeries = async (targetCurrency="SGD") => {
        try {
        // const datesList = getPastThreeMonths()
        let dayListDictionary = {};
        let now = new Date();
        // let oneYearBeforeNow = new Date();
        // oneYearBeforeNow.setFullYear(oneYearBeforeNow.getFullYear() - 1); 1 year ago
        let threeMonthBeforeNow = new Date();
        threeMonthBeforeNow.setMonth(threeMonthBeforeNow.getMonth() - 3);
        let daylist = getDaysArray(threeMonthBeforeNow, now);
        daylist = daylist.map((v)=> {
            const dateFormat = v.toISOString().slice(0,10)
            let tempDictionary = {}
            paymentStates.map((eachState) => {
                tempDictionary[eachState] = {
                    "key": eachState,
                    "doc_count": 0,
                    "amount": {
                        "value": 0
                    },
                    "amountValue": 0.0,
                    "date": `${dateFormat}`
                }
            })
            dayListDictionary[dateFormat] = tempDictionary
            return dateFormat
        })
        console.log('dayListDictionary \n ', dayListDictionary)

        setIsLoading(true)
        setChartData([])
        let totalPayments = []
        const allAggResponse = await fetchApi(environment,
            '/payments-search/organization/:organizationId',
            'post',
            {organizationId: organization.id},
            {
                "query": {
                    "bool": {
                        "must": [
                            {"term": {"currency.keyword": {"value": targetCurrency }}},
                            {"range": {"createdAt": {"gte": threeMonthBeforeNow.toISOString(), "lte": now.toISOString() }}}
                        ]
                    }
                },
                "aggs": {
                    "payments_over_time": {
                      "date_histogram": {
                        "field": "updatedAt",
                        "calendar_interval": "day"
                      },
                      "aggs": {
                        "by_state": {
                          "terms": {"field": "state.keyword"},
                          "aggs": {
                            "amount": { "sum": {"field": "amount" } }
                          }
                        }
                      }
                    }
                  }
            },
        )
        const { data } = allAggResponse;
        // For Each Daily aggregation
        if (data && data.aggregations && data.aggregations.payments_over_time && data.aggregations.payments_over_time.buckets && data.aggregations.payments_over_time.buckets.length > 0) {
            const payments_chronological_bucket = data.aggregations.payments_over_time.buckets
            // For Each Day
            payments_chronological_bucket.map((eachTimePeriod) => {
                if (eachTimePeriod && eachTimePeriod.by_state && eachTimePeriod.by_state.buckets && eachTimePeriod.by_state.buckets.length > 0) {
                    // This should yield YYYY-MM-DD
                    const timePeriodAsKey = eachTimePeriod.key_as_string.slice(0,10);
                    const stateBucket = eachTimePeriod.by_state.buckets;
                    // For Each State
                    stateBucket.map((eachState) => {
                        let convertedAmount = elasticSearchAmountCurrencyConverter(targetCurrency, ((eachState.amount && eachState.amount.value) || 0.0))
                        let stateDictionary = {
                            "key": eachState.key,
                            "doc_count": eachState.doc_count,
                            "amount": {
                                "value": (eachState.amount && eachState.amount.value) || 0
                            },
                            "amountValue":(convertedAmount) || 0.0,
                            "date": `${timePeriodAsKey}`
                        }
                        dayListDictionary[timePeriodAsKey][eachState.key] = stateDictionary
                    })
                }
            })
        }

        console.log('dayListDictionary \n ', dayListDictionary)
        // Iterate through each day
        Object.keys(dayListDictionary).map((eachTimeKey) => {
            // For Payment State
            paymentStates.map((eachState) => {
                totalPayments.push(dayListDictionary[eachTimeKey][eachState])
            })
        })
        totalPayments.sort((a,b) => {
            return new Date(a.date) - new Date(b.date)
        })
        console.log('totalPayments \n ', totalPayments)
        setChartData(totalPayments)
        setIsLoading(false)
        } catch (errta) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        // Return if there's no organizationId
        if (!organization.id) return
        getEnumeration({enumGroup: 'currency'})
        callPaymentSeries()
        setTimeout(() => {
            getEnumeration({enumGroup: 'currency'})
        }, 3000)
    },[organization.id])
    return (
    <main>
        <div className={classes.colorBanner}></div>
        <div className={classes.mainFrame}>
            <div className={classes.title}>Hello There</div>
            <div className={classes.subTitle}><Typography variant="h5" className={classes.subTitle}>Your Recent Payment Summary</Typography></div>
            <Grid contrainer spacing={3}>
                <Grid item md={8} xs={8} sm={8}></Grid>
                <Grid item md={3} xs={3} sm={3}>
                    <Select
                        fullWidth
                        variant="outlined"
                        className={classes.select}
                        value={(selectedCurrency) || ""}
                        onChange={(e) => {
                            setSelectedCurrency(e.target.value)
                            callPaymentSeries(e.target.value)
                        }}
                        label="Currency"
                    >
                        {
                            currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                if (currency.value && currency.value.currency_alpha3_code) {
                                    return <MenuItem key={'dashboardCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                }
                            })
                        }
                    </Select>
                </Grid>
            </Grid>
            <br/><br/>
            <Typography variant="h5" className={classes.subTitle}>Payment values for past 3 months</Typography>
            <Chart 
                size={{height: 350}}
            >
                <Settings
                    showLegend={true}
                    legendPosition="bottom"
                />
                <LineSeries
                    id="amount"
                    name="Amount"
                    data={chartData}
                    xScaleType={ScaleType.Time}
                    xAccessor={"date"}
                    yAccessors={["amountValue"]}
                    // stackAccessors={[0]}
                    splitSeriesAccessors={['key']}
                    />
                <Axis
                    title={"Date"}
                    id="bottom-axis"
                    position="bottom"
                    tickFormat={timeFormatter(niceTimeFormatByDay(90))}
                    showGridLines
                />
                <Axis
                    id="left-axis"
                    position="left"
                    showGridLines
                    title={"Payment Value (" + selectedCurrency + ")"}
                />
            </Chart>
            <br/><br/>
            <Typography variant="h5" className={classes.subTitle}>Payment counts for past 3 months</Typography>
            <Chart 
                size={{height: 350}}
            >
                <Settings
                    showLegend={true}
                    legendPosition="bottom"
                />
                <BarSeries
                    id="count"
                    name="Count"
                    data={chartData}
                    xScaleType={ScaleType.Time}
                    xAccessor={"date"}
                    yAccessors={["doc_count"]}
                    splitSeriesAccessors={['key']}
                    stackAccessors={[0]}
                />
                <Axis
                    title={"Date"}
                    id="bottom-axis"
                    position="bottom"
                    tickFormat={timeFormatter(niceTimeFormatByDay(90))}
                    showGridLines
                />
                <Axis
                    id="left-axis"
                    position="left"
                    showGridLines
                    title={"Payment Count"}
                />
            </Chart>
        </div>
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
      </Backdrop>
    </main>
    )
}

export default CompanyDashboard