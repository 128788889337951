import { CALL_PAYOUTS_REPORT_API } from '../app/middleware/callPayoutsReportApi.js';
import { CALL_PAYOUTS_APPSYNC_API } from '../app/middleware/callPayoutsAppSync.js';

// export const postPayoutsSearch = (params, reqBody) => ({
//     types: ['POST_PAYOUTS_SEARCH_REQUEST', 'POST_PAYOUTS_SEARCH_SUCCESS', 'POST_PAYOUTS_SEARCH_FAILURE'],
//     [CALL_PAYOUTS_REPORT_API]: '/payouts/:organizationId/search',
//     method: 'post',
//     params: params,
//     reqBody: reqBody
// })

// export const postPayoutsSearchAfter = (params, reqBody) => ({
//     types: ['POST_PAYOUTS_SEARCH_AFTER_REQUEST', 'POST_PAYOUTS_SEARCH_AFTER_SUCCESS', 'POST_PAYOUTS_SEARCH_AFTER_FAILURE'],
//     [CALL_PAYOUTS_REPORT_API]: '/payouts/:organizationId/search',
//     method: 'post',
//     params: params,
//     reqBody: reqBody
// })

export const postPayoutsSearch = (reqBody) => ({
    types: ['POST_PAYOUTS_SEARCH_REQUEST', 'POST_PAYOUTS_SEARCH_SUCCESS', 'POST_PAYOUTS_SEARCH_FAILURE'],
    [CALL_PAYOUTS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postPayoutsSearchAfter = (reqBody) => ({
    types: ['POST_PAYOUTS_SEARCH_AFTER_REQUEST', 'POST_PAYOUTS_SEARCH_AFTER_SUCCESS', 'POST_PAYOUTS_SEARCH_AFTER_FAILURE'],
    [CALL_PAYOUTS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const clearPayoutsSearch = () => ({
    type: 'CLEAR_PAYOUTS_SEARCH'
})

export const updatePayoutsSearch = (reqBody) => ({
    type: 'UPDATE_PAYOUTS_SEARCH',
    reqBody: reqBody
})

export const createPayout = (reqBody) => ({
    types: ['POST_CREATE_PAYOUT_REQUEST', 'POST_CREATE_PAYOUT_SUCCESS', 'POST_CREATE_PAYOUT_FAILURE'],
    [CALL_PAYOUTS_REPORT_API]: '/payouts',
    method: 'post',
    reqBody: reqBody
})

export const getPayoutSchedules = (params) => ({
    types: ['GET_PAYOUT_SCHEDULES_REQUEST', 'GET_PAYOUT_SCHEDULES_SUCCESS', 'GET_PAYOUT_SCHEDULES_FAILURE'],
    [CALL_PAYOUTS_REPORT_API]: '/payouts/:organizationId/schedules',
    method: 'get',
    params: params,
})

export const createPayoutSchedule = (params, reqBody) => ({
    types: ['POST_CREATE_PAYOUT_SCHEDULE_REQUEST', 'POST_CREATE_PAYOUT_SCHEDULE_SUCCESS', 'POST_CREATE_PAYOUT_SCHEDULE_FAILURE'],
    [CALL_PAYOUTS_REPORT_API]: '/payouts/:organizationId/schedules',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const updatePayoutSchedule = (params, reqBody) => ({
    types: ['UPDATE_PAYOUT_SCHEDULE_REQUEST', 'UPDATE_PAYOUT_SCHEDULE_SUCCESS', 'UPDATE_PAYOUT_SCHEDULE_FAILURE'],
    [CALL_PAYOUTS_REPORT_API]: '/payouts/:organizationId/schedules/:currency',
    method: 'patch',
    params: params,
    reqBody: reqBody
})