export const setUser = (user) => ({
    type: 'SET_USER',
    data: { user: user }
})

export const setSSO = (sso) => ({
    type: 'SET_SSO',
    data: { sso: sso }
})

// module.exports = {
//     setUser: setUser,
// }