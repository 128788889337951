import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, clearOrgState } from '../actions'
import AppDrawer from '../components/AppDrawer'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    analyticsread: (state.organizations.role && state.organizations.role.analyticsread) || false,
    balancesread: (state.organizations.role && state.organizations.role.balancesread) || false,
    checkoutpagesread: (state.organizations.role && state.organizations.role.checkoutpagesread) || false,
    collectionsread: (state.organizations.role && state.organizations.role.collectionsread) || false,
    customersread: (state.organizations.role && state.organizations.role.customersread) || false,
    ordersread: (state.organizations.role && state.organizations.role.ordersread) || false,
    paymentelementsread: (state.organizations.role && state.organizations.role.paymentelementsread) || false,
    paymentterminalsread: (state.organizations.role && state.organizations.role.paymentterminalsread) || false,
    payoutsread: (state.organizations.role && state.organizations.role.payoutsread) || false,
    invoicesread: (state.organizations.role && state.organizations.role.invoicesread) || false,
    productsvcsread: (state.organizations.role && state.organizations.role.productsvcsread) || false,
    disputesread: (state.organizations.role && state.organizations.role.disputesread) || false,
    transactionsread: (state.organizations.role && state.organizations.role.transactionsread) || false,
    usermgtread: (state.organizations.role && state.organizations.role.usermgtread) || false,
    bankdetailsread: (state.organizations.role && state.organizations.role.bankdetailsread) || false,
    ownersread: (state.organizations.role && state.organizations.role.ownersread) || false,
})
  
const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    clearOrgState: () => dispatch(clearOrgState()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppDrawer)