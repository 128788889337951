import { CALL_INVOICE_API } from '../app/middleware/callInvoiceApi.js';
import { CALL_INVOICE_APPSYNC_API } from '../app/middleware/callInvoicesAppSync.js';

export const postCreateInvoice = (reqBody) => ({
    types: ['POST_CREATE_INVOICE_REQUEST', 'POST_CREATE_INVOICE_SUCCESS', 'POST_CREATE_INVOICE_FAILURE'],
    [CALL_INVOICE_API]: '/invoices',
    method: 'post',
    reqBody: reqBody
})

export const postInvoicesSearch = (reqBody) => ({
    types: ['POST_SEARCH_INVOICES_REQUEST', 'POST_SEARCH_INVOICES_SUCCESS', 'POST_SEARCH_INVOICES_FAILURE'],
    [CALL_INVOICE_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postInvoicesSearchAfter = (reqBody) => ({
    types: ['POST_SEARCH_AFTER_INVOICES_REQUEST', 'POST_SEARCH_AFTER_INVOICES_SUCCESS', 'POST_SEARCH_AFTER_INVOICES_FAILURE'],
    [CALL_INVOICE_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const updateInvoice = (params, reqBody) => ({
    types: ['PATCH_INVOICE_REQUEST', 'PATCH_INVOICE_SUCCESS', 'PATCH_INVOICE_FAILURE'],
    [CALL_INVOICE_API]: '/invoices/:invoiceId/organizations/:organizationId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const clearInvoicesSearch = () => ({
    type: 'CLEAR_INVOICES_SEARCH'
})