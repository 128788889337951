import React, { useState, useEffect, useLayoutEffect } from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInput } from '@material-ui/core';
import { green, red, grey } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchApi} from '../app/utils/callApi';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Fab from '@material-ui/core/Fab';
import { countries as countryContacts } from '../app/globals';

const useStyles = makeStyles(theme => ({
    hiddeninput: {
        display: 'none',
    },
    circularProgress: {
        color: '#68d0af'
    },
    editIcon: {
        color: grey['A200'],
        '&:hover': {
            color: 'black',
            cursor: 'pointer'
        },
    },
    card: {
        padding: '1em',
        zIndex: 50,
    },
    RRbtn: {
        width: '100%',
        backgroundColor: '#1fa595',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#16a09e',
        },
    },
    btn: {
        color: '#1fa595'
    },
    select: {
        '&:after': {
            borderBottom: '2px solid #68d0af', // 1fa595 
        }
    },
    showNote: {
        color: '#68d0af',
        '&:hover': {
            color: '#1fa595',
            cursor: 'pointer'
        },
    }
}));

const PaperMod = withStyles({
    root: {
        marginTop: '1em',
        padding: '1em',
    },
})(Paper);

const GeenFab = withStyles({
    root: {
        backgroundColor: '#68d0af',
        color: '#ffffff'
    },
})(Fab);

const YellowFab = withStyles({
    root: {
        backgroundColor: '#cdd004',
        color: '#ffffff'
    },
})(Fab);

const RedFab = withStyles({
    root: {
        backgroundColor: '#d00404',
        color: '#ffffff'
    },
})(Fab);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        },
    },
})(OutlinedInput);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const GeneralSettings = (props) => {
    const classes = useStyles(props)
    const theme = useTheme();
    const { value, generalread, generalwrite, organization, patchOrganization, mcc, countries, entities, environment, updateOrganizationReady } = props
    // console.log("ORGAN => ", organization);
    const [open, setOpen] = useState(false);
    const [showEdit, setShowEdit] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [organizationID, setOrganizationID] = useState('');
    const [organizationCon3, setOrganizationCon3] = useState('');
    const [organizationCon3Dial, setOrganizationCon3Dial] = useState('');
    const [organizationUen, setOrganizationUen] = useState('');
    const [organizationWeb, setOrganizationWeb] = useState('');
    const [organizationType, setOrganizationType] = useState('');
    const [organizationDescription, setOrganizationDescription] = useState('')
    const [organizationMcc, setOrganizationMcc] = useState('');
    const [entityTypes, setEntityTypes] = useState([]);
    const [state, setOrganizationState] = useState('');
    const [city, setOrganizationCity] = useState('');
    const [postal, setOrganizationPostal] = useState('');
    const [line1, setOrganizationAdd1] = useState('');
    const [line2, setOrganizationAdd2] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactEmail, setcontactEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [showNote, setShowNote] = useState(false);
    const [dba, setDba] = useState('');
    const [description, setDescription] = useState('');
    const [fileLoading, setFileLoading] = useState(false);
    useEffect(() => {
        entities.map((entity) => {
            if (entity && entity.name && organizationCon3 === entity.name) {
                if (entity.value && entity.value.entity_types && entity.value.entity_types.length > 0) {
                    setEntityTypes(entity.value.entity_types)
                }
            }
        })
        console.log('country change');
        countries.map((eachCountry) => {
            // console.log('eachCountry.country_alpha3_code :', eachCountry.value.country_alpha3_code);
            // console.log('organizationCon3: ', organizationCon3);
            if ((eachCountry.value && eachCountry.value.country_alpha3_code) === organizationCon3) {
                countryContacts && countryContacts.map((eachCountryContact) => {
                    if (eachCountryContact.isoCode === eachCountry.value.country_alpha2_code) {
                        setOrganizationCon3Dial(eachCountryContact.dialCode)
                    }
                })
            }
        })
    },[organizationCon3, entities]);
    useEffect(() => {
        // console.log('change in orgnanization => ', organization);
        if (organization && organization.name) setOrganizationName(organization.name)
        if (organization && organization.id) setOrganizationID(organization.id)
        if (organization && organization.country) {
            setOrganizationCon3(organization.country)
            if (countries) {
                countries.map((eachCountry) => {
                    if ((eachCountry.value && eachCountry.value.country_alpha3_code) === organization.country) {
                        countryContacts && countryContacts.map((eachCountryContact) => {
                            if (eachCountryContact.isoCode === eachCountry.value.country_alpha2_code) {
                                setOrganizationCon3Dial(eachCountryContact.dialCode)
                            }
                        })
                    }
                })
            }
        }
        if (organization && organization.uen) setOrganizationUen(organization.uen)
        if (organization && organization.dba) setDba(organization.dba)
        if (organization && organization.website) setOrganizationWeb(organization.website)
        if (organization && organization.type) setOrganizationType(organization.type)
        if (organization && organization.description) setOrganizationDescription(organization.description)
        if (organization && organization.mcc) setOrganizationMcc(organization.mcc)
        if (organization && organization.address && organization.address.state) setOrganizationState(organization.address.state)
        if (organization && organization.address && organization.address.city) setOrganizationCity(organization.address.city)
        if (organization && organization.address && organization.address.postal) setOrganizationPostal(organization.address.postal)
        if (organization && organization.address && organization.address.line1) setOrganizationAdd1(organization.address.line1)
        if (organization && organization.address && organization.address.line2) setOrganizationAdd2(organization.address.line2)
        if (organization && organization.contact && organization.contact.name) setContactName(organization.contact.name)
        if (organization && organization.contact && organization.contact.email) setcontactEmail(organization.contact.email)
        if (organization && organization.contact && organization.contact.number) setContactNumber(organization.contact.number)
    },[organization.id, organization])
    const handleShow = (field) => {
        setShowEdit(field)
    }
    const handleSubmit = (reqBody) => {
        if (!organizationID) return
        const params = { organizationId: organizationID }
        patchOrganization(params, reqBody)
    }

    const handleReview = () => {
        if (!organizationID) return
        const params = { organizationId: organizationID }
        updateOrganizationReady(params)
    }

    const saveFile = (file, content) => {
        try {
            let fileNameArr = file.name.split('.')
            fetchApi(environment,
                '/organizations/:organizationId/kycdoc?section=general',
                'post',
                {organizationId: organization.id},
                {}
                ).then((result) => {
                    // console.log('presigned result => ', result);
                    const { data } =result
                    if (!data.url) return
                    const xhr = new XMLHttpRequest()
                    let url = data.url
                    
                    xhr.open(data.method, url)
                    xhr.setRequestHeader('x-amz-acl', data.XamzAcl)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            // Update organization company registration documents
                            // console.log(`${data.key ? data.key : ''}`);
                            // console.log(`${data}`);
                            handleSubmit({
                                    bizRegCert: {
                                        key: data.key,
                                        name:file.name
                                    }
                                });
                            setFileLoading(false);
                        }
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setFileLoading(false);
            // console.log('error : ', err);
        }
    }

    const fileUpload = (eventFile) => {
        handleShow('')
        setFileLoading(true);
        let file = eventFile[0]
        // console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setFileLoading(false);
            return;
        }
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
            case 'application/pdf':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setFileLoading(false);
            alert('Only png, jpeg and pdf file types allowed')
            // this.imageUploading = false
            return;
        }
        const max_file_size = 102400 * 10
        if (file.size <= max_file_size){
            const reader = new FileReader()
            // console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                // console.log('content -> ', content);
                // console.log('file 2 -> ', file);

                saveFile(file, content)
            }
        }else {
            setFileLoading(false);
            alert('Maximum size exceeded for file! Please make sure file size is less than 1MB');
            // this.imageUploading = false
        }
    }

    return (
        <TabPanel value={value} index={0} dir={theme.direction}>
        <PaperMod elevation={3} >
            <Grid container spacing={3}>
                <Grid item md={12} xs={12} sm={12}>
                    <Typography component={'span'} className={classes.label}>
                        <b className={classes.showNote}>NOTE: </b>When you're done completing the General, Owners, { showNote ? "Bank Details and Other Suporting Documents Tabs, you may request our compliance team to review your business profile by clicking on the 'Request Review' Button. This is a validation process and we might need to contact you to verify certain details. Our compliance team will contact you based on the contact information provided below. Upon approval, you will be able to attach your payment service accounts to your point of sales for payment processing." : <b className={classes.showNote} onClick={() => {  setShowNote(true) }}>... show more</b>}
                        {showNote ? <b className={classes.showNote} onClick={() => { 
                            setShowNote(false)
                        } }> show less</b> : ""}
                    </Typography>
                </Grid>
            </Grid>
        </PaperMod>
        <br/>
        <Grid container spacing={3}>
            <Grid item md={8} xs={8} sm={8}>
                <span className={classes.Title}>Your company</span>&nbsp;&nbsp;&nbsp;
                {organization.complianceApproval === "APPROVED" && <GeenFab size="small" variant="extended">{organization.complianceApproval}</GeenFab>}
                {organization.complianceApproval === "REJECTED" && <RedFab size="small" variant="extended">{organization.complianceApproval}</RedFab>}
                {((organization.complianceApproval === "ADMIN_ACTION_REQUIRED") || (organization.complianceApproval === "ORGANIZATION_ACTION_REQUIRED")) && <YellowFab size="small" variant="extended">{organization.complianceApproval}</YellowFab>}
                {organization.complianceApproval === "NO_STATUS" && <Fab size="small" variant="extended">{organization.complianceApproval}</Fab>}
            </Grid>
            <Grid item md={4} xs={4} sm={4}>
                <Button 
                    className={classes.RRbtn} 
                    onClick={() => {
                        handleReview()
                    }} 
                >
                    Request Review
                </Button>
            </Grid>
        </Grid>
        <PaperMod elevation={3} >
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Company legal business name</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.name}
                    <IconButton onClick={() => handleShow('company name')}>
                        <EditIcon className={classes.editIcon}/>
                    </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'company name' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Company name
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setOrganizationName(e.target.value)} defaultValue={organizationName}/>
                        {organization && organization.name && <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('')
                                setOrganizationName(organization.name)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={(e) => {
                                handleSubmit({ name: organizationName})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>ID</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organizationID}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Country based in</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Select
                        className={classes.select}
                        value={organizationCon3}
                        onChange={(e) => {
                            handleSubmit({ country: e.target.value})
                        }}
                        label="Country"
                    >
                        {
                            countries && countries.map((country, index) => {
                                return <MenuItem key={index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                            })
                        }
                    </Select>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>UEN (unique entity number provided by an authority)</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.uen}
                        <IconButton onClick={() => handleShow('company uen')}>
                            <EditIcon className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'company uen' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Company uen
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setOrganizationUen(e.target.value)} defaultValue={organizationUen}/>
                        {organization && <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('')
                                const uen = organization.uen || ''
                                setOrganizationUen(uen)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleSubmit({ uen: organizationUen})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Business Registration Certificate</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                {organization && organization.bizRegCert && fileLoading ? <CircularProgress className={classes.circularProgress} /> : <div>
                    <Typography component={'span'}>{organization && organization.bizRegCert && organization.bizRegCert.name}</Typography>
                    <IconButton onClick={() => { handleSubmit({ 
                        bizRegCert: {
                            key: '',
                            name:''
                        }
                    })}} component="span">
                        <DeleteOutlineIcon />
                    </IconButton>
                </div>}
                <input className={classes.hiddeninput} onChange={(e) => {fileUpload(e.target.files)}} id="icon-button-file" type="file" />
                <label htmlFor="icon-button-file">
                    <IconButton disabled={fileLoading} color="primary" aria-label="upload file" component="span">
                        <PublishIcon className={classes.editIcon}/>
                    </IconButton>
                </label>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Type</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Select
                            className={classes.select}
                            value={organizationType}
                            onChange={(e) => {
                                handleSubmit({ type: e.target.value})
                            }}
                            label="Type"
                        >
                            {
                                entityTypes.length > 0 && entityTypes.map((entity, index) => {
                                    // console.log("entity => ", entity);
                                    return <MenuItem key={index} value={entity}>{entity}</MenuItem>
                                })
                            }
                    </Select>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Doing business as</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.dba}
                        <IconButton onClick={() => handleShow('dba')}>
                            <EditIcon className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'dba' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Doing Business As
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setDba(e.target.value)} defaultValue={dba}/>
                        {organization && <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('')
                                const dba = organization.dba || ''
                                setDba(dba)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleSubmit({ dba: dba})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Business Website</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.website}
                        <IconButton onClick={() => handleShow('website')}>
                            <EditIcon  className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'website' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Company Website
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setOrganizationWeb(e.target.value)} defaultValue={organizationWeb}/>
                        {organization && <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('')
                                const website = organization.website || ''
                                setOrganizationWeb(website)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleSubmit({ website: organizationWeb})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Industry</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Select
                            className={classes.select}
                            value={organizationMcc}
                            onChange={(e) => {
                                handleSubmit({ mcc: e.target.value})
                            }}
                            label="Industry"
                        >
                            {
                                mcc && mcc.length > 0 && mcc.map((category, index) => {
                                    if (category.value && category.value.code) {
                                        return <MenuItem key={index} value={category.value.code}>{category.name}</MenuItem>
                                    }
                                })
                            }
                    </Select>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Business Description</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>{organization && organization.description}
                        <IconButton onClick={() => handleShow('description')}>
                            <EditIcon className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'description' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Description
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setDescription(e.target.value)} defaultValue={description}/>
                        {organization && <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('')
                                const description = organization.description || ''
                                setDescription(description)
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleSubmit({ description: description})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Addresss</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                        <Typography component={'span'}>{organization && organization.address && organization.address.line1}, {organization && organization.address && organization.address.line2}
                            <IconButton onClick={() => handleShow('address')}>
                                <EditIcon className={classes.editIcon}/>
                            </IconButton>
                        </Typography>
                </Grid>
                {showEdit === 'address' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            State
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setOrganizationState(e.target.value)} defaultValue={state}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            City
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setOrganizationCity(e.target.value)} defaultValue={city}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Postal
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setOrganizationPostal(e.target.value)} defaultValue={postal}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Address Line 1
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setOrganizationAdd1(e.target.value)} defaultValue={line1}/>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Address Line 2
                        </Typography>
                        <OutlinedInputMod fullWidth onChange={(e) => setOrganizationAdd2(e.target.value)} defaultValue={line2}/>
                        {organization && <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('')
                                setOrganizationState(((organization.address && organization.address.state) || ''))
                                setOrganizationCity(((organization.address && organization.address.city) || ''))
                                setOrganizationPostal(((organization.address && organization.address.postal) || ''))
                                setOrganizationAdd1(((organization.address && organization.address.line1) || ''))
                                setOrganizationAdd2(((organization.address && organization.address.line2) || ''))
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleSubmit({ address:{ state: state, city: city, postal: postal, line1: line1, line2: line2 }})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'} className={classes.label}>Contact</Typography>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                    <Typography component={'span'}>Name: {organization && organization.contact && organization.contact.name}, 
                    Email: {organization && organization.contact && organization.contact.email}, 
                    Number: {organization && organization.contact && organization.contact.number}
                        <IconButton onClick={() => handleShow('contact')}>
                            <EditIcon className={classes.editIcon}/>
                        </IconButton>
                    </Typography>
                </Grid>
                {showEdit === 'contact' && <Grid item md={12} xs={12} sm={12}>
                    <Card className={classes.card}>
                        <Grid container >
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                    Contact Name
                                </Typography>
                                <OutlinedInputMod fullWidth onChange={(e) => setContactName(e.target.value)} defaultValue={contactName}/>
                            </Grid>
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                    Contact Email
                                </Typography>
                                <OutlinedInputMod fullWidth onChange={(e) => setcontactEmail(e.target.value)} defaultValue={contactEmail}/>
                            </Grid>
                            {/* HERE TO DO */}
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                                    Contact Number
                                </Typography>
                                <OutlinedInputMod placeholder={`e.g. ${organizationCon3Dial}12345678`} fullWidth onChange={(e) => {
                                    const trimmed = e.target.value.trim()
                                    setContactNumber(trimmed)
                                }} 
                                value={contactNumber}
                                />
                            </Grid>
                        </Grid>
                        {organization && <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleShow('')
                                setContactName(((organization.contact && organization.contact.name) || ''))
                                setcontactEmail(((organization.contact && organization.contact.email) || ''))
                                setContactNumber(((organization.contact && organization.contact.number) || ''))
                            }} 
                            color="primary"
                        >
                            Cancel
                        </Button>}
                        <Button 
                            className={classes.btn} 
                            onClick={() => {
                                handleSubmit({ contact: { name: contactName, email: contactEmail, number: contactNumber }})
                                handleShow('')
                            }} 
                            color="primary"
                        >
                            Save
                        </Button>
                    </Card>
                </Grid>}
            </Grid>
        </PaperMod>
    </TabPanel>
    )
}

export default GeneralSettings