function users(state ={}, action) {
    switch (action.type) {
      case 'SET_USER':
          state = {
            ...{user: action.data.user} 
          }
        return state
      case 'SET_SSO':
        state = {
          ...{sso: action.data.sso} 
        }
      return state
      default:
        return state
    }
  }

export default users