import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { green, red, grey, teal } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Slide from '@material-ui/core/Slide';
import PublishIcon from '@material-ui/icons/Publish';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchApi} from '../app/utils/callDisputeApi';
import {uuidGenerator} from '../app/utils/uuid'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
const currencyFormatter = require('currency-formatter');


LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    textArea: {
        width: '100%',
        '&:focus-visible': {
            'outline-color': '#68d0af',
        },
    },
    showMore: {
        color: '#68d0af',
        fontWeight: 700,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    hiddeninput: {
        display: 'none',
    },
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const DateTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const DisputesManagement = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont, getOrganization,
        getEnumeration, enumeration, organization, nextToken, hits, totalHits, environment,
        disputesread, disputeswrite, getDispute, updateDispute, 
        clearDisputesSearch, updateDisputesSearch, postDisputesSearch, postDisputesSearchAfter,
    } = props
    const urlArr = window.location.href.split('/')
    const orgID = urlArr[urlArr.length - 1]
    const [loading, setLoading] = useState(false);
    const [disputeDialog, setDisputeDialog] = useState(false);
    const [disputeDetails, setDisputeDetails] = useState({});
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchIsResolved, setSearchIsResolved] = useState('');
    const [addDbId, setAddDbId] = useState('');
    const [addCourierName, setAddCourierName] = useState('');
    const [addTrackingNumber, setAddTrackingNumber] = useState('');
    const [searchId, setSearchId] = useState('');
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    const currencyFormat = (currency, amount) => {
        if (!currency || !amount) return ''
        const currencyDetails = currencyFormatter.findCurrency(currency);
        let amountString = `${amount}`
        let amountBeforeDecimal = ''
        let amountAfterDecimal = ''
        if (currencyDetails.decimalDigits > 0 && amount !== '0') {
            let count = 0
            for (var i = amountString.length - 1; i >= 0; i--) {
                if (count < currencyDetails.decimalDigits) {
                    amountAfterDecimal = amountString[i] + amountAfterDecimal
                    count++
                } else {
                    amountBeforeDecimal = amountString[i] + amountBeforeDecimal 
                }
            }
            if (currencyDetails.decimalDigits > amountAfterDecimal.length) {
                for (var i = 0; i < currencyDetails.decimalDigits - amountAfterDecimal.length; i++) {
                    amountAfterDecimal = '0' + amountAfterDecimal
                }
            }
        } else {
            amountBeforeDecimal = amountString
        }
        if (!amountBeforeDecimal.length) amountBeforeDecimal = '0'
        // Add in Seperators to amountBeforeDecimal
        let amountBeforeDecimalReverseArr = amountBeforeDecimal.split('').reverse()
        amountBeforeDecimal = ''
        amountBeforeDecimalReverseArr.forEach((digit, index) => {
            if (((index + 1 ) % 3) === 0) {
                amountBeforeDecimal += `${digit}` + `${currencyDetails.thousandsSeparator}`
            } else {
                amountBeforeDecimal += digit
            }
        })
        amountBeforeDecimal = amountBeforeDecimal.split('').reverse().join('')
        if (amountBeforeDecimal[0] === currencyDetails.thousandsSeparator) {
            amountBeforeDecimal = amountBeforeDecimal.slice(1)
        }
        return `${currencyDetails.symbol}${currencyDetails.spaceBetweenAmountAndSymbol === true ? ' ': ''}${amountBeforeDecimal}${currencyDetails.decimalDigits > 0 ? currencyDetails.decimalSeparator : ''}${(amount == '0' && currencyDetails.decimalDigits == 2) ? '00' : amountAfterDecimal}`
    }

    useEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        if (!organization.id) {
            const url = window.location.href
            let urlArr = url.split('/')
            let organizationID = urlArr[urlArr.length - 2]
            // console.log('organizationID -> ', organizationID);
            getOrganization({organizationId: organizationID})
            // GetOrgPos({organizationId: organizationID})
        } else {
            // GetOrgPos({organizationId: organization.id})
        }
        setLoadedPage(1)
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'currency'})
        getEnumeration({enumGroup: 'productservicetype'})
        getEnumeration({enumGroup: 'dispute'})
        // setOrgState(org)
        return function cleanup() {
            clearDisputesSearch()
        };
    },[environment])

    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [disputes, setDisputes] = useState([]);
    const [disputeEvidences, setDisputeEvidences] = useState([]);
    const [productServiceType, setProductServiceType] = useState([]);

    useEffect(() => {
        if (enumeration && enumeration.country) setCountries(enumeration.country)
        if (enumeration && enumeration.currency) setCurrencies(enumeration.currency)
        if (enumeration && enumeration.productservicetype) setProductServiceType(enumeration.productservicetype)
        if (enumeration && enumeration.dispute) setDisputes(enumeration.dispute)
    }, [enumeration]);

    useEffect(()=> {
        if (organization && organization.id) {
            let esSearchReqBody = constructSearchReqBody()
            postDisputesSearch(esSearchReqBody)
        }
    },[organization.id])

    useEffect(()=> {
        if (disputeDetails && disputeDetails.liberalizeDisputeCategoryCode && disputes && disputes.length > 0) {
            disputes.map((eachDispute, index) => {
                if (eachDispute.value.code == disputeDetails.liberalizeDisputeCategoryCode) {
                    if (eachDispute.value.evidence) {
                        setDisputeEvidences(eachDispute.value.evidence)
                    }
                }
            })
        }
    },[disputeDetails.liberalizeDisputeCategoryCode, disputes])

    const downloadFile = (s3Key) => {
        console.log(s3Key);
        if (!disputeDetails.organizationId) {
            alert('No Organization Selected!')
            throw new Error('No Organization Selected!')
        }
        setLoading(true)
        fetchApi(environment,
            '/organizations/:organizationId/disputedoc',
            'get',
            {organizationId: disputeDetails.organizationId},
            null,
            {key: s3Key}
            ).then((result) => {
                console.log('presigned result => ', result);
                setLoading(false)
                const { data } = result
                window.open(data, "_blank")
        })
    }

    const uploadFile = (eventFile, disputeDetailsAttribute) => {
        setLoading(true)
        let file = eventFile[0]
        // console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setLoading(false)
            return;
        }
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
            case 'application/pdf':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setLoading(false)
            alert('Only png, jpeg and pdf file types allowed')
            // this.imageUploading = false
            return;
        }
        const max_file_size = 102400 * 10
        if (file.size <= max_file_size){
            const reader = new FileReader()
            // console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                // console.log('content -> ', content);
                // console.log('file 2 -> ', file);

                saveFile(file, content, disputeDetailsAttribute)
            }
        } else {
            setLoading(false)
            alert('Maximum size exceeded for file! Please make sure file size is less than 1MB');
            // this.imageUploading = false
        }
    }

    const saveFile = (file, content, disputeDetailsAttribute) => {
        try {
            let fileNameArr = file.name.split('.')
            if (!disputeDetails.organizationId) {
                alert('No Organization Selected!')
                throw new Error('No Organization Selected!')
            }
            fetchApi(environment,
                '/organizations/:organizationId/disputedoc',
                'post',
                {organizationId: disputeDetails.organizationId},
                {}
                ).then((result) => {
                    const { data } =result
                    if (!data.url) return
                    const xhr = new XMLHttpRequest()
                    let url = data.url
                    
                    xhr.open(data.method, url)
                    xhr.setRequestHeader('x-amz-acl', data.XamzAcl)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            let currentDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                            if (disputeDetailsAttribute === 'merchantCustomerSignature') {
                                let currentMerchantCustomerSignature = currentDisputeDetails.merchantCustomerSignature || []
                                currentDisputeDetails.merchantCustomerSignature = [...currentMerchantCustomerSignature, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantCustomerCommunication') {
                                let currentMerchantCustomerCommunication = currentDisputeDetails.merchantCustomerCommunication || []
                                currentDisputeDetails.merchantCustomerCommunication = [...currentMerchantCustomerCommunication, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantReceipt') {
                                if (!currentDisputeDetails.merchantReceipt) currentDisputeDetails.merchantReceipt = { documents: [], channel: ''}
                                let currentMerchantReceiptDocuments = currentDisputeDetails.merchantReceipt.documents || []
                                currentDisputeDetails.merchantReceipt.documents = [...currentMerchantReceiptDocuments, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantProductServiceDescription') {
                                if (!currentDisputeDetails.merchantProductServiceDescription) currentDisputeDetails.merchantProductServiceDescription = { documents: [], description: ''}
                                let currentPdtSvcDescDocs = currentDisputeDetails.merchantProductServiceDescription.documents || []
                                currentDisputeDetails.merchantProductServiceDescription.documents = [...currentPdtSvcDescDocs, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantRefundPolicy') {
                                currentDisputeDetails.merchantRefundPolicy = { name: file.name, key: data.key }
                            } else if (disputeDetailsAttribute === 'merchantRefundPolicyDisclosure') {
                                if (!currentDisputeDetails.merchantRefundPolicyDisclosure) currentDisputeDetails.merchantRefundPolicyDisclosure = { documents: [], description: ''}
                                let currentRefundDisclosureDocs = currentDisputeDetails.merchantRefundPolicyDisclosure.documents || []
                                currentDisputeDetails.merchantRefundPolicyDisclosure.documents = [...currentRefundDisclosureDocs, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantDuplicateChargeDocuments') {
                                let currentMerchantDuplicateChargeDocuments = currentDisputeDetails.merchantDuplicateChargeDocuments || []
                                currentDisputeDetails.merchantDuplicateChargeDocuments = [...currentMerchantDuplicateChargeDocuments, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantServiceDocumentation') {
                                let currentMerchantServiceDocumentation = currentDisputeDetails.merchantServiceDocumentation || []
                                currentDisputeDetails.merchantServiceDocumentation = [...currentMerchantServiceDocumentation, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantShippingDocumentation') {
                                let currentMerchantShippingDocumentation = currentDisputeDetails.merchantShippingDocumentation || []
                                currentDisputeDetails.merchantShippingDocumentation = [...currentMerchantShippingDocumentation, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantUncategorizedFile') {
                                let currentMerchantUncategorizedFile = currentDisputeDetails.merchantUncategorizedFile || []
                                currentDisputeDetails.merchantUncategorizedFile = [...currentMerchantUncategorizedFile, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantAccessActivityLogs') {
                                let currentMerchantAccessActivityLogs = currentDisputeDetails.merchantAccessActivityLogs || []
                                currentDisputeDetails.merchantAccessActivityLogs = [...currentMerchantAccessActivityLogs, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantCancellationPolicy') {
                                currentDisputeDetails.merchantCancellationPolicy = { name: file.name, key: data.key }
                            } else if (disputeDetailsAttribute === 'merchantCancellationPolicyDisclosure') {
                                if (!currentDisputeDetails.merchantCancellationPolicyDisclosure) currentDisputeDetails.merchantCancellationPolicyDisclosure = { documents: [], description: ''}
                                let currentMerchantCancellationPolicyDisclosure = currentDisputeDetails.merchantCancellationPolicyDisclosure.documents || []
                                currentDisputeDetails.merchantCancellationPolicyDisclosure.documents = [...currentMerchantCancellationPolicyDisclosure, { name: file.name, key: data.key }]
                            }
                            setDisputeDetails(currentDisputeDetails)
                            setLoading(false);
                        }
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    }

    const constructSearchReqBody = (passedPageSize, restartSearch=false) => {
        const letter = searchIsResolved.toLowerCase()
        let initialStruct = {}
        let query = `query listDisputes($organizationId: String) {
            queryDisputesByOrganizationId(
                queryInput: { 
                    organizationId: { 
                        eq: $organizationId 
                    }
                }
                ${letter === 'y' ? `filter: {
                    isResolved: { eq: true }
                }` : ""}
                nextToken: "${(!restartSearch && nextToken) ? nextToken : ""}",
            ) 
            { items { 
                createdAt
                id
                organizationId
                accountId
                accountMerchantProfile
                adminFeesBase
                adminFeesCounter
                billingAddress
                chargebackIds
                deducted
                disputeBase
                disputeCounter
                issuerActionDeadline
                issuerTxnIds
                isResolved
                liberalizeActionDeadline
                liberalizeOfficer
                merchantActionDeadline
                merchantCancellationPolicy
                merchantCancellationPolicyDisclosure
                merchantOfficer
                merchantProductServiceDescription
                merchantReceipt
                merchantRefundPolicy
                merchantRefundPolicyDisclosure
                merchantServiceDate
                merchantShippingAddress
                merchantShippingDate
                posIds
                processorActionDeadline
                processorMerchantProfile
                processorOfficer
                processorTxnIds
                type
                updatedAt
            }, nextToken } 
        }
    `
        initialStruct.variables = {
            organizationId: organization.id
        }
        initialStruct.query = query
        return initialStruct
    }

    useEffect(() => {
        // if(prevToken.nextToken) {

        //  // process here
        // }
        if (nextToken && (hits && hits.length < 1000)) {
            console.log('nextToken: ', nextToken);
            let esSearchReqBody = constructSearchReqBody()
            postDisputesSearchAfter(esSearchReqBody)
        }
    }, [nextToken])
    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={disputeDialog} 
            onClose={()=> {setDisputeDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            {/* Loading screen */}
            <Backdrop className={classes.backdrop} open={loading} >
                <CircularProgress className={classes.circularProgress} />
            </Backdrop>
            <CloseIcon className={classes.closeBtn} onClick={()=> {setDisputeDialog(false)}}/>
            <Typography className={classes.Title} color="textSecondary" gutterBottom>Dispute Details</Typography>
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {
                        disputeDetails && disputeDetails.id && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute ID: {disputeDetails.id}</Typography>
                        </Grid>
                    }
                    {/* OrganizationID */}
                    <Grid item md={11} xs={11} sm={11}>
                        {disputeDetails && <Typography className={classes.title} color="textSecondary" gutterBottom>OrganizationID: {disputeDetails.organizationId || "No Organization"}</Typography>}
                    </Grid>
                    {/* POS IDs */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>POS IDs:</Typography>
                        <Grid container>
                            {disputeDetails && disputeDetails.posIds && disputeDetails.posIds.length > 0 && disputeDetails.posIds.map((eachPos, posIndex) => {
                                return <Grid item md={12} xs={12} sm={12}>
                                    {eachPos}
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                    {/* Account ID */}
                    {/* <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Account ID:</Typography>
                        {disputeDetails && <Typography className={classes.title} color="textSecondary" gutterBottom>Payment AccountID: {disputeDetails.accountId || "Undetermined"}</Typography>}
                    </Grid> */}
                    {/* Payments */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payments:</Typography>
                        {
                            disputeDetails && disputeDetails.payments && disputeDetails.payments.length > 0  && disputeDetails.payments.map((eachPmt, pmtIndex) => {
                                return <Grid container>
                                    <Grid item md={12} xs={12} sm={12}>
                                        PaymentID: {eachPmt && eachPmt.paymentId}
                                    </Grid>
                                    <Grid item md={12} xs={12} sm={12}>
                                            {
                                                eachPmt && eachPmt.transactionIds && eachPmt.transactionIds.length > 0 && eachPmt.transactionIds.map((eachTxn, txnIndx) => {
                                                    return <Grid container>
                                                        <Grid item md={2} xs={2} sm={2}>
                                                        </Grid>
                                                        <Grid item md={10} xs={10} sm={10}>
                                                            TransactionID: {eachTxn}
                                                        </Grid>
                                                    </Grid>
                                                })
                                            }
                                    </Grid>
                                </Grid>
                            })
                        }
                    </Grid>
                    {/* Invoice ID */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Invoice ID:</Typography>
                        {disputeDetails && <Typography className={classes.title} color="textSecondary" gutterBottom>{disputeDetails.invoiceId || "Invoice Not Generated"}</Typography>}
                    </Grid>
                    {/* Payout ID */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payout ID:</Typography>
                        {disputeDetails && <Typography className={classes.title} color="textSecondary" gutterBottom>{disputeDetails.payoutId || "Payout Not Generated"}</Typography>}
                    </Grid>
                    {/* Type */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Type:</Typography>
                        {disputeDetails && <Typography className={classes.title} color="textSecondary" gutterBottom>{disputeDetails.type || "Type Not Determined"}</Typography>}
                    </Grid>
                    {/* State */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>State:</Typography>
                        {disputeDetails && <Typography className={classes.title} color="textSecondary" gutterBottom>{
                            disputeDetails.state === "CREATED" ? "CREATED" : disputeDetails.state === "PENDING_MERCHANT_ACTION" ? "PENDING_MERCHANT_ACTION" : "PENDING_LIBERALIZE_ADMIN_ACTION"}</Typography>
                        }
                    </Grid>
                    {/* Merchant Action Deadline */}
                    <Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Action Deadline (UTC):</Typography>
                        {disputeDetails.merchantActionDeadline && new Date(disputeDetails.merchantActionDeadline).toString()}
                    </Grid>
                    {/* Admin Counter Fee Amount */}
                    <Grid item md={8} xs={8} sm={8}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Admin Base Fee: (Fees Paid to Liberalize)</Typography>
                        <div>
                            {
                                disputeDetails.adminFeesCounter && disputeDetails.adminFeesCounter.amount && disputeDetails.adminFeesCounter.currency && 
                                disputeDetails.adminFeesCounter.currency + " " + currencyFormat(disputeDetails.adminFeesCounter.currency, disputeDetails.adminFeesCounter.amount)
                            }
                        </div>
                        <div>Rate: {disputeDetails.adminFeesCounter && disputeDetails.adminFeesCounter.rate}</div>
                    </Grid>
                    {/* Dispute Base Fee Currency */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Base Fee:</Typography>
                        <div>
                            {
                                disputeDetails.disputeCounter && disputeDetails.disputeCounter.amount && disputeDetails.disputeCounter.currency && 
                                disputeDetails.disputeCounter.currency + " " + currencyFormat(disputeDetails.disputeCounter.currency, disputeDetails.disputeCounter.amount)
                            }
                        </div>
                        <div>Rate: {disputeDetails.disputeCounter && disputeDetails.disputeCounter.rate}</div>
                    </Grid>
                    {/* Released Amount */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Released Amount:</Typography>
                        {
                                (disputeDetails.released && disputeDetails.released.amount && disputeDetails.released.currency && 
                                disputeDetails.released.currency + " " + currencyFormat(disputeDetails.released.currency, disputeDetails.released.amount)) || "No Released Amount"
                        }
                    </Grid>
                    {/* Deducted Amount */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Deducted Amount:</Typography>
                        {
                                (disputeDetails.deducted && disputeDetails.deducted.amount && disputeDetails.deducted.currency && 
                                disputeDetails.deducted.currency + " " + currencyFormat(disputeDetails.deducted.currency, disputeDetails.deducted.amount)) || "No Deducted Amount"
                        }
                    </Grid>
                    {/* Disputes */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute:</Typography>
                         ({disputeDetails.liberalizeDisputeCategoryCode}) {disputeDetails.liberalizeDisputeCategory} 
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Requirements:</Typography>
                        {disputes && disputes.length > 0 && disputeDetails.liberalizeDisputeCategory && disputes.map((eachDispute) => {
                            if (eachDispute.value.code == disputeDetails.liberalizeDisputeCategoryCode) {
                                if (eachDispute.value.required) return eachDispute.value.required.join("\n")
                            }
                        })}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Description:</Typography>
                        {disputes && disputes.length > 0 && disputeDetails.liberalizeDisputeCategory && disputes.map((eachDispute) => {
                            if (eachDispute.value.code == disputeDetails.liberalizeDisputeCategoryCode) {
                                return eachDispute.value.description
                            }
                        })}
                    </Grid>
                    {/* Product Service Type */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Product Service Type:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.productServiceType) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.productServiceType = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="PdtSvcType"
                        >
                            {
                                productServiceType && productServiceType.length > 0 && productServiceType.map((pdtSvc, index) => {
                                    if (pdtSvc.value && pdtSvc.value.name) {
                                        return <MenuItem key={'pdtSvc'+index} value={pdtSvc.value.name}>{pdtSvc.value.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Payer Billing Address */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Payer Billing Address:
                        </Typography>
                        {/* Line 1 */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Line 1
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.line1 = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.billingAddress && disputeDetails.billingAddress.line1) || ""}
                        />
                        {/* Line 2 */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Line 2
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.line2 = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.billingAddress && disputeDetails.billingAddress.line2) || ""}
                        />
                        {/* State */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            State
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.state = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.billingAddress && disputeDetails.billingAddress.state) || ""}
                        />
                        {/* City */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            City
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.city = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.billingAddress && disputeDetails.billingAddress.city) || ""}
                        />
                        {/* Postal */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Postal
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.postal = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.billingAddress && disputeDetails.billingAddress.postal) || ""}
                        />
                        {/* Country */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Country
                        </Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.billingAddress && disputeDetails.billingAddress.country) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.country = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Country"
                        >
                            {
                                countries && countries.length > 0 && countries.map((cntry, index) => {
                                    if (cntry.value && cntry.value.country_alpha3_code) {
                                        return <MenuItem key={'billingAddressCountry'+index} value={cntry.value.country_alpha3_code}>{cntry.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Customer Name */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Customer Name
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.customerName = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.customerName) || ""}
                        />
                    </Grid>
                    {/* Customer Email */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Customer Email
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.customerEmail = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.customerEmail) || ""}
                        />
                    </Grid>
                    {/* Customer IP Address */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Customer IP Address
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.customerPurchaseIp = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.customerPurchaseIp) || ""}
                        />
                    </Grid>
                    {/* Merchant Customer Signature Files */}
                    {disputeEvidences.includes("merchantCustomerSignature") && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Customer Signature</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantCustomerSignature && disputeDetails.merchantCustomerSignature.map((eachFile, fileIndex) => {
                            return <div key={'merchantCustomerSignature'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantCustomerSignature = currdisputeDetails.merchantCustomerSignature.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantCustomerSignature')}} id={`merchantCustomerSignature-button-file`} type="file" />
                        <label htmlFor="merchantCustomerSignature-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Customer Communication Files */}
                    {disputeEvidences.includes("merchantCustomerCommunication") && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Customer Communication</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantCustomerCommunication && disputeDetails.merchantCustomerCommunication.map((eachFile, fileIndex) => {
                            return <div key={'merchantCustomerCommunication'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantCustomerCommunication = currdisputeDetails.merchantCustomerCommunication.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantCustomerCommunication')}} id={`merchantCustomerCommunication-button-file`} type="file" />
                        <label htmlFor="merchantCustomerCommunication-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Receipt */}
                    {<Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Receipt:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                                className={classes.select}
                            value={(disputeDetails && disputeDetails.merchantReceipt && disputeDetails.merchantReceipt.channel) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantReceipt) currDisputeDetails.merchantReceipt = { channel: '', documents: []}
                                currDisputeDetails.merchantReceipt.channel = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="merchantReceipt"
                        >
                            {
                                ['SMS', 'EMAIL', 'PRINT_OUT', 'WHATSAPP', 'OTHERS'].map((channels, index) => {
                                    if (channels) {
                                        return <MenuItem key={'channels'+index} value={channels}>{channels}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>}
                    {/* Merchant Receipt Files */}
                    {<Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Receipt Documents</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantReceipt && disputeDetails.merchantReceipt.documents && disputeDetails.merchantReceipt.documents.map((eachFile, fileIndex) => {
                            return <div key={'merchantReceipt'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.merchantReceipt) currdisputeDetails.merchantReceipt = { channel: '', documents: []}
                                        currdisputeDetails.merchantReceipt.documents = currdisputeDetails.merchantReceipt.documents.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantReceipt')}} id={`merchantReceipt-button-file`} type="file" />
                        <label htmlFor="merchantReceipt-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Product Service Description */}
                    {disputeEvidences.includes("merchantProductServiceDescription") && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Product Service Description:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantProductServiceDescription && disputeDetails.merchantProductServiceDescription.description) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantProductServiceDescription) currDisputeDetails.merchantProductServiceDescription = { documents: [], description: ''}
                                currDisputeDetails.merchantProductServiceDescription.description = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>}
                    {/* Merchant Product Service Description Files */}
                    {disputeEvidences.includes("merchantProductServiceDescription") && <Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Product Service Description Documents</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantProductServiceDescription && disputeDetails.merchantProductServiceDescription.documents && disputeDetails.merchantProductServiceDescription.documents.map((eachFile, fileIndex) => {
                            return <div key={'merchantProductServiceDescription'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.merchantProductServiceDescription) currdisputeDetails.merchantProductServiceDescription = { documents: [], description: ''}
                                        currdisputeDetails.merchantProductServiceDescription.documents = currdisputeDetails.merchantProductServiceDescription.documents.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantProductServiceDescription')}} id={`merchantProductServiceDescription-button-file`} type="file" />
                        <label htmlFor="merchantProductServiceDescription-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Refund Policy File */}
                    {disputeEvidences.includes("merchantRefundPolicy") && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Refund Policy Document</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantRefundPolicy && disputeDetails.merchantRefundPolicy.key && <div key={'merchantRefundPolicy'}>
                                <Typography component={'span'}>{disputeDetails.merchantRefundPolicy.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantRefundPolicy = {}
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(disputeDetails.merchantRefundPolicy.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        }
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantRefundPolicy')}} id={`merchantRefundPolicy-button-file`} type="file" />
                        <label htmlFor="merchantRefundPolicy-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Refund Policy Disclosure Description */}
                    {disputeEvidences.includes("merchantRefundPolicyDisclosure") && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Refund Policy Disclosure Description:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantRefundPolicyDisclosure && disputeDetails.merchantRefundPolicyDisclosure.description) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantRefundPolicyDisclosure) currDisputeDetails.merchantRefundPolicyDisclosure = { documents: [], description: ''}
                                currDisputeDetails.merchantRefundPolicyDisclosure.description = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>}
                    {/* Merchant Refund Policy Disclosure Description Files */}
                    {disputeEvidences.includes("merchantRefundPolicyDisclosure") && <Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Refund Policy Disclosure Description Documents</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantRefundPolicyDisclosure && disputeDetails.merchantRefundPolicyDisclosure.documents && disputeDetails.merchantRefundPolicyDisclosure.documents.map((eachFile, fileIndex) => {
                            return <div key={'merchantRefundPolicyDisclosure'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.merchantRefundPolicyDisclosure) currdisputeDetails.merchantRefundPolicyDisclosure = { documents: [], description: ''}
                                        currdisputeDetails.merchantRefundPolicyDisclosure.documents = currdisputeDetails.merchantRefundPolicyDisclosure.documents.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantRefundPolicyDisclosure')}} id={`merchantRefundPolicyDisclosure-button-file`} type="file" />
                        <label htmlFor="merchantRefundPolicyDisclosure-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Refund Refusal Explanation */}
                    {disputeEvidences.includes("merchantRefundRefusalExplanation") && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Refund Refusal Explanation:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantRefundRefusalExplanation) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantRefundRefusalExplanation = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>}
                    {/* Merchant Duplicate Charge IDs */}
                    {disputeEvidences.includes("merchantDuplicateChargeExplanation") && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Duplicate Charge IDs:</Typography>
                        <Grid container>
                            {disputeDetails && disputeDetails.merchantDuplicateChargeIds && disputeDetails.merchantDuplicateChargeIds.length > 0 && disputeDetails.merchantDuplicateChargeIds.map((eachCb, cbIndex) => {
                                return <Grid key={'merchantDuplicateChargeIds'+cbIndex} item md={12} xs={12} sm={12}>
                                    {eachCb} 
                                    <IconButton
                                        disabled={disputeDetails.isResolved}
                                        onClick={() => { 
                                            let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            currdisputeDetails.merchantDuplicateChargeIds = currdisputeDetails.merchantDuplicateChargeIds.filter((targetCb, idx) => {
                                                if (idx == cbIndex) return false
                                                return true
                                            })
                                            setDisputeDetails(currdisputeDetails)
                                        }}
                                        component="span"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            })}
                            <Grid item md={7} xs={7} sm={7}>
                                <OutlinedInputMod
                                    placeholder={'Inpute Duplicate Charge IDs'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addDbId) || ''}
                                    onChange={(e) => setAddDbId(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={5} xs={5} sm={5}>
                                <IconButton
                                    disabled={disputeDetails.isResolved} 
                                    style={{color: '#68d0af'}} 
                                    aria-controls="simple-menu" 
                                    aria-haspopup="true"
                                    onClick={() => {
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.merchantDuplicateChargeIds) currdisputeDetails.merchantDuplicateChargeIds = []
                                        currdisputeDetails.merchantDuplicateChargeIds.push(addDbId)
                                        setDisputeDetails(currdisputeDetails)
                                    }}
                                >
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>}
                    {/* Merchant Duplicate Charge Explanation */}
                    {disputeEvidences.includes("merchantDuplicateChargeExplanation") && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Duplicate Charge Explanation:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantDuplicateChargeExplanation) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantDuplicateChargeExplanation = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>}
                    {/* Merchant Duplicate Charge Documents Files */}
                    {disputeEvidences.includes("merchantDuplicateChargeDocuments") && <Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Duplicate Charge Documents</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantDuplicateChargeDocuments && disputeDetails.merchantDuplicateChargeDocuments.map((eachFile, fileIndex) => {
                            return <div key={'merchantDuplicateChargeDocuments'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantDuplicateChargeDocuments = currdisputeDetails.merchantDuplicateChargeDocuments.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantDuplicateChargeDocuments')}} id={`merchantDuplicateChargeDocuments-button-file`} type="file" />
                        <label htmlFor="merchantDuplicateChargeDocuments-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Service Date */}
                    {disputeEvidences.includes("merchantServiceDate") && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Service Date (UTC):</Typography>
                        <DateTextField
                            disabled={disputeDetails.isResolved} 
                            id="datefrom"
                            type="datetime-local"
                            onChange={(e) => {
                                let date = new Date(e.target.value)
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantServiceDate = date.toISOString()
                                setDisputeDetails(currDisputeDetails)
                            }}
                            defaultValue={(disputeDetails.merchantServiceDate && moment.utc(disputeDetails.merchantServiceDate).local().format('YYYY-MM-DDTHH:mm:ss')) || ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div>
                            {disputeDetails.merchantServiceDate && new Date(disputeDetails.merchantServiceDate).toString()}
                        </div>
                    </Grid>}
                    {/* Merchant Service Documentations Files */}
                    {disputeEvidences.includes("merchantServiceDocumentation") && <Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Service Documentations:</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantServiceDocumentation && disputeDetails.merchantServiceDocumentation.map((eachFile, fileIndex) => {
                            return <div key={'merchantServiceDocumentation'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantServiceDocumentation = currdisputeDetails.merchantServiceDocumentation.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantServiceDocumentation')}} id={`merchantServiceDocumentation-button-file`} type="file" />
                        <label htmlFor="merchantServiceDocumentation-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Shipping Date */}
                    {disputeEvidences.includes("merchantShippingDate") && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Shipping Date (UTC):</Typography>
                        <DateTextField
                            disabled={disputeDetails.isResolved} 
                            id="datefrom"
                            type="datetime-local"
                            onChange={(e) => {
                                let date = new Date(e.target.value)
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantShippingDate = date.toISOString()
                                setDisputeDetails(currDisputeDetails)
                            }}
                            defaultValue={(disputeDetails.merchantShippingDate && moment.utc(disputeDetails.merchantShippingDate).local().format('YYYY-MM-DDTHH:mm:ss')) || "2017-05-24T10:30"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div>
                            {disputeDetails.merchantShippingDate && new Date(disputeDetails.merchantShippingDate).toString()}
                        </div>
                    </Grid>}
                    {/* Merchant Shipping Documentation Files */}
                    {disputeEvidences.includes("merchantShippingDocumentation") && <Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Shipping Documentations:</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantShippingDocumentation && disputeDetails.merchantShippingDocumentation.map((eachFile, fileIndex) => {
                            return <div key={'merchantShippingDocumentation'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantShippingDocumentation = currdisputeDetails.merchantShippingDocumentation.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantShippingDocumentation')}} id={`merchantShippingDocumentation-button-file`} type="file" />
                        <label htmlFor="merchantShippingDocumentation-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Shipping Address */}
                    {disputeEvidences.includes("merchantShippingAddress") && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Merchant Shipping Address:
                        </Typography>
                        {/* Line 1 */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Line 1
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.line1 = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.line1) || ""}
                        />
                        {/* Line 2 */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Line 2
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.line2 = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.line2) || ""}
                        />
                        {/* State */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            State
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.state = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.state) || ""}
                        />
                        {/* City */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            City
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.city = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.city) || ""}
                        />
                        {/* Postal */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Postal
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.postal = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.postal) || ""}
                        />
                        {/* Country */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Country
                        </Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.country) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.country = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Country"
                        >
                            {
                                countries && countries.length > 0 && countries.map((cntry, index) => {
                                    if (cntry.value && cntry.value.country_alpha3_code) {
                                        return <MenuItem key={'merchantShippingAddressCountry'+index} value={cntry.value.country_alpha3_code}>{cntry.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>}
                    {/* Merchant Shipping Couriers */}
                    {disputeEvidences.includes("merchantShippingCouriers") && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Shipping Couriers:</Typography>
                        <Grid container spacing={1}>
                            {disputeDetails && disputeDetails.merchantShippingCouriers && disputeDetails.merchantShippingCouriers.length > 0 && disputeDetails.merchantShippingCouriers.map((eachCourrier, cbIndex) => {
                                return <Grid key={'merchantShippingCouriers'+cbIndex} item md={12} xs={12} sm={12}>
                                    Name: {eachCourrier.name} Tracking No.:{eachCourrier.trackingNumber}
                                    <IconButton
                                        disabled={disputeDetails.isResolved}
                                        onClick={() => { 
                                            let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            currdisputeDetails.merchantShippingCouriers = currdisputeDetails.merchantShippingCouriers.filter((targetCb, idx) => {
                                                if (idx == cbIndex) return false
                                                return true
                                            })
                                            setDisputeDetails(currdisputeDetails)
                                        }}
                                        component="span"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            })}
                            <Grid item md={4} xs={4} sm={4}>
                                <OutlinedInputMod
                                    placeholder={'Input Name Of Courier'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addCourierName) || ''}
                                    onChange={(e) => setAddCourierName(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <OutlinedInputMod
                                    placeholder={'Input Tracking Number'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addTrackingNumber) || ''}
                                    onChange={(e) => setAddTrackingNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={3} xs={3} sm={3}>
                                <IconButton
                                    disabled={disputeDetails.isResolved} 
                                    style={{color: '#68d0af'}} 
                                    aria-controls="simple-menu" 
                                    aria-haspopup="true"
                                    onClick={() => {
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        console.log('currdisputeDetails 1-> ', currdisputeDetails)
                                        if (!currdisputeDetails.merchantShippingCouriers) currdisputeDetails.merchantShippingCouriers = []
                                        console.log('currdisputeDetails 2-> ', currdisputeDetails)
                                        currdisputeDetails.merchantShippingCouriers.push({ name: addCourierName, trackingNumber: addTrackingNumber})
                                        console.log('currdisputeDetails 3-> ', currdisputeDetails)
                                        setDisputeDetails(currdisputeDetails)
                                    }}
                                >
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>}
                    {/* Merchant Uncategorized Text */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Uncategorized Text:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantUncategorizedText) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantUncategorizedText = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>
                    {/* Merchant Uncategorized File */}
                    {<Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Uncategorized Files:</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantUncategorizedFile && disputeDetails.merchantUncategorizedFile.map((eachFile, fileIndex) => {
                            return <div key={'merchantUncategorizedFile'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantUncategorizedFile = currdisputeDetails.merchantUncategorizedFile.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantUncategorizedFile')}} id={`merchantUncategorizedFile-button-file`} type="file" />
                        <label htmlFor="merchantUncategorizedFile-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Access Activity Logs File */}
                    {disputeEvidences.includes("merchantAccessActivityLogs") && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Access Activity Logs Files:</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantAccessActivityLogs && disputeDetails.merchantAccessActivityLogs.map((eachFile, fileIndex) => {
                            return <div key={'merchantAccessActivityLogs'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantAccessActivityLogs = currdisputeDetails.merchantAccessActivityLogs.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantAccessActivityLogs')}} id={`merchantAccessActivityLogs-button-file`} type="file" />
                        <label htmlFor="merchantAccessActivityLogs-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Cancellation Policy File */}
                    {disputeEvidences.includes("merchantCancellationPolicy") && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Cancellation Policy Document</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantCancellationPolicy && disputeDetails.merchantCancellationPolicy.key && <div key={'merchantCancellationPolicy'}>
                                <Typography component={'span'}>{disputeDetails.merchantCancellationPolicy.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantCancellationPolicy = {}
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(disputeDetails.merchantCancellationPolicy.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        }
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantCancellationPolicy')}} id={`merchantCancellationPolicy-button-file`} type="file" />
                        <label htmlFor="merchantCancellationPolicy-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Cancellation Policy Disclosure Description */}
                    {disputeEvidences.includes("merchantCancellationPolicyDisclosure") && <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Cancellation Policy Disclosure Description:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantCancellationPolicyDisclosure && disputeDetails.merchantCancellationPolicyDisclosure.description) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantCancellationPolicyDisclosure) currDisputeDetails.merchantCancellationPolicyDisclosure = { documents: [], description: ''}
                                currDisputeDetails.merchantCancellationPolicyDisclosure.description = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>}
                    {/* Merchant Cancellation Policy Disclosure Description Files */}
                    {disputeEvidences.includes("merchantCancellationPolicyDisclosure") && <Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Cancellation Policy Disclosure Description Documents</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantCancellationPolicyDisclosure && disputeDetails.merchantCancellationPolicyDisclosure.documents && disputeDetails.merchantCancellationPolicyDisclosure.documents.map((eachFile, fileIndex) => {
                            return <div key={'merchantCancellationPolicyDisclosure'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.merchantCancellationPolicyDisclosure) currdisputeDetails.merchantCancellationPolicyDisclosure = { documents: [], description: ''}
                                        currdisputeDetails.merchantCancellationPolicyDisclosure.documents = currdisputeDetails.merchantCancellationPolicyDisclosure.documents.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantCancellationPolicyDisclosure')}} id={`merchantCancellationPolicyDisclosure-button-file`} type="file" />
                        <label htmlFor="merchantCancellationPolicyDisclosure-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={loading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Cancellation Rebuttal */}
                    {disputeEvidences.includes("merchantCancellationRebuttal") && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Cancellation Rebuttal:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantCancellationRebuttal) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantCancellationRebuttal = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>}
                    <Grid item md={11} xs={11} sm={11}>
                        {disputeDetails && disputeDetails.id && !disputeDetails.isResolved && <ButtonMod onClick={() => {
                            if (disputeDetails.adminFeesCounter && disputeDetails.adminFeesCounter.rate) {
                                disputeDetails.adminFeesCounter.rate = parseFloat(disputeDetails.adminFeesCounter.rate)
                            }
                            if (disputeDetails.disputeCounter && disputeDetails.disputeCounter.rate) {
                                disputeDetails.disputeCounter.rate = parseFloat(disputeDetails.disputeCounter.rate)
                            }
                            updateDispute({ disputeId: disputeDetails.id}, disputeDetails)
                            setDisputeDialog(false)
                            setDisputeDetails({})
                        }}>
                            SAVE
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Disputes</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={12} xs={12} sm={12}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Resolved? y/n</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchIsResolved) || ''}
                                            onChange={(e) => setSearchIsResolved(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody(null, true)
                                                postDisputesSearch(esSearchReqBody)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(params) => {
                                if (page >= params.page) return
                                setPage(params.page);
                                if (params.page <= loadedPage) return
                                setLoadedPage(loadedPage + 1)
                                if (!nextToken) {
                                    return
                                }
                                let esSearchReqBody = constructSearchReqBody()
                                postDisputesSearchAfter(esSearchReqBody)
                            }}
                            hideFooterRowCount
                            rowCount={nextToken ? 9999999999999 : (hits.length)}
                            rowsPerPageOptions={[10]}
                            pageSize={13}
                            pagination
                            columns={[
                                { 
                                    field: 'id',
                                    headerName: 'Dispute ID',
                                    width: 140,
                                    valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                                },
                                { 
                                    field: 'state',
                                    headerName: 'State',
                                    width: 210,
                                    valueGetter: (params) => {
                                        let targetState = (params && params.row && params.row.state)
                                        return `${((targetState === "CREATED" || targetState === "PENDING_MERCHANT_ACTION") && targetState) || "PENDING_LIBERALIZE_ADMIN_ACTION"}`
                                    },
                                },
                                { 
                                    field: 'type',
                                    headerName: 'Type',
                                    width: 140,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.type) || ""}`
                                    },
                                },
                                { 
                                    field: 'isResolved',
                                    headerName: 'Resolved',
                                    width: 140,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.isResolved && "YES") || "NO"}`
                                    },
                                },
                                { 
                                    field: 'resolveType',
                                    headerName: 'Resolved Type',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.resolveType) || ""}`
                                    },
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At (UTC)',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'updatedAt',
                                    headerName: 'Updated At (UTC)',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.updatedAt) {
                                            let date = new Date(params.row.updatedAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                            ]}
                            rows={hits}
                            onRowClick={async (rowParams) => {
                                console.log('rowParams.row -> ', rowParams.row);
                                if(!rowParams.row.id) {
                                    alert('No ID Found')
                                    return
                                }
                                setLoading(true)
                                let targetDispute = await fetchApi(environment,
                                    '/disputes/:disputeId',
                                    'get',
                                    {disputeId: rowParams.row.id},
                                )
                                const { data } = targetDispute
                                if (!data.id) {
                                    alert('Not Found')
                                    return
                                }
                                setDisputeDetails(data)
                                setLoading(false)
                                setDisputeDialog(true)
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default DisputesManagement