import React, { useEffect, useLayoutEffect } from 'react'
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import axios from 'axios'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Link } from "react-router-dom"
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { useHistory } from "react-router-dom";
import rocket from '../../src/rocket.png';

const ButtonMod = withStyles({
    root: {
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
    },
})(Button);


const TextFieldMod = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(TextField);

const useStyles = makeStyles(theme => ({
    colorBanner: {
        background: `linear-gradient(210deg,#8CD987 0%,#1FA595 100%)`,
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'initial',
        minHeight: '22em',
        marginBottom: '-14em'
    },
    companylist: {
        margin: '0 auto',
        position: 'relative',
        maxWidth: '60%'
    },
    title: {
        fontWeight: 560,
        fontSize: '1.5em',
        color: '#ffffff',
        marginBottom: '1em',
    },
    name: {
        overflowWrap: 'break-word',
        fontWeight: 490,
        fontSize: '1.5em',
    },
    card: {
        display: 'flex',
        minHeight: '17em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        position: 'absolute',
        bottom: 0,
    },
    add: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        textTransform: 'none'
    },
    closeBtn: {
        zIndex: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    formContainer: {
        margin: '2em'
    },
    bannerRight: {
        zIndex: '0',
        position: 'absolute',
        height: '100%',
        minWidth: '28em',
        right: 0,
        background: `linear-gradient(210deg,#8CD987 0%,#1FA595 100%)`,
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'initial',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    imageRight: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: '-1em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    formTitle: {
        margin: '2em',
        maxWidth: '10em',
        fontSize: '1.2rem',
        '@media (min-width:600px)': {
            fontSize: '1.5rem',
            maxWidth: '28em',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 49,
        },
    },
    greenColor: {
        color: '#68d0af' // 1fa595
    },
    coName: {
        fontSize: '1rem',
        '@media (min-width:600px)': {
            fontSize: '1rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 42,
        },
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const Main = (props) => {
    const classes = useStyles(props)
    const { openDrawer, toggleDrawer, user, organizations, getUserOrganizations, 
        organization, createOrganization } = props
    const [createCompanyOpen, setCreateCompanyOpen] = React.useState(false);
    const [cName, setCName] = React.useState('');
    const history = useHistory();
    // before render events, one time run
    useLayoutEffect(() => {
        if (openDrawer) toggleDrawer() // close it if its open
      },[])
    const orgID = (organization && organization.id) || ''
    useEffect(() => {
        if (orgID) {
            history.push({
                pathname: `/company/${orgID}`,
                state: {organizationId: orgID}
            })
        }
    },[orgID])
    // click events
    const CreateCompany = () => {
        if (cName.length < 1) return
        setCreateCompanyOpen(false)
        createOrganization({
            name: cName
        })
    }
    const companyDetail = (orgId) => {
        history.push({
            pathname: `/company/${orgId}`,
            state: {organizationId: orgId}
        })
    }
    return (
    <main>
        <Dialog fullScreen open={createCompanyOpen} onClose={()=> {setCreateCompanyOpen(false)}} TransitionComponent={Transition}>
            <span className={classes.bannerRight}></span>
            <span  className={classes.imageRight}><img alt='totheMoon' src={rocket}/></span>
            <Container className={classes.formContainer} maxWidth="lg">
                <CloseIcon className={classes.closeBtn} onClick={()=> {setCreateCompanyOpen(false)}}/>
                <Typography className={classes.formTitle} variant="h2" component="h2">
                    Let's start with the name of your company
                    <TextFieldMod
                        onChange={e => setCName(e.target.value)}
                        label="Company name"
                        placeholder="Enter your company name"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                            input: classes.greenColor,
                        }}
                        InputProps={{
                            classes: {
                                input: classes.coName,
                            },
                        }}
                    />
                <ButtonMod size={'large'} onClick={()=> {CreateCompany()}}>
                    Submit
                </ButtonMod>
                </Typography>
            </Container>
        </Dialog>
        <div className={classes.colorBanner}></div>
        <div className={classes.companylist}>
            <div className={classes.title}>Your Liberalize Companies</div>
            <div>
            <Grid container spacing={3}>
                {/* Add company */}
                <Grid item md={4} xs={12} sm={6}>
                    <Card  className={classes.card}>
                        <Button className={classes.add} onClick={()=> {setCreateCompanyOpen(true)}}>
                            <AddIcon/><Typography>Add company</Typography>
                        </Button>
                    </Card>
                </Grid>
                {
                    organizations && organizations.length > 0 && organizations.map((eachOrg, index) => {
                        return <Grid key={index} item md={4} xs={12} sm={6} onClick={() => {companyDetail(eachOrg.organizationId)}}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography className={classes.name} gutterBottom>
                                    {eachOrg.organizationName}
                                </Typography>
                                <Typography className={classes.id} color="textSecondary">
                                    Company ID:
                                </Typography>
                                <Typography className={classes.id} color="textSecondary">
                                    {eachOrg.organizationId}
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.action}>
                                image of integrations or payment modes
                            </CardActions>
                        </Card>
                    </Grid>
                    })
                }
            </Grid>
            </div>
        </div>
    </main>
    )
}

export default Main