import React, { useEffect, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import GeneralSettings from './GeneralSettings';
import BankDetails from './BankDetails';
import OtherKycDocs from './OtherKycDocs';
import UserPermissions from './UserPermissions';
import Owners from './Owners';

const axios = require('axios');
const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);

const PaperMod = withStyles({
    root: {
        marginTop: '1em',
        padding: '1em',
    },
})(Paper);

const TabsMod = withStyles({
    root: {
        '& .MuiTabs-indicator': {
            backgroundColor: '#1fa595',
        },
    },
})(Tabs);

const TabMod = withStyles({
    root: {
        '&.Mui-selected': {
            color: '#1fa595',
        },
    },
})(Tab);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    label: {
        color: '#7f7f7f'
    }
}));

const Settings = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, appbarIsWhiteFont, toggleAppbarWhiteFont, getOrganization,
        patchOrganization, getEnumeration, enumeration, usermgtread, bankdetailsread, ownersread, generalread,
        usermgtwrite, bankdetailswrite, ownerswrite, generalwrite, organization, getRoles, updateRole, deleteRole, createRole,
        getOrgUsers, organizationUsers, organizationRoles, updateOrgUserRole, inviteOrgUser, deleteOrgUser, environment, location,
        updateOrganizationReady, userInviteSuccess, resetUserInviteSuccess
    } = props
    const [value, setValue] = useState(0);
    const [mcc, setMcc] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [countries, setCountries] = useState([]);
    const [entities, setEntityType] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const theme = useTheme();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
        if (appbarIsWhiteFont) toggleAppbarWhiteFont(false)
    },[]);
    useEffect(() => {
        if (enumeration && enumeration.country) setCountries(enumeration.country)
        if (enumeration && enumeration.mcc) setMcc(enumeration.mcc)
        if (enumeration && enumeration.currency) setCurrencies(enumeration.currency)
        if (enumeration && enumeration.legalentitytype) setEntityType(enumeration.legalentitytype)
        if (enumeration && enumeration.nationality) setNationalities(enumeration.nationality)

    }, [enumeration]);
    useEffect(() => {
        if (!organization.id) {
            const url = window.location.href
            let urlArr = url.split('/')
            let organizationID = urlArr[urlArr.length - 2]
            getOrganization({organizationId: organizationID})
        }
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'mcc'})
        getEnumeration({enumGroup: 'currency'})
        getEnumeration({enumGroup: 'legalentitytype'})
        getEnumeration({enumGroup: 'nationality'})
        if (location && location.state && location.state.permissionTab) {
            setValue(parseInt(location.state.permissionTab))
        }
    },[environment])
    // console.log('currenciessss => ', currencies);
    // console.log('entities => ', entities);
    return (
    <main className={classes.topMargin}>
    <Typography variant="h4" className={classes.Title}>Settings</Typography>
    <AppBarMod position="static" color="default">
        <TabsMod
          value={value}
          onChange={handleChange}
        //   indicatorColor="primary"
          textColor="primary"
        //   variant="fullWidth"
          aria-label="full width tabs example"
        >
          <TabMod label="General" {...a11yProps(0)} />
          <TabMod label="Owners" {...a11yProps(1)} />
          <TabMod label="Bank Details" {...a11yProps(2)} />
          <TabMod label="Other Supporting Documents" {...a11yProps(3)} />
          <TabMod label="Users and permissions" {...a11yProps(4)} />
        </TabsMod>
      </AppBarMod>
        <Container>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <GeneralSettings
                    environment={environment}
                    generalread={generalread} 
                    generalwrite={generalwrite}
                    organization={organization}
                    value={value}
                    patchOrganization={patchOrganization}
                    updateOrganizationReady={updateOrganizationReady}
                    mcc={mcc}
                    countries={countries}
                    entities={entities}
                />
                <Owners
                    nationalities={nationalities}
                    entities={entities}
                    environment={environment}
                    ownersread={ownersread}
                    ownerswrite={ownerswrite}
                    value={value}
                    mcc={mcc}
                    patchOrganization={patchOrganization}
                    organization={organization}
                    countries={countries}
                />
                <BankDetails
                    environment={environment}
                    bankdetailsread={bankdetailsread}
                    bankdetailswrite={bankdetailswrite}
                    value={value}
                    patchOrganization={patchOrganization}
                    organization={organization}
                    countries={countries}
                    currencies={currencies}
                />
                <OtherKycDocs
                    environment={environment}
                    value={value}
                    patchOrganization={patchOrganization}
                    organization={organization}
                />
                <UserPermissions
                    deleteOrgUser={deleteOrgUser}
                    inviteOrgUser={inviteOrgUser}
                    updateOrgUserRole={updateOrgUserRole}
                    createRole={createRole}
                    deleteRole={deleteRole}
                    updateRole={updateRole}
                    getRoles={getRoles}
                    getOrgUsers={getOrgUsers}
                    organizationUsers={organizationUsers}
                    organizationRoles={organizationRoles}
                    value={value}
                    index={4}
                    organization={organization}
                    userInviteSuccess={userInviteSuccess}
                    resetUserInviteSuccess={resetUserInviteSuccess}
                />
            </SwipeableViews>
        </Container>
    </main>
    )
}

export default Settings