import { CALL_CUSTOMER_API } from '../app/middleware/callCustomerApi.js';

export const createCustomer = (params, reqBody) => ({
    types: ['POST_CUSTOMER_REQUEST', 'POST_CUSTOMER_SUCCESS', 'POST_CUSTOMER_FAILURE'],
    [CALL_CUSTOMER_API]: '/organizations/:organizationId/customers',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const getCustomer = (params) => ({
    types: ['GET_CUSTOMER_REQUEST', 'GET_CUSTOMER_SUCCESS', 'GET_CUSTOMER_FAILURE'],
    [CALL_CUSTOMER_API]: '/organizations/:organizationId/customers/:customerId',
    method: 'get',
    params: params
})

export const updateCustomer = (params, reqBody) => ({
    types: ['PATCH_CUSTOMER_REQUEST', 'PATCH_CUSTOMER_SUCCESS', 'PATCH_CUSTOMER_FAILURE'],
    [CALL_CUSTOMER_API]: '/organizations/:organizationId/customers/:customerId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const deleteCustomer = (params) => ({
    types: ['DELETE_CUSTOMER_REQUEST', 'DELETE_CUSTOMER_SUCCESS', 'DELETE_CUSTOMER_FAILURE'],
    [CALL_CUSTOMER_API]: '/organizations/:organizationId/customers/:customerId',
    method: 'delete',
    params: params
})

export const postCustomersSearch = (params, reqBody) => ({
    types: ['POST_CUSTOMERS_SEARCH_REQUEST', 'POST_CUSTOMERS_SEARCH_SUCCESS', 'POST_CUSTOMERS_SEARCH_FAILURE'],
    [CALL_CUSTOMER_API]: '/organizations/:organizationId/customers-search',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const postCustomersSearchAfter = (params, reqBody) => ({
    types: ['POST_CUSTOMERS_SEARCH_AFTER_REQUEST', 'POST_CUSTOMERS_SEARCH_AFTER_SUCCESS', 'POST_CUSTOMERS_SEARCH_AFTER_FAILURE'],
    [CALL_CUSTOMER_API]: '/organizations/:organizationId/customers-search',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const clearCustomersSearch = () => ({
    type: 'CLEAR_CUSTOMERS_SEARCH'
})

export const updateCustomersSearch = (reqBody) => ({
    type: 'UPDATE_CUSTOMERS_SEARCH',
    reqBody: reqBody
})

export const removeDeletedCustomerHit = (reqBody) => ({
    type: 'REMOVE_DELETED_CUSTOMER_HIT',
    reqBody: reqBody
})