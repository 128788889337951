import { CALL_BALS_REPORT_API } from '../app/middleware/callBalsReportApi.js';

import { CALL_BALS_APPSYNC_API } from '../app/middleware/callBalsAppSync.js';
import { CALL_BILLINGS_APPSYNC_API } from '../app/middleware/callBillingsAppSync.js';

export const postBalancesSearch = (reqBody) => ({
    types: ['POST_BALANCES_SEARCH_REQUEST', 'POST_BALANCES_SEARCH_SUCCESS', 'POST_BALANCES_SEARCH_FAILURE'],
    [CALL_BALS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postBalancesSearchAfter = (reqBody) => ({
    types: ['POST_BALANCES_SEARCH_AFTER_REQUEST', 'POST_BALANCES_SEARCH_AFTER_SUCCESS', 'POST_BALANCES_SEARCH_AFTER_FAILURE'],
    [CALL_BALS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const getPayables = (params) => ({
    types: ['GET_PAYABLES_REQUEST', 'GET_PAYABLES_SUCCESS', 'GET_PAYABLES_FAILURE'],
    [CALL_BALS_REPORT_API]: '/payables/:organizationId',
    method: 'get',
    params: params,
})

export const clearBalancesSearch = () => ({
    type: 'CLEAR_BALANCES_SEARCH'
})

export const updateBalancesSearch = (reqBody) => ({
    type: 'UPDATE_BALANCES_SEARCH',
    reqBody: reqBody
})

export const postBillingsSearch = (reqBody) => ({
    types: ['POST_BILLINGS_SEARCH_REQUEST', 'POST_BILLINGS_SEARCH_SUCCESS', 'POST_BILLINGS_SEARCH_FAILURE'],
    [CALL_BILLINGS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postBillingsSearchAfter = (reqBody) => ({
    types: ['POST_BILLINGS_SEARCH_AFTER_REQUEST', 'POST_BILLINGS_SEARCH_AFTER_SUCCESS', 'POST_BILLINGS_SEARCH_AFTER_FAILURE'],
    [CALL_BILLINGS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})