import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganization, getEnumeration, createProduct, getProduct, updateProduct, deleteProduct, postProductsSearch, postProductsSearchAfter, clearProductsSearch, updateProductsSearch, removeDeletedProductHit} from '../actions'
import ProductsManagement from '../components/ProductsManagement'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    productservicetypes: (state.misc && state.misc.enum && state.misc.enum.productservicetype) || [],
    productsvcsread: (state.organizations.role && state.organizations.role.productsvcsread) || false,
    productsvcswrite: (state.organizations.role && state.organizations.role.productsvcswrite) || false,
    organizationPos: state.organizations.organizationPos || [],
    search_after: (state.productManagement && state.productManagement.search_after) || [],
    hits: (state.productManagement && state.productManagement.hits) || [],
    totalHits: (state.productManagement && state.productManagement.totalHits) || [],
    environment: state.appstate.environment || 'staging',
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    createProduct: (params, reqBody) => dispatch(createProduct(params, reqBody)),
    getProduct: (params) => dispatch(getProduct(params)),
    updateProduct: (params, reqBody) => dispatch(updateProduct(params, reqBody)),
    deleteProduct: (params) => dispatch(deleteProduct(params)),
    clearProductsSearch: () => dispatch(clearProductsSearch()),
    updateProductsSearch: (reqBody) => dispatch(updateProductsSearch(reqBody)),
    removeDeletedProductHit: (reqBody) => dispatch(removeDeletedProductHit(reqBody)),
    postProductsSearch: (params, reqBody) => dispatch(postProductsSearch(params, reqBody)),
    postProductsSearchAfter: (params, reqBody) => dispatch(postProductsSearchAfter(params, reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsManagement)