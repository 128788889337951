import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getOrganization, getEnumeration, 
    GetOrgAccounts, GetOrgPos, postPaymentsSearch, postPaymentsSearchAfter, 
    clearPaymentsSearch, updatePaymentsSearch } from '../actions'
import PaymentsReport from '../components/PaymentsReport'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    appbarIsWhiteFont: state.appBars.appbarIsWhiteFont,
    organization: state.organizations.organization,
    enumeration: (state.misc.enum) || {},
    transactionsread: (state.organizations.role && state.organizations.role.transactionsread) || false,
    transactionswrite: (state.organizations.role && state.organizations.role.transactionswrite) || false,
    organizationPos: state.organizations.organizationPos || [],
    detailedPos: state.organizations.detailedPos || {},
    detailedPosRoutes: state.organizations.detailedPos || {},
    orgPmtSvcAccs: (state.organizations.orgPmtSvcAccs && state.organizations.orgPmtSvcAccs.accounts) || [],
    nextToken: state.paymentsReport.nextToken || "",
    hits: (state.paymentsReport && state.paymentsReport.hits) || [],
    environment: state.appstate.environment || 'staging',
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getOrganization: (params) => dispatch(getOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    GetOrgPos: (params, query) => dispatch(GetOrgPos(params, query)),
    GetOrgAccounts: (params) => dispatch(GetOrgAccounts(params)),
    postPaymentsSearch: (reqBody) => dispatch(postPaymentsSearch(reqBody)),
    postPaymentsSearchAfter: (reqBody) => dispatch(postPaymentsSearchAfter(reqBody)),
    clearPaymentsSearch: () => dispatch(clearPaymentsSearch()),
    updatePaymentsSearch: (reqBody) => dispatch(updatePaymentsSearch(reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentsReport)